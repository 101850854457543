export type Status = CardProps['status'];

import {
  CtaCircleLockSvg,
  CtaCirclePlaySvg,
  CtaCircleReplaySvg,
} from 'components/Screens/ContentScreens';
import { CardProps } from '..';

type IconComponent =
  | typeof CtaCircleReplaySvg
  | typeof CtaCirclePlaySvg
  | typeof CtaCircleLockSvg;

export const ICONS_BY_STATUS: Record<Status, IconComponent> = {
  COMPLETED: CtaCircleReplaySvg,
  NOT_STARTED: CtaCirclePlaySvg,
  LOCKED: CtaCircleLockSvg,
  IN_PROGRESS: CtaCirclePlaySvg,
};
