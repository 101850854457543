import React, { ReactElement, useContext } from 'react';
import { Platform, View } from 'react-native';
import { DistributionPlatformList } from 'components/DistributionPlatformList';
import {
  ScreenResponsiveLayoutContext,
  useTransformContentStyle,
} from 'components/ResponsiveLayout';
import { Text } from 'components/Text';
import { NavBarStyles } from 'components/Navigation/constants';
import styled from 'styled-components/native';

const VerticalScroll = styled.ScrollView.attrs({
  vertical: true,
  persistentScrollbar: true,
  contentContainerStyle: { flexGrow: 1 },
})`
  width: 100%;
  flex: 1;
`;

export const MobileWebGate = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const scaleFn = useTransformContentStyle();
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  if (
    screenWidth > 0 &&
    screenWidth <= NavBarStyles.navBarCollapseMobileWebBreakPointPx &&
    Platform.OS === 'web'
  ) {
    return (
      <VerticalScroll>
        <View
          style={{
            flexBasis: '100%',
            flex: 1,
            backgroundColor: 'white',
            paddingTop: scaleFn(120),
            paddingLeft: scaleFn(72),
            paddingRight: scaleFn(120),
          }}
        >
          <Text
            style={{
              fontSize: scaleFn(48),
              color: '#003366',
              fontWeight: '400',
            }}
          >
            Uh Oh!
          </Text>
          <Text
            style={{
              fontSize: scaleFn(32),
              color: '#003366',
              fontWeight: '400',
              marginTop: scaleFn(36),
            }}
          >
            Looks like this page is unavailable on mobile web.
          </Text>
          <Text
            style={{
              fontSize: scaleFn(32),
              color: '#003366',
              fontWeight: '400',
              marginTop: scaleFn(36),
            }}
          >
            Switch to a computer or the Sleepio App to get the full Sleepio
            Experience.
          </Text>
          <View
            style={{
              marginTop: scaleFn(60),
            }}
          >
            <DistributionPlatformList
              productName={'Sleepio'}
              showCaption={false}
            />
          </View>
        </View>
      </VerticalScroll>
    );
  }
  return children;
};
