import styled from 'styled-components/native';
import { Heading6, Heading2, Heading3 } from 'components/Heading';
import { SuperButton } from './components/SuperButton';

export const Button = styled(SuperButton)``;
Button.displayName = 'SleepDiary.Button';

export const Wrapper = styled.View`
  min-height: 200px;
`;
Wrapper.displayName = 'SleepDiary.Wrapper';

export const DateLabel = styled(Heading6)``;
DateLabel.displayName = 'SleepDiary.DateLabel';

export const Heading = styled(Heading2)``;
Heading.displayName = 'SleepDiary.Heading';

export const SectionHeading = styled(Heading3)``;
SectionHeading.displayName = 'SleepDiary.SectionHeading';

export const SectionWrapper = styled.View``;
SectionWrapper.displayName = 'SleepDiary.SectionWrapper';
