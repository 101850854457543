import { ScaleValueProps } from 'components/Screens/SessionsScreen/utils/scaler';
import { PseudoTreeStructureProps } from 'lib/getNthChildProps';
import { wrapperStyles } from '../../components/Card/components/CardPresentation/styled';

export type CardStyleCallbackArgs = {
  isMobile?: boolean;
  isFirstChild: boolean;
  isLastChild: boolean;
  screenWidth: number;
} & ScaleValueProps &
  PseudoTreeStructureProps;

type CardStyleCallback = (props: CardStyleCallbackArgs) => string;

const cardStyleCallback: CardStyleCallback = ({
  scaleValue,
  screenWidth,
  isMobile,
  isFirstChild,
  isLastChild,
}) => {
  if (isMobile) {
    // Mobile
    if (isFirstChild) {
      const cardWidth = wrapperStyles.width + wrapperStyles.padding;
      const offset = (screenWidth - cardWidth) / 2;
      return `
        margin-left: ${offset * scaleValue}px;
      `;
    } else if (isLastChild) {
      return `
        margin-right: ${65 * scaleValue}px;
      `;
    }
  } else {
    // Desktop
    if (isFirstChild) {
      return `
        margin-left: ${65 * scaleValue}px;
      `;
    } else if (isLastChild) {
      return `
        margin-right: ${65 * scaleValue}px;
      `;
    }
  }
  return ``;
};

export { cardStyleCallback };
