import React, { ReactElement, ReactNode, useContext } from 'react';
import {
  ScreenResponsiveLayoutContext,
  MediaResponsiveLayoutProvider,
  ScalingContextProvider,
  ScalingContext,
} from '../providers';
import { View } from 'react-native';
import { useGetMediaDimensions } from '../hooks';
import styled from 'styled-components/native';

const CenterClipContainer = styled.View`
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
`;

const FullScreenContainer = styled(CenterClipContainer)``;

FullScreenContainer.displayName = 'FullScreenContainer';

const Container = ({
  children,
  scalingContext,
  testID,
}: {
  children?: ReactNode;
  scalingContext: ScalingContext;
  testID?: string;
}): ReactElement => {
  const { screenHeight, screenWidth } = useContext(
    ScreenResponsiveLayoutContext
  );
  const { mediaHeight, mediaWidth } = useGetMediaDimensions();

  return (
    <ScalingContextProvider scalingContext={scalingContext}>
      <FullScreenContainer
        testID={testID}
        style={{
          height: screenHeight,
          width: screenWidth,
        }}
      >
        <View
          style={{
            height: mediaHeight,
            width: mediaWidth,
            position: 'absolute',
            top: 0,
          }}
        >
          {children}
        </View>
      </FullScreenContainer>
    </ScalingContextProvider>
  );
};

export const ContainerWithContext = ({
  children,
  style,
  scalingContext,
  testID,
}: {
  children?: ReactNode;
  scalingContext: ScalingContext;
  style: {
    height: number;
    width: number;
  };
  testID?: string;
}): ReactElement => (
  <MediaResponsiveLayoutProvider height={style.height} width={style.width}>
    <Container scalingContext={scalingContext} testID={testID}>
      {children}
    </Container>
  </MediaResponsiveLayoutProvider>
);
