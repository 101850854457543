import React, { FunctionComponent } from 'react';
import { CloseButton, MediaBar } from './components';
import { MediaResponsiveLayoutProvider } from 'components/ResponsiveLayout';
import { MediaPlayerControls } from './MediaPlayerControls';
import { getMediaPropsFromSceneSetForScene } from 'lib/player/getMediaPropsFromSceneSetForScene';
import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';
import { SessionDetails } from 'components/Media/PlayerControls/SessionDetails';
import * as contentErrors from 'components/SceneSetView/components/contentErrors';
import { useQueryJumpToSceneSet } from 'lib/api/reactQueryHelpers';

export const PlayerControls: FunctionComponent = () => {
  const { sceneSetId, sceneId } = useSafeParams<SceneSetParams>();
  const { errorDetails } = contentErrors.useContext();
  const sceneSet = useQueryJumpToSceneSet(sceneSetId)?.data?.result
    ?.scene_set_json;

  if (!sceneSet || typeof errorDetails?.error !== 'undefined') {
    return null;
  }

  const { height, width } = getMediaPropsFromSceneSetForScene(
    sceneSet,
    sceneId || 0
  );

  return (
    <MediaResponsiveLayoutProvider height={height} width={width}>
      <MediaBar>
        <MediaPlayerControls />
        <SessionDetails />
      </MediaBar>
      <CloseButton />
    </MediaResponsiveLayoutProvider>
  );
};
