import React from 'react';
import { ErrorModalProps } from '../types';
import { ErrorModal as ErrorModalPresentational } from '../Presentational/ErrorModal';
import { useHistory } from 'cross-platform/react-router';
import { useSafeParams, SceneSetParams } from 'components/Routes/useSafeParams';
import { ErrorDetails } from 'lib/error';
import { RoleProps } from 'cross-platform/utils/roleProps';
import { useErrorModalContext } from './hooks/useErrorModalContext';
import { ErrorModalContext } from './components/ErrorModalContext';

type ProviderProps = { children: React.ReactNode };
export const ErrorModalProvider = ({
  children,
}: ProviderProps): JSX.Element => {
  const [errorDetails, setErrorDetails] = React.useState<
    ErrorDetails | undefined
  >();
  return (
    <ErrorModalContext.Provider value={{ errorDetails, setErrorDetails }}>
      {children}
    </ErrorModalContext.Provider>
  );
};

type ErrorModalWithContextProps = Partial<
  Omit<
    ErrorModalProps,
    'error' // from context
  >
>;
const ErrorModalWithContext = (
  props: ErrorModalWithContextProps & RoleProps
): JSX.Element => {
  const { errorDetails, setErrorDetails } = useErrorModalContext();
  const history = useHistory();
  const { productReference } = useSafeParams<SceneSetParams>();
  const defaultOnClose = () => {
    setErrorDetails?.(undefined);
    history.push(`/${productReference}/home`);
  };
  const onClose = props?.onClose || defaultOnClose;

  return (
    <ErrorModalPresentational {...props} {...errorDetails} onClose={onClose} />
  );
};

export { ErrorModalWithContext };
