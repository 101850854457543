import React, { ReactElement } from 'react';
import { GenericInputButtonProps } from './types';
import { StyleSheet } from 'react-native';
import { Check, Cross } from 'components/icons';
import { QuizAttribute } from 'components/constants';
import { getStylesForButtonStates } from 'lib/styles/inputStateStyles';
import { IconStyles } from 'components/icons/types';

const flatten = StyleSheet.flatten;

export const GenericInputIcon = ({
  quiz,
  style,
  size,
}: GenericInputButtonProps & {
  style: IconStyles;
  size: number;
}): ReactElement | null => {
  return quiz === QuizAttribute.CORRECT ? (
    <Check
      style={{
        ...flatten(style),
        color: getStylesForButtonStates('color')({ isActive: true }),
      }}
      size={size}
    />
  ) : quiz === QuizAttribute.INCORRECT ? (
    <Cross
      style={{
        ...flatten(style),
        color: getStylesForButtonStates('color')({ isActive: true }),
      }}
      size={size}
    />
  ) : null;
};
