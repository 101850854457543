import * as React from 'react';
import styled from 'styled-components/native';
import { Text } from 'components/sceneset-components/Text';
import { UniversalActionButton } from 'components/UniveralButtons';
import { ButtonSizes } from 'components/UniveralButtons';
import { Row } from 'components/layout/Grid';
import {
  scaler,
  yScalerWithOptions,
  ScaleValueProps,
  ScaleYValueProps,
} from 'components/Screens/SessionsScreen/utils/scaler';
import { DOMAIN } from './constants';

export const scalerVertical = yScalerWithOptions({ minScale: 0.6 });

export const HeadingRow = styled(Row)`
  width: 100%;
  justify-content: center;
`;
HeadingRow.displayName = `${DOMAIN}.HeadingRow`;

type HeadingTextProps = React.ComponentProps<typeof Text> &
  ScaleValueProps &
  ScaleYValueProps;

// IDEA Combine X or Y
export const HeadingText = styled(Text).attrs({
  // See getTextAlignmentConfig
  alignment: { horizontal: 'middle' },
})<HeadingTextProps>`
  color: #001948;
  font-size: ${scalerVertical(40)}px;
  max-width: ${scaler(312)}px;
  min-width: ${168}px;
  min-height: ${scalerVertical(80)}px;
  font-weight: 400;
`;
HeadingText.displayName = `${DOMAIN}.HeadingText`;

export const ButtonRow = styled(Row)`
  padding-top: ${scaler(24)}px;
  justify-content: center;
`;
ButtonRow.displayName = `${DOMAIN}.ButtonRow`;

type ButtonProps = React.ComponentProps<typeof UniversalActionButton> &
  ScaleValueProps &
  ScaleYValueProps;
export const Button = styled(UniversalActionButton).attrs({
  size: ButtonSizes.Small,
})<ButtonProps>`
  font-size: ${scalerVertical(20)}px;
  padding-bottom: ${scalerVertical(16)}px;
  padding-top: ${scalerVertical(16)}px;
  min-height: ${scalerVertical(56)}px;
  width: 100%;
`;
Button.displayName = `${DOMAIN}.Button`;

export { Grid } from './Grid';
