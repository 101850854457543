import { SceneSet, SceneChild } from '@bighealth/types';
import { uniq } from 'ramda';

export type RemoteURI = string;

/**
 * Returns if the component of a given type contains a downloadable asset
 * @param componentName
 * @param src
 */
export const canComponentRenderAsset = (
  componentName: string,
  src: string
): boolean =>
  Boolean(['Video', 'Audio', 'Image'].includes(componentName) && src);

/**
 * Returns all the assets used in a given SceneSet
 * @param sceneSet
 */
const getAssetsFromSceneSet = (sceneSet?: SceneSet): RemoteURI[] | [] => {
  if (!sceneSet) {
    return [];
  }
  const assets: RemoteURI[] = [];

  // @TODO replace with deepMapWhere
  // WHEN before merge into develop
  // @see https://github.com/sleepio/react-native-app/blob/legacy-daylight/src/common/lib/deepMapWhere/index.test.js
  // @TODO Replace SceneChild with new interfaces.
  // WHEN ASAP
  const traverseTree = ({
    childNodes: rootChildNodes,
    component: rootComponent,
    sourceProps,
  }: SceneChild & { sourceProps?: { storage_url?: string } }): SceneChild => {
    if (
      typeof sourceProps?.storage_url === 'string' &&
      canComponentRenderAsset(rootComponent, sourceProps?.storage_url as string)
    ) {
      assets.push(sourceProps?.storage_url as string);
    }
    // Pretty much returns the same thing (for convenience)
    return {
      component: rootComponent,
      sourceProps,
      childNodes: rootChildNodes
        ? rootChildNodes.map(
            ({ component, childNodes, sourceProps }): SceneChild => {
              return traverseTree({
                childNodes,
                component,
                sourceProps: sourceProps as { storage_url?: string },
              });
            }
          )
        : undefined,
    };
  };

  // @TODO: Remove this unknown and sort out the definition of SceneSet
  traverseTree((sceneSet as unknown) as SceneChild); // We're treating the SceneSet like a childNode here (for convenience)

  const uniqueArray = uniq(assets);
  return uniqueArray.filter(Boolean);
};

export default getAssetsFromSceneSet;
