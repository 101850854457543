export enum NetworkStates {
  IDLE = 'IDLE',
  FETCHING = 'FETCHING',
  SUCCESS = 'SUCCESS',
}

export enum DomainIDs {
  PLAYER = 'player',
  DOWNLOADER = 'downloader',
  QUESTION_RESPONSE = 'question-response',
  QUESTION_PATH = 'question-path',
}

export enum ApplicationNames {
  HELLO_WORLD = 'Hello World',
  SLEEPIO = 'Sleepio',
  SLEEPIO_DEV = 'SleepioDev',
  SLEEPIO_QA = 'SleepioQA',
  SLEEPIO_STAGE = 'SleepioStage',
  SLEEPIO_PROD = 'SleepioProd',
  DAYLIGHT = 'Daylight',
  DAYLIGHT_DEV = 'DaylightDev',
  DAYLIGHT_QA = 'DaylightQA',
}

export enum ProductReferences {
  HELLO_WORLD = 'helloworld',
  SLEEPIO = 'sleepio',
  DAYLIGHT = 'daylight',
}

export enum ButtonTypes {
  LARGE_CTA = 'LARGE_CTA',
  PRIMARY_BUTTON = 'PRIMARY_BUTTON',
  SECONDARY_BUTTON = 'SECONDARY_BUTTON',
  FACEBOOK_BUTTON = 'FACEBOOK_BUTTON',
  GOOGLE_BUTTON = 'GOOGLE_BUTTON',
}
export enum TextTypes {
  DEFAULT = 'DEFAULT',
  ERROR = 'ERROR',
  HINT = 'HINT',
}
