import { useCallback, useContext } from 'react';
import { useScaleToMedia } from './useScaleToMedia';
import { useScaleToModal } from './useScaleToModal';
import { ScalingContext, ScalingContextContext } from '../providers';

export type UseScaleToContextCallback = (dimension: number) => number;

const noScale: UseScaleToContextCallback = dimension => dimension;

export const useScaleToScalingContext = (): UseScaleToContextCallback => {
  const scalingContext = useContext(ScalingContextContext);
  const scaleToMedia = useScaleToMedia();
  const scaleToModal = useScaleToModal();
  const noScaleCallback: UseScaleToContextCallback = useCallback(noScale, []);
  switch (scalingContext) {
    case ScalingContext.MediaContainer:
      return scaleToMedia;
    case ScalingContext.Modal:
      return scaleToModal;
    case ScalingContext.Screen:
    default:
      return noScaleCallback;
  }
};
