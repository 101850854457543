import { Dispatch, SetStateAction } from 'react';
import { APIResponse } from '@bighealth/api';
import { FormTypes } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';
import { SleepDiaryService } from 'lib/api/SleepDiaryService';
import { WithImplementation } from 'lib/api/hooks';
import { DiaryEntry } from '@bighealth/types/src/services/SleepDiaryPayloads/types';
import { SleepDiaryPayloads } from '@bighealth/types/src/services/SleepDiaryPayloads';

type Responses = SleepDiaryPayloads[FormTypes]['response']['result'];

type Args = {
  fetchData: WithImplementation<
    | typeof SleepDiaryService.create_single_day_entry
    | typeof SleepDiaryService.update_single_day_entry_with_date
    | typeof SleepDiaryService.create_from_weekly_average_estimate
  >;
  fetchDataArgs: [DiaryEntry];
  onLoadingChange: Dispatch<SetStateAction<boolean>>;
  onSuccess: (payload?: Responses) => void;
  onError: Dispatch<SetStateAction<Error | undefined>>;
};

async function fetchSubmitDataAsync({
  fetchData,
  fetchDataArgs,
  onLoadingChange,
  onSuccess,
  onError,
}: Args): Promise<void> {
  try {
    onLoadingChange(true);
    (await fetchData(...fetchDataArgs)) as APIResponse<Responses>;
  } catch (e) {
    onError(e);
  } finally {
    onLoadingChange(false);
    onSuccess();
  }
}

export { fetchSubmitDataAsync };
