import * as React from 'react';
import { ScrollRow, Wrapper, Card } from './styled';
import {
  PlayerControlsStyles,
  ScreenResponsiveLayoutContext,
  useTransformContentStyle,
} from 'components/ResponsiveLayout';
import { getPseudoTreeStructureProps } from 'lib/getNthChildProps';
import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

type Props = {
  data: get_user_session_screen_data.Result['session_list'];
};
/**
 * (Horizontal) scrolling list of Session cards
 *
 * @param {Props} props
 */
const SessionList = (props: Props): React.ReactElement => {
  const scaleFn = useTransformContentStyle();
  const scaleValue = scaleFn(1);
  const { screenWidth } = React.useContext(ScreenResponsiveLayoutContext);
  const data = props.data as React.ComponentProps<typeof Card>[];
  return (
    <Wrapper>
      <ScrollRow scaleValue={scaleValue}>
        {data.map((item, index, { length }) => (
          <Card
            {...item}
            {...getPseudoTreeStructureProps({
              index,
              length,
            })}
            scaleValue={scaleValue}
            screenWidth={screenWidth}
            isMobile={screenWidth < PlayerControlsStyles.largeScreenBreakpoint}
            key={`${index}`}
          />
        ))}
      </ScrollRow>
    </Wrapper>
  );
};

export { SessionList };
