import styled from 'styled-components/native';
import { Text } from 'components/sceneset-components/Text';

import { TextProps } from '@bighealth/types/src/services/SleepDiaryPayloads/get_sleep_diary_component_data_for_time_period';

export const CalendarContainer = styled.View`
  background-color: white;
  border-radius: 4px;
`;

export const CalendarViewContainer = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;

export const CalendarExtrapolateButtonContainer = styled.View`
  align-items: center;
  justify-content: center;
  background-color: white;
`;

export const CalendarTileContainerPadding = styled.View`
  height: 100%;
  align-items: center;
`;

export const CalendarTileContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 82%;
`;

export const CalendarTile = styled.TouchableOpacity`
  background-color: #003366;
  border-radius: 8px;
  align-items: center;
  width: 12.5%;
`;

export const CalendarTileInner = styled.View`
  align-items: center;
`;

export const CalendarTileDayOfWeek = styled(Text)<TextProps>`
  color: ${props => props.color};
  font-weight: bold;
`;

export const CalendarTileDayOfMonth = styled(Text)<TextProps>`
  color: ${props => props.color};
  font-weight: bold;
`;

export const CalendarTileButtonLabel = styled(Text)<TextProps>`
  color: ${props => props.color};
  font-size: ${props => `${props.fontSize}px`};
`;
