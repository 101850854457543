import React, { ReactElement } from 'react';
import styled from 'styled-components/native';
import { InputsAsArrayProps } from 'components/generic-question/InputsAsArray';
import { useGetDynamicInputStyles } from 'components/ResponsiveLayout';
import { Text } from 'components/primitives/cross-platform';

const Container = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

const Label = styled(Text)`
  color: #757575;
`;

export const HorizontalScaleLabels = (
  props: InputsAsArrayProps
): ReactElement | null => {
  const responseOptions = props.questionProps.response_config.response_options;
  const firstLabel = responseOptions[0].label;
  const lastLabel = responseOptions[responseOptions.length - 1].label;
  const {
    inputScaleLabelFontSize,
    inputScaleLabelMargin,
  } = useGetDynamicInputStyles();
  if (firstLabel && lastLabel) {
    return (
      <Container style={{ marginTop: inputScaleLabelMargin }}>
        <Label style={{ fontSize: inputScaleLabelFontSize }}>
          {firstLabel}
        </Label>
        <Label style={{ fontSize: inputScaleLabelFontSize }}>{lastLabel}</Label>
      </Container>
    );
  }
  return null;
};
