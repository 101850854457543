import { ResponseOption, Question } from '@bighealth/types';
import { QuestionId } from 'state/question-response/actions';

const castResponseOptionByResponseType = (
  options: Record<QuestionId, ResponseOption[]>,
  questions: Question[]
): Record<QuestionId, ResponseOption[]> => {
  const newOptions: typeof options = {};
  for (const [semanticId, optionValues] of Object.entries(options)) {
    const question = questions.find(q => q.semantic_id === semanticId);
    if (typeof question === 'undefined') {
      throw Error(
        `No question found that has semantic_id of ${semanticId} in [${questions
          .map(q => `"${q.semantic_id}"`)
          .join(', ')}]`
      );
    }
    newOptions[semanticId] =
      question.response_type.toLowerCase() === 'number'
        ? optionValues.map(option => {
            const newOptionValue = Number(option.value);
            if (isNaN(newOptionValue)) {
              throw TypeError(
                `Expected option.value for semantic_id ${semanticId} (${option.value}) to cast to number, instead got NaN`
              );
            }
            return {
              ...option,
              value: newOptionValue,
            };
          })
        : optionValues;
  }
  return newOptions;
};

export { castResponseOptionByResponseType };
