import React, { ReactElement, useState } from 'react';
import styled from 'styled-components/native';
import { useParams } from 'cross-platform/react-router';
import { FillAbsolute } from 'components/Containers';
import { DEBUG_SHOW_DEV_CONTENT_SELECTOR } from 'config/envVars';
import TextInput from 'components/TextInput';
import * as CrashReporter from 'lib/crashReporter';
import {
  ButtonColorSchemes,
  ButtonSizes,
  UniversalButton,
  UniversalLinkButton,
} from 'components/UniveralButtons';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';

const Container = styled(FillAbsolute)`
  align-items: center;
`;

const LimitWidth = styled.View`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  max-width: 380px;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
`;

const ButtonContainer = styled.View`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  align-items: stretch;
  margin-bottom: 30px;
`;

const ForQATeam = (): ReactElement => {
  useUpdateTitle('QA');
  const params = useParams<{ productReference: string }>();
  const [productReference, setProductReference] = useState<string | undefined>(
    params.productReference
  );

  return (
    <Container>
      <LimitWidth>
        {DEBUG_SHOW_DEV_CONTENT_SELECTOR ? (
          <ButtonContainer>
            <TextInput
              value={productReference}
              onChangeText={setProductReference}
            />
            <UniversalLinkButton
              to={`/${productReference}/login`}
              title={'Login with Email'}
              text={'Go'}
              colorScheme={ButtonColorSchemes.Login}
              size={ButtonSizes.Small}
            />
          </ButtonContainer>
        ) : null}
        <ButtonContainer>
          <UniversalLinkButton
            text={'Back home'}
            to={`/${productReference}`}
            title={'Back Home'}
            colorScheme={ButtonColorSchemes.Login}
            size={ButtonSizes.Small}
          />
        </ButtonContainer>
        <ButtonContainer>
          <UniversalButton
            text={'Trigger test error'}
            colorScheme={ButtonColorSchemes.PrimaryOnDark}
            onPress={() => CrashReporter.javascriptCrash()}
          />
        </ButtonContainer>
      </LimitWidth>
    </Container>
  );
};

export default ForQATeam;
