import { createTheme, ThemeValuesType } from '../utils/createTheme';
import { sleepioValues } from './sleepio';
import { mergeDeepRight } from 'ramda';
import { DeepPartial } from '@bighealth/types';

const daylightColors = {
  orange: '#FD744E',
  white: '#FFFFFF',
  text: '#4A4A4A',
  error: '#993333',
  black: '#111111',
};

const daylightValueOverrides: DeepPartial<ThemeValuesType> = {
  color: {
    primary: daylightColors.orange,
    primaryLight: daylightColors.white,
    primaryDark: 'transparent',
    primaryGrey: daylightColors.orange,
    error: daylightColors.error,
    text: {
      primary: daylightColors.text,
    },
  },
  controls: {
    borderRadius: 100,
    borderWidth: '2px',
    borderStyle: 'solid',
    paddingHorizontal: 15,
    paddingHorizontalLarge: 25,
    paddingVertical: 10,
    paddingVerticalLarge: 20,
    fontSize: 17,
    fontSizeLarge: 20,
  },
};
const daylightValues: ThemeValuesType = mergeDeepRight<
  ThemeValuesType,
  DeepPartial<ThemeValuesType>
>(sleepioValues, daylightValueOverrides);

const daylightTheme = createTheme(daylightValues);

export { daylightTheme };
