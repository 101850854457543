import React, { ReactElement } from 'react';
import { useTransformStylesToContext } from 'components/ResponsiveLayout';
import { ComponentMediaProps } from '../types';
import { CommonPlayer } from '../Common';
import {
  StyleObject,
  ViewStylesObject,
  ScalingMode,
} from '@bighealth/types/src/scene-components/client';

type VideoProps = ComponentMediaProps & {
  style?: ViewStylesObject;
  scaling?: ScalingMode;
};

export const Video = ({
  style,
  scaling,
  ...rest
}: VideoProps): ReactElement => {
  const transformStylesToContext = useTransformStylesToContext();
  const scaledStyles = transformStylesToContext(
    style || ({} as StyleObject),
    scaling
  );
  return <CommonPlayer {...rest} style={scaledStyles} />;
};
