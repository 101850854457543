import React, { ReactElement, ReactNode } from 'react';
import { TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';
import { Text } from 'components/Text';
import { CircleButton } from '../CircleButton';
import {
  useGetDynamicContentStyles,
  useScaleToScalingContext,
} from 'components/ResponsiveLayout';
import { Triangle } from 'components/sceneset-components/SetSleepWindow/components/Triangle/index';
import { PressHandler } from 'components/Button';
import { pass } from 'cross-platform/utils/roleProps';

export const Title = ({
  text,
  color,
}: {
  text: string;
  color?: string;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <Text style={{ color: color, fontSize: styles.titleSetSleepWindow }}>
      {text}
    </Text>
  );
};

export const Label = ({
  text,
  color,
}: {
  text: string;
  color?: string;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <Text style={{ color: color, fontSize: styles.labelSetSleepWindow }}>
      {text}
    </Text>
  );
};

export const Input = ({
  initialValue,
  color,
}: {
  initialValue: string;
  color: string;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <Text style={{ color: color, fontSize: styles.inputSetSleepWindow }}>
      {initialValue}
    </Text>
  );
};

export const ControlWrapper = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const scaleToContext = useScaleToScalingContext();
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        paddingTop: scaleToContext(20),
        paddingBottom: scaleToContext(20),
      }}
    >
      {children}
    </View>
  );
};

export const CircleButtonsContainer = styled.View`
  flex-direction: row-reverse;
  width: 7%;
`;

export const CircleButtonSleepWindow = styled(CircleButton)``;

export const InputContainer = styled.View`
  width: 93%;
`;

export const SetSleepWindowTriangle = styled(Triangle)``;

export const TriangleContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const SetSleepWindowButton = ({
  title,
  onPress,
  ...rest
}: {
  title: string;
  onPress: PressHandler;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={{
        flex: 1,
        justifyContent: 'center',
        backgroundColor: '#00506b',
        alignItems: 'center',
        padding: styles.submitButtonSetSleepWindowPadding,
        borderRadius: styles.submitButtonSetSleepWindowBorderRadius,
        width: styles.submitButtonSetSleepWindowWidth,
        height: styles.submitButtonSetSleepWindowHeight,
      }}
      onPress={onPress}
      {...pass(rest)}
    >
      <Text
        style={{
          color: '#ffffff',
          fontSize: styles.submitButtonSetSleepWindowFontSize,
        }}
      >
        {title}
      </Text>
    </TouchableOpacity>
  );
};

export const SubmitButtonContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
