import {
  ScaleValueProps,
  scaler,
} from 'components/Screens/SessionsScreen/utils/scaler';
import React from 'react';
import styled from 'styled-components/native';
import { BOTTOM_GAP_UNDER_CARD_SCROLLER } from '../../styled/constants';
import { Card as CardUnstyled } from './components/Card';
import { ScrollViewWrapper } from './components/ScrollViewWrapper';
import {
  CardStyleCallbackArgs,
  cardStyleCallback,
} from './utils/cardStyleCallback';
import { scrollStyleCallback } from './utils/scrollStyleCallback';

const DOMAIN = 'List';

const SCROLL_BAR_HEIGHT = 10; // eye-balled on Safari; Could be more accurate
const padSoScrollBarInMiddleOfGap = scaler(
  SCROLL_BAR_HEIGHT + BOTTOM_GAP_UNDER_CARD_SCROLLER / 2
);

export const Wrapper = styled.ScrollView`
  width: 0;
`;
Wrapper.displayName = `${DOMAIN}.Wrapper`;

const ScrollRowInternal = styled.ScrollView.attrs({
  horizontal: true,
  persistentScrollbar: true,
  showsHorizontalScrollIndicator: true,
})<ScaleValueProps>`
  width: 100%;
  padding-bottom: ${padSoScrollBarInMiddleOfGap}px;
  ${scrollStyleCallback}
`;
ScrollRowInternal.displayName = `${DOMAIN}.ScrollRowInternal`;

export const ScrollRow: React.FC<ScaleValueProps> = props => (
  <ScrollViewWrapper>
    <ScrollRowInternal {...props} />
  </ScrollViewWrapper>
);

export const Card = styled(CardUnstyled)<CardStyleCallbackArgs>`
  ${cardStyleCallback}
`;
Card.displayName = `${DOMAIN}.Card`;
