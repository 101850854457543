import { Scene } from '@bighealth/types';
import { isDevMode } from 'lib/isDevMode';

/**
 * Debug data
 */
const getSceneToThrowInSceneSet = (): Scene =>
  (isDevMode()
    ? {
        component: 'DebugThrow',
      }
    : ({ component: null } as unknown)) as Scene;

export { getSceneToThrowInSceneSet };
