import { ProductReferences } from 'common/constants/enums';
import { daylightTheme } from './themes/daylight';
import { sleepioTheme } from './themes/sleepio';
import { Theme as InternalTheme } from './utils/createTheme';

export type Theme = InternalTheme;

export type ThemeProp = {
  readonly theme: Theme;
};

const getThemeForProduct = (productReference: ProductReferences): Theme => {
  switch (productReference) {
    case ProductReferences.DAYLIGHT:
      return daylightTheme;
    case ProductReferences.SLEEPIO:
    default:
      return sleepioTheme;
  }
};

export { getThemeForProduct };
