import React from 'react';
import { ErrorDetailsApi } from 'lib/error/hooks/useErrorDetailsState';

const initialState: Partial<ErrorDetailsApi> = {
  errorDetails: {
    heading: 'Heading',
    error: undefined,
  },
};

export const ErrorModalContext = React.createContext<Partial<ErrorDetailsApi>>(
  initialState
);
