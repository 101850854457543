import React, { ReactElement } from 'react';
import { Animated, View } from 'react-native';
import styled from 'styled-components/native';
import {
  useTransformStylesToContext,
  ModalStyles,
} from 'components/ResponsiveLayout';
import { usePulsingOpacityAnimation } from 'lib/animations';

interface SceneSetSkeletonLoaderProps {
  animationDurationSeconds?: number;
}

const SkeletonView = styled(View)`
  background-color: #bfe2f0;
  width: 100%;
`;

const SkeletonViewTitle = styled(SkeletonView)`
  width: 75%;
`;

export const SceneSetSkeletonLoader = (
  props?: SceneSetSkeletonLoaderProps
): ReactElement => {
  const fadeAnimation = usePulsingOpacityAnimation(
    props?.animationDurationSeconds
  );
  const transformStylesToContext = useTransformStylesToContext();

  const SkeletonViewScaled = (): ReactElement => (
    <SkeletonView
      style={transformStylesToContext({
        height: ModalStyles.skeletonLoaderCellHeightPx,
        borderRadius: ModalStyles.skeletonLoaderCellBorderRadiusPx,
        marginBottom: ModalStyles.skeletonLoaderMarginBottomPx,
      })}
    />
  );
  return (
    <Animated.View
      style={{
        opacity: fadeAnimation,
      }}
    >
      <SkeletonViewTitle
        style={transformStylesToContext({
          height: ModalStyles.skeletonLoaderTitleHeightPx,
          marginBottom: ModalStyles.skeletonLoaderMarginBottomPx,
        })}
      />
      <SkeletonViewScaled />
      <SkeletonViewScaled />
      <SkeletonViewScaled />
    </Animated.View>
  );
};
