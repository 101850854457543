import React, { ReactElement, useEffect, useState } from 'react';
import {
  LoginPhases,
  useBigHealthLogin,
} from 'lib/api/hooks/useBigHealthLogin';
import { Redirect, useHistory, useParams } from 'cross-platform/react-router';
import { showQAMenu } from 'lib/showQAMenu';
import {
  ButtonColorSchemes,
  ButtonSizes,
  UniversalButton,
  UniversalLinkButton,
} from 'components/UniveralButtons';
import { roles } from 'cross-platform/utils/roleProps';
import {
  ButtonContainer,
  Container,
  ErrorContainer,
  FormattedText,
  Input,
  LabelContainer,
  LimitWidth,
  LogoContainer,
  PaddingContainer,
  ValidationText,
  LoginScroll,
} from 'components/Screens/LoginScreen/styled';
import logger from 'lib/logger';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';
import { isDevMode } from 'lib/isDevMode';
import { LoginFooter } from 'components/Footer';
import { SleepioLogoBranding } from 'components/icons';
import { useRedirectIsAuthenticated } from 'lib/api/hooks/useRedirectIsAuthenticated';
import { ButtonTypes } from 'config/index';
import { ButtonContainerWithMinMax } from 'components/Button/components';

type Params = {
  productReference: string;
};

export const copy = {
  missingEmail: 'Please enter your email',
  invalidEmail: 'Please enter a valid email',
  updateTitle: 'Forgot Password',
  forgotPasswordBackLink: '< Back to Forgot Password',
  loginLinkTitle: 'Back to Login',
  loginBackLink: '< Back to Login',
  successHeader: 'Check your email',
  pageHeader: 'Forgot your password?',
  instructionsBegin: 'Please check your email for further details. Contact ',
  emailTo: 'mailto:hello@sleepio.com',
  emailAddress: 'hello@sleepio.com',
  instructionsEnd: ' with any questions.',
  instructions:
    "Forgotten your password? No problem. Just enter the email you signed up with below and we'll send you a link to reset your password.",
  emailPlaceholder: 'Type your email here',
  emailLabel: 'Email',
  actionButtonText: 'Submit',
};

const MISSING_EMAIL = 'Please enter your email';
const INVALID_EMAIL = 'Please enter a valid email';
const MISSING_PASSWORD = 'Please enter a valid password';
const LOGIN_ERROR = 'Incorrect email or password. Please try again.';

const Logo = ({ onPress }: { onPress: () => void }): ReactElement => {
  return (
    <LogoContainer testID={'DeveloperButton'} onPress={onPress}>
      <SleepioLogoBranding height={28} />
    </LogoContainer>
  );
};

const LoginScreen = (): ReactElement => {
  useRedirectIsAuthenticated();

  useUpdateTitle('Login');

  const [email, setEmail] = useState({
    isValid: false,
    value: '',
    isPresent: false,
    isSubmitted: false,
  });
  const [password, setPassword] = useState({
    isValid: false,
    isSubmitted: false,
    value: '',
  });
  const [hasLoginError, setLoginError] = useState(false);
  const { login, error, status } = useBigHealthLogin();
  const { productReference } = useParams<Params>();
  const history = useHistory();
  const canSignIn = email.isValid && password.isValid;
  const hasEmailPresentError = email.isSubmitted && !email.isPresent;
  const hasEmailValidError =
    email.isSubmitted && !email.isValid && email.isPresent;
  const hasPasswordError = password.isSubmitted && !password.isValid;

  useEffect(() => {
    if (error) {
      logger(error.message, error, { silent: true });
      setLoginError(true);
    }
  }, [error]);

  const handleSubmit = async (): Promise<void> => {
    setEmail(prevState => ({ ...prevState, isSubmitted: true }));
    setPassword(prevState => ({ ...prevState, isSubmitted: true }));
    setLoginError(false);

    if (canSignIn) {
      try {
        await login({ email: email.value, password: password.value });
      } catch (e) {
        logger(e.message, e);
      }
    }
  };

  const onChangeEmail = (value: string): void => {
    setEmail(prevState => ({
      ...prevState,
      value: value,
      isValid: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value),
      isPresent: value.length > 0,
      isSubmitted: false,
    }));
  };

  const onChangePassword = (value: string): void => {
    setPassword(prevState => ({
      ...prevState,
      value: value,
      isValid: value.length > 0,
      isSubmitted: false,
    }));
  };

  if (status === LoginPhases.SUCCESS) {
    return <Redirect to={`/${productReference}/home`} />;
  }

  const onLogoPress = (): void => {
    if (isDevMode()) {
      showQAMenu(productReference as string, history);
    }
  };

  return (
    <Container>
      <LoginScroll>
        <LimitWidth>
          <Logo onPress={onLogoPress} />
          <ButtonContainer>
            <PaddingContainer>
              <LabelContainer>
                <FormattedText>Email</FormattedText>
              </LabelContainer>
              <Input
                {...roles('LoginInput-email')}
                onChangeText={onChangeEmail}
                placeholder={'Type your email here'}
                placeholderTextColor={'#757575'}
                keyboardType={'email-address'}
                type={'email'}
                hasErrors={Boolean(
                  hasEmailPresentError || hasEmailValidError || error
                )}
                required={true}
                autoCapitalize={'none'}
                returnKeyType={'next'}
                autoCorrect={false}
                value={email.value}
              />
              {hasEmailPresentError ? (
                <ErrorContainer>
                  <ValidationText>{MISSING_EMAIL}</ValidationText>
                </ErrorContainer>
              ) : null}
              {hasEmailValidError ? (
                <ErrorContainer>
                  <ValidationText>{INVALID_EMAIL}</ValidationText>
                </ErrorContainer>
              ) : null}
            </PaddingContainer>
            <PaddingContainer>
              <LabelContainer>
                <FormattedText>Password</FormattedText>
              </LabelContainer>
              <Input
                {...roles('GenericInput-password')}
                onChangeText={onChangePassword}
                placeholder={'Type your password here'}
                placeholderTextColor={'#757575'}
                required={true}
                value={password.value}
                secureTextEntry={true}
                hasErrors={Boolean(hasPasswordError || error)}
              />
              {hasPasswordError ? (
                <ErrorContainer>
                  <ValidationText>{MISSING_PASSWORD}</ValidationText>
                </ErrorContainer>
              ) : null}
              {hasLoginError ? (
                <ErrorContainer>
                  <ValidationText>{LOGIN_ERROR}</ValidationText>
                </ErrorContainer>
              ) : null}
            </PaddingContainer>
            <ButtonContainerWithMinMax type={ButtonTypes.PRIMARY_BUTTON}>
              <UniversalButton
                {...roles('login-button')}
                onPress={handleSubmit}
                text={'Log In'}
                colorScheme={ButtonColorSchemes.Login}
                size={ButtonSizes.Small}
              />
            </ButtonContainerWithMinMax>
            <UniversalLinkButton
              {...roles('forgot-password-link')}
              text={'Forgot Password'}
              colorScheme={ButtonColorSchemes.LinkInverse}
              to={`/${productReference}/forgot-password`}
              title={'Forgot Password'}
            />
          </ButtonContainer>
        </LimitWidth>
        <LoginFooter />
      </LoginScroll>
    </Container>
  );
};

export default LoginScreen;
