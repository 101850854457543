import { ReactNode } from 'react';

export type NavItemDisplayType = 'category' | 'subcategory';

export type PressableNavItem = {
  label: string;
  onPress: (() => void) | (() => Promise<void>);
  type?: NavItemDisplayType;
};

export type OnOpen = ({
  pageX,
  locationX,
}: {
  pageX: number;
  locationX: number;
}) => void;

export type ExpandableNavItemProps = {
  testID: string;
  onOpen: OnOpen;
  children: ReactNode;
  isOpen?: boolean;
  onDismiss?: () => void;
};

export function isPressableNavItem(item: any): item is PressableNavItem {
  return !!item.onPress;
}

export type LinkNavItem = {
  label: string;
  to: string;
  type?: NavItemDisplayType;
  external?: boolean;
};

export function isLinkNavItem(item: any): item is LinkNavItem {
  return !!item.to;
}

export type NavEntry = NavCategory | LinkNavItem | PressableNavItem;

export type NavCategory = {
  label: string;
  subMenu: NavEntry[];
};

export function isCategoryNavItem(item: any): item is NavCategory {
  return item.subMenu?.length > 0;
}

export type NavMenuConfig = NavEntry[];
