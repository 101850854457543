import React from 'react';
import { ErrorBoundary as LibraryErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryProps } from './types';
import { defaultRenderFallbackFactory } from './utils/defaultRenderFallbackFactory';
import { withDefaultErrorHandler } from './utils/withDefaultErrorHandler';

/**
 * An opinionated Error-boundary
 *
 * WARNING: In dev mode, dev-time "error overlay" will _still_ show
 * ([more info](https://stackoverflow.com/a/47400249/225813))
 *
 * @param {FallbackRender} props.fallbackRender callback for fallback rendering (preferred)
 * @param {FallbackRenderWithWrapper} props.fallbackRenderWithWrapper Wraps rendered fallback
 *        (handy as default can occur in many types of containers)
 * @param {OnError} props.onError Callback when error caught by ErrorBoundary
 * @param {onRecover} props.onRecover Callback when (User is) trying to recover state
 * @returns
 */
export const ErrorBoundary = (props: ErrorBoundaryProps): JSX.Element => {
  const { fallbackRenderWithWrapper, onRecover } = props;
  let { fallbackRender } = props;

  if (typeof fallbackRender === 'undefined') {
    fallbackRender = defaultRenderFallbackFactory({
      onRecover,
    });
  }
  if (fallbackRenderWithWrapper) {
    fallbackRender = fallbackRenderWithWrapper(fallbackRender);
  }

  const errorHandler = withDefaultErrorHandler(props.onError); // IDEA memoise
  return (
    <LibraryErrorBoundary
      fallbackRender={fallbackRender}
      onError={errorHandler}
    >
      {props.children}
    </LibraryErrorBoundary>
  );
};
