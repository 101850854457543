import { APIRequestBody, APIResponse } from '../../types';
import { ScreenResourceEvent } from '@bighealth/analytics';
import { api } from '../../api';

export const fire_ui_events = async (
  args: fire_client_events.Args
): Promise<fire_client_events.Response> => {
  return await api({
    service_name: 'Event',
    service_version: '1',
    service_method: 'fire_ui_events',
    args: args,
  } as fire_client_events.Request);
};

fire_ui_events.queryKey = 'Analytics/fire_ui_events';

export declare namespace fire_client_events {
  export type Args = {
    events: [ScreenResourceEvent];
  };

  export interface Request extends APIRequestBody {
    service_name: 'Event';
    service_version: '1';
    service_method: 'fire_ui_events';
    args: Args;
  }

  export type Result = {};

  export type Response = APIResponse<Result>;
}
