import styled from 'styled-components/native';
import { Row } from 'components/layout/Grid';
import { DOMAIN } from '../components/SessionStatus/styled/constants';
export { BottomRow } from './BottomRow';

export const Grid = styled.View.attrs({
  width: '100%',
})`
  flex-basis: 100%;
  width: 100%;
  margin-left: 0;
`;
Grid.displayName = `${DOMAIN}.Grid`;

export const TopRow = styled(Row)`
  justify-content: center;
`;
TopRow.displayName = `${DOMAIN}.TopRow`;
