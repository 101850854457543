import { Value } from 'components/forms/types';
import { isValidDate } from 'lib/isValidDate';
import { isStringArray } from 'lib/type-guarded';
import { SelectHTMLAttributes } from 'react';

/**
 * Convert from he value used in question state
 * to the value used by the input control element
 *
 * IDEA support multiple selections
 * WHEN initialValue required for multi-select
 * HOW
 * - change input type from Value to Value | Value[]
 * - remove "(Defensive coding)..." comment below
 * - remove "Defensive casting" marked in test
 */
//
const fromValueToSelectHTMLAttributesValue = (
  value?: Value
): SelectHTMLAttributes<HTMLSelectElement>['value'] => {
  // (Defensive coding) Handle more types than generic-questions currently use this for
  if (typeof value === 'string' || typeof value === 'number') {
    return value;
  } else if (typeof value === 'undefined' || value === null) {
    return '';
  } else if (typeof value === 'boolean' || isValidDate(value)) {
    return String(value);
  } else if (Array.isArray(value) && isStringArray(value)) {
    return value;
  }
  throw Error(
    `Expected value toSelectValue() could convert to SelectHTMLAttributes<HTMLSelectElement>>['value'], instead got "${value}" (${typeof value})`
  );
};

export { fromValueToSelectHTMLAttributesValue };
