import * as React from 'react';
import { ReactElement } from 'react';
import { StyleSheet, TouchableOpacity, Text, View } from 'react-native';
import useActionHandler from 'lib/player/useActionHandler';
import { ButtonProps } from 'components/UniveralButtons/types';
import { roles } from 'cross-platform/utils/roleProps';

// We could not use the UniversalActionButton because we can't customise the styles
// TODO: use the UniversalActionButton
// WHEN: we move the button in the shared package and we can customize the styles
export const ActionButton = (
  props: ButtonProps & { textColor: string; textSize: number }
): ReactElement => {
  const actionHandler = useActionHandler(props.action);
  const handlePress = async (): Promise<void> => {
    if (typeof actionHandler === 'function') {
      await actionHandler();
    }
  };
  const flatProps = Array.isArray(props.style)
    ? StyleSheet.flatten(props.style)
    : props.style;

  return (
    <View>
      <TouchableOpacity
        {...roles('ActionButton')}
        style={{
          ...(flatProps as Record<string, unknown>),
          borderStyle: 'solid',
          alignItems: 'center',
        }}
        onPress={handlePress}
      >
        <Text style={{ color: props.textColor, fontSize: props.textSize }}>
          {props.text}
        </Text>
      </TouchableOpacity>
    </View>
  );
};
