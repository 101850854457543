import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLatestQuestionId } from 'state/question-response/actions';
import { getLatestQuestionId } from 'state/question-response/selectors';
import { showControls } from 'state/player/actions';
import { getControlsAreVisible } from 'state/player/selectors';

export type UseLatestFocusedQuestion = {
  latestQuestionId: number | undefined;
  setLatestFocusedQuestion: (id: number | undefined) => void;
};

export const useLatestFocusedQuestion = (): UseLatestFocusedQuestion => {
  const dispatch = useDispatch();
  const latestQuestionId = useSelector(getLatestQuestionId);
  const controlsAreVisible = useSelector(getControlsAreVisible);

  const setLatestFocusedQuestion = useCallback(
    (id: number | undefined): void => {
      if (typeof id === 'number' && controlsAreVisible) {
        dispatch(showControls(false));
      }
      dispatch(setLatestQuestionId(id));
    },
    [controlsAreVisible, dispatch]
  );

  return { setLatestFocusedQuestion, latestQuestionId };
};
