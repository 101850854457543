import { throwIfMissingEnvVar } from 'config/envVars/helpers';

export const PLATGEN_FF_USE_MOCK_SCHEDULE_DATA =
  throwIfMissingEnvVar<string>(
    'PLATGEN_FF_USE_MOCK_SCHEDULE_DATA',
    process.env.PLATGEN_FF_USE_MOCK_SCHEDULE_DATA
  ) === '1';

export const PLATGEN_FF_USE_MOCK_DOWNLOADS_DATA =
  throwIfMissingEnvVar<string>(
    'PLATGEN_FF_USE_MOCK_DOWNLOADS_DATA',
    process.env.PLATGEN_FF_USE_MOCK_DOWNLOADS_DATA
  ) === '1';

export const PLATGEN_FF_USE_MOCK_PROGRESS_DATA =
  throwIfMissingEnvVar<string>(
    'PLATGEN_FF_USE_MOCK_PROGRESS_DATA',
    process.env.PLATGEN_FF_USE_MOCK_PROGRESS_DATA
  ) === '1';

export const PLATGEN_FF_USE_SNAPENGAGE =
  throwIfMissingEnvVar<string>(
    'PLATGEN_FF_USE_SNAPENGAGE',
    process.env.PLATGEN_FF_USE_SNAPENGAGE
  ) === '1';

export const PLATGEN_FF_USE_INTERNAL_LOGIN_ROUTE =
  throwIfMissingEnvVar<string>(
    'PLATGEN_FF_USE_INTERNAL_LOGIN_ROUTE',
    process.env.PLATGEN_FF_USE_INTERNAL_LOGIN_ROUTE
  ) === '1';
