import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';

export const unsubscribe_from_emails = async (
  args: unsubscribe_from_emails.Args
): Promise<unsubscribe_from_emails.Response> => {
  return await api({
    service_name: 'UserSleepioMetadata',
    service_version: '1',
    service_method: 'unsubscribe_from_emails',
    args: args,
  } as unsubscribe_from_emails.Request);
};

unsubscribe_from_emails.queryKey =
  'UserSleepioMetadata/unsubscribe_from_emails';

export declare namespace unsubscribe_from_emails {
  export type Args = {
    product_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'UserSleepioMetadata';
    service_version: '1';
    service_method: 'unsubscribe_from_emails';
    args: Args;
  }

  export type Result = {
    message: string;
  };

  export type Response = APIResponse<Result>;
}
