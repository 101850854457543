import { HOUR } from 'lib/durations';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { QuestionId } from 'state/question-response/actions';
import { getSelected } from '../../helpers/getSelected';
import { Config } from '..';
import { createQuestionProps } from './helpers/createQuestionProps';
import {
  TIME_INTO_BED,
  TIME_TRY_TO_SLEEP,
} from 'components/SleepDiaryForm/constants';
/**
 *
 * @param {Record<QuestionId, DropdownItem[]>} inputs from `<ResponseForm onValidate={inputs => {}} />`
 */
const getTryToSleepConfigFromIntoBed = (
  inputs: Record<QuestionId, DropdownItem[]>
): Record<QuestionId, Config> | null => {
  const timeInBed = getSelected(inputs[TIME_INTO_BED])?.valueOf();
  if (typeof timeInBed !== 'number') {
    return null;
  }
  const min = timeInBed;
  const max = timeInBed + 8 * HOUR;
  return {
    [TIME_TRY_TO_SLEEP]: createQuestionProps(min, max),
  };
};

export { getTryToSleepConfigFromIntoBed };
