import React, { ReactElement } from 'react';
import { CommonPropType } from 'components/generic-question/index.story';
import { TimePicker } from '..';

export const TimePickerStory = (props: CommonPropType): ReactElement => (
  <TimePicker
    date={new Date(0)}
    onDateChange={console.log}
    highlight={props.highlight}
  />
);
