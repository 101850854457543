import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import Modal from 'modal-react-native-web';
const el = document.getElementById('root');
Modal.setAppElement(el);

export default ReactDOM.render(<App />, el);
