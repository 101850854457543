import React, { FunctionComponent, ReactElement } from 'react';
import { ResponseInput } from 'components/forms/ResponseOptions/ResponseInput';
import { ResponseForm } from 'components/forms/ResponseOptions/ResponseForm';
import { ResponseOptionValue } from '@bighealth/types/src';
import { roles } from 'cross-platform/utils/roleProps';
import { useFormikContext } from 'formik';
import { ExtendSleepWindowProps } from '../FormState';
import {
  ControlWrapper,
  SubmitButton,
  SubmitButtonContainer,
  Title,
  WrapperForm,
} from './styled';

export type FormProps = {
  onSubmit: (value: ResponseOptionValue) => void;
};

export type FormPresentationProps = ExtendSleepWindowProps & FormProps;

const SubmitExtendSleepWindowButton = ({
  text,
}: {
  text: string;
}): ReactElement => {
  const { handleSubmit } = useFormikContext();
  return (
    <SubmitButtonContainer>
      <SubmitButton
        title={text}
        onPress={handleSubmit}
        {...roles('ExtendSleepWindow.SubmitButton')}
      ></SubmitButton>
    </SubmitButtonContainer>
  );
};

/** * Just renders. Handlers are configured elsewhere
 * @param props
 */
const FormPresentation: FunctionComponent<FormPresentationProps> = props => {
  return (
    <WrapperForm>
      <Title text={props?.title.text} />

      <ControlWrapper>
        <ResponseForm
          onSubmit={inputs => {
            // Data hard-coded, so we can assume there will be only one entry here
            // because there is only one response option
            Object.entries(inputs).forEach(([, optionsAnswer]) => {
              props?.onSubmit(optionsAnswer[0].value);
            });
          }}
        >
          <ResponseInput
            key={props?.questionProps.semantic_id}
            component={'TextInput'}
            questionProps={props?.questionProps}
          />

          <SubmitExtendSleepWindowButton text={props?.submit_button.text} />
        </ResponseForm>
      </ControlWrapper>
    </WrapperForm>
  );
};

export { FormPresentation };
