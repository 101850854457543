import { useHistory } from 'cross-platform/react-router';
import { useEffect } from 'react';

export const useDispatchPathToParent = (): void => {
  const history = useHistory();
  const path = history.location.pathname;
  useEffect(() => {
    window.top.postMessage(JSON.stringify({ clientPath: path }), '*');
  }, [path]);
};
