import React, { ReactElement } from 'react';
import CrossPlatformImage from 'cross-platform/Image';
import { useSelector } from 'react-redux';
import { getLocalURIForRemoteURL } from 'state/downloader/selectors';
import { useTransformStylesToContext } from 'components/ResponsiveLayout';
import {
  ViewStylesObject,
  ScalingMode,
} from '@bighealth/types/src/scene-components/client';
import { View } from 'react-native';

export type ImageProps = {
  src: string;
  style?: ViewStylesObject;
  scaling?: ScalingMode;
};

export const Image = ({ src, style, scaling }: ImageProps): ReactElement => {
  const transformStylesToContext = useTransformStylesToContext();
  const localURL = useSelector(getLocalURIForRemoteURL(src));
  const scaledStyles = transformStylesToContext(style || {}, scaling);
  return (
    <View pointerEvents={'none'}>
      <CrossPlatformImage src={localURL} style={scaledStyles} />
    </View>
  );
};
