import React, { ReactElement, useContext } from 'react';
import { roles } from 'cross-platform/utils/roleProps';
import {
  ScalingContext,
  ScalingContextProvider,
  ScreenResponsiveLayoutContext,
  useGetDynamicModalStyles,
  useScaleToModal,
} from 'components/ResponsiveLayout';
import BackButton from 'components/BackButton';
import { ScrollView } from 'react-native';
import styled from 'styled-components/native';
import { Modal as ModalType } from '@bighealth/types/src/scene-components/client';
import { isEditorBooleanTrue } from 'cross-platform/utils/utils';
import { KeyboardDimensionsContext } from 'components/ProvidersContainer/KeyboardDimensionsProviders';
import { Errors } from 'components/forms';
import { ResponseForm } from 'components/forms/ResponseOptions/ResponseForm';
import {
  SceneComponentContextProvider,
  SceneComponentContextType,
} from 'components/Scene/SceneComponentContext';
import { useSelector } from 'react-redux';
import { FillAbsoluteCenter } from 'components/Containers';
import * as contentErrors from 'components/SceneSetView/components/contentErrors';
import { getControlsAreVisible } from 'state/player/selectors';
import { ControlsDismisser } from './ControlsDismisser';

const ModalLayoutContainer = styled(FillAbsoluteCenter)`
  background-color: transparent;
  opacity: 1;
  flex-direction: row;
  align-content: center;
`;

const ModalBounds = styled.View<{ shadowSize?: number }>`
  background-color: white;
  display: flex;
  box-shadow: 0 0 ${({ shadowSize }): number => (shadowSize || 80) / 10}px
    rgba(0, 51, 102, 0.15);
  flex-direction: column;
  overflow: hidden;
`;

export type ModalProps = React.PropsWithChildren<
  {
    showBackButton?: 'true' | 'false' | boolean;
  } & Omit<ModalType, 'component'>
>;

const Modal = ({
  children,
  style,
  alignment,
  showBackButton,
}: ModalProps): ReactElement => {
  const controlsAreVisible = useSelector(getControlsAreVisible);
  const { height: keyboardHeight } = useContext(KeyboardDimensionsContext);
  const { screenHeight } = useContext(ScreenResponsiveLayoutContext);

  const {
    borderRadius,
    contentInset,
    marginRight,
    maxWidth,
    minWidth,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    width,
    alignModal,
  } = useGetDynamicModalStyles(style, alignment);
  const scaleToModal = useScaleToModal();
  const backButton = isEditorBooleanTrue(showBackButton) ? (
    <BackButton
      style={{
        position: 'absolute',
        top: scaleToModal(25),
        left: scaleToModal(20),
      }}
    />
  ) : null;
  return (
    <ScalingContextProvider scalingContext={ScalingContext.Modal}>
      <ModalLayoutContainer
        // This component fills the whole screen
        pointerEvents={'box-none'}
        style={{
          justifyContent: alignModal,
          display: style?.display || 'flex',
        }}
      >
        <ModalBounds
          // This component is the outermost element for the "white" modal. It contains the shadow and the border radii etc
          {...roles(
            `ModalCore-${controlsAreVisible ? 'background' : 'foreground'}`
          )}
          shadowSize={width * 0.5}
          style={{
            borderRadius,
            marginRight,
            maxWidth,
            minWidth,
            width,
            paddingRight: contentInset, // <--- This is for the notch
          }}
        >
          <ScrollView
            // This is the scrollable content
            style={{
              maxHeight: screenHeight,
            }}
          >
            <ControlsDismisser
              role={roles(
                `ControlsDismiss-${
                  controlsAreVisible ? 'background' : 'foreground'
                }`
              )}
              style={{
                paddingBottom: paddingBottom + keyboardHeight,
                paddingLeft,
                paddingRight, // <--- We're not adding notch handling here as the padding is big anyway
                paddingTop,
              }}
            >
              <contentErrors.ErrorBoundary>
                <SceneComponentContextProvider
                  sceneComponentContext={SceneComponentContextType.Modal}
                >
                  <ResponseForm
                    onSubmit={() => undefined}
                    onValidate={(): Errors => {
                      return {};
                    }}
                  >
                    {children}
                  </ResponseForm>
                </SceneComponentContextProvider>
              </contentErrors.ErrorBoundary>
            </ControlsDismisser>
          </ScrollView>
          {backButton}
        </ModalBounds>
      </ModalLayoutContainer>
    </ScalingContextProvider>
  );
};
Modal.displayName = 'Modal';

export default Modal;
