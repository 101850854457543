import React from 'react';
import { addStory } from 'lib/story-books';
import { ErrorBoundary } from 'lib/error/ErrorBoundary/ErrorBoundary';
import { ThrowButton } from './components/ThrowButton';
import {
  ScalingContextProvider,
  ScalingContext,
} from 'components/ResponsiveLayout';
import { useThrowingErroringApi } from './utils/useThrowingErroringApi';
import * as roleProps from 'cross-platform/utils/roleProps';

const handleAction = console.log;
export const OnRecoverSetStory = (): JSX.Element => {
  const { throwing, erroring } = useThrowingErroringApi();
  return (
    <ScalingContextProvider scalingContext={ScalingContext.ContentScreen}>
      <ErrorBoundary
        onRecover={() => {
          handleAction('Recover!');
          // Custom behavior to (hopefully) reset the situation, so there is no error
          erroring.setErrorDetails(undefined);
          throwing.setIsThrow(false); // Recover
        }}
        onError={error => {
          erroring.setErrorDetails({
            error,
          });
        }}
      >
        <ThrowButton
          {...roleProps.roles('ThrowButton')}
          api={throwing}
          text="Recoverable error"
        />
      </ErrorBoundary>
    </ScalingContextProvider>
  );
};

addStory('error-handling/onRecover/set', OnRecoverSetStory);

export const OnRecoverNotSetStory = (): JSX.Element => {
  const { throwing, erroring } = useThrowingErroringApi();
  return (
    <ErrorBoundary
      onRecover={
        // No custom behavior to (hopefully) reset the situation, so there is no error
        undefined
      }
      onError={error => {
        erroring.setErrorDetails({
          error,
        });
      }}
    >
      <ThrowButton api={throwing} text="Non-recoverable error" />
    </ErrorBoundary>
  );
};
addStory('error-handling/onRecover/not-set', OnRecoverNotSetStory);
