/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react';
import {
  loginWithEmailAndPassword,
  loginWithFacebookToken,
  loginWithGoogleToken,
} from 'lib/api';
import { APIResponse } from '@bighealth/api';
import { GENERAL_ERROR_MESSAGE } from 'lib/strings';

import {
  authenticate_with_email,
  authenticate_with_facebook,
  authenticate_with_google,
} from '@bighealth/api/UserAccountAuthorization/v1';
import { useQueryProduct } from 'lib/api/reactQueryHelpers';

type LoginWithFacebookConfig = {
  facebookToken: string;
};

type LoginWithGoogleConfig = {
  googleToken: string;
};

type LoginWithEmailPasswordConfig = {
  email: string;
  password: string;
};

const isFacebookConfig = (
  payload: Record<string, string>
): payload is LoginWithFacebookConfig => {
  return !!payload.facebookToken;
};

const isGoogleConfig = (
  payload: Record<string, string>
): payload is LoginWithGoogleConfig => {
  return !!payload.googleToken;
};

const isEmailPasswordConfig = (
  payload: Record<string, string>
): payload is LoginWithEmailPasswordConfig => {
  return !!(payload.email && payload.password);
};

enum LoginMethods {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  EMAIL_PASSWORD = 'EMAIL_PASSWORD',
  OTHER = 'OTHER',
}

type LoginTypes =
  | LoginWithEmailPasswordConfig
  | LoginWithFacebookConfig
  | LoginWithGoogleConfig;

export const getLoginMethodForConfig = (config: LoginTypes): LoginMethods => {
  if (isEmailPasswordConfig(config as LoginWithEmailPasswordConfig)) {
    return LoginMethods.EMAIL_PASSWORD;
  }
  if (isFacebookConfig(config as LoginWithFacebookConfig)) {
    return LoginMethods.FACEBOOK;
  }
  if (isGoogleConfig(config as LoginWithGoogleConfig)) {
    return LoginMethods.GOOGLE;
  }
  return LoginMethods.OTHER;
};

export enum LoginPhases {
  IDLE = 'IDLE',
  FETCHING = 'FETCHING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

type ErrorState = {
  error: Error;
  status: LoginPhases.ERROR;
};

type OkState = {
  error: null;
  status: LoginPhases.FETCHING | LoginPhases.IDLE | LoginPhases.SUCCESS;
};

const isErrorState = (state: any): state is ErrorState => {
  return !!(state.status === LoginPhases.ERROR && state.error);
};

export type LoginCallback = (config: LoginTypes) => Promise<void>;

type APICallFactory = () => Promise<APIResponse>;

export function useBigHealthLogin(): (ErrorState | OkState) & {
  login: LoginCallback;
} {
  const [loginState, setLoginState] = useState<OkState | ErrorState>({
    status: LoginPhases.IDLE,
    error: null,
  });
  const productId = useQueryProduct()?.data?.result.id;
  const login: LoginCallback = useCallback(
    async config => {
      const loginMethod = getLoginMethodForConfig(config);
      let apiCallFactory: APICallFactory | null = null;
      switch (loginMethod) {
        case LoginMethods.EMAIL_PASSWORD: {
          const { email, password } = config as LoginWithEmailPasswordConfig;

          apiCallFactory = (): Promise<authenticate_with_email.Response> =>
            loginWithEmailAndPassword(email, password, {
              productId: productId as number,
            });
          break;
        }
        case LoginMethods.FACEBOOK: {
          const { facebookToken } = config as LoginWithFacebookConfig;
          apiCallFactory = (): Promise<authenticate_with_facebook.Response> =>
            loginWithFacebookToken(facebookToken, {
              productId: productId as number,
            });
          break;
        }
        case LoginMethods.GOOGLE: {
          const { googleToken } = config as LoginWithGoogleConfig;
          apiCallFactory = (): Promise<authenticate_with_google.Response> =>
            loginWithGoogleToken(googleToken, {
              productId: productId as number,
            });
          break;
        }
        default: {
          // NO OP
        }
      }
      if (typeof apiCallFactory === 'function') {
        try {
          setLoginState({
            status: LoginPhases.FETCHING,
            error: null,
          });
          const response = await apiCallFactory();
          if (response.status_code === 401) {
            // We'll hard-code this message here until API design complete
            throw Error('Incorrect login details');
          }
          if (response.status_code >= 400) {
            throw Error(GENERAL_ERROR_MESSAGE);
          }
          setLoginState({
            status: LoginPhases.SUCCESS,
            error: null,
          });
        } catch (e) {
          setLoginState({
            status: LoginPhases.ERROR,
            error: e,
          });
        }
      }
    },
    [productId]
  );

  if (isErrorState(loginState)) {
    return {
      error: loginState.error,
      status: LoginPhases.ERROR,
      login,
    };
  }
  return {
    status: loginState.status,
    error: null,
    login,
  };
}
