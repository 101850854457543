import {
  handleGetLoginStatusResponse,
  handleLoginResponse,
} from './statusResponseHelpers';
import { FacebookAuthResponseWeb } from './handleFacebookSignin';

export const ERROR_TEXT = 'Facebook SDK not loaded';

export const getLoginStatusAsync = (): Promise<FacebookAuthResponseWeb> =>
  new Promise((resolve, reject) => {
    if (window.FB) {
      window.FB.getLoginStatus((response: FacebookAuthResponseWeb) => {
        handleGetLoginStatusResponse(response, resolve);
      });
    } else {
      reject(Error(ERROR_TEXT));
    }
  });

export const loginAsync = (): Promise<FacebookAuthResponseWeb> =>
  new Promise((resolve, reject) => {
    if (window.FB) {
      window.FB.login((response: FacebookAuthResponseWeb) => {
        handleLoginResponse(response, resolve);
      });
    } else {
      reject(Error(ERROR_TEXT));
    }
  });
