import React from 'react';
import { ErrorBoundary } from 'lib/error/ErrorBoundary/ErrorBoundary';
import { ErrorBoundaryProps } from 'lib/error/ErrorBoundary/types';
import { useErrorModalContext } from '../../hooks/useErrorModalContext';
import { useHistory } from 'cross-platform/react-router';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { createActions } from './createActions';

type Props = Omit<ErrorBoundaryProps, 'onError' | 'fallbackRender'>;

const ErrorBoundaryForErrorModal = (props: Props): JSX.Element => {
  const errorModalContext = useErrorModalContext();
  const history = useHistory();
  const { productReference } = useSafeParams();

  return (
    <ErrorBoundary
      fallbackRender={
        () => (
          <></>
        ) /* 
        Note: if boundary detects an error, then it renders nothing 

        TODO Keep rendering children
        WHY Want to keep visuals as consistent as possible
        HOW I don't know; perhaps revert to loading state?
          But if re-renders, it creates generates a new error which
          skips the "occupied" ErrorBoundary and hits the
          outer ErrorBoundary on SleepioSwitch
          OPTION 1: we can use (or mimic) window.alert for its synchronous
            execution, as this will block all js execution including
            re-renders
          OPTION 2: New "error state". Only renders safe (best-guess) things?
      */
      }
      fallbackRenderWithWrapper={props?.fallbackRenderWithWrapper}
      onRecover={props?.onRecover}
      onError={(error: Error) => {
        errorModalContext?.setErrorDetails?.({
          error,
          actions: createActions({ history, productReference }),
        });
      }}
    >
      {props?.children}
    </ErrorBoundary>
  );
};

export { ErrorBoundaryForErrorModal };
