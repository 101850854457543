import { CardProps } from '..';

export type Status = CardProps['status'];

export type ThemeByStatus = {
  color: '#007A60' | '#1a80a2' | '#00506B';
};
export const THEME_BY_STATUS: Record<Status, ThemeByStatus> = {
  COMPLETED: {
    color: '#1a80a2',
  },
  IN_PROGRESS: {
    color: '#007A60',
  },
  NOT_STARTED: {
    color: '#007A60',
  },
  LOCKED: {
    color: '#00506B',
  },
};
