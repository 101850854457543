import React, { ReactElement } from 'react';
import { Text } from 'react-native';
import { addStory } from 'lib/story-books';
import { Col, Grid, Row } from 'components/layout/Grid';
import { printableProps } from 'components/layout/lib/printableProps';
import { DateTimePickerStory } from './DateTimePickers/stories/DateTimePicker.story';
import { DatePickerStory } from './DateTimePickers/stories/DatePicker.story';
import { TimePickerStory } from './DateTimePickers/stories/TimePicker.story';
import { DropdownStory } from './Dropdown/index.story';
import { DropdownDurationPickerStory } from './DropdownDurationPicker/index.story';
import { DropdownTimePickerStory } from './DropdownTimePicker/index.story';

export type CommonPropType = Partial<{ highlight: boolean }>;

export const COMMON_PROP_LIST: CommonPropType[] = [
  // different props to display
  {},
  { highlight: true },
];

type GenericQuestionType =
  | typeof DateTimePickerStory
  | typeof DatePickerStory
  | typeof TimePickerStory
  | typeof DropdownStory
  | typeof DropdownDurationPickerStory
  | typeof DropdownTimePickerStory;

export const commonPropStory = (
  GenericQuestion: GenericQuestionType,
  name: string
): ReactElement => (
  <Row>
    {COMMON_PROP_LIST.map(props => (
      <Col flexGrow={0} width={'auto'} key={JSON.stringify({ name, props })}>
        <Text>{name}</Text>
        <Text>{`    ${printableProps(props)}`}</Text>
        <GenericQuestion {...props} />
      </Col>
    ))}
  </Row>
);

addStory('generic-question', () => (
  <Grid>
    {commonPropStory(DateTimePickerStory, 'DateTimePicker')}
    {commonPropStory(DatePickerStory, 'DatePicker')}
    {commonPropStory(TimePickerStory, 'TimePicker')}
    {commonPropStory(DropdownStory, 'Dropdown')}
    {commonPropStory(DropdownDurationPickerStory, 'DropdownDurationPicker')}
    {commonPropStory(DropdownTimePickerStory, 'DropdownTimePicker')}
  </Grid>
));
