import { Product } from '@bighealth/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';

export const find_with_reference = async (
  args: find_with_reference.Args
): Promise<find_with_reference.Response> => {
  return await api({
    service_name: 'Product',
    service_version: '2',
    service_method: 'find_with_reference',
    args: args,
  } as find_with_reference.Request);
};

find_with_reference.queryKey = 'Product/find_with_reference';

export declare namespace find_with_reference {
  export type Args = {
    reference: string;
  };

  export interface Request extends APIRequestBody {
    service_name: 'Product';
    service_version: '2';
    service_method: 'find_with_reference';
    args: Args;
  }

  export type Result = Product;

  export type Response = APIResponse<Result>;
}
