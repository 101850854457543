import React, { ReactElement } from 'react';
import { StyleSheet } from 'react-native';
const flatten = StyleSheet.flatten;

/**
  @TODO(JA): give this the same preload/caching ability of FastImage
  WHY: we will want preloading images for the web to avoid flashing there too
  WHEN: when the roadmap requires it
*/

const Image = ({
  style,
  fit,
  alt,
  src,
}: React.ImgHTMLAttributes<HTMLImageElement>): ReactElement => {
  const flattenedStyles = flatten(style);
  const moddedStyles: React.CSSProperties = {
    ...flattenedStyles,
    objectFit: fit,
  };
  return <img src={src} style={moddedStyles} alt={alt} />;
};

export default Image;
