import { PlayerState, initialPlayerState } from '../player/reducer';
import { DownloaderState, initialDownloaderState } from '../downloader/reducer';
import { DomainIDs } from 'common/constants/enums';
import {
  QuestionResponseState,
  initialQuestionResponseState,
} from 'state/question-response/reducer';
import {
  QuestionPathState,
  initialQuestionPathState,
} from 'state/question-path/reducer';
import { PersistPartial } from 'redux-persist/es/persistReducer';

export interface GlobalState {
  [DomainIDs.PLAYER]: PlayerState & PersistPartial;
  [DomainIDs.DOWNLOADER]: DownloaderState;
  [DomainIDs.QUESTION_RESPONSE]: QuestionResponseState;
  [DomainIDs.QUESTION_PATH]: QuestionPathState;
}

export const initialState: GlobalState = {
  [DomainIDs.PLAYER]: initialPlayerState,
  [DomainIDs.DOWNLOADER]: initialDownloaderState,
  [DomainIDs.QUESTION_RESPONSE]: initialQuestionResponseState,
  [DomainIDs.QUESTION_PATH]: initialQuestionPathState,
};

export default initialState;
