/* eslint-disable camelcase */
import { SceneSetGraph } from '@bighealth/types';
import {
  DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID,
  EDITOR_SCENESET_GRAPH_ID,
} from 'developer/constants';

export const DEVELOPER_COMPONENTS_TEST_SCENESET_GRAPH: SceneSetGraph = {
  id: DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID,
  menu_asset_temp_url: '',
  name: 'Components Test',
  reference: 'secret_developer_features',
};

export const EDITOR_SCENESET_GRAPH: SceneSetGraph = {
  id: EDITOR_SCENESET_GRAPH_ID,
  menu_asset_temp_url: '',
  name: 'Editor Preview',
  reference: 'secret_developer_features',
};
