import { matchPath } from 'cross-platform/react-router';
import * as History from 'history';

export type HistoryType = History.History;

export const matchesTitleScreen = (history: HistoryType): boolean =>
  !!matchPath(history.location.pathname, {
    path: '/:productReference',
    exact: true,
  });

export const matchesLogin = (history: HistoryType): boolean =>
  !!matchPath(history.location.pathname, {
    path: '/:productReference/login',
    exact: true,
  });

export const matchesHomeScreen = (history: HistoryType): boolean =>
  !!matchPath(history.location.pathname, {
    path: '/:productReference/home',
    exact: true,
  });

export const matchesResourcesScreen = (history: HistoryType): boolean =>
  !!matchPath(history.location.pathname, {
    path: '/:productReference/resources',
    exact: true,
  });

type PathParams = {
  productReference: string;
  sceneSetGraphId?: string;
  sceneSetId?: string;
  sceneId?: string;
};

export const matchesSceneSet = (history: HistoryType): boolean => {
  const short = matchPath<PathParams>(history.location.pathname, {
    path: '/:productReference/:sceneSetGraphId/:sceneSetId',
    exact: true,
  });
  const long = matchPath<PathParams>(history.location.pathname, {
    path: '/:productReference/:sceneSetGraphId/:sceneSetId/:sceneId',
    exact: true,
  });

  if (short) {
    const params = [short.params.sceneSetGraphId, short.params.sceneSetId];
    for (const param of params) {
      if (Number.isNaN(Number(param))) {
        return false;
      }
    }
    return true;
  }
  if (long) {
    const params = [
      long.params.sceneSetGraphId,
      long.params.sceneSetId,
      long.params.sceneId,
    ];
    for (const param of params) {
      if (Number.isNaN(Number(param))) {
        return false;
      }
    }
    return true;
  }
  return false;
};
