import {
  API_ENDPOINT_FULL_URL,
  PLATGEN_DATADOG_APPLICATION_ID,
  PLATGEN_DATADOG_CLIENT_TOKEN,
  STAGE,
} from 'config/envVars';
import { PRODUCT_STREAM_VERSION } from 'lib/strings';
import URL from 'url-parse';
import { RUMOptions } from 'lib/realUserMetrics';

export const getRUMConfig = (): RUMOptions => {
  // NOTE Nathan said not to use the web app url config value, it's value is not reliable so we parse the url from the api
  // react native does not properly support URL so I use a lightweight library
  const url = new URL(API_ENDPOINT_FULL_URL);
  return {
    clientToken: PLATGEN_DATADOG_CLIENT_TOKEN,
    applicationId: PLATGEN_DATADOG_APPLICATION_ID,
    // TODO: update to use correct version once we have some form of versioning
    version: PRODUCT_STREAM_VERSION,
    environment: STAGE,
    allowedTracingOrigins: [url.origin],
  };
};
