import * as React from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
import useActionHandler from 'lib/player/useActionHandler';
import { roles } from 'cross-platform/utils/roleProps';
import { stringify } from 'lib/stringify';
import { CardPresentation } from './components/CardPresentation';
import { Touchable } from './components/CardPresentation/styled';
import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

export type CardProps = get_user_session_screen_data.Result['session_list'][number] & {
  style?: StyleProp<ViewStyle>;
};

/**
 * Clicked to access a session
 *
 * @param {CardProps} param0
 */
const Card = ({
  heading,
  status,
  status_text,
  play_action,
  style,
}: CardProps): React.ReactElement => {
  const actionHandler = useActionHandler(
    play_action || undefined
  ) as React.ComponentProps<typeof Touchable>['onPress'];

  const scaleYFn = useTransformContentStyleVertical();
  const scaleYValue = scaleYFn(1);
  if (play_action?.type === 'jump_to_sceneset' && !play_action?.payload) {
    throw Error(
      `Expected play_action.payload to be defined if type is "jump_to_sceneset"`
    );
  }

  return (
    <CardPresentation
      {...roles('Card--' + heading.text)}
      style={Array.isArray(style) ? StyleSheet.flatten(style) : style}
      onPress={actionHandler}
      data-play_action={stringify({ status, play_action })}
      heading={heading}
      status={status}
      status_text={status_text}
      play_action={play_action}
      scaleValue={scaleYValue}
    />
  );
};
export { Card };
