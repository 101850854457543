import styled from 'styled-components/native';
import { ViewStyle, ScrollView as ScrollViewUnstyled } from 'react-native';
import { CloseButton as CloseButtonUnstyled } from '../CloseButton';

export const Wrapper = styled.View`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
`;
Wrapper.displayName = 'PopupModal.Wrapper';

export const OverlayTint = styled(Wrapper)<{
  overlayTintColor?: ViewStyle['backgroundColor'];
}>`
  background-color: ${p => p.overlayTintColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
OverlayTint.displayName = 'PopupModal.OverlayTint';
OverlayTint.defaultProps = {
  overlayTintColor: 'rgba(0, 51, 102, 0.8)',
};

export const Panel = styled.View`
  max-width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
`;
Panel.displayName = 'PopupModal.Panel';

export const CloseButton = styled(CloseButtonUnstyled)`
  position: absolute;
  right: 40px;
  top: 30px;
`;
CloseButton.displayName = 'PopupModal.CloseButton';

export const ScrollView = ScrollViewUnstyled;
