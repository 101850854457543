import { GlobalState } from '../store/initialState';
import { PlaybackState, PLAYER_STATUS } from './reducer';
import { DomainIDs } from 'common/constants/enums';

export const getPlayerStatus = (state: GlobalState): PLAYER_STATUS =>
  state[DomainIDs.PLAYER].status;

export const getControlsAreVisible = (state: GlobalState): boolean =>
  state[DomainIDs.PLAYER].controlsAreVisible;

export const getPlayerState = (state: GlobalState): PlaybackState =>
  state[DomainIDs.PLAYER].playerState;

export const getSceneSetRenderKey = (state: GlobalState): number =>
  state[DomainIDs.PLAYER].sceneSetRenderKey;
