import * as R from 'ramda';
import isPlainObject from 'is-plain-object';

type Key = string | number | symbol;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Value = any;

/**
 * @callback PredicateFn determines if key/value should be modified
 * @param {Value} key The key of the point of interest
 * @param {Value} value The value of the point of interest
 * @return {Boolean}
 */
export type PredicateFn = (key?: Key, value?: Value) => boolean;

/**
 * @callback TransformFn takes a value and modifies it
 * @param {Value} value The value of the point of interest
 * @return {Value}
 */
export type TransformFn = (value: Value) => Value;

/**
 * @callback MapFn applies transform if predicate passes
 * @param {Value} value The value of the point of interest
 * @return {Value}
 */
export type MapFn = (value: Value, key?: Key) => Value;

/**
 * Check if type of Array or Object
 * @param {Value} val
 * @return {Boolean}
 */
const isArrayOrObject = (val: Value): boolean =>
  R.or(R.is(Array, val), R.and(R.is(Object, val), isPlainObject(val)));

/**
 * Default predicate
 */
const NEVER_PASS: PredicateFn = (): boolean => false;

/**
 * Applies R.map for arrays and R.mapObjIndexed for objects
 *
 * @param {MapFn} mapFn map function
 * @param {Object|Array} val value to map over
 */
const mapCollection = (mapFn: MapFn, val: Value): Value =>
  R.is(Array, val) ? R.map(mapFn, val as Value[]) : R.mapObjIndexed(mapFn, val);

/**
 * Recursively maps over nested object
 *
 * @param {MapFn} mapFn
 * @param {Value} value current value
 */
const deepMap = (mapFn: MapFn, value: Value): Value =>
  mapCollection((val, key) => {
    const mappedVal = mapFn(val, key);
    return isArrayOrObject(mappedVal) ? deepMap(mapFn, mappedVal) : mappedVal;
  }, value);

/**
 * Object equivalent, of a conditional map for Arrays:
 *  1. deeply-walks object keys/values
 *  2. tests each key/value with a predicate function
 *  3. if predicate passes, value is transformed with a transform function
 * WARNING don't need to use if dealing with JSON only
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/parse}
 *
 * @param {Object} source the object to map over
 * @param {PredicateFn} predicateFn
 * @param {TransformFn} transformFn
 */
const deepMapWhere = (
  source: Record<Key, Value> | Value[],
  predicateFn: PredicateFn = NEVER_PASS,
  transformFn: TransformFn
): Record<Key, Value> =>
  deepMap(
    (val: Value, key?: Key): Value =>
      predicateFn(key, val) ? transformFn(val) : val,
    source
  );

export default deepMapWhere;
