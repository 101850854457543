import { Content, SpeechLocation } from 'components/layout/Content';
import React from 'react';
import { ContentAttribute } from 'components/constants';

type QuoteProps = {
  children: React.ReactNode;
};

export const QuoteLeft = (props: QuoteProps): React.ReactElement => (
  <Content
    content={ContentAttribute.QUOTE}
    speech={{ location: SpeechLocation.LEFT }}
    {...props}
  />
);

export const QuoteRight = (props: QuoteProps): React.ReactElement => (
  <Content
    content={ContentAttribute.QUOTE}
    speech={{ location: SpeechLocation.RIGHT }}
    {...props}
  />
);
