import { roles } from 'cross-platform/utils/roleProps';
import React, { ReactElement } from 'react';
import { TouchableOpacity } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

/**
 * Today's icon is a sun
 * @param size: the size of the square where the icon lives
 * @param color: the color of the icon
 */
export const XIcon = ({
  size,
  color,
  onPress,
}: {
  size: number;
  color: string;
  onPress?: () => void;
}): ReactElement => {
  return (
    <TouchableOpacity {...roles('XIcon')} onPress={onPress}>
      <Svg
        width={`${size}px`}
        height={`${size}px`}
        fill="none"
        viewBox={`0 0 ${size} ${size}`}
      >
        <G>
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.39548 8.49304L0.451218 2.54878C-0.131851 1.96571 -0.131851 1.02037 0.451218 0.437302C1.03429 -0.145767 1.97963 -0.145767 2.5627 0.437302L8.50696 6.38156L14.4512 0.437302C15.0343 -0.145767 15.9796 -0.145767 16.5627 0.437302C17.1458 1.02037 17.1458 1.96571 16.5627 2.54878L10.6184 8.49304L16.5627 14.4373C17.1458 15.0204 17.1458 15.9657 16.5627 16.5488C15.9796 17.1319 15.0343 17.1319 14.4512 16.5488L8.50696 10.6045L2.5627 16.5488C1.97963 17.1319 1.03429 17.1319 0.451218 16.5488C-0.131851 15.9657 -0.131851 15.0204 0.451218 14.4373L6.39548 8.49304Z"
            fill="#4A4A4A"
            stroke={color}
          />
        </G>
      </Svg>
    </TouchableOpacity>
  );
};
