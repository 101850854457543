import * as React from 'react';
import { ReactElement, useState } from 'react';
import useActionHandler from 'lib/player/useActionHandler';
import { ButtonProps } from './types';
import { UniversalButton } from './UniversalButton';
import { StyleSheet, ViewStyle } from 'react-native';
import { showControls } from 'state/player/actions';
import { useDispatch } from 'react-redux';
import { useLatestFocusedQuestion } from 'lib/question-response/useLatestFocusedQuestion';
import { mediaPlayerOrchestrator } from 'lib/player/media/MediaPlayerOrchestrator';
import { toggleVisibilityOrchestrator } from 'lib/player/media/ToggleVisibilityOrchestrator';

export const UniversalActionButton = (props: ButtonProps): ReactElement => {
  const unmounted = React.useRef(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const actionHandler = useActionHandler(props.action);
  const { setLatestFocusedQuestion } = useLatestFocusedQuestion();
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const handlePress = async (): Promise<void> => {
    // SceneComponents with click handlers should release any locks as they are
    // a signal that the user wants to do something. For instance, if the locks
    // are set as a result of the app being backgrounded then when the user
    // presses a button they are indicating they wish to proceed normally
    mediaPlayerOrchestrator.releasePlayLock();
    toggleVisibilityOrchestrator.releasePlayLock();
    dispatch(showControls(false));
    setLatestFocusedQuestion(undefined);
    if (typeof actionHandler === 'function') {
      setIsDisabled(true);
      try {
        await actionHandler();
      } finally {
        // In some occasions the setIsDisabled is called when the component is destroyed
        if (!unmounted.current) {
          setIsDisabled(false);
        }
      }
    }
  };
  const flatStyle: ViewStyle = Array.isArray(props.style)
    ? StyleSheet.flatten(props.style)
    : props.style;

  return (
    <UniversalButton
      {...props}
      isDisabled={props.isDisabled || isDisabled}
      style={{
        ...(flatStyle || {}),
        alignSelf: flatStyle?.alignSelf || 'center',
      }}
      onPress={handlePress}
    />
  );
};

UniversalActionButton.displayName = 'UniversalActionButton';
