import React, { ReactElement, useContext } from 'react';
import {
  ScalingContext,
  ScalingContextProvider,
  ScreenResponsiveLayoutContext,
} from 'components/ResponsiveLayout';
import { ExpandedNav } from './ExpandedNav';
import { CollapsedNav } from './CollapsedNav';
import { useNavigationBarContext } from './providers/NavigationBarProvider';

export const NavigationWithProvider = (): ReactElement => {
  const { isCollapsed } = useNavigationBarContext();
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  // NOTE: we are moving to a responsive layout not dependant device detection for navigation
  if (isCollapsed(screenWidth)) {
    return <CollapsedNav />;
  }

  return <ExpandedNav />;
};

export const GlobalNavigationBar = (): ReactElement => {
  return (
    <ScalingContextProvider scalingContext={ScalingContext.ContentScreen}>
      <NavigationWithProvider />
    </ScalingContextProvider>
  );
};
