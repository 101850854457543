import { Scene } from '@bighealth/types/src/scene-component';
import { StyleObject } from '@bighealth/types/src/scene-components/client';
import { FontFamilies } from './fonts';

export interface FlexAlignment {
  justifyContent: 'center' | 'flex-start' | 'flex-end';
  textAlign: 'center' | 'left' | 'right';
  flexDirection: 'column';
}

/**
 * Convert the product facing values for our text alignment
 * into flex compatible properties and values.
 */
export const getTextAlignmentConfig = (
  alignment?: Scene.Presentational.Alignment
): FlexAlignment => {
  let justifyContent: FlexAlignment['justifyContent'];
  if (alignment?.vertical === 'middle') {
    justifyContent = 'center';
  } else if (alignment?.vertical === 'bottom') {
    justifyContent = 'flex-end';
  } else if (alignment?.vertical === 'top') {
    justifyContent = 'flex-start';
  } else {
    justifyContent = 'flex-start';
  }

  let textAlign: FlexAlignment['textAlign'];
  if (alignment?.horizontal === 'middle') {
    textAlign = 'center';
  } else if (alignment?.horizontal) {
    textAlign = alignment?.horizontal;
  } else {
    textAlign = 'left';
  }

  return {
    justifyContent,
    textAlign,
    flexDirection: 'column',
  };
};

/**
 * Mobile requires specific font family names
 * to render the right weight and style.
 *
 * Take the style prop of the Text and use it
 * to deduce which font family CC actually wants
 */
export const getFontFamilyFromStyles = (
  style: StyleObject = {},
  italic = false
): typeof FontFamilies[keyof typeof FontFamilies] => {
  let fontFamily: typeof FontFamilies[keyof typeof FontFamilies] =
    FontFamilies.Regular;

  // WARNING fontWeight from styled components (typeof string) is not represented in in StyleObject (typeof number)
  const fontWeight = style?.fontWeight ? +style.fontWeight : -1;
  if (italic) {
    switch (fontWeight) {
      case 900:
        fontFamily = FontFamilies.BlackItalic;
        break;
      case 700:
        fontFamily = FontFamilies.BoldItalic;
        break;
      case 500:
        fontFamily = FontFamilies.MediumItalic;
        break;
      default:
      case 400:
        fontFamily = FontFamilies.Italic;
        break;
      case 200:
        fontFamily = FontFamilies.LightItalic;
        break;
    }
  } else {
    switch (fontWeight) {
      case 900:
        fontFamily = FontFamilies.Black;
        break;
      case 700:
        fontFamily = FontFamilies.Bold;
        break;
      case 500:
        fontFamily = FontFamilies.Medium;
        break;
      default:
      case 400:
        fontFamily = FontFamilies.Regular;
        break;
      case 200:
        fontFamily = FontFamilies.Light;
        break;
    }
  }

  return fontFamily;
};
