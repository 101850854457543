import React from 'react';
import { DefaultErrorFallback } from '../components/DefaultErrorFallback/DefaultErrorFallback';
import { ErrorBoundaryProps, FallbackProps } from '../types';

/**
 * IDEA: Don't use a default error fallback
 * WHEN: We never want default fallback behavior
 * WHY: Less complex internals, at cost of more usage complexity
 * WHO: Anyone
 */

/**
 * *Higher-order function*
 *
 * Decorates to DefaultErrorFallback with onRecover()
 *
 * @param {onRecover} decorateOptions.onRecover Reset to safe-state such that no error is thrown by render process
 * @returns
 */
const defaultRenderFallbackFactory = (
  decorateOptions: Pick<ErrorBoundaryProps, 'onRecover'>
) =>
  /**
   * DefaultErrorFallback decorated with onRecover
   *
   * @param props
   * @returns DefaultErrorFallback decorated with options ({ onRecover })
   */
  (props: Omit<FallbackProps, 'onRecover'>): React.ReactElement => {
    const fallbackProps = { ...decorateOptions, ...props };
    return <DefaultErrorFallback {...fallbackProps} />;
  };

export { defaultRenderFallbackFactory };
