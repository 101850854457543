import React from 'react';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import { LoadingWrapper } from './styled';
import { useTransformContentStyle } from 'components/ResponsiveLayout';

type Props = RoleProps;

const Loading = (props: Props): React.ReactElement => {
  const scaleFn = useTransformContentStyle();
  const scaleValue = scaleFn(1);
  return <LoadingWrapper {...roles.pass(props)} scaleValue={scaleValue} />;
};
export { Loading };
