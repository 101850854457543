import React, { ReactElement } from 'react';
import { addStory } from 'lib/story-books';
import { useFakeStorybookSceneSet } from 'lib/story-books/useFakeStorybookSceneSet';
import { Networked } from '../components/Networked';
import { mockPresentationPropsCalendarWeek } from './helpers/mockPresentationPropsCalendarWeek';
import { ArgPeriod } from '@bighealth/types/src/services/SleepDiaryPayloads/get_sleep_diary_component_data_for_time_period/';

const NetworkedStory = (): ReactElement | null => {
  const sceneSet = useFakeStorybookSceneSet();
  if (!sceneSet) {
    return null;
  }
  return (
    <Networked
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      initialPayload={mockPresentationPropsCalendarWeek}
      // only initial payload matters. Other props are ignored
      // The rest of the props are here simply to satisfy typescript
      includeMetadata={true}
      isReadOnly={true}
      period={'last_seven_days' as ArgPeriod}
      shouldShowContinueButton={true}
      isContinueButtonEnabled={true}
      minCountOfCompleteDiariesForContinueButtonToExtrapolate={5}
      maxCountOfCompleteDiariesForContinueButtonToExtrapolate={7}
      continueButtonText={'Continue'}
      titleAlignment={{ horizontal: 'left' }}
      title={{
        text: 'Sleep Diary',
        fontSize: 30,
        color: '#000000',
      }}
      subtitle={{
        fontSize: 24,
        color: '#003366',
      }}
      subtitleAlignment={{ horizontal: 'left' }}
    />
  );
};

addStory('SleepDiaryWeek/Network', () => <NetworkedStory />);
