// See Zeplin for these values: https://app.zeplin.io/project/5ea89f6fa9c2e9255ee771d0

export const MODAL_REFERENCE_DIMENSION = 670;

export const ModalStyles = {
  // NOTE: All these values are relative to the "670px" value above
  minWidthPx: 320,
  maxWidthPx: MODAL_REFERENCE_DIMENSION,
  modalVerticalPaddingPx: 36,
  modalHorizontalPaddingPx: 60,
  modalBreakpointMarginRightPx: 18,
  modalBorderRadiusPx: 4,
  largeScreenBreakpointPx: 1024,

  inputHeightPx: 57,
  inputBorderRadiusPx: 4,
  inputHorizontalPaddingPx: 16,
  inputVerticalPaddingPx: 16,
  inputPaddingRightExtraPx: 69,
  inputFontSizePx: 20,
  questionPreTextFontSizePx: 15,
  inputMarginBottomPx: 10,
  inputHorizontalScaleHorizontalPaddingPx: 5,
  inputEditFontSizePx: 16,
  inputLabelFontSizePx: 16,
  inputCaretSizePx: 40,
  inputIconSizePx: 24,
  inputIconMarginRightPx: 9,
  inputScaleLabelFontSizePx: 16,
  inputScaleLabelMarginPx: 18, // 20 in the design but we're offsetting the border

  modalQuestionTitleFontSizePx: 22,
  modalQuestionTitleHeightPx: 26,
  modalQuestionTitleMarginBottomPx: 16,
  modalQuestionPretextFontSizePx: 16,
  modalQuestionPretextHeightPx: 19,
  modalQuestionPretextMarginBottomPx: 4,
  modalQuestionMarginBottom: 30, // Design is 40 but we need to accommodate margin bottom from inputs,

  skeletonLoaderCellHeightPx: 69,
  skeletonLoaderCellBorderRadiusPx: 4,
  skeletonLoaderCellMarginBottomPx: 10,
  skeletonLoaderTitleHeightPx: 19,
  skeletonLoaderMarginBottomPx: 21,
} as const;
