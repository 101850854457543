import React, { ReactElement } from 'react';
import {
  StyleProp,
  TextStyle,
  View,
  ViewProps,
  ViewStyle,
  StyleSheet,
} from 'react-native';

import {
  ModalStyles,
  useTransformStylesToContext,
} from 'components/ResponsiveLayout';
import { Text } from 'components/Text';
import styled from 'styled-components/native';
import { StyleObject } from '@bighealth/types/src/scene-components/client';
const flatten = StyleSheet.flatten;

export const QuestionTitleContainer: React.FC<ViewProps> = ({
  style,
  ...rest
}: {
  style?: StyleProp<ViewStyle>;
}): ReactElement => {
  const transformStyles = useTransformStylesToContext();
  const styles = {
    ...flatten(style),
    paddingBottom: ModalStyles.modalQuestionTitleMarginBottomPx,
  };
  return (
    <View
      {...rest}
      // Needs coercing due to slight (and not meaningful) difference between types
      style={transformStyles((styles as unknown) as StyleObject) as ViewStyle}
    />
  );
};

const QuestionTitleStatic = styled(Text)`
  color: #282828;
`;

export const QuestionTitle: React.FC<{ style?: TextStyle }> = ({
  style,
  ...rest
}: {
  style?: TextStyle;
}): ReactElement => {
  const transformStyles = useTransformStylesToContext();
  const styles = {
    ...flatten(style),
    fontSize: ModalStyles.modalQuestionTitleFontSizePx,
  };
  return (
    <QuestionTitleStatic
      {...rest}
      style={transformStyles((styles as unknown) as StyleObject) as ViewStyle}
    />
  );
};

export const PreTextContainer: React.FC<ViewProps> = ({
  style,
  ...rest
}: {
  style?: StyleProp<ViewStyle>;
}): ReactElement => {
  const transformStyles = useTransformStylesToContext();
  const styles = {
    ...flatten(style),
    marginBottom: ModalStyles.modalQuestionPretextMarginBottomPx,
  };
  return (
    <View
      {...rest}
      style={transformStyles((styles as unknown) as StyleObject) as ViewStyle}
    />
  );
};

const QuestionPreTextStatic = styled(Text)`
  color: black;
`;

export const QuestionPreText: React.FC<{ style?: TextStyle }> = ({
  style,
  ...rest
}: {
  style?: TextStyle;
}): ReactElement => {
  const transformStyles = useTransformStylesToContext();
  const styles = {
    ...flatten(style),
    fontSize: ModalStyles.modalQuestionPretextFontSizePx,
  };
  return (
    <QuestionPreTextStatic
      {...rest}
      style={transformStyles((styles as unknown) as StyleObject) as ViewStyle}
    />
  );
};

const ResponsePreTextStatic = styled(Text)`
  color: #666666;
`;

export const ResponsePreText: React.FC<{ style?: TextStyle }> = ({
  style,
  ...rest
}: {
  style?: TextStyle;
}): ReactElement => {
  const transformStyles = useTransformStylesToContext();
  const styles = {
    ...flatten(style),
    fontSize: ModalStyles.modalQuestionPretextFontSizePx,
  };
  return (
    <ResponsePreTextStatic
      {...rest}
      style={transformStyles((styles as unknown) as StyleObject) as ViewStyle}
    />
  );
};

export const InputPadding: React.FC<ViewProps> = ({
  style,
  ...rest
}: {
  style?: StyleProp<ViewStyle>;
}): ReactElement => {
  const transformStyles = useTransformStylesToContext();
  const styles = {
    ...flatten(style),
    paddingBottom: ModalStyles.inputMarginBottomPx,
  };
  return (
    <View
      {...rest}
      style={transformStyles((styles as unknown) as StyleObject) as ViewStyle}
    />
  );
};
