import { ResponseOption, ResponseOptionValue } from '@bighealth/types';
import { TimeSerialized } from '@bighealth/types/src/services/SleepDiaryPayloads/types';
import stringify from 'fast-safe-stringify';
import { isDate } from 'lib/isDate';
import { isNumber } from 'lib/type-guarded';
import { MINUTE } from 'lib/durations';
import { DatePickerDisplayFormat } from 'components/generic-question/DateTimePickers';
import { format } from 'date-fns';

export const createError = (
  type: string,
  name: string,
  value?: ResponseOptionValue
): TypeError =>
  TypeError(
    `Expected ${name} to be ${type}, instead got: ${
      typeof value === 'object' || Array.isArray(value)
        ? stringify(value)
        : typeof value === 'string'
        ? `"${value}"`
        : value
    }, (${typeof value})`
  );

export const tryGetTime = (
  values: Record<React.ReactText, ResponseOption[]>,
  addr: string
): TimeSerialized => {
  const val = values[addr][0].value;
  if (!isDate(val)) {
    throw createError('Date', addr, val);
  }
  return {
    $time: format(val, DatePickerDisplayFormat.TIME_FORMAT),
  };
};

export const tryGetNumber = (
  values: Record<React.ReactText, ResponseOption[]>,
  name: string
): number => {
  const val = values[name][0].value;
  if (!isNumber(val)) {
    throw createError('number', name, val);
  }
  return val;
};

export const tryFromMillisecondsToMinutes = (
  values: Record<React.ReactText, ResponseOption[]>,
  name: string,
  defaultValue?: number
): number => {
  const actualValue = values?.[name]?.[0]?.value;
  if (typeof defaultValue === 'number') {
    if (actualValue === '' || typeof actualValue === 'undefined') {
      return defaultValue;
    }
  }
  const ms = tryGetNumber(values, name);
  const remainder = ms % MINUTE;
  if (remainder) {
    throw Error(
      `Expected ${name} to be round minute, but got ${remainder /
        MINUTE} minutes remainder`
    );
  }
  return ms / MINUTE;
};

export const tryParseInt = (
  values: Record<React.ReactText, ResponseOption[]>,
  name: string,
  defaultValue?: string
): number => {
  const value = values[name][0].value || defaultValue;
  if (typeof value === 'number') {
    return value;
  } else if (typeof value !== 'string') {
    throw createError(
      'string (for parseInt)',
      name,
      typeof values[name][0].value === 'undefined'
        ? defaultValue
        : values[name][0].value
    );
  }
  const valueAsNumber = parseInt(value, 10);
  if (!isNumber(valueAsNumber)) {
    throw createError('number', name, value);
  }
  return valueAsNumber;
};
