import React, { ReactElement, useContext } from 'react';
import { Platform, View } from 'react-native';
import { NavBarStyles } from 'components/Navigation/constants';
import {
  ScreenResponsiveLayoutContext,
  useGetDynamicContentStyles,
  useTransformContentStyle,
} from 'components/ResponsiveLayout';
import { PresentationProps } from '.';
import { SubTitle, Title } from './styled';

export const Header = (props: PresentationProps): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const scaleFn = useTransformContentStyle();
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  const isMobileWeb =
    screenWidth <= NavBarStyles.navBarCollapseMobileWebBreakPointPx &&
    Platform.OS === 'web';

  const titleProps = {
    text: props.title.text,
    alignment: props.titleAlignment,
  };

  const subtitleProps = {
    text: props.diaries.date_range_label.text,
    alignment: props.subtitleAlignment,
  };

  let subTitleStyle = {
    fontSize: scaleFn(props.subtitle.fontSize),
    color: props.subtitle.color,
    marginTop: styles.sleepDiaryWeekCalendarSubTitleMarginTopNarrow,
  };
  let titleStyle = {
    fontSize: scaleFn(props.title.fontSize),
    color: props.title.color,
  };

  if (isMobileWeb) {
    titleStyle = {
      fontSize: styles.sleepDiaryWeekCalendarTitleFontSizeNarrow,
      color: props.title.color,
    };

    subTitleStyle = {
      fontSize: styles.sleepDiaryWeekCalendarSubTitleFontSizeMobileWeb,
      color: props.subtitle.color,
      marginTop: styles.sleepDiaryWeekCalendarSubTitleMarginTopNarrow,
    };
  }

  return (
    <View
      style={{
        paddingTop: styles.sleepDiaryWeekCalendarContainerPaddingTop,
      }}
    >
      <Title {...titleProps} style={titleStyle} />
      <SubTitle {...subtitleProps} style={subTitleStyle} />
    </View>
  );
};
