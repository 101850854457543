/* eslint-disable @typescript-eslint/no-explicit-any */
import { matchPath, useHistory, useParams } from 'cross-platform/react-router';
import { useCallback } from 'react';
import { HttpStatusCode } from '@bighealth/api';
import { logout } from 'lib/api';
import { GENERAL_ERROR_MESSAGE } from 'lib/strings';
import { isDevMode } from 'lib/isDevMode';
import { queryClientRefreshToken } from 'lib/api/reactQueryHelpers';

type AnyFunction = (...args: any[]) => any;

// For a given function F of type "AnyFunction" it returns the type of its arguments, A.
type ArgumentsType<F extends AnyFunction> = F extends (...args: infer A) => any
  ? A
  : never;

export type WithImplementation<C extends AnyFunction> = <
  R extends ArgumentsType<C>
>(
  ...args: R
) => Promise<ReturnType<C>>;

type MatchParams = {
  params: {
    productReference: string;
  };
};

const useHandleHTTPResponseCallback = (): ((
  status_code: HttpStatusCode
) => void) => {
  const history = useHistory();
  // Seems like we can't use `useParams` due to the nested route. So we'll do it manually instead
  const match = matchPath(history.location.pathname, {
    path: '/:productReference/',
  }) as MatchParams;
  const productReference = match.params.productReference;
  const returnFunction = useCallback(
    (status_code: HttpStatusCode) => {
      if (status_code === HttpStatusCode.UNAUTHORIZED) {
        history.push(`/${productReference}`);
        throw Error(
          `Logged out${isDevMode() ? ` of ${productReference}` : ''}`
        );
      }
      if (status_code === HttpStatusCode.FORBIDDEN) {
        history.push(`/${productReference}`);
        throw new Error('Forbidden');
      }
      if (status_code >= 400) {
        history.push(`/${productReference}/home`);
        throw Error(GENERAL_ERROR_MESSAGE);
      }
    },
    [history, productReference]
  );
  return returnFunction;
};

export function useWithResponseHooks<C extends AnyFunction>(
  implementation: C
): WithImplementation<C> {
  const handleHTTPResponse = useHandleHTTPResponseCallback();
  const { productReference } = useParams();
  const history = useHistory();
  const returnFunction: WithImplementation<C> = useCallback(
    async (...args) => {
      let apiResult = await implementation(...args);
      // If unauthorized, might need a new refresh token so attempt to refresh
      if (apiResult.status_code === HttpStatusCode.UNAUTHORIZED) {
        const refreshResult = await queryClientRefreshToken();
        if (refreshResult.status_code >= 400) {
          // Even refreshToken failed, so let's just log the user out and start again
          await logout();
          history.push(`/${productReference}`);
        } else {
          // Try the request again, hopefully with the new token set successfully
          apiResult = await implementation(...args);
        }
      }
      handleHTTPResponse(apiResult.status_code);
      return apiResult;
    },
    [implementation, handleHTTPResponse, history, productReference]
  );
  return returnFunction;
}
