import React, { ReactElement } from 'react';
import { Redirect, useParams } from 'cross-platform/react-router';
import { DownloadsContent } from './DownloadsContent';
import { DownloadsScreenParams } from './types';
import { ContentNav } from '../components';
import {
  getFirstSlugForScreen,
  isNonEmptyPageData,
} from '../components/helpers';
import { ContentScreen } from '../ContentScreen';
import { useGetFullContentScreensPath } from 'components/Screens/ContentScreens/hooks/useGetFullContentScreensPath';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';
import { useQueryAllDownloads } from 'lib/api/reactQueryHelpers';
import { LoadingBlankScreen } from 'components/LoadingBlankScreen';

export const DownloadsScreen = (): ReactElement => {
  useUpdateTitle('Downloads');
  const { page } = useParams<DownloadsScreenParams>();

  const { isLoading, data } = useQueryAllDownloads();

  const getDownloadsScreensPath = useGetFullContentScreensPath();

  if (isLoading) {
    // the global throbber will be rendered
    return <LoadingBlankScreen />;
  }

  const isCompleteButIsOnRoot =
    !page && !isLoading && isNonEmptyPageData(data?.result);
  if (isCompleteButIsOnRoot) {
    return (
      <Redirect
        exact
        to={getDownloadsScreensPath(getFirstSlugForScreen(data?.result))}
      />
    );
  }

  // Else it's either loading and no data or is done and has data
  return (
    <ContentScreen
      navTitle={'Downloads'}
      nav={<ContentNav isLoading={isLoading} navigation={data?.result} />}
      content={
        <DownloadsContent isLoading={isLoading} content={data?.result} />
      }
    />
  );
};
