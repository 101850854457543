import React, { ReactElement } from 'react';
import { Redirect, useParams } from 'cross-platform/react-router';
import { LibraryContent } from './LibraryContent';
import { LibraryScreenParams } from './types';
import { ContentNav } from '../components';
import {
  getFirstSlugForScreen,
  isNonEmptyPageData,
} from '../components/helpers';
import { ContentScreen } from '../ContentScreen';
import { useGetFullContentScreensPath } from 'components/Screens/ContentScreens/hooks/useGetFullContentScreensPath';
import { ArticleContent } from './ArticleContent';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';
import {
  useQueryFullArticle,
  useQueryLibraryContent,
} from 'lib/api/reactQueryHelpers';
import { LoadingBlankScreen } from 'components/LoadingBlankScreen';

export const LibraryScreen = (): ReactElement => {
  useUpdateTitle('Library');
  const { page, articleId } = useParams<LibraryScreenParams>();
  const getLibraryScreensPath = useGetFullContentScreensPath();
  const articleResult = useQueryFullArticle({
    entity_id:
      articleId && !Number.isNaN(Number(articleId))
        ? Number(articleId)
        : undefined,
  });
  const libraryContentResult = useQueryLibraryContent({ search_words: [] });
  const isLoading = articleResult.isLoading || libraryContentResult.isLoading;
  const libraryData = libraryContentResult.data?.result;
  const articleEntity = articleResult.data?.result;

  if (isLoading) {
    // the global throbber will be rendered
    return <LoadingBlankScreen />;
  }

  // if we have data but no params, it needs redirection
  const isCompleteButIsOnRoot =
    !articleId && !page && !isLoading && isNonEmptyPageData(libraryData);
  if (isCompleteButIsOnRoot) {
    return (
      <Redirect
        exact
        to={getLibraryScreensPath(getFirstSlugForScreen(libraryData))}
      />
    );
  }

  return typeof articleId !== 'undefined' && articleId !== null ? (
    <ContentScreen
      navTitle={'Library'}
      nav={<ContentNav isLoading={isLoading} navigation={libraryData} />}
      content={
        <ArticleContent
          isLoading={articleEntity === undefined}
          article={articleEntity}
          content={libraryData}
        />
      }
    />
  ) : (
    <ContentScreen
      navTitle={'Library'}
      nav={<ContentNav isLoading={isLoading} navigation={libraryData} />}
      content={<LibraryContent isLoading={isLoading} content={libraryData} />}
    />
  );
};
