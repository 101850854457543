import React, { ReactElement } from 'react';
import { Dropdown, DropdownOption } from '../Dropdown';
import { deriveDurationResponseOptions } from './helpers/deriveDurationResponseOptions';
import { createValueChangeHandler } from './helpers/createValueChangeHandler';
import { formatDurationDisplayText } from './helpers/formatDurationDisplayText';
import { valueToDropdownItem } from './helpers/valueToDropdownItem';
import { findSelectedOption } from './helpers/findSelectedOption';

type DropdownDurationPickerProps = {
  min?: number;
  max?: number;
  selectedValue?: number | '';
  onValueChange?: (value: number | null) => void;
  highlight?: boolean;
};
const DropdownDurationPicker = ({
  min,
  max,
  selectedValue,
  onValueChange,
  highlight,
}: DropdownDurationPickerProps): ReactElement => {
  const options = deriveDurationResponseOptions({ min, max });
  const selectedOption = findSelectedOption({ options, selectedValue });
  const selectedOptionAsDropdownItem = valueToDropdownItem(selectedOption);

  return (
    <Dropdown
      value={selectedOptionAsDropdownItem}
      onValueChange={createValueChangeHandler(onValueChange)}
      highlight={highlight}
    >
      {options.map(val => (
        <DropdownOption
          key={`${val}`}
          value={`${val}`}
          displayText={formatDurationDisplayText(val)}
        />
      ))}
    </Dropdown>
  );
};
export { DropdownDurationPicker };
