import { useLocation } from 'react-router-dom'; // Note: web only

/**
 * Hook that uses react-router's useLocation to parse
 * the query string:
 *
 * @usage
 *    const query = useQuery();
 *    query.get("name");
 */
function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

export { useQuery };
