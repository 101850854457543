import React, { ReactElement } from 'react';
import { ButtonTypes } from 'common/constants/enums';
import ActionButton from './ActionButton';
import LinkButton from './LinkButton';
import { StyleSheet } from 'react-native';
import { CSSProperties } from 'styled-components';
import { SceneAction } from '@bighealth/types/src/scene-components/client';
import { PressHandler } from './common';
const flatten = StyleSheet.flatten;

export { LinkButton, ActionButton };
export * from './common';

/**
 * A Button that switches based on whether it's supplied "to" or an action
 */

console.warn(
  'Developer: all exports from components/Button are all deprecated. Please use UniversalButton versions'
);
const Button = ({
  to,
  text,
  type = ButtonTypes.PRIMARY_BUTTON,
  align,
  action,
  style,
  isDisabled = false,
  onPress,
  ...rest
}: {
  to?: string;
  text: string;
  align?: string;
  type?: ButtonTypes;
  action?: SceneAction;
  style?: CSSProperties;
  isDisabled?: boolean;
  onPress?: PressHandler;
}): ReactElement => {
  if (action) {
    return (
      <ActionButton
        text={text}
        action={action}
        type={type}
        align={align}
        style={flatten(style)}
        isDisabled={isDisabled}
        onPress={onPress}
        {...rest}
      />
    );
  }
  if (typeof onPress !== 'undefined' && to) {
    throw Error(
      'Link Button (no action) cannot have onPress ' +
        JSON.stringify({ text, type, align, to })
    );
  }
  return (
    <LinkButton
      text={text}
      type={type}
      align={align}
      to={to}
      style={flatten(style)}
      isDisabled={isDisabled}
      {...rest}
    />
  );
};

export default Button;
