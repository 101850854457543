import * as React from 'react';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import { ImageFromResponse } from './components/ImageFromResponse';
import { WithNetworkAndState } from '../WithNetworkAndState';
import { Providers } from './components/Providers';
import { VerticalScroll } from 'components/VerticalScroll/VerticalScroll';
import { Footer, FooterTheme } from 'components/Footer';

/**
 * SessionScreen: Providers and "page" wrappers
 */
export const WithNetworkAndStateAndWrappers: React.FC<RoleProps> = () => {
  return (
    <Providers>
      <>
        <ImageFromResponse />
        <VerticalScroll {...roles('SessionScreenVerticalScroll')}>
          <WithNetworkAndState />
          <Footer footerTheme={FooterTheme.Dark} />
        </VerticalScroll>
      </>
    </Providers>
  );
};
