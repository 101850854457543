import styled from 'styled-components/native';
import Image from 'cross-platform/Image';
import { ResizeMode } from 'cross-platform/Image';

// "Ink-dropped" from image's primary background color
const APPROX_BACKGROUND_IMAGE_COLOR = '#A9DDF5';

export const BackgroundImage = styled(Image).attrs({
  fit: ResizeMode.cover,
})`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${APPROX_BACKGROUND_IMAGE_COLOR};
`;
BackgroundImage.displayName = 'BackgroundImage';
