import { DAY, HOUR } from 'lib/durations';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { QuestionId } from 'state/question-response/actions';
import { getSelected } from '../../helpers/getSelected';
import { WarningAndHighlight } from '..';

/**
 *
 * @param {Record<QuestionId, DropdownItem[]>} inputs from `<ResponseForm onValidate={inputs => {}} />`
 */
const getLeaveBedTimeGreaterThan12Hours = (
  inputs: Record<QuestionId, DropdownItem[]>
): Record<QuestionId, Partial<WarningAndHighlight>> | null => {
  const finalAwakening = getSelected(
    inputs['sleep_efficiency_time_final_awakening']
  )?.valueOf();
  const getOutOfBed = getSelected(
    inputs['sleep_efficiency_time_get_out_of_bed']
  )?.valueOf();

  if (typeof finalAwakening === 'number' && typeof getOutOfBed === 'number') {
    // Crosses over midnight 🕛
    const getOutOfBedFuture =
      finalAwakening > getOutOfBed ? getOutOfBed + DAY : getOutOfBed;
    if (getOutOfBedFuture - finalAwakening > 12 * HOUR) {
      return {
        sleep_efficiency_time_get_out_of_bed: {
          highlight: true,
          warning:
            'That’s a very long time to be awake before getting out of bed. Are you sure?',
        },
        sleep_efficiency_time_final_awakening: { highlight: true },
      };
    }
  }
  return null;
};

export { getLeaveBedTimeGreaterThan12Hours };
