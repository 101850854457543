import {
  DEVELOPER_AV_SCENESET_ID,
  DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID,
  DEVELOPER_COMPONENTS_SCENESET_ID,
  DEVELOPER_RESPONSIVE_SCENESET_ID,
  EDITOR_SCENESET_GRAPH_ID,
} from 'developer/constants';
import {
  DEVELOPER_AV_TEST_SCENESET,
  DEVELOPER_COMPONENTS_TEST_SCENESET,
  DEVELOPER_COMPONENTS_TEST_SCENESET_GRAPH,
  DEVELOPER_RESPONSIVE_TEST_SCENESET,
  EDITOR_SCENESET_GRAPH,
} from 'developer/data';
import { SceneSet, SceneSetGraph } from '@bighealth/types';

export const isEditorSSG = (ssgId: number | undefined): boolean =>
  String(ssgId) === String(EDITOR_SCENESET_GRAPH_ID);

const isDeveloperComponentsSceneSet = (
  sceneSetId: number | undefined
): boolean => String(sceneSetId) === String(DEVELOPER_COMPONENTS_SCENESET_ID);

export const isHardCodedSSG = (ssgId: number | undefined): boolean =>
  isEditorSSG(ssgId) || isDeveloperSSG(ssgId);

const isDeveloperResponsiveSceneSet = (
  sceneSetId: number | undefined
): boolean => String(sceneSetId) === String(DEVELOPER_RESPONSIVE_SCENESET_ID);

const isDeveloperAVSceneSet = (sceneSetId: number | undefined): boolean =>
  String(sceneSetId) === String(DEVELOPER_AV_SCENESET_ID);

const isDeveloperSSG = (ssgId: number | undefined): boolean =>
  String(ssgId) === String(DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID);

export const getWhichHardCodedSceneSetGraph = (
  sceneSetGraphId: number
): SceneSetGraph | undefined => {
  if (isEditorSSG(sceneSetGraphId)) {
    return EDITOR_SCENESET_GRAPH;
  }
  if (isDeveloperSSG(sceneSetGraphId)) {
    return DEVELOPER_COMPONENTS_TEST_SCENESET_GRAPH;
  }
  return undefined;
};

export const getWhichDeveloperSceneSet = (
  sceneSetGraphId: number | undefined,
  sceneSetId: number | undefined
): SceneSet | undefined => {
  if (!isDeveloperSSG(sceneSetGraphId)) {
    return undefined;
  }
  if (isDeveloperResponsiveSceneSet(sceneSetId)) {
    return DEVELOPER_RESPONSIVE_TEST_SCENESET;
  }
  if (isDeveloperComponentsSceneSet(sceneSetId)) {
    return DEVELOPER_COMPONENTS_TEST_SCENESET;
  }
  if (isDeveloperAVSceneSet(sceneSetId)) {
    return DEVELOPER_AV_TEST_SCENESET;
  }
  return undefined;
};
