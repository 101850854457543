import { combineReducers, Action, CombinedState } from 'redux';
import { downloaderReducer } from '../downloader/reducer';
import { questionResponseReducer } from '../question-response/reducer';
import { questionPathReducer } from 'state/question-path/reducer';
import { DomainIDs } from 'common/constants/enums';
import { persistReducer } from 'redux-persist';
import { GlobalState, initialState } from './initialState';
import { STATE_RESET } from 'state/store/actions';
import storage from '@react-native-community/async-storage';
import logger from 'lib/logger';
import { authPersistConfig, playerReducer } from 'state/player/reducer';

export const rootReducersCombined = combineReducers({
  [DomainIDs.PLAYER]: persistReducer(authPersistConfig, playerReducer),
  [DomainIDs.DOWNLOADER]: downloaderReducer,
  [DomainIDs.QUESTION_RESPONSE]: questionResponseReducer,
  [DomainIDs.QUESTION_PATH]: questionPathReducer,
});

type CombinedStateType = CombinedState<GlobalState>;

const rootReducer = (
  state: CombinedStateType,
  action: Action
): CombinedStateType => {
  if (action.type === STATE_RESET) {
    try {
      // @NOTE: These are async, if they fail they'll
      // bubble up to this try/catch as an unhandled promise rejection
      // and we handle here. Might be worth moving this into
      // the component that implements the logout action.
      storage.removeItem('persist:root');
      storage.removeItem('persist:auth');
    } catch (storageError) {
      logger('An error occurred while trying to clear state');
      logger(storageError);
    }
    return rootReducersCombined(initialState, action);
  } else {
    return rootReducersCombined(state, action);
  }
};
export default rootReducer;
