import { bookNextSession } from 'lib/api';
import logger from 'lib/logger';
import { format } from 'date-fns';
import { book_next_session } from '@bighealth/api/SessionProgress/v1';

async function submitScheduleNextSession({
  productId,
  nextSessionDate,
  userTimezone,
}: {
  productId: number;
  nextSessionDate: Date;
  userTimezone: string;
}): Promise<[book_next_session.Result | null, Error | null]> {
  try {
    const response = await bookNextSession({
      productId: productId,
      nextSessionDateStr: format(nextSessionDate, 'yyyy-MM-dd HH:mm:SS'),
      userTimezone: userTimezone,
    });
    if (response.status_code !== 200) {
      logger('Error when booking the next session');
      return [null, new Error('Error when booking the next session')];
    }
    return [response.result, null];
  } catch (e) {
    logger(e);
    return [null, e];
  }
}

export { submitScheduleNextSession };
