import React, { ReactElement, ReactNode } from 'react';
import {
  contentStyles,
  ScalingContext,
  ScalingContextProvider,
  useGetDynamicContentStyles,
} from 'components/ResponsiveLayout';
import styled from 'styled-components/native';
import { View } from 'react-native';
import * as roleProps from 'cross-platform/utils/roleProps';
import { Footer } from 'components/Footer';
import {
  ContentPlaceholderText,
  PageTitle,
  ScreenScroll,
} from '../components/components';

export const ContainerStatic = styled.View`
  flex-direction: column;
  align-items: center;
  background-color: white;
  flex: 1;
  justify-content: flex-start;
`;

export const Container = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const {
    pagePaddingRight,
    pagePaddingLeft,
    pagePaddingBottom,
  } = useGetDynamicContentStyles();
  return (
    <ContainerStatic
      {...roleProps.roles('ContainerStatic')}
      style={{
        flex: 1,
        paddingLeft: pagePaddingLeft,
        paddingRight: pagePaddingRight,
        paddingBottom: pagePaddingBottom,
      }}
    >
      <View
        style={{
          width: '100%',
          maxWidth:
            contentStyles.contentWidthMax +
            contentStyles.navWidthMax +
            contentStyles.navMarginRightMaxWidth,
        }}
      >
        {children}
      </View>
    </ContainerStatic>
  );
};

type ResponsivePlaceholderProps = {
  placeholderText: string;
  title: string;
};

export const ResponsivePlaceholder = ({
  title,
  placeholderText,
}: ResponsivePlaceholderProps): ReactElement => {
  return (
    <ScreenScroll>
      <Container>
        <PageTitle>{title}</PageTitle>
        {placeholderText.split(/[\r\n/]/).map((text, index) => (
          <ContentPlaceholderText key={index}>{text}</ContentPlaceholderText>
        ))}
      </Container>
      <Footer />
    </ScreenScroll>
  );
};

export const ContentPlaceholder = (
  props: ResponsivePlaceholderProps
): ReactElement => (
  <ScalingContextProvider scalingContext={ScalingContext.ContentScreen}>
    <ResponsivePlaceholder {...props} />
  </ScalingContextProvider>
);
