import { useTransformStylesToContext } from './useTransformStylesToContext';
import { ModalStyles } from '../constants';

type InputStyles = {
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
  paddingTop: number;
  borderRadius: number;
  height: number;
  fontSize: number;
  inputEditFontSize: number;
  labelFontSize: number;
  caretSize: number;
  iconSize: number;
  inputIconMarginRight: number;
  inputPaddingRightExtra: number;
  inputScaleLabelFontSize: number;
  inputScaleLabelMargin: number;
};

export const useGetDynamicInputStyles = (): InputStyles => {
  const transformStyles = useTransformStylesToContext();
  const styles = {
    height: ModalStyles.inputHeightPx,
    borderRadius: ModalStyles.inputBorderRadiusPx,
    paddingLeft: ModalStyles.inputHorizontalPaddingPx,
    paddingRight: ModalStyles.inputHorizontalPaddingPx,
    paddingTop: ModalStyles.inputVerticalPaddingPx,
    paddingBottom: ModalStyles.inputVerticalPaddingPx,
    fontSize: ModalStyles.inputFontSizePx,
    inputEditFontSize: ModalStyles.inputEditFontSizePx,
    labelFontSize: ModalStyles.inputLabelFontSizePx,
    caretSize: ModalStyles.inputCaretSizePx,
    iconSize: ModalStyles.inputIconSizePx,
    inputIconMarginRight: ModalStyles.inputIconMarginRightPx,
    inputPaddingRightExtra: ModalStyles.inputPaddingRightExtraPx,
    inputScaleLabelFontSize: ModalStyles.inputScaleLabelFontSizePx,
    inputScaleLabelMargin: ModalStyles.inputScaleLabelMarginPx,
  };
  return transformStyles(styles) as InputStyles;
};
