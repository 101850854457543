import { ErrorModalWithContext } from './ErrorModal/Contextualized/ErrorModalWithContext';
import { ErrorBoundaryForErrorModal } from './ErrorModal/Contextualized/components/ErrorBoundaryForErrorModal/ErrorBoundaryForErrorModal';
import { ErrorModalProvider } from 'components/SceneSetView/components/ErrorModal/Contextualized/ErrorModalWithContext';
import React from 'react';
import { ErrorDetailsApi } from 'lib/error/hooks/useErrorDetailsState';
import { ErrorModalContext } from './ErrorModal/Contextualized/components/ErrorModalContext';

export const ErrorBoundary = ErrorBoundaryForErrorModal;
export const ErrorModal = ErrorModalWithContext;
export const Provider = ErrorModalProvider;
export const Context = ErrorModalContext;
export const useContext = (): Partial<ErrorDetailsApi> => {
  const context = React.useContext(ErrorModalContext);
  return context;
};
