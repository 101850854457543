import { get_assigned_program_with_user_and_product } from '@bighealth/api/UserAccountAuthorization/v1';
import { getQueryClient } from 'components/ProvidersContainer';

export const queryClientProgram = async ({
  product_id,
}: {
  product_id: number;
}): Promise<get_assigned_program_with_user_and_product.Response> => {
  const result = await getQueryClient().fetchQuery(
    get_assigned_program_with_user_and_product.queryKey,
    () =>
      get_assigned_program_with_user_and_product({
        product_id: product_id,
      }),
    {
      cacheTime: Infinity,
    }
  );
  return result;
};
