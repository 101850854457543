import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { APIErrorResponse } from '@bighealth/api';
import { get_sleep_diary_component_data_for_time_period } from '@bighealth/api/SleepDiary/v1';
import { getTimezone } from 'lib/timezone';
import { useQueryProgram } from './useQueryProgram';
import { useQueryProduct } from './useQueryProduct';

// Hack (for expediency) to keep TypeScript happy without having to refined the types
type WithOffsetType = get_sleep_diary_component_data_for_time_period.Args & {
  calendar_week_offset: number;
};

export const useQuerySleepDiaryWeekly = (
  args: Omit<
    get_sleep_diary_component_data_for_time_period.Args,
    'product_id' | 'program_id' | 'user_timezone'
  >,
  queryHash: number | undefined
): UseQueryResult<
  get_sleep_diary_component_data_for_time_period.Response,
  APIErrorResponse
> => {
  const programId = useQueryProgram()?.data?.result.id;
  const productId = useQueryProduct()?.data?.result.id;
  // See type declaration above for reason behind this hack
  const offsetExists = args as WithOffsetType;
  const result = useQuery({
    queryKey: [
      [
        get_sleep_diary_component_data_for_time_period.queryKey,
        offsetExists.calendar_week_offset || 0,
      ],
      queryHash,
    ],
    queryFn: () =>
      get_sleep_diary_component_data_for_time_period({
        ...args,
        user_timezone: getTimezone(),
        product_id: productId as number,
        program_id: programId as number,
      }),
    enabled: typeof programId === 'number' && typeof productId === 'number',
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    staleTime: 0,
    cacheTime: 0,
  });
  return result;
};
