/* istanbul ignore file */
import React, { ReactElement, useEffect, useState } from 'react';
import { getAssetsForProduct } from 'config/index';
import { ButtonTypes } from 'common/constants/enums';
import { ResizeMode } from 'cross-platform/Image';
import TextInput from 'components/TextInput';
import Throbber from 'components/Throbber';
import { Redirect, useHistory, useParams } from 'cross-platform/react-router';
import {
  ButtonContainer,
  FormattedText,
  LimitWidth,
  Logo,
  LogoLink,
  PaddingContainer,
} from './styled';
import {
  LoginPhases,
  useBigHealthLogin,
} from 'lib/api/hooks/useBigHealthLogin';
import logger from 'lib/logger';
import { FillAbsoluteCenter, ScrollableContainer } from 'components/Containers';
import styled from 'styled-components/native';
import { TouchableOpacity } from 'components/primitives/cross-platform';

import {
  UniversalButton,
  ButtonSizes,
  ButtonColorSchemes,
} from 'components/UniveralButtons';
import { roles } from 'cross-platform/utils/roleProps';
import { showQAMenu } from 'lib/showQAMenu';

const PaddedContainer = styled(FillAbsoluteCenter)`
  padding-bottom: 23%;
`;

export const LoginScreen = (): ReactElement => {
  const { login, error, status } = useBigHealthLogin();
  const { productReference } = useParams();
  const { splashLogoUrl } = getAssetsForProduct(productReference as string);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (error) {
      logger(error.message, error);
    }
  }, [error]);

  const handleSubmit = async (): Promise<void> => {
    await login({ email, password });
  };

  if (status === LoginPhases.SUCCESS) {
    return <Redirect to={`/${productReference}/home`} />;
  }

  const onLogoPress = (): void => {
    showQAMenu(productReference as string, history);
  };

  return (
    <PaddedContainer>
      <LimitWidth>
        <LogoLink to={`/${productReference}`}>
          <TouchableOpacity testID={'DeveloperButton'} onPress={onLogoPress}>
            <Logo src={splashLogoUrl} fit={ResizeMode.contain} />
          </TouchableOpacity>
        </LogoLink>
        <PaddingContainer>
          <FormattedText>Login</FormattedText>
        </PaddingContainer>
        <ButtonContainer>
          <ScrollableContainer>
            <PaddingContainer>
              <TextInput
                {...roles('LoginInput-email')}
                onChangeText={(value: string): void => setEmail(value)}
                placeholder={'Email'}
                presentationType={ButtonTypes.LARGE_CTA}
                required={true}
                autoFocus={true}
                value={email}
              />
            </PaddingContainer>
            <PaddingContainer>
              <TextInput
                {...roles('GenericInput-password')}
                onChangeText={(value: string): void => setPassword(value)}
                placeholder={'Password'}
                presentationType={ButtonTypes.LARGE_CTA}
                required={true}
                value={password}
                secureTextEntry={true}
              />
            </PaddingContainer>
            <UniversalButton
              {...roles('Login-button')}
              isDisabled={!(password && email)}
              onPress={handleSubmit}
              text={'Submit'}
              colorScheme={ButtonColorSchemes.Login}
              size={ButtonSizes.Small}
            />
          </ScrollableContainer>
        </ButtonContainer>
      </LimitWidth>
      {status === LoginPhases.FETCHING ? <Throbber /> : null}
    </PaddedContainer>
  );
};

export default LoginScreen;
