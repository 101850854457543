import React, { ReactElement } from 'react';
import { RouteProps } from 'react-router';
import {
  SceneComponentContextProvider,
  SceneComponentContextType,
} from './SceneComponentContext';
import { useQuerySendSessionAnalytics } from 'lib/api/reactQueryHelpers/useQueryAnalogs/useQuerySendSessionAnalytics';
import * as contentErrors from 'components/SceneSetView/components/contentErrors';
import { getIsDebugThrowInSceneSet } from 'config/index';
import { DebugButtonThrow } from 'components/DebugButtonThrow';

/**
 *
 * "Scene"
 * @param routeProps
 * @constructor
 */

export const Scene = ({ children }: RouteProps): ReactElement | null => {
  useQuerySendSessionAnalytics();
  if (getIsDebugThrowInSceneSet()) {
    // TODO remove this if statement
    // WHEN PG-1066 passed QA
    // WHY Only required for QA of error-handling
    //    in prod-like environment
    return <DebugButtonThrow />;
  }
  return (
    <SceneComponentContextProvider
      sceneComponentContext={SceneComponentContextType.Scene}
    >
      <contentErrors.ErrorBoundary>{children}</contentErrors.ErrorBoundary>
    </SceneComponentContextProvider>
  );
};
