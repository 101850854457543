import { OnAuthCallback } from 'components/AuthButtons';
import { getLoginStatusAsync, loginAsync } from './facebookAPIMethods';

export type FacebookAuthResponseWeb = {
  authResponse: {
    accessToken: string;
    expiresIn: number;
    signedRequest: string;
    graphDomain: 'facebook';
    data_access_expiration_time: string;
  };
  status: 'connected' | 'not_authorized' | 'unknown';
};

export const handleFacebookSignInWeb = async (
  onAuth: OnAuthCallback
): Promise<void> => {
  try {
    // Check login status first before attempting a hard login because hard login issues
    // a new token which is quite a pain to support in the current Milestone 2 API
    const loginState = await getLoginStatusAsync();
    if (loginState.status == 'connected') {
      // Great, there's a valid token. We can just return that.
      onAuth({ token: loginState.authResponse.accessToken });
    } else {
      // There isn't a valid token so we need to login
      const newLoginData = await loginAsync();
      const newToken = newLoginData?.authResponse?.accessToken;
      if (newToken) {
        onAuth({ token: newLoginData.authResponse.accessToken });
      } else {
        throw Error("Couldn't log in to Facebook for some unknown reason");
      }
    }
  } catch (e) {
    onAuth(e);
  }
};
