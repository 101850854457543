import { getQueryClient } from 'components/ProvidersContainer';
import { refresh_access_token } from '@bighealth/api/UserAccountAuthentication/v1';

export const queryClientRefreshToken = async (): Promise<refresh_access_token.Response> => {
  // @TODO: add polling here
  // @WHEN: when we are confident the token refreshing code (as of writing there is a bug in the
  // backend related to duplicate tokens that could make this polling incorrectly fail
  return await getQueryClient().fetchQuery(refresh_access_token.queryKey, () =>
    refresh_access_token()
  );
};
