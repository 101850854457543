import { useEffect, useMemo, useRef, useState } from 'react';
import { SceneSet } from '@bighealth/types';
import { useLazyQueryDownloadSceneSetAssets } from '../useLazyQueries/useLazyQueryDownloadSceneSetAssets';

export type QueryDownloadOptions = {
  enabled?: boolean;
};

type IdleState = {
  isLoading: false;
  error: undefined;
  isSuccess: false;
  isError: false;
  status: 'idle';
};

export const idleState: IdleState = {
  isLoading: false,
  error: undefined,
  isSuccess: false,
  isError: false,
  status: 'idle',
};

type LoadingState = {
  isLoading: true;
  error: undefined;
  status: 'loading';
  isSuccess: false;
  isError: false;
};

export const loadingState: LoadingState = {
  isLoading: true,
  error: undefined,
  isSuccess: false,
  isError: false,
  status: 'loading',
};

type SuccessState = {
  isLoading: false;
  error: undefined;
  isSuccess: true;
  status: 'success';
  isError: false;
};

export const successState: SuccessState = {
  isLoading: false,
  error: undefined,
  isSuccess: true,
  isError: false,
  status: 'success',
};

type ErrorState = {
  isLoading: false;
  error: string;
  isSuccess: false;
  isError: true;
  status: 'error';
};

const errorState: ErrorState = {
  isLoading: false,
  error: 'unknown error',
  isSuccess: false,
  isError: true,
  status: 'error',
};

export type QueryDownloadResponse =
  | IdleState
  | LoadingState
  | SuccessState
  | ErrorState;

/**
 *
 * a wrapper to downloader that has a similar interface to useQuery. Uses redux to "cache"
 */

export type UseQueryDownloadsProps = {
  sceneSetJSON: SceneSet | undefined;
  enabled?: boolean;
};

export const useQueryDownloadAssetsForSceneSet = ({
  sceneSetJSON,
  enabled,
}: UseQueryDownloadsProps): QueryDownloadResponse => {
  const downloadAssets = useLazyQueryDownloadSceneSetAssets();
  const prevEnabled = useRef(enabled);

  const [state, setState] = useState<QueryDownloadResponse>(idleState);
  useEffect(() => {
    if (enabled && state.status === 'idle') {
      // first load
      setState(loadingState);
    } else if (prevEnabled.current === false && enabled === true) {
      // In this case we have received the signal to re-fetch once. We use the switch
      // to "loadingState" to trigger the useEffect that downloads the assets
      setState(loadingState);
    }
    prevEnabled.current = enabled;
  }, [enabled, state.status]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (state.status !== 'loading' && enabled) {
      return;
    }
    (async () => {
      try {
        await downloadAssets(sceneSetJSON);
        setState(successState);
      } catch (e) {
        setState({
          ...errorState,
          error: e.message,
        });
      }
    })();
  }, [
    downloadAssets,
    enabled,
    sceneSetJSON,
    state,
    state.isLoading,
    state.isSuccess,
    state.status,
  ]);
  return useMemo(() => state, [state]) as QueryDownloadResponse;
};
