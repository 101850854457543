import { CardProps } from '../..';
import {
  ThemeByStatus,
  THEME_BY_STATUS,
} from '../../constants/THEME_BY_STATUS';

export type StatusProps = Pick<CardProps, 'status'>;

/**
 * Configure callback for use by styled-component
 *
 * @param {keyof ThemeByStatus} property
 * @example
 *
 * ```tsx
 * const Thing = styled.View`
 *  color: ${byStatus('color')};
 * `;
 * ```
 */
export const byStatus = (property: keyof ThemeByStatus) =>
  /**
   * Callback for styled-component
   */
  (props: StatusProps): string | number =>
    THEME_BY_STATUS[props.status][property];
