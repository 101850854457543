import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { DownloadsScreen } from '@bighealth/types';

export const get_all_downloads = async (): Promise<get_all_downloads.Response> => {
  return await api({
    service_name: 'SleepioDownload',
    service_version: '1',
    service_method: 'get_all_downloads',
    args: {},
  } as get_all_downloads.Request);
};

get_all_downloads.queryKey = 'SleepioDownload/get_all_downloads';

export declare namespace get_all_downloads {
  export interface Request extends APIRequestBody {
    service_name: 'SleepioDownload';
    service_version: '1';
    service_method: 'get_all_downloads';
    args: {};
  }

  export type Result = DownloadsScreen;

  export type Response = APIResponse<Result>;
}
