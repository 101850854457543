import * as Sentry from '@sentry/react';
import { PLATGEN_SENTRY_DSN } from 'config/envVars';

export const NATIVE_CRASH_MESSAGE =
  'Native Sentry errors are not supported on non-native platforms';

export const init = () => {
  Sentry.init({
    dsn: PLATGEN_SENTRY_DSN,
  });
};

export const nativeCrash = () => {
  console.log(NATIVE_CRASH_MESSAGE);
};

export const javascriptCrash = () => {
  throw Error('Sentry Test');
};

export const setContext = (name: string, context: { [key: string]: any }) => {
  Sentry.setContext(name, context);
};

export const setUser = (userUuid: string) => {
  Sentry.setUser({ id: userUuid });
};
