import { useEffect, useState } from 'react';
import { useWithResponseHooks } from 'lib/api/hooks';

import { fetchPracticeLibraryScreenData as fetch } from 'daylight/lib/api';
import { DaylightPracticeLibraryPayload } from 'daylight/lib/api/types/apiResponses';
import logger from 'lib/logger';

export enum FetchState {
  Loading = 'loading',
  GateMessage = 'gate-message',
  Error = 'error',
  Success = 'success',
}

type ReturnType =
  | {
      state: FetchState.Success;
      payload: DaylightPracticeLibraryPayload;
    }
  | {
      state: FetchState.Error;
      error: Error;
    }
  | {
      state: FetchState.GateMessage;
      payload: DaylightPracticeLibraryPayload;
    }
  | {
      state: FetchState.Loading;
    };

export const useFetchPracticeLibraryScreenData = ({
  programId,
}: {
  programId: number | undefined;
}): ReturnType => {
  const [data, setData] = useState<DaylightPracticeLibraryPayload | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(true);
  const fetchPracticeLibraryData = useWithResponseHooks(fetch);

  useEffect(() => {
    const fetchData = async ({ programId }: { programId: number }) => {
      try {
        const practiceLibraryData = await fetchPracticeLibraryData({
          programId: programId,
        });
        setData(practiceLibraryData.result);
      } catch (e) {
        logger('Could not fetch practice library data');
      } finally {
        setIsLoading(false);
      }
    };

    if (typeof programId !== 'undefined' && !data && isLoading) {
      fetchData({ programId: programId });
    }
  }, [data, fetchPracticeLibraryData, isLoading, programId]);
  if (isLoading && !data) {
    return { state: FetchState.Loading };
  }

  if (!isLoading && !data) {
    return {
      state: FetchState.Error,
      error: Error('Could not fetch practice library data'),
    };
  }

  const payload = data as DaylightPracticeLibraryPayload;
  if (payload.warning_message) {
    return {
      state: FetchState.GateMessage,
      payload: payload,
    };
  }

  return {
    state: FetchState.Success,
    payload: payload,
  };
};
