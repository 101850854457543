import React, { HTMLProps, ReactElement, ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { RawDropdownProps, DropdownItem } from './types';
import {
  getStylesForButtonStates,
  ButtonStates,
} from 'lib/styles/inputStateStyles';
import { useGetDynamicInputStyles } from 'components/ResponsiveLayout';
import { roles } from 'cross-platform/utils/roleProps';
import { Select } from 'components/primitives/web-only';
import { DropdownCaret } from 'components/icons';
import { NO_OPTION_SELECTION_TEXT } from './constants';
import { Value } from 'components/forms/types';
import { fromValueToSelectHTMLAttributesValue } from '../helpers/fromValueToSelectHTMLAttributesValue';
import { useInputFocusContext } from 'components/forms/ResponseOptions/providers/InputFocusProvider';

interface SelectHandlers {
  onClick?: () => void;
  onBlur?: (e: unknown) => void;
  onFocus?: () => void;
  selected?: boolean;
}

const StyledPickerStatic = styled(Select)<ButtonStates & SelectHandlers>`
  appearance: none;
  background-color: ${getStylesForButtonStates('backgroundColor')};
  border: 0;
  color: ${getStylesForButtonStates('color')};
  cursor: pointer;
  flex-grow: 1;
  &:hover {
    color: ${({ highlight }): string =>
      getStylesForButtonStates('color')({ isHovering: true, highlight })};
    background-color: ${({ highlight }): string =>
      getStylesForButtonStates('backgroundColor')({
        isHovering: true,
        highlight,
      })};
  }
  &:focus {
    color: ${({ highlight }): string =>
      getStylesForButtonStates('color')({ isActive: true, highlight })};
    background-color: ${({ highlight }): string =>
      getStylesForButtonStates('backgroundColor')({
        isActive: true,
        highlight,
      })};
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

type StyledPickerType = ButtonStates &
  SelectHandlers & {
    style?: CSSProperties;
    children?: ReactNode;
    onChange?: HTMLProps<HTMLSelectElement>['onChange'];
    value?: Value;
  };
const StyledPicker = ({
  style,
  children,
  highlight,
  value,
  ...rest
}: StyledPickerType): ReactElement => {
  const {
    height,
    borderRadius,
    paddingLeft,
    paddingBottom,
    paddingTop,
    fontSize,
    caretSize,
    inputIconMarginRight,
    inputPaddingRightExtra,
  } = useGetDynamicInputStyles();
  return (
    <Container>
      <StyledPickerStatic
        {...rest}
        highlight={highlight}
        style={{
          ...(style || {}),
          height,
          borderRadius,
          paddingLeft,
          paddingRight: inputPaddingRightExtra,
          paddingTop,
          paddingBottom,
          fontSize,
        }}
        value={fromValueToSelectHTMLAttributesValue(value)}
      >
        {children}
      </StyledPickerStatic>
      <DropdownCaret
        pointerEvents="none"
        style={{
          position: 'absolute',
          top: 0.5 * (height - caretSize),
          right: inputIconMarginRight,
          color: getStylesForButtonStates('color')({ isActive: true }),
          pointerEvents: 'none',
        }}
        size={caretSize}
      />
    </Container>
  );
};

export const DropdownOption = ({
  displayText,
  value,
}: DropdownItem): ReactElement => {
  return (
    <option {...roles('DropdownOption')} value={`${value}`}>
      {displayText}
    </option>
  );
};

export const Dropdown = ({
  value,
  onValueChange,
  onBlur,
  children,
  highlight,
}: RawDropdownProps): ReactElement => {
  const { onFocus, onBlur: onContextBlur } = useInputFocusContext();
  const handleValueChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    onValueChange(event.currentTarget.value);
  };

  const handleFocus = () => {
    onFocus();
  };

  const handleBlur = (e: unknown) => {
    onContextBlur();
    onBlur?.(e);
  };

  return (
    <StyledPicker
      {...roles('DropdownPicker')}
      value={value?.[0]?.value}
      onChange={handleValueChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      isSelected={!!value?.length}
      highlight={highlight}
    >
      <option {...roles('DropdownOption')} value={''}>
        {NO_OPTION_SELECTION_TEXT}
      </option>
      {children}
    </StyledPicker>
  );
};
