import React from 'react';
import { Wrapper, Range, Value } from './styled';
import { roles } from 'cross-platform/utils/roleProps';
import { ViewStyle, StyleProp } from 'react-native';

export interface ProgressBarProps {
  max: number;
  min?: number;
  value: number;
  style?: {
    width?: ViewStyle['width'];
    height?: ViewStyle['height'];
    borderRadius?: ViewStyle['borderRadius'];
  } & StyleProp<ViewStyle>;
}

const ProgressBar = ({
  max,
  min = 0,
  value,
  style = {},
}: ProgressBarProps): React.ReactElement => {
  const { width, height, borderRadius } = style;
  return (
    <Wrapper
      {...roles('ProgressBar', {
        progressBar: { min, max, value: `${value}` },
      })}
      style={{ width }}
    >
      <Range style={{ height, borderRadius }} />
      <Value
        min={min}
        max={max}
        value={value}
        style={{ height, borderRadius }}
      />
    </Wrapper>
  );
};

export { ProgressBar };
