import { SpeechTypes, QuizAttribute } from 'components/constants';
import { SpeechLocation } from 'components/layout/Content';
import { HeadingLevel } from 'components/Heading';
import { ButtonTypes, TextTypes } from 'common/constants/enums';
import { FontFamilies } from 'components/sceneset-components/Text/fonts';
import { FooterTheme } from 'components/Footer';

export enum ControlAttrs {
  isPressing = 'isPressing',
  isActive = 'isActive',
  isSelected = 'isSelected',
  highlight = 'highlight',
}
/**
 * Roughly equivalent to variables.scss
 * @see {@link https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss}
 */
export type ThemeValuesType = {
  color: {
    primary: string;
    primaryLight: string;
    primaryDark: string;
    primaryGrey: string;
    error: string;
    hint: string;
    success: string;
    text: {
      primary: string;
      primaryInverse: string;
    };
    textGoogle: {
      primary: string;
      secondary: string;
    };
    textFacebook: {
      primary: string;
      secondary: string;
    };
  };
  heading: Record<
    | HeadingLevel.LEVEL1
    | HeadingLevel.LEVEL2
    | HeadingLevel.LEVEL3
    | HeadingLevel.LEVEL4
    | HeadingLevel.LEVEL5
    | HeadingLevel.LEVEL6,
    { fontSize: number; paddingBottom: number }
  >;
  footer: Record<
    FooterTheme.Light | FooterTheme.Dark,
    { color: string; backgroundColor: string }
  >;
  controls: {
    player: {
      color: string;
      backgroundColorDim: string;
    };
    borderRadius: number;
    borderWidth: string;
    borderStyle: string;
    borderColor: string;
    paddingHorizontal: number;
    paddingHorizontalLarge: number;
    paddingVertical: number;
    paddingVerticalLarge: number;
    fontSize: number;
    fontSizeLarge: number;
    color: string;
    backgroundColor: string;
    opacity: number;
    ctaMinWidth: number;
    ctaMaxWidth: number;
    ctaFixedWidth: number;
    isDisabled: {
      opacity: number;
    };
    quiz: Record<
      QuizAttribute.CORRECT | QuizAttribute.INCORRECT,
      {
        color: string;
        backgroundColor: string;
        borderColor: string;
      }
    >;
  } & Record<
    | ControlAttrs.isActive
    | ControlAttrs.isPressing
    | ControlAttrs.isSelected
    | ControlAttrs.highlight,
    {
      color: string;
      backgroundColor: string;
      borderColor: string;
    }
  >;
};

/**
 * Roughly equivalent to bootstrap.scss
 * * @see {@link https://github.com/twbs/bootstrap/blob/v4-dev/scss/bootstrap.scss}
 * * @see {@link https://github.com/twbs/bootstrap/blob/v4-dev/scss/_buttons.scss}
 */
/**
 * TODO fix theme types
 *  @see {@link https://github.com/styled-components/styled-components/issues/1589}
 */

export type Theme = {
  color: ThemeValuesType['color'];
  text: {
    fontFamily: string;
    nativeFontFamily: string;
    mono: {
      fontFamily: string;
    };
  } & Record<
    TextTypes.ERROR | TextTypes.DEFAULT | TextTypes.HINT,
    {
      color: string;
      paddingHorizontal: number;
      paddingVertical: number;
      fontSize: number;
    }
  >;
  heading: Record<
    | HeadingLevel.LEVEL1
    | HeadingLevel.LEVEL2
    | HeadingLevel.LEVEL3
    | HeadingLevel.LEVEL4
    | HeadingLevel.LEVEL5
    | HeadingLevel.LEVEL6,
    {
      fontSize: number;
      paddingBottom: number;
    }
  >;
  footer: Record<
    FooterTheme.Light | FooterTheme.Dark,
    { color: string; backgroundColor: string }
  >;
  homeScreen: {
    backgroundColor: string;
    titleColor: string;
  };
  overlay: {
    color: string;
    backgroundColor: string;
    headerColor: string;
    headerBackgroundColor: string;
  };
  speech: Record<
    SpeechTypes.DEFAULT | SpeechTypes.COLUMN_INFO,
    {
      [SpeechLocation.LEFT]: {
        marginLeft: number;
      };
      [SpeechLocation.RIGHT]: {
        marginRight: number;
      };
    }
  >;
  layout: {
    paddingHorizontal: number;
    paddingVertical: number;
  };
  content: {
    backgroundColor: string;
    // TODO Move back to root
    // WHEN old  controls is removed
    controls: ThemeValuesType['controls'];
  };
  // TODO REMOVE old controls
  // WHEN ASAP
  controls: {
    closeButtonColor: string;
  } & Record<
    | ButtonTypes.FACEBOOK_BUTTON
    | ButtonTypes.GOOGLE_BUTTON
    | ButtonTypes.LARGE_CTA
    | ButtonTypes.PRIMARY_BUTTON
    | ButtonTypes.SECONDARY_BUTTON,
    {
      backgroundColor: string;
      borderWidth: string;
      borderStyle: string;
      borderRadius: number;
      borderColor: string;
      color: string;
      fontSize: number;
      paddingHorizontal: number;
      paddingVertical: number;
      minWidth: number;
      maxWidth: number;
      width: number;
    }
  >;
};

// Equivalent to theme.scss (Semantic - raw values only for static)
const createTheme = ({
  color,
  footer,
  controls,
  heading,
}: ThemeValuesType): Theme => ({
  color,
  text: {
    // For web we inherit from body's "GT0WalsheimProRegular", " -apple-system", BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    // Why inherit?
    // THe problem is we mix react-native and react-native-web by importing things like Text from RN. In the app this is ok, but in tests we create
    // a problem where Babel can't parse that long-form font family with fallbacks. To ensure web and mobile and react-native-web all line up
    // in practice and in tests we need to force the web font-family to inherit from body.
    fontFamily: 'inherit',
    nativeFontFamily: FontFamilies.Regular,
    mono: {
      fontFamily: 'monospace',
    },
    [TextTypes.DEFAULT]: {
      color: color.text.primary,
      paddingHorizontal: controls.paddingHorizontal,
      paddingVertical: controls.paddingVerticalLarge,
      fontSize: controls.fontSize,
    },
    [TextTypes.ERROR]: {
      color: color.error,
      paddingHorizontal: controls.paddingHorizontal,
      paddingVertical: controls.paddingVerticalLarge,
      fontSize: controls.fontSize,
    },
    [TextTypes.HINT]: {
      color: color.hint,
      paddingHorizontal: controls.paddingHorizontal,
      paddingVertical: controls.paddingVerticalLarge,
      fontSize: controls.fontSize,
    },
  },
  footer: {
    [FooterTheme.Light]: {
      color: footer[FooterTheme.Light].color,
      backgroundColor: footer[FooterTheme.Light].backgroundColor,
    },
    [FooterTheme.Dark]: {
      color: footer[FooterTheme.Dark].color,
      backgroundColor: footer[FooterTheme.Dark].backgroundColor,
    },
  },
  heading: {
    [HeadingLevel.LEVEL1]: {
      fontSize: heading[HeadingLevel.LEVEL1].fontSize,
      paddingBottom: heading[HeadingLevel.LEVEL1].paddingBottom,
    },
    [HeadingLevel.LEVEL2]: {
      fontSize: heading[HeadingLevel.LEVEL2].fontSize,
      paddingBottom: heading[HeadingLevel.LEVEL2].paddingBottom,
    },
    [HeadingLevel.LEVEL3]: {
      fontSize: heading[HeadingLevel.LEVEL3].fontSize,
      paddingBottom: heading[HeadingLevel.LEVEL3].paddingBottom,
    },
    [HeadingLevel.LEVEL4]: {
      fontSize: heading[HeadingLevel.LEVEL4].fontSize,
      paddingBottom: heading[HeadingLevel.LEVEL4].paddingBottom,
    },
    [HeadingLevel.LEVEL5]: {
      fontSize: heading[HeadingLevel.LEVEL5].fontSize,
      paddingBottom: heading[HeadingLevel.LEVEL5].paddingBottom,
    },
    [HeadingLevel.LEVEL6]: {
      fontSize: heading[HeadingLevel.LEVEL6].fontSize,
      paddingBottom: heading[HeadingLevel.LEVEL6].paddingBottom,
    },
  },
  overlay: {
    backgroundColor: color.primary,
    headerColor: color.primaryLight,
    headerBackgroundColor: color.primaryDark,
    color: color.text.primary,
  },
  homeScreen: {
    backgroundColor: color.primaryGrey,
    titleColor: color.text.primary,
  },
  speech: {
    [SpeechTypes.DEFAULT]: {
      [SpeechLocation.LEFT]: {
        marginLeft: controls.paddingHorizontal,
      },
      [SpeechLocation.RIGHT]: {
        marginRight: controls.paddingHorizontal,
      },
    },
    [SpeechTypes.COLUMN_INFO]: {
      [SpeechLocation.LEFT]: {
        marginLeft: controls.paddingHorizontalLarge,
      },
      [SpeechLocation.RIGHT]: {
        marginRight: controls.paddingHorizontalLarge,
      },
    },
  },
  layout: {
    paddingHorizontal: controls.paddingHorizontal,
    paddingVertical: controls.paddingVertical,
  },
  content: {
    backgroundColor: color.text.primaryInverse,
    // TODO Move back to root
    // WHEN old controls is removed
    controls,
  },
  // TODO REMOVE old controls
  // WHEN ASAP
  controls: {
    closeButtonColor: color.primaryLight,
    [ButtonTypes.LARGE_CTA]: {
      backgroundColor: color.primaryLight,
      borderWidth: controls.borderWidth,
      borderStyle: controls.borderStyle,
      borderColor: controls.borderColor,
      borderRadius: controls.borderRadius,
      color: color.primary,
      fontSize: controls.fontSize,
      paddingHorizontal: controls.paddingHorizontal,
      paddingVertical: controls.paddingVerticalLarge,
      minWidth: controls.ctaMinWidth,
      maxWidth: controls.ctaMaxWidth,
      width: controls.ctaFixedWidth,
    },
    [ButtonTypes.PRIMARY_BUTTON]: {
      backgroundColor: color.text.primary,
      borderWidth: controls.borderWidth,
      borderStyle: controls.borderStyle,
      borderColor: controls.borderColor,
      borderRadius: controls.borderRadius,
      color: color.primary,
      paddingHorizontal: controls.paddingHorizontal,
      paddingVertical: controls.paddingVerticalLarge,
      fontSize: controls.fontSizeLarge,
      minWidth: controls.ctaMinWidth,
      maxWidth: controls.ctaMaxWidth,
      width: controls.ctaFixedWidth,
    },
    [ButtonTypes.SECONDARY_BUTTON]: {
      backgroundColor: 'transparent',
      borderWidth: controls.borderWidth,
      borderStyle: controls.borderStyle,
      borderColor: color.text.primary,
      borderRadius: controls.borderRadius,
      color: color.text.primary,
      paddingHorizontal: controls.paddingHorizontal,
      paddingVertical: controls.paddingVertical,
      fontSize: controls.fontSize,
      minWidth: controls.ctaMinWidth,
      maxWidth: controls.ctaMaxWidth,
      width: controls.ctaFixedWidth,
    },
    [ButtonTypes.FACEBOOK_BUTTON]: {
      backgroundColor: color.textFacebook.secondary,
      borderWidth: controls.borderWidth,
      borderStyle: controls.borderStyle,
      borderColor: color.textFacebook.secondary,
      borderRadius: controls.borderRadius,
      color: color.text.primary,
      paddingHorizontal: controls.paddingHorizontal,
      paddingVertical: controls.paddingVertical,
      fontSize: controls.fontSize,
      minWidth: controls.ctaMinWidth,
      maxWidth: controls.ctaMaxWidth,
      width: controls.ctaFixedWidth,
    },
    [ButtonTypes.GOOGLE_BUTTON]: {
      backgroundColor: color.textGoogle.primary,
      borderWidth: controls.borderWidth,
      borderStyle: controls.borderStyle,
      borderColor: color.text.primary,
      borderRadius: controls.borderRadius,
      color: color.textGoogle.secondary,
      paddingHorizontal: controls.paddingHorizontal,
      paddingVertical: controls.paddingVertical,
      fontSize: controls.fontSize,
      minWidth: controls.ctaMinWidth,
      maxWidth: controls.ctaMaxWidth,
      width: controls.ctaFixedWidth,
    },
  },
});
/**
 *
 *  TODO enable proper TypeScript support (Sooner the better)
 *  WHEN have time to fix type errors it uncovers
 *  WHY
 *
 *  before:
 *  ```js
 *  const WarningText = styled(Text)`
 *    color: ${(p: ThemeProps) => p.theme.text[TextTypes.WARNING].color};
 *  `;
 *  ```
 *  after:
 *  ```js
 *  const WarningText = styled(Text)`
 *    color: ${p => p.theme.text[TextTypes.WARNING].color};
 *  `;
 *  ```
 */
// declare module 'styled-components' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   export interface DefaultTheme extends Theme {} // https://github.com/styled-components/styled-components-website/issues/447
// }

export { createTheme };
