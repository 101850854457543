import { GlobalState } from '../store/initialState';
import { DomainIDs } from 'common/constants/enums';
import { FormikPersistableState, QuestionId } from './actions';
import { QuizInfo } from 'components/forms/InputField';
import { filter } from 'ramda';
import { Question, Response, ResponseOption } from '@bighealth/types';
import { QuestionResponseState } from './reducer';

export const getQuestionResponseToSubmit = (
  state: GlobalState
): Record<QuestionId, Response> => {
  return state[DomainIDs.QUESTION_RESPONSE].responseOptionsToSubmit;
};

export const getQuestionResponsesCorrectAnswers = (
  state: GlobalState
): Record<QuestionId, QuizInfo> =>
  state[DomainIDs.QUESTION_RESPONSE].correctAnswers;

export const getQuestions = (state: GlobalState): Question[] =>
  state[DomainIDs.QUESTION_RESPONSE].questions;

export const getQuestionResponsesQuizAttempts = (
  state: GlobalState
): QuestionResponseState['quizAttemptsByPath'] =>
  state[DomainIDs.QUESTION_RESPONSE].quizAttemptsByPath;

export const getQuestionResponsesQuizAttemptsByPath = (path: string) => (
  state: GlobalState
): ResponseOption[][] => {
  return filter(
    el => el.path === path,
    state[DomainIDs.QUESTION_RESPONSE].quizAttemptsByPath
  ).map(el => el.attempt);
};

export const getAllState = (state: GlobalState): GlobalState => state;

export const getFormikState = (
  state: GlobalState
): Record<string, FormikPersistableState | undefined> =>
  state[DomainIDs.QUESTION_RESPONSE].formikState;

/**
 *
 * Takes formik persisted state dictionary from the store and returns the state for that form
 * for a given path
 *
 * WHY DO THIS and not a nested selector?
 * For reasons we haven't been able to fathom, returning a function from the selector to use
 * with useSelector - the most basic implementation being `useSelector(() => () => undefined)`
 * causes the player to re-render _even though it's a sibling and not a parent_. Very odd
 */

export const getFormikStateByPathFromFormikState = (
  formikState: Record<string, FormikPersistableState | undefined>,
  path: string | undefined
): FormikPersistableState =>
  typeof path == 'string' ? formikState[path] || {} : {};

export const getLatestQuestionId = (state: GlobalState): number | undefined =>
  state[DomainIDs.QUESTION_RESPONSE].latestQuestionId;
