import * as React from 'react';
import {
  useTransformContentStyle,
  useTransformContentStyleVertical,
} from 'components/ResponsiveLayout';
import { Grid, HeadingRow, ButtonRow, Button, HeadingText } from './styled';
import { ButtonContainerFixedWidthScalable } from 'components/Button/components';

export type SessionStatusProps = {
  heading: React.ComponentProps<typeof HeadingText>;
  buttons: React.ComponentProps<typeof Button>[];
};

/**
 * The "hero" panel of the Session Progress screen
 *
 * @param {SessionStatusProps} props
 */
const SessionStatus = ({
  heading,
  buttons,
}: SessionStatusProps): React.ReactElement => {
  const scaleFn = useTransformContentStyle();
  const scaleValue = scaleFn(1);
  const scaleYFn = useTransformContentStyleVertical();
  const scaleYValue = scaleYFn(1);
  return (
    <Grid scaleYValue={scaleYValue}>
      <HeadingRow>
        <HeadingText
          scaleValue={scaleValue}
          scaleYValue={scaleYValue}
          {...heading}
        />
      </HeadingRow>
      <ButtonRow scaleValue={scaleValue}>
        {buttons.map((button, i) => (
          <ButtonContainerFixedWidthScalable
            key={`${i}`}
            style={{
              marginLeft: 25 / 2,
              marginRight: 25 / 2,
            }}
            scaleYValue={scaleYValue}
          >
            <Button
              scaleValue={scaleValue}
              scaleYValue={scaleYValue}
              {...button}
            />
          </ButtonContainerFixedWidthScalable>
        ))}
      </ButtonRow>
    </Grid>
  );
};

export { SessionStatus };
