import styled from 'styled-components/native';
import { getTheme } from 'lib/styles/getTheme';
import { Text } from 'components/sceneset-components/Text';

export enum HeadingLevel {
  LEVEL1 = 'level1',
  LEVEL2 = 'level2',
  LEVEL3 = 'level3',
  LEVEL4 = 'level4',
  LEVEL5 = 'level5',
  LEVEL6 = 'level6',
}

export interface HeadingProps {
  level: HeadingLevel;
}

const Heading = styled(Text)<HeadingProps>`
  font-size: ${getTheme('heading[level].fontSize')}px;
  padding-bottom: ${getTheme('heading[level].paddingBottom')}px;
  /* font-family set by components/sceneset-components/Text/utils */
  font-weight: 700;
  color: #003366;
`;

export const Heading1 = styled(Heading).attrs({ level: HeadingLevel.LEVEL1 })``;
Heading1.displayName = 'Heading1';
export const Heading2 = styled(Heading).attrs({ level: HeadingLevel.LEVEL2 })``;
Heading2.displayName = 'Heading2';
export const Heading3 = styled(Heading).attrs({ level: HeadingLevel.LEVEL3 })``;
Heading3.displayName = 'Heading3';
export const Heading4 = styled(Heading).attrs({ level: HeadingLevel.LEVEL4 })``;
Heading4.displayName = 'Heading4';
export const Heading5 = styled(Heading).attrs({ level: HeadingLevel.LEVEL5 })``;
Heading5.displayName = 'Heading5';
export const Heading6 = styled(Heading).attrs({ level: HeadingLevel.LEVEL6 })``;
Heading6.displayName = 'Heading6';
