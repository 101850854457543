import styled from 'styled-components/native';
import { getTheme } from 'lib/styles/getTheme';
import { Content as ContentUnstyled } from '../';
import { ControlProps } from '.';
import { ThemeProp } from 'config/getThemeForProducts';
import { daylightTheme } from 'config/getThemeForProducts/themes/daylight';

export const getControlThemes = (props: ControlProps & ThemeProp): string => {
  let controls = props?.theme?.content?.controls;
  if (!controls) {
    // TODO Why is theme not "Provider" provided
    // JA: Because importing ThemeProvider styled-components/native versus styled-components.
    controls = daylightTheme.content.controls;
  }
  return props.highlight
    ? `
    color: ${controls.highlight.color};
    background-color: ${controls.highlight.backgroundColor};
    border-color: ${controls.highlight.borderColor};
    `
    : props.quiz
    ? `
      color: ${controls.quiz[props.quiz].color};
      background-color: ${controls.quiz[props.quiz].backgroundColor};
      border-color: ${controls.quiz[props.quiz].borderColor};
      `
    : props.isPressing
    ? `
      color: ${controls.isPressing.color};
      background-color: ${controls.isPressing.backgroundColor};
      border-color: ${controls.isPressing.borderColor};
      `
    : props.isActive
    ? `
      color: ${controls.isActive.color};
      background-color: ${controls.isActive.backgroundColor};
      border-color: ${controls.isActive.borderColor};
      `
    : props.isSelected
    ? `
      color: ${controls.isSelected.color};
      background-color: ${controls.isSelected.backgroundColor};
      border-color: ${controls.isSelected.borderColor};
      `
    : `
      color: ${controls.color};
      background-color: ${controls.backgroundColor};
      border-color: ${controls.borderColor};
  `;
};
export const Content = styled(ContentUnstyled)<ControlProps>`
  border-style: ${getTheme('content.controls.borderStyle')};
  border-width: ${getTheme('content.controls.borderWidth')};
  opacity: ${({ theme, isDisabled }: ControlProps & ThemeProp): number =>
    isDisabled
      ? theme.content.controls.isDisabled.opacity
      : theme.content.controls.opacity};

  ${getControlThemes};
`;
