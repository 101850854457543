import styled from 'styled-components/native';

export const Wrapper = styled.View``;
Wrapper.displayName = 'Form.Wrapper';

export const HeadingForm = styled.Text``;

export const ButtonContainer = styled.View`
  width: 50%;
  margin: auto;
  align-items: center;
  justify-content: center;
`;
