/* eslint-disable react/prop-types */ // TS can't handle FunctionComponent
import { SleepWindowTypes } from '@bighealth/types/src/scene-components/SleepWindowTypes';
import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { roles } from 'cross-platform/utils/roleProps';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import {
  CircleButtonSleepWindow,
  CircleButtonsContainer,
  ControlWrapper,
  Input,
  InputContainer,
  Label,
  SetSleepWindowButton,
  SetSleepWindowTriangle,
  SubmitButtonContainer,
  Title,
  TriangleContainer,
} from './styled';

export type SleepWindowFormProps = {
  onSubmit?: () => void;
  onPressedSubtract?: () => void;
  onPressedAdd?: () => void;
};

export type FormPresentationProps = SleepWindowTypes['Props'] &
  SleepWindowFormProps;

/**
 * Just renders. Handlers are configured elsewhere
 * @param props
 */
const FormPresentation: FunctionComponent<FormPresentationProps> = props => {
  const {
    title,
    fields: { from_time, to_time },
    submit_button,
  } = props;
  const styles = useGetDynamicContentStyles();

  // TODO: implement the hover of the circle button for web
  return (
    <View>
      <Title {...title} />
      <ControlWrapper>
        <InputContainer>
          <Label {...from_time.label} />
          <Input {...from_time.input} />
        </InputContainer>
        <CircleButtonsContainer>
          <CircleButtonSleepWindow
            {...roles('SetSleepWindow.RightCircleButton')}
            onPress={props.onPressedAdd}
          >
            <TriangleContainer>
              <SetSleepWindowTriangle
                width={styles.triangleSetSleepWindowWidth}
                height={styles.triangleSetSleepWindowHeight}
                color={'#ffffff'}
                direction={'right'}
              />
            </TriangleContainer>
          </CircleButtonSleepWindow>
          <CircleButtonSleepWindow
            {...roles('SetSleepWindow.LeftCircleButton')}
            onPress={props.onPressedSubtract}
          >
            <TriangleContainer>
              <SetSleepWindowTriangle
                width={styles.triangleSetSleepWindowWidth}
                height={styles.triangleSetSleepWindowHeight}
                color={'#ffffff'}
                direction={'left'}
              />
            </TriangleContainer>
          </CircleButtonSleepWindow>
        </CircleButtonsContainer>
      </ControlWrapper>

      <ControlWrapper>
        <InputContainer>
          <Label {...to_time.label} />
          <Input {...to_time.input} />
        </InputContainer>
        <CircleButtonsContainer>
          <CircleButtonSleepWindow onPress={props.onPressedAdd}>
            <TriangleContainer>
              <SetSleepWindowTriangle
                width={styles.triangleSetSleepWindowWidth}
                height={styles.triangleSetSleepWindowHeight}
                color={'#ffffff'}
                direction={'right'}
              />
            </TriangleContainer>
          </CircleButtonSleepWindow>
          <CircleButtonSleepWindow onPress={props.onPressedSubtract}>
            <TriangleContainer>
              <SetSleepWindowTriangle
                width={styles.triangleSetSleepWindowWidth}
                height={styles.triangleSetSleepWindowHeight}
                color={'#ffffff'}
                direction={'left'}
              />
            </TriangleContainer>
          </CircleButtonSleepWindow>
        </CircleButtonsContainer>
      </ControlWrapper>
      <SubmitButtonContainer>
        <SetSleepWindowButton
          title={submit_button.text}
          onPress={props.onSubmit}
          {...roles('SetSleepWindow.SubmitButton')}
        ></SetSleepWindowButton>
      </SubmitButtonContainer>
    </View>
  );
};

export { FormPresentation };
