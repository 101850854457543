import React, { ComponentProps, ReactElement } from 'react';
import { useFormikContext } from 'formik';
import { SceneActionTypes } from '@bighealth/types/dist/enums';
import { UniversalActionButton } from 'components/UniveralButtons';
import { WithBackupFormikContext } from 'components/WithBackupFormikContext';

type ButtonProps = ComponentProps<typeof UniversalActionButton>;

const SceneSetButtonWithoutBackupFormikContext = (
  props: ButtonProps
): ReactElement => {
  const { isValid, isSubmitting } = useFormikContext();
  let isDisabled = false;
  if (props.action) {
    if (
      props.action.type === SceneActionTypes.NEXT ||
      props.action.type === SceneActionTypes.SUBMIT ||
      props.action.type === SceneActionTypes.JUMP_TO_SCENESET_BY_ID
    ) {
      isDisabled = !isValid || isSubmitting;
    }
  }
  return <UniversalActionButton {...props} isDisabled={isDisabled} />;
};

const Button = (props: ButtonProps): ReactElement => {
  return WithBackupFormikContext(
    props,
    SceneSetButtonWithoutBackupFormikContext
  );
};

export default Button;
