import { getStateKeyFromIdentifiers } from 'components/EphemeralStateProvider';
import { useCallback } from 'react';
import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';

type QuestionPathGetter = (questionId: number) => string;

/**
 * @TODO Unify state, by making use of getStateKeyFromIdentifiers optional
 * This is largely the same as EphStateProvider, except the allowed props
 * is type-checked directly from the state object. Something will need to be
 * deleted.
 *
 * State should be unified
 *
 * @see {@link https://bighealth.atlassian.net/browse/PG-760}
 */
const useQuestionPathGetter = (): QuestionPathGetter => {
  const { sceneSetGraphId, sceneSetId } = useSafeParams<SceneSetParams>();
  const questionPathGetter: QuestionPathGetter = useCallback(
    questionId => {
      return getStateKeyFromIdentifiers({
        sceneSetGraphId,
        sceneSetId,
        questionId,
      });
    },
    [sceneSetGraphId, sceneSetId]
  );
  return questionPathGetter;
};

export { useQuestionPathGetter };
