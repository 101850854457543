import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { APIErrorResponse } from '@bighealth/api';
import { read_full_article } from '@bighealth/api/LibraryArticle/v1';

export const useQueryFullArticle = (args: {
  entity_id?: number;
}): UseQueryResult<read_full_article.Response, APIErrorResponse> => {
  const result = useQuery({
    queryKey: read_full_article.queryKey,
    queryFn: () =>
      read_full_article({
        entity_id: args.entity_id as number,
      }),
    enabled: typeof args.entity_id === 'number',
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
  });
  return result;
};
