import React from 'react';
import { Button } from 'react-native';
import { addStory } from 'lib/story-books';
import { Provider } from 'react-redux';
import {
  ErrorModalProvider,
  ErrorModalWithContext,
} from '../ErrorModalWithContext';
import { hackWrap } from '../../Presentational/ErrorModal.story';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import { createMemoryHistory } from 'history';
import { Router } from 'react-router-dom';
import * as roleProps from 'cross-platform/utils/roleProps';
import { createTestStore } from 'state/store/createTestStore';
import { useErrorModalContext } from '../hooks/useErrorModalContext';

export type StoryProps = {
  onClose?: () => void; // IDEA change test to render web Presentation component
};

const StoryProviders = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const history = createMemoryHistory();
  history.push('/sleepio/888888/1/1/');
  const store = createTestStore();
  return (
    <ErrorModalProvider>
      <Provider store={store}>
        <Router history={history}>{children}</Router>
      </Provider>
    </ErrorModalProvider>
  );
};

const ButtonWithContext = (props: RoleProps): JSX.Element => {
  const errorModalContext = useErrorModalContext();
  const onPress = () => {
    const str = `##Error##`;
    errorModalContext?.setErrorDetails?.({
      error: Error(str),
      heading: str,
    });
  };
  return (
    <Button
      {...roleProps.pass(props)}
      title="Set new error"
      onPress={onPress}
    />
  );
};

export const StoryWithContext = (props: StoryProps): JSX.Element => {
  const errorModalContext = useErrorModalContext();
  return hackWrap(
    <>
      {/*

      USAGE EXAMPLE
      1. ButtonWithContext onPress puts error into context
      2. ErrorModalWithContext reads error from context
      3. ErrorModalWithContext.Close button onPress clears error
      */}
      <ButtonWithContext {...roles('Button')} />
      <ErrorModalWithContext
        {...roles('Modal')}
        onClose={() => {
          errorModalContext?.setErrorDetails?.(undefined);
          props?.onClose?.();
        }}
      />
    </>
  );
};

export const StoryErrorModalWithContext = (): JSX.Element => {
  return (
    <StoryProviders>
      <StoryWithContext />
    </StoryProviders>
  );
};

addStory(
  'components/SceneSetView/components/ErrorModal/Contextualized/ErrorModalWithContext',
  () => {
    return <StoryErrorModalWithContext />;
  }
);
