import React, { ReactElement } from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  ViewStyle,
} from 'react-native';
import { Svg, Path } from 'react-native-svg';
type Props = {
  onPress?: TouchableOpacityProps['onPress'];
  width?: ViewStyle['width'];
  height?: ViewStyle['height'];
  style?: ViewStyle & { color: string };
} & TouchableOpacityProps;

const CloseButton = (props: Props): ReactElement => {
  const { onPress, width, height, style, ...rest } = props;
  const flatStyles = StyleSheet.flatten(style);
  return (
    <TouchableOpacity
      {...rest}
      onPress={onPress}
      style={{ ...flatStyles, width, height }}
    >
      <Svg style={{ width, height }} viewBox="0 0 40 40">
        <Path
          fill={(flatStyles as Props['style'])?.color || 'black'}
          fillRule="evenodd"
          d="M100 79L96 75 80 91 64 75 60 79 76 95 60 111 64 115 80 99 96 115 100 111 84 95z"
          transform="translate(-60 -75)"
        />
      </Svg>
    </TouchableOpacity>
  );
};

CloseButton.defaultProps = {
  width: 21,
  height: 21,
};
export { CloseButton };
