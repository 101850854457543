import * as React from 'react';
import { ReactElement } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Svg, G, Rect, Polygon } from 'react-native-svg';

import { useHistory, useParams } from 'cross-platform/react-router';

import { SceneAction } from '@bighealth/types/src/scene-components/client';

import { useWithResponseHooks } from 'lib/api/hooks';
import logger from 'lib/logger';
import useActionHandler from 'lib/player/useActionHandler';
import { GENERAL_ERROR_MESSAGE } from 'lib/strings';

import { useGetSvgDimensions } from 'daylight/components/ResponsiveLayout/useGetSvgDimensions';
import { startCheckin } from 'daylight/lib/api';
import getPathForParams from 'lib/player/getPathForParams';
import { roles } from 'cross-platform/utils/roleProps';

// TODO: use this in Sleepio as icon as well because it is responsive
// WHEN: we are ready to create packages
export const CtaCirclePlaySvg = ({
  width,
  height,
  viewBox,
  circleColor,
  triangleColor,
}: {
  width: number;
  height: number;
  viewBox: string;
  circleColor?: string;
  triangleColor?: string;
}): ReactElement => {
  const trianglePointUpLeft = `${width / 2.5}, ${height / 3}`;
  const trianglePointDownLeft = `${width / 2.5}, ${(height / 3) * 2}`;
  const trianglePointRight = `${(width / 3) * 2}, ${height / 2}`;

  return (
    <Svg width={width} height={height} viewBox={viewBox}>
      <G>
        <Rect
          width={width}
          height={height}
          fill={circleColor}
          rx={`${width / 2}`}
        />
        <Polygon
          fill={triangleColor}
          points={`${trianglePointUpLeft} ${trianglePointRight} ${trianglePointDownLeft}`}
          opacity="1"
        />
      </G>
    </Svg>
  );
};
export type PlayButtonProps = {
  action?: SceneAction;
  testID?: string;
  style: {
    width: number;
    height: number;
    backgroundColor: string;
    triangleColor: string;
  };

  onPress?: () => void;
};

// TODO: return the button to navigate the Daylight content:
// - In case of custom action (Daylight/Start_Checkin action), call the custom endpoint
// - In case of Platform action (Jump to SceneSet, GO_TO actions), use the UniversalButton (via the ActionButton)
export const PlayButton = (props: PlayButtonProps): ReactElement => {
  const { productReference } = useParams<{ productReference: string }>();
  const actionHandler = useActionHandler(props.action);
  const history = useHistory();

  const startCheckinWithHooks = useWithResponseHooks(startCheckin);
  const handlePress = async (): Promise<void> => {
    // TODO: consider to use Platform code
    // WHEN a Platorm package is created.
    if (props.action?.type === 'daylight/START-CHECKIN') {
      try {
        const checkinSS = await startCheckinWithHooks({
          productId: props.action?.payload.productId,
          programId: props.action?.payload.programId,
          sceneSetGraphId: props.action?.payload.sceneSetGraphId,
          sceneSetId: props.action?.payload.destinationSceneSetId,
        });
        if (checkinSS) {
          const path = getPathForParams({
            productReference: productReference,
            sceneSetGraphId: props.action?.payload.sceneSetGraphId,
            sceneSetId: props.action?.payload.destinationSceneSetId,
            sceneId: 0,
          });
          history.push(path);
        } else {
          history.push(`/${productReference}/home`);
        }
      } catch (e) {
        logger(`${GENERAL_ERROR_MESSAGE} logging out`, e);
      }
    } else if (typeof actionHandler === 'function') {
      await actionHandler();
    }
  };

  const dimensionProps = useGetSvgDimensions({
    width: props.style.width,
    height: props.style.height,
  });

  return (
    <View>
      <TouchableOpacity {...roles('DaylightPlayButton')} onPress={handlePress}>
        <CtaCirclePlaySvg
          {...dimensionProps}
          circleColor={props.style.backgroundColor}
          triangleColor={props.style.triangleColor}
        />
      </TouchableOpacity>
    </View>
  );
};
