import { useCallback } from 'react';
import { jump_to_specific_scene_set } from '@bighealth/api/SceneSetGraph/v1';
import { useLazyQueryDownloadSceneSetAssets } from './useLazyQueryDownloadSceneSetAssets';
import { queryClientJumpToSceneSet } from '../queryClientAnalogs/queryClientJumpToSceneSet';

export type JumpCallback = (
  args: jump_to_specific_scene_set.Args
) => Promise<undefined | jump_to_specific_scene_set.Response>;

export const useLazyQueryJumpToSceneSetWithAssets = (): JumpCallback => {
  const downloadAssets = useLazyQueryDownloadSceneSetAssets();

  return useCallback(
    async args => {
      try {
        const response = await queryClientJumpToSceneSet(args);
        const sceneSetJSON = response?.result?.scene_set_json;
        await downloadAssets(sceneSetJSON);
        return response;
      } catch (e) {
        return e;
      }
    },
    [downloadAssets]
  );
};
