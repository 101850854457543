import { Responses as QuestionResponses } from '@bighealth/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';

export const create_responses_for_questions = async (
  args: create_responses_for_questions.Args
): Promise<create_responses_for_questions.Response> => {
  return await api({
    service_name: 'Response',
    service_version: '2',
    service_method: 'create_responses_for_questions',
    args: args,
  } as create_responses_for_questions.Request);
};

create_responses_for_questions.queryKey =
  'Response/create_responses_for_questions';

export declare namespace create_responses_for_questions {
  export type Args = QuestionResponses;

  export interface Request extends APIRequestBody {
    service_name: 'Response';
    service_version: '2';
    service_method: 'create_responses_for_questions';
    args: Args;
  }

  export type Result = {};

  export type Response = APIResponse<Result>;
}
