import React from 'react';
import { ScheduleNextSessionForm } from '.';
import { addStory } from 'lib/story-books';
import { mockFormPresentationProps } from './__mocks__/scheduleNextSessionMocks';
import { ProductReferences } from 'common/constants/enums';
import { EphemeralStateProvider } from 'components/EphemeralStateProvider';
import { CrossPlatformThemeProvider } from 'components/ProvidersContainer/ProductThemeProvider';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { clone } from 'ramda';

addStory('ScheduleNextSession', () => {
  const mockFormPresentationPropsNoInitialValue = clone(
    mockFormPresentationProps
  );
  mockFormPresentationPropsNoInitialValue.initialValue = null; // no initial Value
  return (
    <EphemeralStateProvider>
      <CrossPlatformThemeProvider
        theme={getThemeForProduct(ProductReferences.SLEEPIO)}
      >
        <ScheduleNextSessionForm {...mockFormPresentationPropsNoInitialValue} />
      </CrossPlatformThemeProvider>
    </EphemeralStateProvider>
  );
});
