import React from 'react';
import { Text } from 'react-native';
import { ProgressBar } from './';
import { addStory } from 'lib/story-books';
import { ThemeProvider } from 'styled-components';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { ProductReferences } from 'common/constants/enums';

const theme = getThemeForProduct(ProductReferences.SLEEPIO);

addStory('ProgressBar', () => (
  <ThemeProvider theme={theme}>
    <>
      <Text>{'<ProgressBar max={10} value={0} />'}</Text>
      <ProgressBar max={10} value={0} />
      <Text>{'<ProgressBar max={10} value={4} />'}</Text>
      <ProgressBar max={10} value={4} />
      <Text>{'<ProgressBar min={-10} max={10} value={4} '}</Text>
      <ProgressBar min={-10} max={10} value={4} />
      <Text>{'<ProgressBar max={10} value={10} />'}</Text>
      <ProgressBar max={10} value={10} />
    </>
  </ThemeProvider>
));
