import {
  createFromWeeklyAverageEstimate,
  createSingleDayEntry,
  getDiaryEntryForm,
  updateSingleEntryWithDate,
} from '..';

export const SleepDiaryService = {
  get_diary_entry_form: getDiaryEntryForm,
  create_from_weekly_average_estimate: createFromWeeklyAverageEstimate,
  create_single_day_entry: createSingleDayEntry,
  update_single_day_entry_with_date: updateSingleEntryWithDate,
};
