import React, { ReactElement } from 'react';
import Svg, { G, Path } from 'react-native-svg';

/**
 * Today's icon is a sun
 * @param size: the size of the square where the icon lives
 * @param color: the color of the icon
 */
export const TodayIcon = ({
  size,
  color,
}: {
  size: number;
  color: string;
}): ReactElement => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 40 40">
    <G fill="none" fillRule="evenodd">
      <G>
        <Path d="M0 0H40V40H0z" />
        <G
          stroke={`${color}`}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
        >
          <G>
            <Path
              d="M22.377 13.716c0 4.784-3.878 8.662-8.66 8.662-4.785 0-8.663-3.878-8.663-8.662 0-4.783 3.878-8.662 8.662-8.662 4.783 0 8.661 3.879 8.661 8.662zM13.716.5L13.716 3.653M4.371 4.371L6.6 6.6M.5 13.716L3.653 13.716M4.371 23.061L6.6 20.832M13.716 26.932L13.716 23.779M23.061 23.061L20.832 20.832M26.932 13.716L23.779 13.716M23.061 4.371L20.832 6.6"
              transform="translate(6 6)"
            />
          </G>
        </G>
      </G>
    </G>
  </Svg>
);
