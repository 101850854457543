import React, { ReactElement, ReactNode } from 'react';
import {
  ResponseOptionValue,
  Question,
  ResponseTypeType,
  ResponseOption,
  DateResponseOption,
  ResponseType,
} from '@bighealth/types';
import { ResponseInputComponentType } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';
import {
  GenericInput,
  GenericInputTypes,
  coerceValueByInputType,
} from 'components/generic-question/GenericInput';
import {
  DateTimePicker,
  DatePicker,
  TimePicker,
} from 'components/generic-question/DateTimePickers';
import { ButtonContainer } from 'components/generic-question/QuestionsLayout';
import { GenericInputButton } from 'components/generic-question/GenericInputButton';
import { Dropdown, DropdownItem, DropdownOption } from '../Dropdown';
import { DropdownTimePicker } from '../DropdownTimePicker';
import { DropdownDurationPicker } from '../DropdownDurationPicker';
import { HintText } from 'components/Text/HintText';
import { ErrorText } from 'components/Text/ErrorText';
import { WarningText } from 'components/Text/WarningText';
import { QuizAttribute } from 'components/constants';
import { HorizontalScaleItem, ItemLocation } from '../HorizontalScale';
import { complement, filter } from 'ramda';
import { Value } from 'components/forms/types';
import { Row } from 'components/layout/Grid';
import { stringify } from 'lib/stringify';
import { roles } from 'cross-platform/utils/roleProps';
import {
  InputPadding,
  QuestionPreText,
  PreTextContainer,
  QuestionTitle,
  QuestionTitleContainer,
  ResponsePreText,
} from './components';
import { getIsToggleable } from 'components/generic-question/InputsAsArray/helpers';
import { HorizontalScaleLabels } from 'components/generic-question/HorizontalScale';
import { getQuizFromAttempts } from './helpers/getQuizFromAttempts';
import { createTimeDate } from 'lib/createTimeDate';
import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { useLatestFocusedQuestion } from 'lib/question-response/useLatestFocusedQuestion';

type Props = {
  // IDEA (92acef87) Make non optional AC: which one?
  component: ResponseInputComponentType;
  dataPath: string;
  displayText?: string;
  id: number;
  inputValue: Value;
  isSelected: boolean;
  isToggleable?: boolean;
  label?: string;
  location: ItemLocation;
  max?: Value;
  min?: Value;
  onChangeItem?: (val: string) => void;
  onSelect?: (val?: string) => void;
  onSetValue?: (val?: Value) => void;
  quiz?: QuizAttribute;
  type: ResponseTypeType;
  value: ResponseOptionValue;
  highlight?: boolean;
};

const isOptionInput = (option: ResponseOption | Props): boolean =>
  option.value === '$input';

const AnyInput = (props: Props): ReactElement => {
  const {
    component,
    dataPath,
    displayText,
    inputValue,
    isSelected = false,
    isToggleable = true,
    location,
    onSelect,
    onSetValue,
    quiz,
    type,
    label,
    highlight,
  } = props;

  let { max, min } = props;
  if (isOptionInput(props)) {
    switch (component) {
      case 'DropdownTimePicker':
      case 'DateTimePicker':
      case 'DatePicker':
      case 'TimePicker':
        // @TODO: Remove date conversion.
        // WHY: because deserialising from disk
        // was causing errors in date format.
        // Remove this.
        // WHEN we revisit with QR state fixes.
        // PG-741 might be related
        if (typeof min === 'string') {
          min = new Date(min);
        }
        if (typeof max === 'string') {
          max = new Date(max);
        }

        if (
          !(
            (typeof min === 'undefined' || min instanceof Date) &&
            (typeof max === 'undefined' || max instanceof Date)
          )
        ) {
          if (
            isNaN(min?.valueOf() as number) ||
            isNaN(max?.valueOf() as number)
          ) {
            throw TypeError(
              `Expected min & max to be Date or undefined, instead got min: ${stringify(
                min
              )} & max: ${stringify(max)}`
            );
          }
        }
        if (
          inputValue instanceof Date ||
          inputValue === null ||
          typeof inputValue === 'undefined'
        ) {
          switch (component) {
            case 'DateTimePicker':
              return (
                <InputPadding>
                  <DateTimePicker
                    minDate={min as Date}
                    maxDate={max as Date}
                    onDateChange={(date: Date | null): void => {
                      onSetValue?.(date || undefined); //  IDEA use null?
                    }}
                    date={inputValue || null}
                  />
                </InputPadding>
              );
            case 'DatePicker':
              return (
                <InputPadding>
                  <DatePicker
                    minDate={(min as Date) || undefined}
                    maxDate={(max as Date) || undefined}
                    onDateChange={(date: Date | null): void => {
                      onSetValue?.(date || undefined); //  IDEA use null?
                    }}
                    date={inputValue || null}
                  />
                </InputPadding>
              );
            case 'TimePicker':
              return (
                <InputPadding>
                  <TimePicker
                    onDateChange={(date: Date | null): void => {
                      if (onSetValue) {
                        if (date) {
                          onSetValue(createTimeDate(date));
                        } else {
                          onSetValue?.(date);
                        }
                      }
                    }}
                    date={inputValue || null}
                  />
                </InputPadding>
              );
            case 'DropdownTimePicker':
              // NOTE Parent takes care of onValueChange handler on native
              return (
                <InputPadding>
                  <DropdownTimePicker
                    min={(min as Date) || undefined}
                    max={(max as Date) || undefined}
                    selectedValue={inputValue}
                    onValueChange={onSetValue}
                    highlight={highlight}
                  />
                </InputPadding>
              );
          }
        } else {
          throw TypeError(
            `Date/Time Pickers expected instanceof Date or null, instead got inputValue: ${typeof inputValue} "${inputValue}" (${typeof inputValue}) ${JSON.stringify(
              props
            )}`
          );
        }
        break;
      // For all other "$input" that is not date..
      case 'DropdownDurationPicker':
        // NOTE Parent takes care of onValueChange handler on native
        if (typeof min !== 'number' && typeof min !== 'undefined') {
          throw TypeError(
            `Expected min to be of type "number" or "undefined", instead got ${min} (${typeof min})`
          );
        }
        if (typeof max !== 'number' && typeof max !== 'undefined') {
          throw TypeError(
            `Expected max to be of type "number" or "undefined", instead got ${max} (${typeof max})`
          );
        }
        if (
          inputValue !== '' &&
          typeof inputValue !== 'number' &&
          typeof inputValue !== 'undefined'
        ) {
          throw TypeError(
            `Expected inputValue to be of type "number" or "undefined", instead got ${inputValue} (${typeof inputValue})`
          );
        }
        return (
          <InputPadding>
            <DropdownDurationPicker
              min={min || undefined}
              max={max || undefined}
              selectedValue={inputValue}
              onValueChange={onSetValue}
              highlight={highlight}
            />
          </InputPadding>
        );
      default:
        if (
          typeof inputValue === 'string' ||
          typeof inputValue === 'number' ||
          typeof inputValue === 'undefined'
        ) {
          return (
            <InputPadding>
              <GenericInput
                dataPath={dataPath}
                isSelected={isSelected}
                value={String(
                  typeof inputValue === 'undefined' ? '' : String(inputValue)
                )}
                onValueChange={(newValue: string): void => {
                  if (newValue === '') {
                    // We're always cool with empty string
                    onSetValue?.(newValue);
                    return;
                  }

                  const isNumberField = type === ResponseType.NUMBER;
                  if (!isNumberField) {
                    // For text fields/whatever we can blindly set as there's no coercion
                    onSetValue?.(newValue);
                    return;
                  }

                  const coercedValue = coerceValueByInputType(newValue, type);
                  const isNumber =
                    typeof coercedValue === 'number' &&
                    !Number.isNaN(coercedValue);
                  if (isNumber) {
                    onSetValue?.(coercedValue);
                    return;
                  }
                  // NOOP: one we've fallen through to here, there's nothing valid to set
                }}
                onClick={isToggleable ? onSelect : undefined}
                type={type as GenericInputTypes}
                // highlight={highlight} // IDEA add highlight WHEN its needed
              />
            </InputPadding>
          );
        } else {
          throw TypeError(
            `TextInput & NumberInput expected type string, number or undefined, instead got ${typeof inputValue}`
          );
        }
    }
  } else {
    // Not option input
    switch (component) {
      case 'Dropdown':
        // Parent takes care of handler
        return (
          <DropdownOption
            value={inputValue}
            displayText={displayText || `${inputValue}`}
          />
        );
      case 'HorizontalScale':
        return (
          <HorizontalScaleItem
            displayText={displayText || String(inputValue)}
            value={inputValue}
            selected={isSelected || !!quiz}
            onSelect={(): void => {
              onSelect?.();
            }}
            location={location}
            quiz={quiz}
            // highlight={highlight} // TODO add highlight WHEN its needed
          />
        );
    }
  }
  return (
    <InputPadding>
      <GenericInputButton
        isSelected={isSelected || !!quiz}
        quiz={quiz}
        onClick={isToggleable ? onSelect : undefined}
        onPress={isToggleable ? onSelect : undefined}
        label={label}
        highlight={highlight}
      >
        {displayText}
      </GenericInputButton>
    </InputPadding>
  );
};

type MapControlsToResponseOptionsTypes = InputsAsArrayProps & {
  filterFn?: (value: ResponseOption) => boolean;
};

const mapControlsToResponseOptions = ({
  questionProps,
  selection,
  correctSelection = [],
  incorrectSelection = [],
  attempts,
  onSelect,
  onSetValue,
  component,
  isQuizCompletedComplete = false,
  filterFn = (): boolean => true,
  highlight,
}: MapControlsToResponseOptionsTypes): ReactNode[] =>
  questionProps.response_config.response_options
    // optional filter to allow for grouping
    .filter(filterFn)
    // render inputs
    .map(
      (
        option: ResponseOption,
        index: number,
        arr: ResponseOption[]
      ): ReactNode => {
        const { id, value, display_text: displayText, label } = option;
        const selected: DropdownItem | undefined = selection.find(
          e => e.id === id
        );
        if (typeof selected === 'undefined') {
          throw ReferenceError(
            `selected can't be undefined: looking of id (${id}) in selection (${JSON.stringify(
              selection,
              null,
              2
            )})`
          );
        }

        const isToggleable = getIsToggleable(questionProps);
        const quiz =
          typeof attempts === 'undefined'
            ? undefined
            : getQuizFromAttempts(
                id,
                attempts,
                selected,
                isQuizCompletedComplete,
                incorrectSelection,
                correctSelection,
                questionProps.response_config.correct_response_ids
              );

        return (
          <AnyInput
            key={`${id}.${value}.${displayText}`}
            dataPath={`question:${questionProps.semantic_id} option:${id}`}
            id={id}
            inputValue={selected.value}
            isSelected={!!selected.isSelected}
            isToggleable={isToggleable}
            max={(option as DateResponseOption)?.max_response}
            min={(option as DateResponseOption)?.min_response}
            type={questionProps.response_type}
            value={
              component === 'Dropdown' && typeof value === 'number'
                ? `${value}`
                : value
            }
            quiz={quiz}
            displayText={displayText || ''}
            label={
              component === 'Dropdown' ? displayText || label : label
              // NOTE AC: For native, the immediate-child's label is used.
              // The label of sub-children (e.g. DropdownOption) are IGNORED ("Duck-typing")
            }
            onSelect={(): void => {
              onSelect(option);
            }}
            component={component}
            onSetValue={(inputValue?: string | Date | null | number): void => {
              onSetValue(option, inputValue);
            }}
            location={
              index === 0
                ? ItemLocation.START
                : index === arr.length - 1
                ? ItemLocation.END
                : ItemLocation.MIDDLE
            }
            highlight={highlight}
          />
        );
      }
    );

const MapControlsToResponseOptions = (
  props: MapControlsToResponseOptionsTypes
): ReactElement => <>{mapControlsToResponseOptions(props)}</>;

export type InputsAsArrayProps = {
  questionProps: Question;
  component: ResponseInputComponentType;
  selection: DropdownItem[];
  correctSelection?: ResponseOption['id'][];
  incorrectSelection?: ResponseOption['id'][];
  attempts?: ResponseOption[][];
  error?: string;
  hint?: string;
  warning?: string;
  highlight?: boolean;
  isTouched?: boolean;
  onSelect: (option: ResponseOption) => void;
  isQuizCompletedComplete: boolean;
  onSelectValue: (selectedValue: string | Date | null) => void; // For Dropdown only
  onSetValue: (option: ResponseOption, inputValue?: Value) => void;
  onBlur: () => void;
};

export const InputsAsArray = (props: InputsAsArrayProps): ReactElement => {
  const { latestQuestionId } = useLatestFocusedQuestion();
  const selectedOptions = filter(e => !!e.isSelected, props.selection);
  const responsePreText = props.questionProps.response_pre_text;
  const questionTitle = props.questionProps.question_title;
  const questionPreText = props.questionProps.question_pre_text;
  const responseOptions = props.questionProps.response_config.response_options;

  const styles = useGetDynamicContentStyles();
  const isInFocus = latestQuestionId === props.questionProps.id;

  return (
    <ButtonContainer {...roles.pass(props)}>
      {questionPreText ? (
        <PreTextContainer>
          <QuestionPreText>{questionPreText}</QuestionPreText>
        </PreTextContainer>
      ) : null}
      {questionTitle ? (
        <QuestionTitleContainer>
          <QuestionTitle>{questionTitle}</QuestionTitle>
        </QuestionTitleContainer>
      ) : null}
      {responsePreText ? (
        <PreTextContainer>
          <ResponsePreText>{responsePreText}</ResponsePreText>
        </PreTextContainer>
      ) : null}
      {props.component === 'Dropdown' ? (
        <InputPadding>
          <Dropdown
            value={selectedOptions}
            onValueChange={props.onSelectValue}
            onBlur={props.onBlur}
            options={responseOptions}
            highlight={props?.highlight}
          >
            {mapControlsToResponseOptions({
              ...props,
              filterFn: complement(isOptionInput),
            })}
          </Dropdown>
        </InputPadding>
      ) : props.component === 'HorizontalScale' ? (
        <>
          <InputPadding>
            <Row>
              <MapControlsToResponseOptions
                {...props}
                filterFn={complement(isOptionInput)}
              />
            </Row>
            <Row>
              <HorizontalScaleLabels {...props} />
            </Row>
          </InputPadding>
          <MapControlsToResponseOptions {...props} filterFn={isOptionInput} />
        </>
      ) : (
        <MapControlsToResponseOptions {...props} />
      )}
      {props.error && props.isTouched && !isInFocus ? (
        <ErrorText
          {...roles('Error')}
          style={{ fontSize: styles.sleepDiaryFormErrorFontSize }}
        >
          {props.error}
        </ErrorText>
      ) : null}
      {props.warning ? (
        <WarningText
          {...roles('Warning')}
          style={{ fontSize: styles.sleepDiaryFormErrorFontSize }}
        >
          {props.warning}
        </WarningText>
      ) : null}
      {props.error && props.isTouched && isInFocus ? (
        <HintText
          {...roles('Hint')}
          style={{ fontSize: styles.sleepDiaryFormErrorFontSize }}
        >
          {props.hint ? props.hint : props.error}
        </HintText>
      ) : null}
    </ButtonContainer>
  );
};
