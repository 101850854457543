import React, { ReactElement, ReactNode, useContext } from 'react';
import { FillAbsolute } from 'components/Containers';
import styled from 'styled-components/native';
import { GlobalNavigationBar } from './GlobalNavigationBar';
import { NavBarStyles } from './constants';
import { createErrorBoundaryForDomain } from 'lib/error/utils/createErrorBoundaryForDomain';
import { withContentPlaceholderWrapper } from 'lib/error/ErrorBoundary/utils/withContentPlaceholderWrapper/withContentPlaceholderWrapper';
import { renderFallbackReturnsHomeForDomainFactory } from 'components/Routes/SleepioSwitch/components/renderFallbackReturnsHomeForDomainFactory';
import { NavigationBarProvider } from './providers/NavigationBarProvider';
import { ScreenResponsiveLayoutContext } from 'components/ResponsiveLayout';

const ERROR_DOMAIN = 'GlobalNavigation';
const ErrorBoundaryForGlobalNavigation = createErrorBoundaryForDomain(
  ERROR_DOMAIN
);
const renderFallbackForGlobalNavigation = renderFallbackReturnsHomeForDomainFactory(
  ERROR_DOMAIN
);

export type MobileOptions = {
  enableMobileMode?: boolean;
};

const HeightWithNav = styled.View`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const WithGlobalNavigation = ({
  children,
  enableMobileMode,
}: {
  children: ReactNode;
} & MobileOptions): ReactElement => {
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  // NOTE: we are moving to a responsive layout not dependant device detection for navigation
  // Transparency is only for the collapsed nav so space is only needed when width and height are appropriate
  const allowSpaceForNav = !(
    enableMobileMode &&
    screenWidth <= NavBarStyles.navBarCollapseTabletWebBreakPointPx
  );

  return (
    <ErrorBoundaryForGlobalNavigation
      fallbackRender={renderFallbackForGlobalNavigation}
      fallbackRenderWithWrapper={withContentPlaceholderWrapper}
    >
      <FillAbsolute>
        <NavigationBarProvider mobileOptions={{ enableMobileMode }}>
          <>
            <HeightWithNav
              style={{
                top: allowSpaceForNav ? NavBarStyles.navBarHeightPx : 0,
              }}
            >
              {children}
            </HeightWithNav>
            <GlobalNavigationBar />
          </>
        </NavigationBarProvider>
      </FillAbsolute>
    </ErrorBoundaryForGlobalNavigation>
  );
};
