import React from 'react';
import { ReactElement } from 'react';
import { roles } from 'cross-platform/utils/roleProps';
import { useFormikContext } from 'formik';
import { SubmitButton } from './styled';

const FormikSubmitButton = ({ text }: { text: string }): ReactElement => {
  const { isValid, isSubmitting, handleSubmit } = useFormikContext();
  const isDisabled = !isValid && isSubmitting;
  return (
    <SubmitButton
      title={text}
      onPress={handleSubmit}
      {...roles(
        isDisabled
          ? 'ScheduleNextSession.SubmitButton-disabled'
          : 'ScheduleNextSession.SubmitButton'
      )}
    ></SubmitButton>
  );
};

export { FormikSubmitButton };
