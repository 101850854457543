import React from 'react';
import { isDevMode } from 'lib/isDevMode';
import { ErrorDescription, ErrorDetails } from 'lib/error';
import { Markdown } from './components/Markdown/Markdown';
import {
  Wrapper,
  MarkdownWrapper,
  PageTitle,
  Button,
  DevModeErrorText,
} from './styled';
import { FallbackProps } from 'lib/error/ErrorBoundary/types';
import { roles } from 'cross-platform/utils/roleProps';

const RECOVERY_TEXT = 'Try again';

const DEFAULTS: ErrorDescription = {
  heading: 'Whoops',
  message: `It seems something has gone wrong.
Need help? Please reach out to us at [hello@sleepio.com](mailto:hello@sleepio.com)
  `,
};

type Props = Partial<ErrorDetails & ErrorDescription> &
  Partial<FallbackProps> & { debugMessage?: string };

/**
 *
 * @param {Error | undefined} props.error Caught error
 * @param {{text: string, action: Function}[]} props.actions list of buttons
 * @param {string} heading
 * @returns
 */
const DefaultErrorFallback: React.ComponentType<Props> = (props: Props) => {
  const {
    heading,
    message,
    error = undefined,
    actions = [],
    onRecover,
    resetErrorBoundary,
    debugMessage = '',
  } = {
    ...DEFAULTS,
    ...props,
  };
  const debugMessageFormatted = debugMessage.length
    ? `${debugMessage}: `
    : debugMessage;
  const errorMessage =
    typeof error !== 'undefined'
      ? `${error}`
      : 'DefaultErrorFallback: No error';
  const devModeErrorText = `${debugMessageFormatted}${errorMessage}`;

  return (
    <Wrapper {...roles('DefaultErrorFallback')}>
      <PageTitle {...roles('heading')}>{heading}</PageTitle>
      <MarkdownWrapper>
        <Markdown {...roles('message')}>{message}</Markdown>
      </MarkdownWrapper>
      {isDevMode() ? (
        <DevModeErrorText {...roles('error')}>
          {devModeErrorText}
        </DevModeErrorText>
      ) : null}
      {actions?.map((action, index) => (
        <Button
          key={action.text + index}
          {...roles(`action--index:${index}`)}
          {...action}
        />
      ))}
      {onRecover ? (
        <Button
          {...roles('onRecoverButton')}
          text={RECOVERY_TEXT}
          onPress={() => {
            onRecover?.(error);
            resetErrorBoundary?.();
          }}
        />
      ) : null}
    </Wrapper>
  );
};

export { DefaultErrorFallback };
