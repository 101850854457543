import React, { ReactElement } from 'react';
import { useParams } from 'cross-platform/react-router';
import {
  Content,
  ContentTitle,
  ContentTitleBlock,
  SkeletonOuterContainerAnimated,
} from '../components';
import { isNonEmptyPageData } from '../components/helpers';
import {
  LibraryContentEntry,
  MissingContentEntry,
} from './LibraryContentEntry';
import { Category, LibraryScreen } from '@bighealth/types';
import { LibraryScreenParams } from './types';

export const LibraryContent = ({
  content,
  isLoading,
}: {
  isLoading?: boolean;
  content?: LibraryScreen;
}): ReactElement | null => {
  const { page } = useParams<LibraryScreenParams>();
  if (isLoading) {
    // the global throbber will be rendered
    return <SkeletonOuterContainerAnimated />;
  }
  if (typeof content === 'undefined') {
    return null;
  }
  if (!isNonEmptyPageData(content)) {
    return null;
  }
  const selectedContent = content.find(({ slug }) => slug === page);

  return (
    <Content>
      <ContentTitleBlock>
        <ContentTitle>{selectedContent?.title}</ContentTitle>
      </ContentTitleBlock>
      {(selectedContent?.content as Category[]).map(props => (
        <LibraryContentEntry {...props} key={props.heading} />
      ))}
      <MissingContentEntry
        heading={'Think we’re missing an article?'}
        subHeading={'Suggest a new expert article'}
      />
    </Content>
  );
};
