import React from 'react';
import { addStory } from 'lib/story-books';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { ProductReferences } from 'common/constants/enums';
import { CrossPlatformThemeProvider } from 'components/ProvidersContainer/ProductThemeProvider';
import { EphemeralStateProvider } from 'components/EphemeralStateProvider';
import { Form } from '..';
import { payload } from '.';
import { useFakeStorybookSceneSet } from 'lib/story-books/useFakeStorybookSceneSet';

const Story = () => {
  const sceneSets = useFakeStorybookSceneSet();
  if (!sceneSets) {
    return null;
  }
  return (
    <Form
      fieldProps={{
        sd2: { hidden: true },
        sd4: { hidden: true },
        sd6: { hidden: true },
      }}
      {...payload}
    />
  );
};

addStory('sleep-diary/Form hidden', () => (
  <EphemeralStateProvider>
    <CrossPlatformThemeProvider
      theme={getThemeForProduct(ProductReferences.SLEEPIO)}
    >
      <Story />
    </CrossPlatformThemeProvider>
  </EphemeralStateProvider>
));
