import React from 'react';
import styled from 'styled-components/native';
import { Text } from 'components/sceneset-components/Text';
import { Col, Row as RowRaw } from 'components/layout/Grid';
import {
  ScaleValueProps,
  scalerWithOptions,
} from 'components/Screens/SessionsScreen/utils/scaler';
import { ICONS_BY_STATUS } from '../../constants/ICONS_BY_STATUS';
import { byStatus, StatusProps } from '../../utils/byStatus';
import { CardProps } from '.';
import { StyleProp, ViewStyle } from 'react-native';

export { Grid } from 'components/layout/Grid';

const DOMAIN = 'Card';

const cardScaler = scalerWithOptions({ minScale: 100 / 145 });

export const Touchable = styled.TouchableOpacity`
  opacity: ${props => (props.disabled ? '0.5' : '1.0')};
`;
Touchable.displayName = `${DOMAIN}.Touchable`;

export const FirstCol = styled(Col)`
  width: 50%;
  overflow: hidden;
`;
FirstCol.displayName = `${DOMAIN}.FirstCol`;

export const SecondCol = styled(Col)`
  flex-basis: 0;
`;
SecondCol.displayName = `${DOMAIN}.SecondCol`;

export const Row = styled(RowRaw)`
  align-content: center;
`;
Row.displayName = `${DOMAIN}.Row`;

export const wrapperStyles = {
  width: 450,
  padding: 33,
};

export const Wrapper = styled.View<
  ScaleValueProps & StatusProps & StyleProp<ViewStyle>
>`
  width: ${cardScaler(wrapperStyles.width)}px;
  height: ${cardScaler(145)}px;
  margin: 0 ${cardScaler(24)}px 0 0;
  padding-left: ${cardScaler(wrapperStyles.padding)}px;
  padding-right: ${cardScaler(wrapperStyles.padding)}px;
  border-radius: ${cardScaler(8)}px;
  background-color: #ffffff;
`;
Wrapper.displayName = `${DOMAIN}.Wrapper`;

export const HeadingText = styled(Text).attrs({
  numberOfLines: 1,
})`
  color: #003366;
  font-size: ${cardScaler(40)}px;
  font-weight: 400;
`;
HeadingText.displayName = `${DOMAIN}.HeadingText`;

export const StatusText = styled(Text).attrs({
  numberOfLines: 1,
})<Pick<CardProps, 'status'> & ScaleValueProps>`
  font-size: ${cardScaler(20)}px;
  color: ${byStatus('color')};
  font-weight: 400;
`;
StatusText.displayName = `${DOMAIN}.StatusText`;

const ICON_BASE = 80;
const ICON_VIEW_BOX = `0 0 ${ICON_BASE} ${ICON_BASE}`;
export const IconByStatus = ({
  status,
  scaleValue,
}: Pick<CardProps, 'status'> & ScaleValueProps): React.ReactElement => {
  const IconComponent = ICONS_BY_STATUS[status];
  if (typeof IconComponent === 'undefined') {
    throw Error(
      `No icon for ${status} in ${Object.keys(ICONS_BY_STATUS).join(', ')}`
    );
  }
  const unit = cardScaler(ICON_BASE)({ scaleValue });
  return <IconComponent width={unit} height={unit} viewBox={ICON_VIEW_BOX} />;
};
IconByStatus.displayName = `${DOMAIN}.IconByStatus`;
