import { ResponseInputComponentType } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { Value } from 'components/forms/types';
import { ResponseOption } from '@bighealth/types';
import { getValidatedValue } from '../getValidatedValue';

type GetDefaultValue = (
  component: ResponseInputComponentType,
  initialValueOverride?: Value
) => Value;

const getDefaultValue: GetDefaultValue = (component, initialValueOverride) => {
  switch (component) {
    case 'DateTimePicker':
    case 'DatePicker':
    case 'TimePicker':
    case 'DropdownTimePicker':
      return getValidatedValue(component, initialValueOverride);
    default:
      return '';
  }
};

const getSelectedValueObject = (
  option: ResponseOption,
  initialValueOverride?: Value
) =>
  option.value === '$input' || option.value === initialValueOverride
    ? {
        isSelected: true,
      }
    : null;

const toDropdownItemFactory = (
  component: ResponseInputComponentType,
  initialValueOverride?: Value
) => (option: ResponseOption): DropdownItem => {
  return {
    ...(option?.label ? { label: option.label } : null),
    ...getSelectedValueObject(option, initialValueOverride),
    value:
      option.value === '$input'
        ? getDefaultValue(component, initialValueOverride)
        : typeof option.value === 'number'
        ? String(option.value)
        : option.value,
    id: option?.id,
  };
};
export { toDropdownItemFactory };
