import React, { ReactElement } from 'react';
import { CommonPropType } from 'components/generic-question/index.story';
import { Dropdown, DropdownOption } from './Dropdown';

export const DropdownStory = (props: CommonPropType): ReactElement => {
  const options = [{ id: 1, value: 1, displayText: 'One' }];
  return (
    <Dropdown
      options={options}
      onValueChange={console.log}
      highlight={props.highlight}
    >
      {options.map(props => (
        <DropdownOption {...props} key={props.id} />
      ))}
    </Dropdown>
  );
};
