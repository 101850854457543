import React from 'react';
import { DefaultErrorFallback } from 'lib/error/ErrorBoundary/components/DefaultErrorFallback/DefaultErrorFallback';
import { useHistory } from 'cross-platform/react-router';
import { refresh } from 'lib/router/utils/refresh';
import { FallbackProps } from 'lib/error/ErrorBoundary/types';
import { useLazyQueryLogout } from 'lib/api/reactQueryHelpers';
import { useSafeParams } from 'components/Routes/useSafeParams';
import logger from 'lib/logger';

const ErrorFallbackReturnsHome = (
  props: FallbackProps & { domain?: string }
): JSX.Element => {
  const { domain, ...restProps } = props;
  const history = useHistory();
  const logout = useLazyQueryLogout();
  const { productReference } = useSafeParams();
  return (
    <DefaultErrorFallback
      {...restProps}
      heading="Whoops..."
      message="Try again later or try logging out"
      debugMessage={`ErrorFallbackReturnsHome domain: ${domain || 'unknown'})`}
      actions={[
        {
          text: 'Sign Out',
          onPress: async () => {
            try {
              await logout();
            } catch (err) {
              logger(`DefaultErrorFallback: ${err}`, err, {
                silentUnlessDevOnly: true,
              });
            } finally {
              restProps.resetErrorBoundary();
              history.push(`/${productReference}/home`);
            }
          },
        },
        {
          text: 'Try again',
          onPress: () => {
            refresh(history);
            restProps.resetErrorBoundary(); // For safety
          },
        },
      ]}
    />
  );
};

/**
 * FACTORY: Create renderFallback
 *
 * @param {string} domain For debugging
 */
const renderFallbackReturnsHomeForDomainFactory = (
  domain?: string
): typeof renderFallbackReturnsHomeForDomain => {
  /**
   * renderFallback
   * @param props
   * @returns
   */
  const renderFallbackReturnsHomeForDomain = (
    props: FallbackProps
  ): JSX.Element => {
    return <ErrorFallbackReturnsHome {...props} domain={domain} />;
  };
  return renderFallbackReturnsHomeForDomain;
};

export { renderFallbackReturnsHomeForDomainFactory };
