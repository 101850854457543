import { QuizInfo } from 'components/forms/InputField';
import { Question, Response, ResponseOption } from '@bighealth/types';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { Errors } from 'components/forms';
import { logger } from 'lib/logger';
import { stringify } from 'lib/stringify';

export enum QUESTION_RESPONSE_ACTIONS {
  ADD_TO_SUBMIT = 'question-response/ADD_TO_SUBMIT',
  CLEAR_TO_SUBMIT = 'question-response/CLEAR_TO_SUBMIT',
  ADD_CORRECT_ANSWERS = 'question-response/ADD_CORRECT_ANSWERS',
  CLEAR_CORRECT_ANSWERS = 'question-response/CLEAR_CORRECT_ANSWERS',
  PUSH = 'question-response/PUSH',
  CLEAR = 'question-response/CLEAR',
  PUSH_QUIZ_ANSWER_BY_SCENE_SET = 'question-response/PUSH_QUIZ_ANSWER_BY_SCENE_SET',
  CLEAR_QUIZ_ANSWER_BY_SCENE_SET = 'question-response/CLEAR_QUIZ_ANSWER_BY_SCENE_SET',
  STORE_FORMIK_STATE_BY_PATH = 'question-response/STORE_FORMIK_STATE_BY_PATH',
  SET_LATEST_QUESTION = 'question-response/SET_LATEST_QUESTION',
}

// TODO move to @bighealth/types
// TODO change to only number...or...string
export type QuestionId = Question['semantic_id'] | number;

export type FormikPersistableState = {
  values?: Record<QuestionId, DropdownItem[]>;
  errors?: Errors;
  touched?: Record<string, boolean>;
};

export type QuestionResponseAction =
  | {
      type: QUESTION_RESPONSE_ACTIONS.ADD_TO_SUBMIT;
      payload?: Record<QuestionId, Response>;
    }
  | { type: QUESTION_RESPONSE_ACTIONS.CLEAR_TO_SUBMIT }
  | {
      type: QUESTION_RESPONSE_ACTIONS.PUSH;
      payload: Question;
    }
  | { type: QUESTION_RESPONSE_ACTIONS.CLEAR }
  | {
      type: QUESTION_RESPONSE_ACTIONS.ADD_CORRECT_ANSWERS;
      payload?: Record<string, QuizInfo>;
    }
  | { type: QUESTION_RESPONSE_ACTIONS.CLEAR_CORRECT_ANSWERS }
  | {
      type: QUESTION_RESPONSE_ACTIONS.PUSH_QUIZ_ANSWER_BY_SCENE_SET;
      payload: {
        path: string;
        attempt: ResponseOption[];
      };
    }
  | {
      type: QUESTION_RESPONSE_ACTIONS.CLEAR_QUIZ_ANSWER_BY_SCENE_SET;
      payload: string;
    }
  | {
      type: QUESTION_RESPONSE_ACTIONS.STORE_FORMIK_STATE_BY_PATH;
      payload: {
        state: FormikPersistableState;
        path: string;
      };
    }
  | {
      type: QUESTION_RESPONSE_ACTIONS.SET_LATEST_QUESTION;
      payload: number | undefined;
    };

export const addQuestionResponseToSubmit = (
  payload: Record<QuestionId, Response>
): QuestionResponseAction => ({
  type: QUESTION_RESPONSE_ACTIONS.ADD_TO_SUBMIT,
  payload,
});

// IDEA consistent naming plurality
export const clearQuestionResponsesToSubmit = (): QuestionResponseAction => ({
  type: QUESTION_RESPONSE_ACTIONS.CLEAR_TO_SUBMIT,
});

export const addQuestionResponseCorrectAnswers = (
  payload: Record<string, QuizInfo>
): QuestionResponseAction => ({
  type: QUESTION_RESPONSE_ACTIONS.ADD_CORRECT_ANSWERS,
  payload,
});

export const clearQuestionResponseCorrectAnswers = (): QuestionResponseAction => ({
  type: QUESTION_RESPONSE_ACTIONS.CLEAR_CORRECT_ANSWERS,
});

export const pushQuestionResponse = (
  payload: Question
): QuestionResponseAction => ({
  type: QUESTION_RESPONSE_ACTIONS.PUSH,
  payload,
});

export const clearQuestionResponses = (): QuestionResponseAction => ({
  type: QUESTION_RESPONSE_ACTIONS.CLEAR,
});

export const pushQuestionResponseQuizAnswersByPath = (
  path: string,
  attempt: ResponseOption[]
): QuestionResponseAction => ({
  type: QUESTION_RESPONSE_ACTIONS.PUSH_QUIZ_ANSWER_BY_SCENE_SET,
  payload: { path, attempt },
});

export const clearQuestionResponseQuizAnswersByPath = (
  path: string
): QuestionResponseAction => ({
  type: QUESTION_RESPONSE_ACTIONS.CLEAR_QUIZ_ANSWER_BY_SCENE_SET,
  payload: path,
});

export const persistFormikStateByPath = (
  path: string,
  formikState: FormikPersistableState
): {
  type: QUESTION_RESPONSE_ACTIONS.STORE_FORMIK_STATE_BY_PATH;
  payload: { path: string; state: FormikPersistableState };
} => {
  if (typeof path !== 'string' /* Defensive coding */) {
    const formikStateStr = stringify(formikState);
    const warnStr = `Expected persistFormikStateByPath path to be string, instead got: ${path}(${typeof path}) for ${formikStateStr}`;
    logger(warnStr, undefined, { silent: true });
  }
  return {
    type: QUESTION_RESPONSE_ACTIONS.STORE_FORMIK_STATE_BY_PATH,
    payload: {
      state: formikState,
      path,
    },
  };
};

export const setLatestQuestionId = (
  payload: number | undefined
): QuestionResponseAction => ({
  type: QUESTION_RESPONSE_ACTIONS.SET_LATEST_QUESTION,
  payload,
});
