export type RawDatePickersProps = {
  onDateChange: (value: Date | null) => void;
  date: Date | null;
  minDate?: Date;
  maxDate?: Date;
  highlight?: boolean;
};

export enum DatePickerDisplayFormat {
  TIME_FORMAT = 'h:mm aa',
  DATE_FORMAT = 'MM/dd/yyyy',
  DATE_TIME_FORMAT = 'MM/dd/yyyy h:mm aa',
}

export enum DatePickerPlaceholder {
  TIME_PLACEHOLDER = '-- : -- --',
  DATE_PLACEHOLDER = 'mm-dd-yyyy',
  DATE_TIME_PLACEHOLDER = 'mm-dd-yyyy  -- : -- --',
}
