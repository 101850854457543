import { matchPath, useHistory } from 'cross-platform/react-router';
import { useEffect, useRef } from 'react';

type Callback = () => void;

const callbackRegistry: Record<string, Callback> = {};

export const registerBackButtonTriggerForPath = (
  triggerPath: string,
  callback: Callback
): void => {
  callbackRegistry[triggerPath] = callback;
};

export const clearBackButtonTriggerRegistryEntryByPath = (
  triggerPath: string
): void => {
  delete callbackRegistry[triggerPath];
};

/**
 *
 * Triggers the registered callback when the user navigates to a page using the back button
 */
export const useBackButtonTriggerForPath = (): void => {
  const history = useHistory();
  const currentPath = history.location.pathname;
  const currentAction = history.action;
  const lastPath = useRef(currentPath);

  useEffect(() => {
    if (currentAction === 'POP' && currentPath !== lastPath.current) {
      // We got here via the back button so trigger all the callbacks that match that path
      for (const [triggerPath, callback] of Object.entries(callbackRegistry)) {
        const matches = matchPath(currentPath, {
          path: triggerPath,
        });
        if (matches && typeof callback === 'function') {
          callback();
        }
      }
      // Reset
      lastPath.current = currentPath;
    }
    if (lastPath.current !== currentPath) {
      lastPath.current = currentPath;
    }
  }, [currentAction, currentPath]);
};
