import React, { ReactElement } from 'react';
import { PressButton, PressButtonProps } from './PressButton';
import { ButtonTypes } from 'common/constants/enums';

export type PrimaryButtonProps = Omit<PressButtonProps, 'type' | 'text'> & {
  // TODO change to React.ReactNode to allow for icons etc
  // WHEN we revise buttons
  children: string;
};

const PrimaryButton = (props: PrimaryButtonProps): ReactElement => (
  <PressButton
    type={ButtonTypes.PRIMARY_BUTTON}
    text={props.children}
    {...props}
  />
);

export { PrimaryButton };
