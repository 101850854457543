import { MINUTE, DAY, HOUR, SECOND } from 'lib/durations';

export const INTERVAL_MS = 5 * MINUTE;
const MAX_RANGE = DAY - INTERVAL_MS;

type Options = {
  min?: Date; // in Locale time
  max?: Date; // in Locale time
};

// WARNING Should not convert min/max `response_option` to UTC,
// as this is now out of whack with min/max in `validation_schema`
// TODO Use locale dates for these calculating range
// WHY transformation is error-prone and cases divergence
// WHO Ash
const createUTCFromLocale = (date: Date) =>
  new Date(
    date.getHours() * HOUR +
      date.getMinutes() * MINUTE +
      date.getSeconds() * SECOND +
      date.getMilliseconds()
  );
export const getMinAndMaxMilliseconds = (
  options: Options
): { minNum: number; maxNum: number } => {
  const min = options?.min
    ? createUTCFromLocale(options.min) // get time
    : new Date(0);
  const minNum = min.valueOf();
  const max = options?.max
    ? createUTCFromLocale(options.max) // get time
    : new Date(minNum + MAX_RANGE);

  let maxNum = max.valueOf();
  if (maxNum <= minNum) {
    maxNum += DAY;
  }
  if (maxNum - minNum > MAX_RANGE) {
    maxNum = minNum + MAX_RANGE;
  }
  return { minNum, maxNum };
};

export const createDates = ({
  offsetFromUTC,
  interval,
  minNum,
  maxNum,
}: {
  offsetFromUTC: number;
  interval: number;
  minNum: number;
  maxNum: number;
}): Date[] => {
  const entries = Math.ceil(maxNum / interval);
  if (entries < 0) {
    throw Error(`${new Date(maxNum)} ${maxNum} / ${interval}`);
  }
  return [...Array<number>(entries + 1)]
    .map((_, index) => index * interval) // increment by position
    .filter(stepMs => stepMs >= minNum)
    .filter(stepMs => stepMs <= maxNum)
    .map(stepMs => new Date(stepMs + offsetFromUTC));
};

const deriveTimeResponseOptions = (options: Options): Date[] => {
  const offsetFromUTC = new Date(0).getTimezoneOffset() * MINUTE;
  const { minNum, maxNum } = getMinAndMaxMilliseconds(options);
  return createDates({
    offsetFromUTC,
    interval: INTERVAL_MS,
    minNum,
    maxNum,
  });
};

export { deriveTimeResponseOptions };
