import React, { ReactElement, Component } from 'react';
import { StyleSheet } from 'react-native';
const flatten = StyleSheet.flatten;
import { Scene } from '@bighealth/types/src/scene-component';
import { getShouldInitiallyShowFromProps } from './helpers';
type ToggleVisibilityProps = Scene.Utils.ToggleVisibility;

interface State {
  show: boolean;
}

interface Props extends ToggleVisibilityProps {
  // This "any" is a bit lazy but but truth is:
  //   1) the only thing this component cares about is style.display if that's missing it adds it anyway
  //   2) to make this cross-platform we need to get the type of props.style due to CSSProperties,
  //      ViewProps<ViewStyle> and ViewStyleObject clashes etc.
  // Making this anything other than "any" seems like a micro-optimisation in terms of code quality

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
  // Match React's internal types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ReactElement<any>;
}

export class ToggleableVisibility extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      show: getShouldInitiallyShowFromProps(props),
    };
  }

  show = (): void => {
    this.setState({ show: true });
  };

  hide = (): void => {
    this.setState({ show: false });
  };

  render(): ReactElement {
    const { children, hideAtSeconds, showAtSeconds, ...rest } = this.props;
    const flatStyle = flatten(children.props.style || {});
    // "any" is used here to mimic React.cloneElement
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return React.cloneElement(children as React.ReactElement<any>, {
      ...rest,
      hideAtSeconds,
      showAtSeconds,
      style: {
        ...flatStyle,
        display: this.state.show ? flatStyle?.display || 'flex' : 'none',
      },
    });
  }
}
