import * as React from 'react';
import { ReactElement } from 'react';
import { Wrapper, ErrorText } from './styled';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import TextInput from 'components/TextInput';
import { NullText } from 'components/Text/NullText';
import { Label } from '../Label';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { SingleSelect } from 'components/SingleSelect';
import { ResponseOption } from '@bighealth/types';
import { Value } from 'components/forms/types';

export type TextFieldTypeData = {
  fieldType: 'TEXT';
  placeholder?: string;
  secureTextEntry?: boolean;
};
export type SelectionFieldTypeData = {
  fieldType: 'SELECTION';
  layout?: 'HORIZONTAL' | 'VERTICAL' | 'DROPDOWN';
};
export type InputFieldTypeData = {
  name: string;
  label: string;
  value?: DropdownItem[];
  error?: string;
  correctSelection?: ResponseOption['id'][];
  incorrectSelection?: ResponseOption['id'][];
  items?: DropdownItem[];
  fieldTypeProps: TextFieldTypeData | SelectionFieldTypeData;
} & Partial<RoleProps>;

export type InputFieldPresentationalProps = InputFieldTypeData & {
  touched?: boolean;
  onChange: (value?: Value) => void;
  getTextValue?: (value: any) => string;
  onBlur: (e: unknown) => void;
};

export enum InputFieldRoles {
  ERROR = 'error',
  INPUT = 'input',
}

/**
 * A type-guard to pick the field
 * @param props
 */
const discriminateField = (
  props: InputFieldPresentationalProps
): ReactElement => {
  switch (props.fieldTypeProps.fieldType) {
    case 'SELECTION':
      switch (props.fieldTypeProps.layout) {
        case 'DROPDOWN':
        case 'HORIZONTAL':
        case 'VERTICAL':
        default:
          return (
            <SingleSelect
              {...roles.pass(props, InputFieldRoles.INPUT as string)}
              onChange={props.onChange}
              value={props.value}
              items={props.items}
              correctSelection={props.correctSelection}
              incorrectSelection={props.incorrectSelection}
            />
          );
      }
    case 'TEXT':
    default: {
      return (
        <TextInput
          onChangeText={props.onChange}
          onBlur={props.onBlur}
          value={
            props.getTextValue
              ? props.getTextValue(props.value)
              : props?.value || ''
          }
          placeholder={props.fieldTypeProps.placeholder}
          secureTextEntry={props.fieldTypeProps.secureTextEntry}
          {...roles.pass(props, InputFieldRoles.INPUT as string)}
        />
      );
    }
  }
};
const InputFieldPresentational = (
  props: InputFieldPresentationalProps
): ReactElement => (
  <Wrapper {...roles.pass(props)}>
    <Label>{props.label}</Label>
    {discriminateField(props)}
    {props.touched && props.error ? (
      <ErrorText {...roles.pass(props, InputFieldRoles.ERROR as string)}>
        {props.error}
      </ErrorText>
    ) : (
      <NullText />
    )}
  </Wrapper>
);

export { InputFieldPresentational };
