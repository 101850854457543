import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { APIErrorResponse } from '@bighealth/api';
import { useQueryProduct } from './useQueryProduct';
import { get_settings } from '@bighealth/api/UserSleepioMetadata/v1';

export const useQuerySettings = (): UseQueryResult<
  get_settings.Response,
  APIErrorResponse
> => {
  const productId = useQueryProduct().data?.result?.id;

  const result = useQuery({
    queryKey: get_settings.queryKey,
    queryFn: () =>
      get_settings({
        product_id: productId as number,
      }),
    enabled: typeof productId === 'number',
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0,
    staleTime: 0,
    retry: false,
  });
  return result;
};
