export const navigationBarConfig = {
  tabs: {
    Today: {
      path: '/daylight/home',
      activeColor: '#FF754F',
      inactiveColor: '#4A4A4A',
    },
    Progress: {
      path: '/daylight/progress',
      activeColor: '#FF754F',
      inactiveColor: '#4A4A4A',
    },
  },
};
