import _ from 'lodash';
import { Question } from '@bighealth/types/src';
import { createDateAtDayEnd } from 'lib/createDateAtDayEnd';
// types
type ValidationSchema = Question['response_config']['validation_schema'];

// Types to work-around Typescript issues
// TODO don't use these
// WHEN someone explains what is going on with the types
type FauxProperties = Record<string, { maximum?: number }> & {
  selections: Array<number>;
};
type FauxSchema = {
  properties?: FauxProperties;
};
const asFauxSchema = (validationSchema: ValidationSchema) =>
  (validationSchema as unknown) as FauxSchema;

/**
 * Take a validation schema, and push the "maximum" values
 * to the last millisecond of the day
 *
 * @param schema
 * @returns
 */
const transformDateMaxToUpperBound = (
  schema: ValidationSchema
): ValidationSchema => {
  const fauxSchema = asFauxSchema(_.cloneDeep(schema));
  if (typeof fauxSchema?.properties === 'undefined') {
    return schema;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _selections, ...restOfProperties } = fauxSchema.properties; // "delete" selections
  for (const value of Object.values(restOfProperties)) {
    if (typeof value.maximum === 'number') {
      value.maximum = createDateAtDayEnd(new Date(value.maximum)).getTime();
    }
  }
  return (fauxSchema as unknown) as ValidationSchema;
};

export { transformDateMaxToUpperBound };
