/* eslint-disable camelcase */
import { QuestionId } from 'state/question-response/actions';
import { Question, ResponseOption } from '@bighealth/types';
import { QuestionResponseState } from 'state/question-response/reducer';
import { QuizInfo } from 'components/forms/InputField';
import { getAssessment } from 'lib/question-response/useQuizForCurrentQuestion/utils/getAssessment';
import { pipeOrThrowIfUndefined } from 'lib/type-guarded';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { setSelection } from 'components/forms/ResponseOptions/ResponseInput/utils/queue';
import { clone, filter } from 'ramda';
import { FormikHelpers } from 'formik';

export type TryForceComplete = (
  formItems: Record<QuestionId, DropdownItem[]>,
  setValues: FormikHelpers<Record<QuestionId, DropdownItem[]>>['setValues']
) => Record<QuestionId, DropdownItem[]>;

// IDEA clear quiz attempts?
// IDEA More code reuse is possible with checkQuizCorrect
const tryForceCompleteWithQuestionsAndQuizAttempts = (
  questions: Question[],
  allQuizAttempts: QuestionResponseState['quizAttemptsByPath'],
  getPath: (questionSemanticId: number) => string,
  // IDEA rename setSelectedQueue to match
  // WHY match src/components/forms/ResponseOptions/ResponseInput/index.tsx
  setSelectionQueue: (questionPath: string, value: DropdownItem[]) => void
): TryForceComplete => {
  /**
   * For any input with quiz attempt === 0,
   * force the correct answers
   * @param values
   */
  const tryForceComplete: TryForceComplete = (formItems, setValues) => {
    const updateFormItems = clone(formItems);
    const completed: Record<string, ResponseOption[]> = {};
    Object.keys(formItems).forEach((questionSemanticIdKey: string): void => {
      // TS infers string: https://github.com/microsoft/TypeScript/issues/32811#issuecomment-520448992
      const questionSemanticId = (questionSemanticIdKey as unknown) as keyof typeof formItems;
      const question = questions.find(
        q => q.semantic_id === questionSemanticId
      );
      if (typeof question?.id === 'undefined') {
        throw ReferenceError('No id');
      }
      const { max_number_attempts, correct_response_ids } =
        question?.response_config || {};
      // No quiz
      if (
        typeof max_number_attempts === 'undefined' ||
        typeof correct_response_ids === 'undefined' ||
        correct_response_ids?.length === 0
      ) {
        return undefined;
      }
      // Has quiz...
      const quizInfo: QuizInfo = {
        max_number_attempts,
        correct_response_ids,
      };
      const questionPath = getPath(question.id);
      const attempts = allQuizAttempts
        .filter(a => a.path === questionSemanticId)
        .map(a => a.attempt)
        .map(pipeOrThrowIfUndefined); // IDEA abstract so this code doesn't know about state shape

      const assessment = getAssessment(attempts, quizInfo);

      // Force-deselect wrong answers
      updateFormItems[questionSemanticId] = setSelection(
        max_number_attempts,
        formItems[questionSemanticId],
        formItems[questionSemanticId].filter(item => {
          if (typeof item.id !== 'number') {
            throw TypeError('id is string');
          }
          if (
            quizInfo.correct_response_ids.includes(item.id) === false &&
            item.isSelected
          ) {
            return false;
          }
          return item.isSelected;
        })
      );

      if (assessment?.attemptsLeft === 0) {
        // Force-select correct answers...
        // ...update dropdown options
        updateFormItems[questionSemanticId] = setSelection(
          max_number_attempts,
          updateFormItems[questionSemanticId],
          updateFormItems[questionSemanticId].filter(item => {
            if (typeof item.id !== 'number') {
              throw TypeError('id is string');
            }
            return quizInfo.correct_response_ids.includes(item.id);
          })
        );
        // ...update quiz answer
        completed[questionPath] = filter(
          (option: ResponseOption): boolean =>
            quizInfo.correct_response_ids.includes(option.id),
          question.response_config.response_options
        );
      }
      setSelectionQueue(
        questionPath,
        updateFormItems[questionSemanticId].filter(e => e.isSelected)
      );
    });

    setValues(updateFormItems);
    return updateFormItems;
  };
  return tryForceComplete;
};

export { tryForceCompleteWithQuestionsAndQuizAttempts };
