import * as React from 'react';
import { Text } from './components/Text';
import { COPYRIGHT_TEXT } from 'lib/strings';
import styled from 'styled-components/native';
import { useSleepioVersion } from './hooks/useSleepioVersion';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
const DOMAIN = 'NavFooter';

const Wrapper = styled.View<WrapperProps>`
  width: 100%;
  height: 44px;
  margin-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 40px;
  flex-direction: column;
  justify-content: space-between;
`;
Wrapper.displayName = `${DOMAIN}.Wrapper`;

type FooterProps = React.ComponentProps<typeof Wrapper> & RoleProps;
type WrapperProps = { color: string };

export const NavFooter = (props: FooterProps): React.ReactElement => {
  const sleepioVersion = useSleepioVersion();

  return (
    <Wrapper {...props} {...roles.pass(props, DOMAIN)}>
      <Text text={COPYRIGHT_TEXT} />
      <Text text={sleepioVersion} />
    </Wrapper>
  );
};
