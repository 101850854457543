import styled from 'styled-components/native';
import { Text } from '../Text';
import { getTheme } from 'lib/styles/getTheme';
import { TextTypes } from 'common/constants/enums';

const ErrorText = styled(Text).attrs({ presentationType: TextTypes.ERROR })`
  color: ${getTheme('text[presentationType].color')};
`;

export { ErrorText };
