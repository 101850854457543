import { useQueryProduct, useQueryProgram } from 'lib/api/reactQueryHelpers';
import { useAnalytics } from '@bighealth/analytics';
import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { APIErrorResponse } from '@bighealth/api';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { sendSessionAnalytics } from 'lib/api/analytics/sendSessionAnalytics';
import { fire_ui_events } from '@bighealth/api/Event/v1/fire_ui_events';
import { useGetURL } from 'lib/api/analytics/useGetURL';
import { useHistory } from 'cross-platform/react-router';

export const useQuerySendSessionAnalytics = (): UseQueryResult<
  void,
  APIErrorResponse
> => {
  const programId = useQueryProgram().data?.result.id;
  const productId = useQueryProduct().data?.result.id;
  const analytics = useAnalytics();
  const getUrl = useGetURL();
  const pathname = useHistory().location.pathname;
  const { sceneSetGraphId, sceneSetId, sceneId } = useSafeParams();

  const result = useQuery({
    queryKey: [fire_ui_events.queryKey, pathname],
    queryFn: async () => {
      const url = await getUrl();
      return sendSessionAnalytics({
        programId: programId as number,
        productId: productId as number,
        sceneSetGraphId,
        sceneSetId: sceneSetId as number,
        sceneId: sceneId || 0,
        analytics,
        url,
      });
    },
    enabled: typeof productId === 'number' && typeof programId === 'number',
    cacheTime: 0,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnReconnect: false,
    staleTime: 0,
  });
  return result;
};
