import React, { ReactElement } from 'react';
import { ActionImage } from 'components/Media';
import { getStylesFromProps } from '../helpers';
import {
  Image as ImageType,
  SceneAction,
} from '@bighealth/types/src/scene-components/client';
import { WithBackupFormikContext } from 'components/WithBackupFormikContext';

type ImageProps = ImageType & {
  action?: SceneAction;
};

const SceneSetImageWithoutBackupFormikContext = (
  props: ImageProps
): ReactElement => {
  const { sourceProps, ...rest } = props;
  const src = sourceProps?.storage_url || '';
  return <ActionImage {...rest} src={src} style={getStylesFromProps(props)} />;
};

const SceneSetImage = (props: ImageProps): ReactElement => {
  return WithBackupFormikContext(
    props,
    SceneSetImageWithoutBackupFormikContext
  );
};

export default SceneSetImage;
