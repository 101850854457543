import styled from 'styled-components/native';

export const ColumnContainer = styled.View`
  flex-direction: column;
  height: 100%;
`;

export const RawContainer = styled.View`
  flex-direction: row;
  width: 100%;
`;

export const ContainerSettings = styled.View`
  position: absolute;
  right: 6%;
  top: 8%;
`;

export const ContainerContent = styled.View`
  width: 100%;
  height: 90%;
`;

export const ContainerNextSSGInfo = styled.View``;

export const ContainerText = styled.View`
  width: 80%;
`;

export const ContainerPlayButton = styled.View`
  flex-direction: row;
  width: 20%;
  align-items: center;
  justify-content: flex-end;
`;

export const ContainerPracticeLibraryButton = styled.View`
  width: 100%;
  height: 10%;
  justify-content: flex-end;
`;
