import styled from 'styled-components/native';
import { Row } from 'components/layout/Grid';
import { ScaleYValueProps, yScaler } from '../../../utils/scaler';
import { DOMAIN } from '../components/SessionStatus/styled/constants';
import { BOTTOM_GAP_UNDER_CARD_SCROLLER } from './constants';

const BottomRow = styled(Row)<ScaleYValueProps>`
  margin-top: ${yScaler(238)}px;
  margin-bottom: ${yScaler(BOTTOM_GAP_UNDER_CARD_SCROLLER / 2)}px;
`;
BottomRow.displayName = `${DOMAIN}.BottomRow`;

export { BottomRow };
