import styled from 'styled-components/native';
import { Text } from 'components/sceneset-components/Text';

export const Container = styled.View`
  display: flex;
  flex-direction: column;
  padding-left: 3.5%
  padding-right: 2.5%
  background-color: #ddefff;
  border-radius: 6.4px;
`;

export const MetricContainer = styled.View`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  background-color: #ddefff;
  justify-content: space-between;
  align-items: center;
`;

export const MetricTitle = styled<typeof Text>(Text)`
  color: #001948;
  font-weight: normal;
`;

export const MetricItemView = styled.View`
  background-color: white;
  border-radius: 4px;
  margin-bottom: 2.2%;
`;

export const MetricItemTitle = styled<typeof Text>(Text)`
  color: black;
  font-weight: normal;
`;

export const MetricItemValue = styled<typeof Text>(Text)`
  margin-top: 1%;
  color: ${props => props.style?.color};
  font-weight: normal;
`;
