import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';

export const logout = async (): Promise<logout.Response> => {
  return await api({
    service_name: 'UserAccountAuthentication',
    service_version: '1',
    service_method: 'logout',
    args: {},
  } as logout.Request);
};

logout.queryKey = 'UserAccountAuthentication/logout';

export declare namespace logout {
  export interface Request extends APIRequestBody {
    service_name: 'UserAccountAuthentication';
    service_version: '1';
    service_method: 'logout';
    args: {};
  }

  export type Result = {};

  export type Response = APIResponse<Result>;
}
