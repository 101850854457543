import { isValidDate } from 'lib/isValidDate';

export const regexHHmmss = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;

/**
 * Create a date object from $time string
 * WARNING default relative to epoch - not to user time
 *
 * @param {string} $time Expected format HH:mm:ss
 * @param {boolean} isUTC default relative to epoch - not to user time
 */
const getDateFromHoursMinutesSeconds = (
  $time: string,
  // IDEA change to options object
  // WHY for option readability in call
  // WHEN happy to refactor getLocaleHoursMinutesSecondsFromDate
  isUTC = true
): Date => {
  if (!regexHHmmss.test($time)) {
    throw Error(
      `Expected valid time string in the format HH:mm:ss, instead got "${$time}"`
    );
  }
  const [, hhStr, mmStr, ssStr] = $time.match(regexHHmmss) as string[];
  const [hh, mm, ss] = [hhStr, mmStr, ssStr].map(str => parseInt(str, 10));
  const date = new Date(0);
  if (isUTC) {
    // Default: relative to epoch - not to user time
    date.setUTCHours(hh);
    date.setUTCMinutes(mm);
    date.setUTCSeconds(ss);
  } else {
    date.setHours(hh);
    date.setMinutes(mm);
    date.setSeconds(ss);
  }
  if (!isValidDate(date)) {
    // Impossible code path: defensive coding against vender & future refactors
    throw Error(`Date is not valid ${$time}`);
  }
  return date;
};
// FIXME remove
const getDateFromHHmmss = getDateFromHoursMinutesSeconds; // alias using time format pattern

export { getDateFromHoursMinutesSeconds, getDateFromHHmmss };
