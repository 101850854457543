import React from 'react';
import { addStory } from 'lib/story-books';
import { Grid, Row } from 'components/layout/Grid';
import { ButtonTypes } from 'common/constants/enums';
import { PrimaryButton } from './PrimaryButton';
import { PressButton } from './PressButton';
import Button from '.';

addStory('buttons', () => (
  <Grid>
    {Object.entries({ PrimaryButton }).map(([name, Component]) => (
      <Row key={name}>
        <Component onPress={console.log}>{name}</Component>
      </Row>
    ))}
    <PrimaryButton onPress={console.log}>PrimaryButton</PrimaryButton>
    <PressButton
      text="PressButton"
      onPress={console.log}
      type={ButtonTypes.SECONDARY_BUTTON}
    />
    <Button text="Button (default)" />
    <Button
      text="Button (SECONDARY_BUTTON)"
      type={ButtonTypes.SECONDARY_BUTTON}
    />
  </Grid>
));
