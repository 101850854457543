import React, { ReactElement } from 'react';
import { Link as UnprotectedLink, LinkProps } from 'react-router-dom';
import { StyleSheet } from 'react-native';
const flatten = StyleSheet.flatten;

const Link = ({ style, ...rest }: LinkProps): ReactElement => (
  <UnprotectedLink style={flatten(style)} {...rest} />
);

export default Link;
