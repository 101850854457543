import styled from 'styled-components/native';
import React, { ReactElement, ReactNode, useContext } from 'react';
import {
  CONTENT_MIN_REFERENCE_DIMENSION,
  ScreenResponsiveLayoutContext,
  useGetDynamicContentStyles,
} from 'components/ResponsiveLayout';

import { Text } from 'components/Text';
import { Footer } from 'components/Footer';

// NOTE: At the moment the resource page does not look good under this point in which I make it look like the native app portrait mocks
// If we decide to have a global mobile portrait content break point we can swap, this value later
export const SMALL_BREAK_POINT = 550;

//
// Generic Containers
//
const ColumnContainerStatic = styled.View`
  flex: 1;
  min-height: auto;
  flex-basis: auto;
  background-color: white;
  flex-direction: row;
  justify-content: space-between;
`;

const ScrollView = styled.ScrollView``;

export const ColumnContainer = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  const isSmall = screenWidth <= SMALL_BREAK_POINT;
  const isMedium = screenWidth <= CONTENT_MIN_REFERENCE_DIMENSION && !isSmall;

  const horizontalPadding = isMedium
    ? styles.resourcesHorizontalPaddingNarrow
    : styles.resourcesHorizontalPadding;
  const verticalPadding = isMedium
    ? styles.resourcesVerticalPaddingNarrow
    : styles.resourcesVerticalPadding;

  return (
    <ScrollView contentContainerStyle={{ flex: 1 }}>
      <ColumnContainerStatic
        style={{
          paddingLeft: horizontalPadding,
          paddingRight: horizontalPadding,
          paddingTop: verticalPadding,
          paddingBottom: verticalPadding,
          flexWrap: isSmall ? 'wrap' : 'nowrap',
        }}
      >
        {children}
      </ColumnContainerStatic>
      <Footer />
    </ScrollView>
  );
};

export const View = styled.View``;

//
// Left Column
//

export const LeftColumn = styled.View`
  width: 30%;
  min-width: 185px;
  max-width: 267px;
`;

export const LeftColumnTitle = styled(Text)`
  font-size: 40px;
  color: #003366;
  margin-bottom: 35px;
  text-align: left;
`;

export const LeftColumnTextLink = styled(Text)`
  color: #1a80a2;
  font-size: 20px;
  line-height: 50px;
`;

//
// Right Column
//

export const RightColumn = styled.View`
  min-height: auto;
  max-width: 725px;
  align-items: flex-start;
`;

export const RightColumnTitleContainer = styled.View`
  margin-bottom: 40px;
`;

export const RightColumnBodyContainer = styled.View`
  width: 100%;
`;

export const AboutUsTitle = styled(Text)`
  font-weight: bold;
  color: #003366;
`;

export const AboutUsSubtitle = styled(Text)`
  color: #003366;
`;

export const AddressContainer = styled.View``;

export const BodyText = styled(Text)`
  font-size: 18px;
  line-height: 24px;
  color: #003366;
`;
