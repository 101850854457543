import React, { FunctionComponent } from 'react';
import { ProgressBar } from 'components/ProgressBar/';
import { useGetDynamicPlayerControlsStyles } from 'components/ResponsiveLayout';
import { TitlesText } from './styled';
import { getSessionName } from 'lib/player/getSessionName';
import { getSessionDescription } from 'lib/player/getSessionDescription';
import { getSessionProgressPercent } from 'lib/player/getSessionProgressPercent';
import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';
import styled from 'styled-components/native';
import {
  useQueryJumpToSceneSet,
  useQuerySceneSetGraphsBulk,
} from 'lib/api/reactQueryHelpers';

const PlayerTextContainer = styled.View`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const Row = styled.View``;

const SessionDetails: FunctionComponent = () => {
  const {
    titleFontSize,
    subtitleFontSize,
    titleMarginBottom,
    subtitleMarginBottom,
    progressBarWidth,
    progressBarHeight,
    progressBarBorderRadius,
  } = useGetDynamicPlayerControlsStyles();
  const { sceneSetGraphId, sceneSetId } = useSafeParams<SceneSetParams>();
  const {
    textContainerHorizontalPadding,
  } = useGetDynamicPlayerControlsStyles();

  const sceneSetGraphsResponse = useQuerySceneSetGraphsBulk();
  const sceneSet = useQueryJumpToSceneSet(sceneSetId)?.data?.result
    ?.scene_set_json;
  if (!sceneSet) {
    return null;
  }

  const sessionDetails = {
    heading: getSessionName(
      sceneSetGraphsResponse.data?.result,
      sceneSetGraphId
    ), // "Session Number" in specs
    description: getSessionDescription(sceneSet), // "Session title" in specs
    progressPercent: getSessionProgressPercent(sceneSet),
  };
  return (
    <>
      <PlayerTextContainer
        style={{
          paddingLeft: textContainerHorizontalPadding,
          paddingRight: textContainerHorizontalPadding,
        }}
      >
        <Row>
          <TitlesText
            style={{
              fontSize: titleFontSize,
              // 1.2 is used here for two reasons:
              // 1) The design docs don't specify the height correctly but using 1.2 matches it
              // 2) The design docs actually show a line height equal to the font size however,
              //    React Native in seems to crop text when the line-height is exactly equal
              //    to the font size. There is much argument about this but our fix is fine
              //    https://github.com/facebook/react-native/issues/7687
              lineHeight: titleFontSize * 1.2,
              fontWeight: 'bold',
              marginBottom: titleMarginBottom,
            }}
          >
            {sessionDetails.heading}
          </TitlesText>

          <TitlesText
            style={{
              fontSize: subtitleFontSize,
              lineHeight: subtitleFontSize * 1.2,
              marginBottom: subtitleMarginBottom,
            }}
          >
            {sessionDetails.description}
          </TitlesText>
          {typeof sessionDetails?.progressPercent !== 'undefined' ? (
            <ProgressBar
              value={sessionDetails.progressPercent}
              max={100}
              style={{
                width: progressBarWidth,
                height: progressBarHeight,
                borderRadius: progressBarBorderRadius,
              }}
            />
          ) : null}
        </Row>
      </PlayerTextContainer>
    </>
  );
};

export { SessionDetails };
