import { useCallback, useContext, useMemo, useState } from 'react';
import { ScreenResponsiveLayoutContext } from 'components/ResponsiveLayout';
import { ViewProps } from 'react-native';

export type ClickPosition = {
  top: number;
  left: number;
};

type LayoutHandler = ViewProps['onLayout'];

/**
 * Enables a menu to avoid overlapping the edge of the screen
 *
 * @param clickPosition
 */
export const useWindowAwarePosition = (
  clickPosition: ClickPosition
): {
  top: number;
  left: number;
  handleLayout: LayoutHandler;
  isReady: boolean;
} => {
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  const [isReady, setIsReady] = useState(false);
  const [position, setPosition] = useState(clickPosition);

  const handleLayout: LayoutHandler = useCallback(
    e => {
      const { width } = e.nativeEvent.layout;
      if (clickPosition.left + width > screenWidth) {
        const newLeft = screenWidth - width - 10;
        setPosition({
          top: clickPosition.top,
          left: newLeft,
        });
      }
      setIsReady(true);
    },
    [clickPosition, screenWidth]
  );
  const { top, left } = position;
  return useMemo(
    () => ({
      handleLayout,
      top,
      left,
      isReady,
    }),
    [handleLayout, isReady, left, top]
  );
};
