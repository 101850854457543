import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { ViewStyle } from 'react-native';
import { ReactElement } from 'react';
import { sleepioColors } from 'config/getThemeForProducts/themes/sleepio';

export const QuoteMarkIcon = ({
  style,
  color = sleepioColors.lightBlue,
}: {
  style?: ViewStyle;
  color?: string;
}): ReactElement => {
  return (
    <Svg viewBox="0 0 15 14" style={style}>
      <Path
        fill={color || sleepioColors.lightBlue}
        d="M6,13.45 L6,7.1 L3.8,7.1 C3.8,5.55 4.4,4.4 6,4 L6,4 L6,0.7 C2.15,1.1 -7.28306304e-13,3.5 -7.28306304e-13,7.35 L-7.28306304e-13,7.35 L-7.28306304e-13,13.45 L6,13.45 Z M14.85,13.45 L14.85,7.1 L12.7,7.1 C12.65,5.55 13.25,4.4 14.85,4 L14.85,4 L14.85,0.7 C11,1.1 8.85,3.5 8.85,7.35 L8.85,7.35 L8.85,13.45 L14.85,13.45 Z"
      />
    </Svg>
  );
};
