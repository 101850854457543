// IDEA curry and memoize
// WARNING to myself(Ash) moving to hook and making stateful is risky
// WHY AVOID? state updates fighting, duplicated state (hard to handle initialValues)
import { DropdownItem } from 'components/generic-question/Dropdown';
import { filter } from 'ramda';
import { pipeOrThrowIfUndefined } from 'lib/type-guarded';

const matchIsSelected = (source: DropdownItem[]) => (
  targetItem: DropdownItem
): DropdownItem => {
  const sourceItem = source.find(el => el.id === targetItem.id);
  return {
    ...targetItem,
    isSelected: !!sourceItem,
  };
};
/**
 * Utility
 * @param items
 * @param max
 */
export const limitLength = (
  max: number,
  all: DropdownItem[]
): DropdownItem[] => {
  return [...all]
    .reverse()
    .slice(0, max)
    .reverse();
};

/**
 * If in selected, set isSelected to true
 * @param all
 * @param selected
 */
export const mapAndSetIsSelectedProperty = (
  all: DropdownItem[],
  selected: DropdownItem[]
): DropdownItem[] => {
  return all.map(item => {
    const isSelected = selected.find(e => e.id === item.id);
    if (isSelected && !item.isSelected) {
      return { ...item, isSelected: true };
    } else if (!isSelected && item.isSelected) {
      return { ...item, isSelected: false };
    }
    return item;
  });
};

const getForced = (
  intended: DropdownItem[],
  included: DropdownItem[],
  excluded: DropdownItem[]
): DropdownItem[] => [
  ...(excluded.length === 0
    ? intended // Do nothing
    : intended.filter(
        // Remove excluded
        intendedItem => !excluded.map(e => e.id).includes(intendedItem.id)
      )),
  // Add included
  ...included,
];

/**
 *
 * @param all
 * @param selected
 */
export const setSelection = (
  maxSelected: number,
  all: DropdownItem[],
  intended: DropdownItem[] = [],
  included: DropdownItem[] = [],
  excluded: DropdownItem[] = []
): DropdownItem[] => {
  const forced = getForced(intended, included, excluded);
  const selected = limitLength(maxSelected, forced);
  return mapAndSetIsSelectedProperty(all, selected);
};

/**
 *
 * @param maxSelected
 * @param all
 * @param item
 */
export const addItem = (
  maxSelected: number,
  all: DropdownItem[],
  item?: DropdownItem,
  included: DropdownItem[] = [],
  excluded: DropdownItem[] = []
): DropdownItem[] => {
  const oldSelected = filter<DropdownItem>(item => !!item.isSelected, all);
  const itemNotSelected = !oldSelected.find(el => !item || el.id === item.id);
  if (
    typeof item !== 'undefined' &&
    (itemNotSelected || maxSelected > oldSelected.length)
  ) {
    oldSelected.push(item);
  }
  const forced = getForced(oldSelected, included, excluded);
  const selected = limitLength(maxSelected, forced).map(el => ({
    ...el,
    isSelected: true,
  }));
  return mapAndSetIsSelectedProperty(all, selected);
};

/**
 *
 * @param maxSelected
 * @param all
 * @param itemToRemove
 */
export const removeItem = (
  maxSelected: number,
  all: DropdownItem[],
  itemToRemove: DropdownItem,
  included: DropdownItem[] = [],
  excluded: DropdownItem[] = []
): DropdownItem[] => {
  const oldAndRemovedSelected = filter<DropdownItem>(
    item => !!item.isSelected && itemToRemove.id !== item.id,
    all
  );
  const selected = limitLength(maxSelected, oldAndRemovedSelected);
  const forced = getForced(selected, included, excluded);
  return mapAndSetIsSelectedProperty(all, forced);
};

export const toggleItem = (
  maxSelected: number,
  all: DropdownItem[],
  oldSelectedQueue: DropdownItem[],
  item?: DropdownItem,
  included: DropdownItem[] = [],
  excluded: DropdownItem[] = []
): { all: DropdownItem[]; selectedQueue: DropdownItem[] } => {
  if (typeof item === 'undefined') {
    return {
      all,
      selectedQueue: setSelection(
        maxSelected,
        oldSelectedQueue,
        included,
        excluded
      ),
    };
  }

  if (oldSelectedQueue.find(el => el.id === item.id)) {
    const selectedQueueIntended = oldSelectedQueue
      .filter(el => el.id !== item.id)
      .map(pipeOrThrowIfUndefined);
    const selectedQueue = getForced(selectedQueueIntended, included, excluded);
    const matched = all.map(matchIsSelected(selectedQueue));
    const newAll = removeItem(maxSelected, matched, item, included, excluded);
    return {
      selectedQueue,
      all: newAll,
    };
  } else {
    const selectedQueueIntended = [...oldSelectedQueue, item];
    const forced = getForced(selectedQueueIntended, included, excluded);
    const selectedQueue = limitLength(maxSelected, forced).map(el => ({
      ...el,
      isSelected: true,
    }));
    const newAll = addItem(maxSelected, all, item, included, excluded)
      .map(el => ({
        ...el,
        isSelected: false,
      }))
      .map(matchIsSelected(selectedQueue));
    return {
      selectedQueue,
      all: newAll,
    };
  }
};
