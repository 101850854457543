import React, { ReactElement } from 'react';
import styled from 'styled-components/native';
import { useHistory } from 'cross-platform/react-router';
import { TouchableHighlightProps, TouchableOpacity } from 'react-native';
import { PressHandler } from 'components/Button';
import { roles } from 'cross-platform/utils/roleProps';
import { Svg, Path } from 'react-native-svg';

type Pressable = {
  onClick?: PressHandler;
};

export const BackButtonContainer = styled.View`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Touchable = styled(TouchableOpacity)<Pressable>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const BackButton = ({ ...rest }: TouchableHighlightProps): ReactElement => {
  const history = useHistory();
  const handlePress = (): void => {
    history.goBack();
  };
  return (
    <Touchable {...rest} {...roles('ModalBackButton')} onPress={handlePress}>
      <BackButtonContainer>
        <Svg width="10" height="20" viewBox="0 0 10 20">
          <Path
            fill="none"
            fillRule="evenodd"
            stroke="#00506B"
            strokeLinecap="round"
            strokeWidth="2"
            d="M10 0L0 9 10 18"
          />
        </Svg>
      </BackButtonContainer>
    </Touchable>
  );
};

export default BackButton;
