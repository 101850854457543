import { matchPath, useHistory } from 'cross-platform/react-router';
import { tryReturnHardCodedParams } from './tryReturnHardCodedParams';

export type PathParams = {
  productReference: string;
  sceneSetGraphId: string;
  sceneSetId?: string;
  sceneId?: string;
};

export type SceneSetParams = {
  productReference: string;
  sceneSetGraphId: number;
  sceneSetId: number;
  sceneId: number;
};

export type GenericPathParams =
  | SceneSetParams
  | {
      productReference: string;
      sceneSetGraphId: number;
      sceneSetId: number;
      sceneId?: number;
    }
  | {
      productReference: string;
      sceneSetGraphId: number;
      sceneSetId?: number;
      sceneId?: number;
    };

const unNan = (value?: string): number | undefined => {
  if (typeof value === 'undefined') {
    return undefined;
  }
  const parsedValue = parseInt(value, 10);
  if (Number.isNaN(parsedValue)) {
    return undefined;
  }
  return parsedValue;
};

function toNumericalParams(params: PathParams): GenericPathParams {
  return {
    productReference: params.productReference,
    sceneSetGraphId: unNan(params.sceneSetGraphId) as number,
    sceneSetId: unNan(params.sceneSetId),
    sceneId: unNan(params.sceneId),
  };
}

/**
 * A replacement for useParams which doesn't work well with our dynamically-generated routes.
 * EVERYTHING from SceneSet down must use this and not router-router's useParams
 *
 * There will be cases that useSafeParams is needed but we are not in an "in Player" experience.
 * In these cases, we check `tryReturnHardCodedParams` to see if our route matches one
 * of these cases.
 */
export function useSafeParams<
  T extends SceneSetParams | GenericPathParams
>(): T {
  const history = useHistory();
  // Match here
  let params = matchPath<PathParams>(history.location.pathname, {
    path: '/:productReference/:sceneSetGraphId/:sceneSetId/:sceneId/',
  })?.params;
  if (!params) {
    // Try again but with the shorter path
    params = matchPath<PathParams>(history.location.pathname, {
      path: '/:productReference/:sceneSetGraphId/:sceneSetId/',
    })?.params;
  }
  if (!params) {
    // and again
    params = matchPath<PathParams>(history.location.pathname, {
      path: '/:productReference/:sceneSetGraphId/',
    })?.params;
  }
  if (!params) {
    // and again
    params = matchPath<PathParams>(history.location.pathname, {
      path: '/:productReference/',
    })?.params as PathParams;
  }
  // Once we have tried all the dynamically generated routes, we then check
  // our route against a small set of routes that require hard-coded params.
  const safe = tryReturnHardCodedParams(params) || toNumericalParams(params);
  return safe as T;
}
