import { getQueryClient } from 'components/ProvidersContainer';
import { unsubscribe_from_emails } from '@bighealth/api/UserSleepioMetadata/v1';

export const queryClientUnsubscribe = async (args: {
  product_id: number;
}): Promise<unsubscribe_from_emails.Response> => {
  return await getQueryClient().fetchQuery(
    unsubscribe_from_emails.queryKey,
    () =>
      unsubscribe_from_emails({
        product_id: args.product_id,
      }),
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );
};
