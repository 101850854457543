import { Dispatch, SetStateAction } from 'react';
import { APIResponse } from '@bighealth/api';
import { SleepDiaryFormProps } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';

type Args = {
  fetchData: () => Promise<APIResponse<SleepDiaryFormProps>>;
  onLoadingChange: Dispatch<SetStateAction<boolean>>;
  onSuccess: Dispatch<SetStateAction<SleepDiaryFormProps | undefined>>;
  onError: Dispatch<SetStateAction<Error | undefined>>;
};

async function fetchRenderDataAsync({
  fetchData,
  onLoadingChange,
  onSuccess,
  onError,
}: Args): Promise<void> {
  try {
    onLoadingChange(true);
    const response = (await fetchData()) as APIResponse<SleepDiaryFormProps>;
    onSuccess(response.result);
  } catch (e) {
    onError(e);
  } finally {
    onLoadingChange(false);
  }
}

export { fetchRenderDataAsync };
