export type PlayerControlsStyleType = typeof PlayerControlsStyles;

export const PlayerControlsStyles = {
  largeScreenBreakpoint: 1440,
  controlsHeight: 188,
  horizontalPadding: 60,
  playPauseIconSize: 100,

  titleFontSize: 40,
  subtitleFontSize: 30,

  titleMarginBottom: 6,
  subtitleMarginBottom: 6,

  closeButtonSize: 40,
  closeButtonTop: 75,
  closeButtonLeft: 60,

  textContainerHorizontalPadding: 32,

  progressBarHeight: 7,
  progressBarWidth: 397,

  progressBarBorderRadius: 12.34,
};
