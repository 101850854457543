type ValueChangeHandler = (date: Date | string | null) => void;
type OnValueChange = (value: Date | null) => void;

/**
 * Handler Factory function
 * @param {OnValueChange | undefined} onValueChange
 */
const createValueChangeHandler = (
  onValueChange?: OnValueChange
): ValueChangeHandler =>
  /**
   * Actual handler
   * @param {Date |string |null} date
   */
  (date: Date | string | null): void => {
    if (date === '') {
      return;
    }
    if (typeof date === 'string') {
      onValueChange?.(new Date(date) || null);
      return;
    }
    onValueChange?.(date || null);
  };

export { createValueChangeHandler };
