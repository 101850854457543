import deepMerge from 'deepmerge';

interface Options extends deepMerge.Options {
  cloneUnlessOtherwiseSpecified(item: any, options?: deepMerge.Options): any;
  isMergeableObject(item: any): boolean;
  combineMerge: Options['arrayMerge'];
}

export const combineMerge: Options['arrayMerge'] = (
  target,
  source,
  options: Options
) => {
  const destination = target.slice();

  source.forEach((item, index) => {
    if (typeof destination[index] === 'undefined') {
      destination[index] = options.cloneUnlessOtherwiseSpecified(item, options);
    } else if (options.isMergeableObject(item)) {
      destination[index] = deepMerge(target[index], item, options);
    } else if (target.indexOf(item) === -1) {
      destination.push(item);
    }
  });
  return destination;
};

const mergeDeepAndByIndex = (a: any, b: any): any =>
  deepMerge(a, b, {
    arrayMerge: combineMerge,
  });

export { mergeDeepAndByIndex };
