import React from 'react';
import { FlexBoxProps, FlexBox } from '../FlexBox';

type Props = Pick<FlexBoxProps, 'children' | 'style'>;
const Grid = (props: Props): React.ReactElement => (
  <FlexBox flexGrow={1} width={'100%'} {...props} />
);

// Grid entry point
export * from './Col';
export * from './Row';

export { Grid };
