import { Text } from '../Text';
import styled from 'styled-components/native';
import { TextTypes } from 'common/constants/enums';
import { ThemeProp } from 'config/getThemeForProducts';

const HintText = styled(Text)`
  color: ${(p: ThemeProp) => p.theme.text[TextTypes.HINT].color};
`;

export { HintText };
