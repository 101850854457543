import React, { ReactElement } from 'react';
import Svg, { G, Ellipse, Polygon } from 'react-native-svg';

export const Red = ({ size }: { size: number }): ReactElement => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 39 39">
    <G
      id="Plat-Gen-Sticker-Sheet-Sticker-Sheet"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <G id="Artboard" transform="translate(-135.000000, -247.000000)">
        <G id="Progress/Red" transform="translate(135.000000, 247.000000)">
          <Ellipse
            id="Oval"
            fill="#D47061"
            cx="19.3176047"
            cy="19.2"
            rx="19.3176047"
            ry="19.2"
          />
          <Polygon
            id="Shape-Copy"
            fill="#FFFFFF"
            transform="translate(19.639565, 19.520000) scale(1, -1) rotate(90.000000) translate(-19.639565, -19.520000) "
            points="12.7515648 17.88 21.9355648 8.696 19.6395648 6.4 6.51956482 19.52 19.6395648 32.64 21.9355648 30.344 12.7515648 21.16 32.7595648 21.16 32.7595648 17.88"
          />
        </G>
      </G>
    </G>
  </Svg>
);
