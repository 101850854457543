import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import Throbber from 'components/Throbber';

import SceneSetView from 'components/SceneSetView';
import { StoryBook } from '../StoryBook';
import { WithGlobalNavigation } from 'components/Navigation/WithGlobalNavigation';
import { PRODUCT_STREAM_ID } from 'components/Routes/constants';
import {
  HomeScreen,
  LoginScreen,
  ResetPasswordScreen,
  SettingsScreen,
  TitleScreen,
  ForgotPasswordScreen,
} from 'components/Screens';
import { ProgressScreen } from 'components/Screens/ContentScreens/ProgressScreen';
import { SleepDiaryScreen } from 'components/Screens/ContentScreens/DiaryScreen';
import { DownloadsScreen } from 'components/Screens/ContentScreens/DownloadsScreen';
import { LibraryScreen } from 'components/Screens/ContentScreens/LibraryScreen';
import { ResourcesScreen } from 'components/Screens/ContentScreens/Resources/Resources';
import { ScheduleScreen } from 'components/Screens/ContentScreens/ScheduleScreen';
import ForQATeam from 'components/Screens/ForQATeam';
import { ForQATeamSSGbySS } from 'components/Screens/ForQATeamSSGbySS';
import { HomeScreenSwitcher } from 'components/Screens/HomeScreenSwitcher';
import { MobileWebGate } from 'components/Screens/MobileWebGate';
import { SessionGate } from 'components/SessionGate';
import { SLEEP_DIARY_ROUTE_PARAMETER } from 'components/SleepDiaryWeek/constants';
import { LandscapeOrientationLock } from 'components/ScreenOrientationLocks';

import { Formik } from 'formik';

import { Container } from 'components/Screens/ContentScreens/components/ContentPlaceholder';

import { noOpFunction } from 'lib/noOpFunction';
import { Platform } from 'react-native';
import {
  PLATGEN_FF_USE_INTERNAL_LOGIN_ROUTE,
  PLATGEN_LOGIN_URL,
  PLATGEN_ONBOARDING_URL,
} from 'config/envVars';
import { createErrorBoundaryForDomain } from 'lib/error/utils/createErrorBoundaryForDomain';
import { DebugThrow } from 'components/sceneset-components/DebugThrow';
import { DefaultErrorFallback } from 'lib/error/ErrorBoundary/components/DefaultErrorFallback/DefaultErrorFallback';
import { DebugButtonThrow } from 'components/DebugButtonThrow';
import { withContentPlaceholderWrapper } from 'lib/error/ErrorBoundary/utils/withContentPlaceholderWrapper/withContentPlaceholderWrapper';
import { renderFallbackReturnsHomeForDomainFactory } from './components/renderFallbackReturnsHomeForDomainFactory';
import { useIsFetching } from 'react-query';
import { fire_ui_events } from '@bighealth/api/Event/v1';
import { Footer } from 'components/Footer';
import { VerticalScroll } from '../../VerticalScroll/VerticalScroll';
import { roles } from 'cross-platform/utils/roleProps';
import { NonSessionAnalyticsSender } from 'lib/api/analytics/NonSessionAnalyticsSender';

const ERROR_DOMAIN = 'SleepioSwitch';
const ErrorBoundaryForSleepioSwitch = createErrorBoundaryForDomain(
  ERROR_DOMAIN
);
const renderFallbackForSleepioSwitch = renderFallbackReturnsHomeForDomainFactory(
  ERROR_DOMAIN
);

export const SleepioSwitch = (): ReactElement => {
  const fetchingCount = useIsFetching({
    predicate: queryMeta => {
      const { queryKey } = queryMeta;
      // Don't show the loading spinner in these cases:
      return queryKey !== fire_ui_events.queryKey;
    },
  });
  return (
    <ErrorBoundaryForSleepioSwitch
      fallbackRenderWithWrapper={withContentPlaceholderWrapper}
      fallbackRender={renderFallbackForSleepioSwitch}
    >
      <Switch>
        <Route path={'/:productReference'} exact>
          <TitleScreen />
        </Route>

        {Platform.OS !== 'web' || PLATGEN_FF_USE_INTERNAL_LOGIN_ROUTE ? (
          <Route path={'/:productReference/login'} exact>
            <LoginScreen />
          </Route>
        ) : (
          <Route
            path={'/:productReference/login'}
            component={() => {
              window.location.href = `${PLATGEN_LOGIN_URL}?product_stream_id=${PRODUCT_STREAM_ID}`;
              return null;
            }}
          />
        )}

        <Route path={'/:productReference/home-debug'} exact>
          <WithGlobalNavigation enableMobileMode>
            <HomeScreenSwitcher />
            <DebugButtonThrow title="Test error" error={Error('Home test')} />
          </WithGlobalNavigation>
        </Route>

        <Route path={'/:productReference/home'} exact>
          <WithGlobalNavigation enableMobileMode>
            <HomeScreenSwitcher />
          </WithGlobalNavigation>
          <NonSessionAnalyticsSender
            field={{ area: 'menu', section: 'home' }}
          />
        </Route>
        <Route path={'/:productReference/menu'} exact>
          <WithGlobalNavigation enableMobileMode={true}>
            <MobileWebGate>
              <HomeScreen />
            </MobileWebGate>
          </WithGlobalNavigation>
        </Route>

        <Route path={'/:productReference/settings'} exact>
          <WithGlobalNavigation>
            <MobileWebGate>
              <SettingsScreen />
            </MobileWebGate>
          </WithGlobalNavigation>
          <NonSessionAnalyticsSender
            field={{ area: 'menu', section: 'settings' }}
          />
        </Route>

        <Route path={'/:productReference/qa'} exact>
          <WithGlobalNavigation>
            <ForQATeam />
          </WithGlobalNavigation>
        </Route>
        <Route path={'/:productReference/qa-ssg-ss'} exact>
          <WithGlobalNavigation>
            <ForQATeamSSGbySS />
          </WithGlobalNavigation>
        </Route>

        <Route path={'/:productReference/qa-throw'} exact>
          <DebugThrow error={Error('DebugThrow.qa-throw')} />
        </Route>

        <Route path={'/:productReference/qa-throw-with-nav'} exact>
          <WithGlobalNavigation>
            <DebugThrow error={Error('DebugThrow.qa-throw-with-nav')} />
          </WithGlobalNavigation>
        </Route>
        <Route path={'/:productReference/schedule'} exact>
          <WithGlobalNavigation>
            <MobileWebGate>
              <SessionGate minSessionRequired={1} titleScreen={'Schedule'}>
                <ScheduleScreen />
              </SessionGate>
            </MobileWebGate>
          </WithGlobalNavigation>
          <NonSessionAnalyticsSender
            field={{ area: 'casefile', section: 'your_schedule' }}
          />
        </Route>

        <Route path={'/:productReference/schedule/:page'} exact>
          <WithGlobalNavigation>
            <MobileWebGate>
              <SessionGate minSessionRequired={1} titleScreen={'Schedule'}>
                <ScheduleScreen />
              </SessionGate>
            </MobileWebGate>
          </WithGlobalNavigation>
          <NonSessionAnalyticsSender
            field={{ area: 'casefile', section: 'your_schedule' }}
          />
        </Route>

        <Route path={'/:productReference/casefile/progress'} exact>
          <WithGlobalNavigation>
            <MobileWebGate>
              <SessionGate minSessionRequired={1} titleScreen={'Progress'}>
                <ProgressScreen />
              </SessionGate>
            </MobileWebGate>
          </WithGlobalNavigation>
          <NonSessionAnalyticsSender
            field={{ area: 'casefile', section: 'your_progress' }}
          />
        </Route>

        <Route path={'/:productReference/downloads'} exact>
          <WithGlobalNavigation>
            <MobileWebGate>
              <SessionGate minSessionRequired={1} titleScreen={'Downloads'}>
                <DownloadsScreen />
              </SessionGate>
            </MobileWebGate>
          </WithGlobalNavigation>
          <NonSessionAnalyticsSender
            field={{ area: 'downloads', section: 'home' }}
          />
        </Route>

        <Route path={'/:productReference/downloads/:page'} exact>
          <WithGlobalNavigation>
            <MobileWebGate>
              <SessionGate minSessionRequired={1} titleScreen={'Downloads'}>
                <DownloadsScreen />
              </SessionGate>
            </MobileWebGate>
          </WithGlobalNavigation>
          <NonSessionAnalyticsSender
            field={{ area: 'downloads', section: 'download' }}
          />
        </Route>

        <Route path={'/:productReference/library'} exact>
          <WithGlobalNavigation>
            <MobileWebGate>
              <SessionGate minSessionRequired={1} titleScreen={'Library'}>
                <LibraryScreen />
              </SessionGate>
            </MobileWebGate>
          </WithGlobalNavigation>
          <NonSessionAnalyticsSender
            field={{ area: 'library', section: 'home' }}
          />
        </Route>

        <Route path={'/:productReference/library/:page'} exact>
          <WithGlobalNavigation>
            <MobileWebGate>
              <SessionGate minSessionRequired={1} titleScreen={'Library'}>
                <LibraryScreen />
              </SessionGate>
            </MobileWebGate>
          </WithGlobalNavigation>
          <NonSessionAnalyticsSender
            field={{ area: 'library', section: 'topic' }}
          />
        </Route>

        <Route path={'/:productReference/library/:page/:articleId'} exact>
          <WithGlobalNavigation>
            <MobileWebGate>
              <SessionGate minSessionRequired={1} titleScreen={'Library'}>
                <LibraryScreen />
              </SessionGate>
            </MobileWebGate>
          </WithGlobalNavigation>
          <NonSessionAnalyticsSender
            field={{ area: 'library', section: 'article' }}
          />
        </Route>

        <Route path={'/:productReference/reset-password'} exact>
          <ResetPasswordScreen isHardCodeSchema />
        </Route>

        {Platform.OS !== 'web' || PLATGEN_FF_USE_INTERNAL_LOGIN_ROUTE ? (
          <Route path={'/:productReference/forgot-password'} exact>
            <ForgotPasswordScreen />
          </Route>
        ) : (
          <Route
            path={'/:productReference/forgot-password'}
            component={() => {
              window.location.href = `${PLATGEN_ONBOARDING_URL}/login-component/forgot`;
              return null;
            }}
          />
        )}

        <Route path={'/:productReference/resources'} exact>
          <WithGlobalNavigation>
            <ResourcesScreen />
          </WithGlobalNavigation>
        </Route>

        <Route
          path={'/:productReference/888888/storybook'}
          component={StoryBook}
          exact
        />

        <Route path={'/:productReference/:sceneSetGraphId/:sceneSetId'} exact>
          <MobileWebGate>
            <LandscapeOrientationLock>
              <SceneSetView />
            </LandscapeOrientationLock>
          </MobileWebGate>
        </Route>

        <Route
          path={'/:productReference/888888/storybook/:story(.*)'}
          component={StoryBook}
        />

        <Route
          path={
            '/:productReference/:sceneSetGraphId/:sceneSetReference/:sceneId'
          }
          exact
        >
          <MobileWebGate>
            <LandscapeOrientationLock>
              <SceneSetView />
            </LandscapeOrientationLock>
          </MobileWebGate>
        </Route>

        <Route path={`/:productReference/${SLEEP_DIARY_ROUTE_PARAMETER}`} exact>
          <WithGlobalNavigation>
            <SessionGate minSessionRequired={1} titleScreen={'Sleep Diary'}>
              <Formik initialValues={[]} onSubmit={noOpFunction}>
                <SleepDiaryScreen />
              </Formik>
            </SessionGate>
          </WithGlobalNavigation>
          <NonSessionAnalyticsSender
            field={{ area: 'diary', section: 'view' }}
          />
        </Route>
        <Route>
          {/* Not found / 404 */}
          <WithGlobalNavigation>
            <VerticalScroll>
              <Container>
                <DefaultErrorFallback />
              </Container>
              <Footer />
            </VerticalScroll>
          </WithGlobalNavigation>
        </Route>
      </Switch>
      {fetchingCount > 0 ? <Throbber {...roles('RootThrobber')} /> : null}
    </ErrorBoundaryForSleepioSwitch>
  );
};
