import React, { ReactElement } from 'react';
import { Router } from 'cross-platform/react-router';
import ReduxProvider from 'state/ReduxProvider';
import ProductThemeProvider from './ProductThemeProvider';
import { EphemeralStateProvider } from 'components/EphemeralStateProvider';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import {
  ScalingContextProvider,
  ScreenResponsiveLayoutProvider,
} from 'components/ResponsiveLayout';
import { KeyboardDimensionsProviders } from './KeyboardDimensionsProviders';
import { ScreenOrientationProvider } from './ScreenOrientationProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AnalyticsProvider } from '@bighealth/analytics';
import { sendAnalytics } from 'lib/api';

const queryClient = new QueryClient();

// This makes it easier to mock/spy versus just exporting the constant
export const getQueryClient = (): QueryClient => queryClient;

const ProvidersContainer = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  return (
    <Router>
      <ProvidersContainerWithoutRouter>
        {children}
      </ProvidersContainerWithoutRouter>
    </Router>
  );
};

export const ProvidersContainerWithoutRouter = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  return (
    <QueryClientProvider client={queryClient}>
      <KeyboardDimensionsProviders>
        <ReduxProvider>
          <EphemeralStateProvider>
            <SafeAreaProvider>
              <ScreenResponsiveLayoutProvider>
                <AnalyticsProvider analyticsSender={sendAnalytics}>
                  <ScalingContextProvider>
                    <ProductThemeProvider>
                      <ScreenOrientationProvider>
                        {children}
                      </ScreenOrientationProvider>
                    </ProductThemeProvider>
                  </ScalingContextProvider>
                </AnalyticsProvider>
              </ScreenResponsiveLayoutProvider>
            </SafeAreaProvider>
          </EphemeralStateProvider>
        </ReduxProvider>
      </KeyboardDimensionsProviders>
    </QueryClientProvider>
  );
};

export default ProvidersContainer;
