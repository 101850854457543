import { useEffect } from 'react';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { getAppName } from 'lib/productInfo';

export const useUpdateTitle = (prefix?: string): void => {
  const { productReference } = useSafeParams();
  const appName = getAppName(productReference);
  useEffect(() => {
    const updateTitleTo = prefix ? `${prefix} - ${appName}` : appName;
    if (document.title !== updateTitleTo) {
      document.title = updateTitleTo;
    }
    return () => {
      document.title = appName;
    };
  }, [appName, prefix]);
};
