import { UniversalButton } from 'components/UniveralButtons';
import styled from 'styled-components/native';

export const Wrapper = styled.View`
  min-height: 200px;
`;
Wrapper.displayName = 'NetworkedFlowingForm.Wrapper';

export const LoadingWrapper = styled.View`
  flex-grow: 1;
`;
LoadingWrapper.displayName = 'NetworkedFlowingForm.LoadingWrapper';

export const ErrorWrapper = styled.View`
  flex-grow: 1;
`;
ErrorWrapper.displayName = 'NetworkedFlowingForm.ErrorWrapper';

export const ErrorButton = styled(UniversalButton)``;
ErrorButton.displayName = 'NetworkedFlowingForm.ErrorButton';
