import styled from 'styled-components/native';
import { Animated, View, StyleSheet } from 'react-native';
import React, { ReactElement, ReactNode } from 'react';
import { usePulsingOpacityAnimation } from 'lib/animations';
import { Text } from 'components/Text';
import {
  contentStyles,
  useGetDynamicContentStyles,
} from 'components/ResponsiveLayout';
import { Footer } from 'components/Footer';
import { ScheduleTag } from '@bighealth/types';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import { useGetNavDimensions, useGetContentDimensions } from '../hooks';

const ContentStatic = styled.View`
  background-color: #ddefff;
`;

export const Content = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <ContentStatic
      style={{
        paddingLeft: styles.contentHorizontalPadding,
        paddingRight: styles.contentHorizontalPadding,
        paddingTop: styles.contentVerticalPadding,
        paddingBottom: 0,
        borderRadius: styles.contentBorderRadius,
        marginBottom: styles.contentMarginBottom,
      }}
    >
      {children}
    </ContentStatic>
  );
};

const SkeletonOuterContainerStatic = styled(Animated.View)`
  background-color: #ddefff;
  min-height: 200px;
`;

export const SkeletonOuterContainerAnimated = (): ReactElement => {
  const fadeAnimation = usePulsingOpacityAnimation();
  const styles = useGetDynamicContentStyles();
  return (
    <SkeletonOuterContainerStatic
      {...roles('SkeletonOuterContainerAnimated')}
      style={{
        opacity: fadeAnimation,
        paddingLeft: styles.contentHorizontalPadding,
        paddingRight: styles.contentHorizontalPadding,
        paddingTop: styles.contentVerticalPadding,
        bottom: styles.contentVerticalPadding,
        borderRadius: styles.contentBorderRadius,
        marginBottom: styles.contentMarginBottom,
      }}
    />
  );
};

export const ContentTitle = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <Text
      style={{
        fontSize: styles.contentTitleFontSize,
        fontWeight: 'bold',
        color: '#003366',
      }}
    >
      {children}
    </Text>
  );
};

export const ContentSubtitle = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <Text
      style={{
        fontSize: styles.contentSubtitleFontSize,
        color: '#003366',
      }}
    >
      {children}
    </Text>
  );
};

export const ActivityContainer = styled.View`
  flex-direction: row;
  flex-shrink: 1;
  flex-grow: 1;
  width: 100%;
`;

export const ActivityText = ({
  children,
}: {
  children?: string;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const isEmpty = !children;
  return (
    <Text
      style={{
        fontSize: styles.entryPointFontSize,
        lineHeight: styles.entryPointFontSize * 1.3,
        color: '#001948',
        opacity: isEmpty ? 0.5 : 1,
        flex: 1,
        width: 0, // Fix this still-existing text wrap bug https://github.com/facebook/react-native/issues/1438
      }}
    >
      {isEmpty ? 'No tasks yet' : `• ${children}`}
    </Text>
  );
};

const EntryStatic = styled.View`
  background-color: white;
  flex-direction: column;
  align-items: flex-start;
`;

export const Entry = ({ children }: { children: ReactNode }): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <EntryStatic
      style={{
        paddingLeft: styles.entryHorizontalPadding,
        paddingRight: styles.entryHorizontalPadding,
        paddingTop: styles.entryVerticalPadding,
        paddingBottom: styles.entryVerticalPadding,
        borderRadius: styles.entryBorderRadius,
        marginBottom: styles.entryMarginBottom,
      }}
    >
      {children}
    </EntryStatic>
  );
};

const ContentTitleBlockStatic = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ContentTitleBlock = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <ContentTitleBlockStatic
      style={{
        marginBottom: styles.contentTitleBlockMarginBottom,
        marginTop: styles.contentTitleBlockMarginTop,
      }}
    >
      {children}
    </ContentTitleBlockStatic>
  );
};

export const TagsContainer = styled.View`
  margin-bottom: 8px;
`;

export const Tag = ({
  text,
  color = '#1a80a2',
  backgroundColor = '#d8f6ff',
}: ScheduleTag): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <View
      style={{
        backgroundColor,
        marginRight: styles.entryTagMarginRight,
        marginBottom: styles.entryTagMarginBottom,
        paddingTop: styles.entryTagVerticalPadding,
        paddingBottom: styles.entryTagVerticalPadding,
        paddingLeft: styles.entryTagHorizontalPadding,
        paddingRight: styles.entryTagHorizontalPadding,
        overflow: 'hidden',
        borderRadius: 1000,
      }}
    >
      <Text style={{ color, fontSize: styles.entryTagFontSize }}>{text}</Text>
    </View>
  );
};

export const Extension = ({ text }: { text: string }): ReactElement => {
  const color = '#003366';
  const styles = useGetDynamicContentStyles();
  return (
    <View>
      <Text style={{ color, fontSize: styles.entryDownloadExtensionFontSize }}>
        {text.toUpperCase()}
      </Text>
    </View>
  );
};

export const DownloadName = ({ text }: { text: string }): ReactElement => {
  const color = '#003366';
  const styles = useGetDynamicContentStyles();
  return (
    <View>
      <Text style={{ color, fontSize: styles.entryDownloadNameFontSize }}>
        {text}
      </Text>
    </View>
  );
};

// ----------------------------------------
//
// Nav

export const NavContainer = styled.View`
  flex-direction: column;
  align-items: stretch;
`;

export const PageTitle = ({
  children,
  ...restProps
}: {
  children: ReactNode;
} & RoleProps): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <View
      {...roles.pass(restProps)}
      style={{
        marginBottom: styles.pageTitlePaddingBottom,
        marginTop: styles.pageTitlePaddingTop,
      }}
    >
      <Text
        {...roles.pass(restProps, 'Text')}
        style={{ fontSize: styles.pageTitleFontSize, color: '#003366' }}
      >
        {children}
      </Text>
    </View>
  );
};

export const PageSubTitle = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <View
      style={{
        marginBottom: styles.pageSubTitlePaddingBottom,
      }}
    >
      <Text style={{ fontSize: styles.pageSubTitleFontSize, color: '#003366' }}>
        {children}
      </Text>
    </View>
  );
};

export const ScrollColumn = styled.ScrollView.attrs({
  contentContainerStyle: { flex: 1 },
})``;

const NavColumnStatic = styled.View`
  align-content: stretch;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const NavColumn = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const { navWidth, marginRight } = useGetNavDimensions();
  return (
    <NavColumnStatic
      style={{
        marginRight: marginRight,
        width: navWidth,
        minWidth: navWidth,
      }}
    >
      {children}
    </NavColumnStatic>
  );
};

export const ContentColumnStatic = styled.View`
  align-content: stretch;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const ContentColumn = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const { contentWidth } = useGetContentDimensions();
  return (
    <ContentColumnStatic
      style={{
        width: contentWidth,
        minWidth: contentWidth,
        marginTop: styles.pageTitlePaddingTop,
      }}
    >
      {children}
    </ContentColumnStatic>
  );
};

const FullPageStatic = styled.View`
  flex: 1;
  flex-basis: auto;
  flex-direction: row;
  align-items: flex-start;
  background-color: white;
  justify-content: center;
`;

export const FullPage = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const {
    pagePaddingRight,
    pagePaddingLeft,
    pagePaddingBottom,
  } = useGetDynamicContentStyles();
  return (
    <FullPageStatic
      style={{
        flex: 1,
        paddingLeft: pagePaddingLeft,
        paddingRight: pagePaddingRight,
        paddingBottom: pagePaddingBottom,
      }}
    >
      {children}
    </FullPageStatic>
  );
};

const HeaderedPageStatic = styled.View`
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  justify-content: center;
  flex: 1;
`;

export const HeaderedPage = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const { pagePaddingRight, pagePaddingLeft } = useGetDynamicContentStyles();
  return (
    <HeaderedPageStatic
      style={{
        paddingLeft: pagePaddingLeft,
        paddingRight: pagePaddingRight,
      }}
    >
      {children}
    </HeaderedPageStatic>
  );
};

export const HeaderedContent = styled.View`
  flex-direction: row;
  align-items: flex-start;
  background-color: white;
  justify-content: center;
  flex: 1;
`;

export const FullWidthTitleContainer = styled.View``;

export const ContentPlaceholderText = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <View
      // Deliberately not scaled
      style={{ width: contentStyles.contentPlaceholderMaxWidth }}
    >
      <Text
        style={{
          fontSize: styles.contentPlaceholderFontSize,
          color: '#1c4281',
        }}
      >
        {children}
      </Text>
    </View>
  );
};

export const ScreenScroll = styled.ScrollView.attrs(props => ({
  contentContainerStyle: StyleSheet.flatten([
    {
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
    props.contentContainerStyle,
  ]),
}))`
  width: 100%;
  background-color: white;
`;
ScreenScroll.displayName = 'ScreenScroll';

export const ContentContainer = styled.View`
  align-self: center;
`;
ContentContainer.displayName = 'ContentPadding';

const LoadingPlaceholder = styled.View`
  flex: 1;
  justify-content: flex-end;
`;
LoadingPlaceholder.displayName = 'LoadingPlaceholder';

const LoadingBackground = styled(Animated.View)`
  flex: 1;
  background-color: #ddefff;
`;
LoadingBackground.displayName = 'LoadingBackground';

const BackgroundAnimated = (): ReactElement => {
  const opacity = usePulsingOpacityAnimation();
  return <LoadingBackground style={{ opacity }} />;
};

export const LoadingWithFooter = (): ReactElement => (
  <LoadingPlaceholder {...roles('LoadingPlaceholder')}>
    <BackgroundAnimated />
    <Footer />
  </LoadingPlaceholder>
);
