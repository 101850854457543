import React, { FunctionComponent } from 'react';
import { ScheduleNextSessionProps } from '../FormState';
import { FormikSubmitButton } from './FormikSubmitButton';
import {
  ContinueButton,
  ControlWrapper,
  SubmitButtonContainer,
  Title,
  WrapperForm,
} from './styled';
import { ResponseInput } from 'components/forms/ResponseOptions/ResponseInput';
import { ResponseForm } from 'components/forms/ResponseOptions/ResponseForm';
import { ResponseOptionValue } from '@bighealth/types/src';
import { roles } from 'cross-platform/utils/roleProps';
import { handleValidate } from './handleValidate';
import { getDateTimePickerValue } from './getDateTimePickerValue';

export type FormProps = {
  onSubmit: (value: ResponseOptionValue) => void;
  onContinue: () => void;
};

export type FormPresentationProps = ScheduleNextSessionProps & FormProps;

/** * Just renders. Handlers are configured elsewhere
 * @param props
 */
const FormPresentation: FunctionComponent<FormPresentationProps> = props => {
  return (
    <WrapperForm>
      <Title {...props?.title} />
      <ControlWrapper>
        <ResponseForm
          onSubmit={inputs => {
            // Data hard-coded, so we can assume there will be only one entry here
            // because there is only one response option
            Object.entries(inputs).forEach(([, optionsAnswer]) => {
              props?.onSubmit(getDateTimePickerValue(optionsAnswer));
            });
          }}
          onValidate={handleValidate}
        >
          <ResponseInput
            key={props?.questionProps.semantic_id}
            component={'DateTimePicker'}
            questionProps={props?.questionProps}
            initialValue={props?.initialValue}
          />

          <SubmitButtonContainer>
            <FormikSubmitButton
              text={props?.submit_button.text}
            ></FormikSubmitButton>
          </SubmitButtonContainer>
        </ResponseForm>
      </ControlWrapper>

      <SubmitButtonContainer>
        <ContinueButton
          text={props?.continue_button.text}
          onPress={props?.onContinue}
          {...roles('ScheduleNextSession.ContinueButton')}
        ></ContinueButton>
      </SubmitButtonContainer>
    </WrapperForm>
  );
};

export { FormPresentation };
