import styled from 'styled-components/native';
import { Text } from 'components/Text';
import { FontFamilies } from 'components/sceneset-components/Text/fonts';
import { FillAbsolute, ScrollableContainer } from 'components/Containers';
import TextInput from 'components/TextInput';

export const LimitWidth = styled.View`
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  max-width: 667px;
  width: 100%;
  margin-top: 25px;
`;

export const LogoContainer = styled.TouchableOpacity`
  align-self: center;
`;

export const Input = styled(TextInput)<{ hasErrors?: boolean }>`
  font-size: 20px;
  color: #2d2d2d;
  border: ${({ hasErrors }) =>
    hasErrors ? `solid 2px #d0021b;` : `solid 1px #ffffff;`};
`;

export const FormattedText = styled<typeof Text>(Text)`
  font-size: 20px;
  color: white;
  text-align: left;
  font-family: '${FontFamilies.Regular}';
`;

export const BackLinkContainer = styled.TouchableOpacity`
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const ButtonContainer = styled.View`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: stretch;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const ActionButtonContainer = styled.View`
  padding-left: 106px;
  padding-right: 106px;
`;

export const HeadingText = styled<typeof Text>(Text)`
  font-size: 40px;
  text-align: left;
  font-family: '${FontFamilies.Regular}';
  color: #ffffff;
`;

export const PaddingContainer = styled.View`
  flex-direction: column;
  margin-bottom: 20px;
  display: flex;
  align-items: stretch;
`;

export const Container = styled(FillAbsolute)`
  align-items: center;
  background-color: #003366;
`;

export const ForgotPasswordScroll = styled(ScrollableContainer).attrs({
  contentContainerStyle: {
    flexGrow: 1,
    alignItems: 'center',
  },
})`
  width: 100%;
`;

export const ErrorContainer = styled.View`
  margin-top: 8px;
  padding: 10px 16px;
  border: solid 2px #d0021b;
  background-color: #ffe4e4;
  border-radius: 2px;
`;

export const BackText = styled<typeof Text>(Text)`
  font-size: 20px;
  text-align: left;
  font-family: '${FontFamilies.Regular}';
  color: #ffffff;
`;

export const InstructionText = styled<typeof Text>(Text)`
  font-size: 20px;
  text-align: left;
  font-family: '${FontFamilies.Regular}';
  color: #ffffff;
`;

export const LabelContainer = styled.View`
  display: flex;
  padding-bottom: 7px;
`;

export const ValidationText = styled<typeof Text>(Text)`
  font-size: 16px;
  text-align: left;
  font-family: '${FontFamilies.Regular}';
  color: #d0021b;
`;

export const InlineLink = styled<typeof InstructionText>(Text)`
  text-decoration-line: underline;
`;
