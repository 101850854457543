import React, { ReactElement } from 'react';
import ProvidersContainer from 'components/ProvidersContainer';
import { AppContainer } from 'components/Containers';
import Routes from 'components/Routes';
import { useSnapEngage } from 'lib/snapEngage/useSnapEngage';
import * as CrashReporter from 'lib/crashReporter';
import { useRealUserMetrics } from 'lib/realUserMetrics';
import { getRUMConfig } from 'config/getRUMConfig';
import { ErrorBoundaryForApp } from './components/ErrorBoundaryForApp';
import { withContentPlaceholderWrapper } from 'lib/error/ErrorBoundary/utils/withContentPlaceholderWrapper/withContentPlaceholderWrapper';
import { SetupNativeAVEvents } from 'lib/player/media/nativeAVEvents';
import ScreenOrientation from 'react-native-orientation-locker';

export const CoreApp = (): ReactElement => {
  return (
    <AppContainer>
      <Routes />
      <SetupNativeAVEvents />
    </AppContainer>
  );
};
export const App = (): ReactElement => {
  // We unlock all orientations at launch as a failsafe because in some areas in
  // the app, we lock to portrait.
  ScreenOrientation.unlockAllOrientations();
  useSnapEngage();
  useRealUserMetrics(getRUMConfig());
  CrashReporter.init();

  return (
    <ErrorBoundaryForApp
      fallbackRenderWithWrapper={withContentPlaceholderWrapper}
    >
      <ProvidersContainer>
        <CoreApp />
      </ProvidersContainer>
    </ErrorBoundaryForApp>
  );
};
