import { useQuery } from 'react-query';
import { find_with_reference } from '@bighealth/api/Product/v2';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { UseQueryResult } from 'react-query/types/react/types';
import { APIErrorResponse } from '@bighealth/api';

export const useQueryProduct = (): UseQueryResult<
  find_with_reference.Response,
  APIErrorResponse
> => {
  const { productReference } = useSafeParams();
  return useQuery({
    queryKey: find_with_reference.queryKey,
    queryFn: () =>
      find_with_reference({
        reference: productReference,
      }),
    cacheTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });
};
