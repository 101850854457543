/**
 * Create a 24hr HH:mm:ss LOCALE time string from a date object
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat};
 * @param {Date} date to locale time
 */
const getLocaleHoursMinutesSecondsFromDate = (
  date: Date,
  // IDEA change to options object
  // WHY for option readability in call
  // WHEN happy to refactor getDateFromHoursMinutesSeconds
  isUTC = true
): string => {
  // AC (6Nov2020): 💀💀💀 DANGER 💀💀💀  - These options are not fully supported in jest
  // See test for more details
  const options = {
    /**
     * NOTE: Back in 6Nov2020 { hour12: true } used to be bugged Chrome
     * @see {@link https://bighealth.slack.com/archives/CMLPECH4M/p1612537737003000?thread_ts=1604664823.000500&cid=CMLPECH4M}
     */
    hourCycle: 'h12',
    timeZone: isUTC ? 'UTC' : undefined,
  };
  // WARNING - Not after the real country code; After consistent output for backend format
  const fauxCountryCode = 'en-UK';
  return date.toLocaleTimeString(fauxCountryCode, options);
};

const getLocaleHHmmssFromDate = getLocaleHoursMinutesSecondsFromDate; // alias using time format pattern
export { getLocaleHoursMinutesSecondsFromDate, getLocaleHHmmssFromDate };
