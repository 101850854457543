import React, { ReactElement } from 'react';
import { ImageBackground, StyleSheet, Text as RawText } from 'react-native';
import { FillAbsolute, FillAbsoluteCenter } from 'components/Containers';
import { roles } from 'cross-platform/utils/roleProps';
import { noOpFunction } from 'lib/noOpFunction';
import { Formik } from 'formik';
import { useFetchHomeScreenData } from './hooks/useFetchHomeScreenData';
// TODO: move the Text from the sceneset-components to the components folder as a shared folder
// WHEN: during Daylight Rebuild
import { Text } from 'components/sceneset-components/Text';
import { ActionButton } from './ActionButton';
import { PlayButton } from './PlayButton';
import {
  ColumnContainer,
  ContainerContent,
  ContainerNextSSGInfo,
  ContainerPlayButton,
  ContainerPracticeLibraryButton,
  ContainerSettings,
  ContainerText,
  RawContainer,
} from './styled';
import { SettingsIcon } from './icons/SettingsIcon';
import { useTransformContentStyle } from 'daylight/components/ResponsiveLayout/useTransformContentStyle';
import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
import {
  useLazyQueryLogout,
  useQueryProduct,
  useQueryProgram,
} from 'lib/api/reactQueryHelpers';

export const HomeScreen = (): ReactElement => {
  const productId = useQueryProduct()?.data?.result.id;
  const logout = useLazyQueryLogout();
  const programId = useQueryProgram()?.data?.result.id;

  const [isPayloadLoading, payload] = useFetchHomeScreenData({
    productId: productId || 2,
    programId: programId,
  });

  const transformStyle = useTransformContentStyle();
  const transformVerticalStyle = useTransformContentStyleVertical();

  const handleLogout = async (): Promise<void> => {
    await logout();
  };

  if (isPayloadLoading) {
    return (
      <FillAbsoluteCenter
        {...roles('homeScreenLoading')}
        style={{
          backgroundColor: 'white',
        }}
      >
        <RawText>Loading...</RawText>
      </FillAbsoluteCenter>
    );
  }

  if (typeof payload === 'undefined') {
    return (
      <FillAbsoluteCenter
        style={{
          backgroundColor: 'white',
        }}
      >
        <RawText>Error </RawText>
      </FillAbsoluteCenter>
    );
  }

  const srcBackgroundImage = payload.background_image.sourceProps.storage_url;

  // there is always at least one button in the payload
  let buttonPracticeLibraryProps = payload.buttons[0];
  let buttonPlayProps = undefined;
  if (payload.buttons.length > 1) {
    buttonPlayProps = payload.buttons[0];
    buttonPracticeLibraryProps = payload.buttons[1];
  }

  return (
    <FillAbsolute {...roles('homeScreen')} style={{ backgroundColor: 'white' }}>
      <ContainerContent {...roles('containerContent')}>
        <ImageBackground
          style={{
            flex: 1,
          }}
          imageStyle={{}}
          resizeMode={'cover'}
          source={{ uri: srcBackgroundImage }}
        >
          <ColumnContainer style={{ justifyContent: 'flex-end' }}>
            <ContainerSettings>
              <SettingsIcon
                size={transformStyle(40)}
                color={payload.settings_icon_color}
                onPress={handleLogout}
              />
            </ContainerSettings>

            <ContainerNextSSGInfo
              style={{
                paddingBottom: transformVerticalStyle(20),
                paddingHorizontal: '10%',
                height: '20%',
                justifyContent: 'flex-end',
              }}
            >
              <RawContainer
                style={{
                  paddingBottom: transformVerticalStyle(10),
                }}
              >
                {payload.length_minutes.text ? (
                  <Text {...payload.length_minutes} />
                ) : null}
                <Text {...payload.exercise_label} />
              </RawContainer>

              <RawContainer>
                <ContainerText>
                  <Text {...payload.title} />
                  <Text {...payload.subtitle} />
                </ContainerText>

                {typeof buttonPlayProps !== 'undefined' ? (
                  <ContainerPlayButton>
                    <Formik initialValues={[]} onSubmit={noOpFunction}>
                      <PlayButton
                        {...buttonPlayProps}
                        style={{
                          ...(Array.isArray(buttonPlayProps.style)
                            ? StyleSheet.flatten(buttonPlayProps.style)
                            : buttonPlayProps.style),
                          width: transformStyle(62),
                          height: transformStyle(62),
                        }}
                        onPress={noOpFunction}
                      />
                    </Formik>
                  </ContainerPlayButton>
                ) : null}
              </RawContainer>
            </ContainerNextSSGInfo>
          </ColumnContainer>
        </ImageBackground>
      </ContainerContent>

      <ContainerPracticeLibraryButton
        {...roles('containerPracticeLibraryButton')}
      >
        <Formik initialValues={[]} onSubmit={noOpFunction}>
          <ActionButton
            {...buttonPracticeLibraryProps}
            style={{
              ...(Array.isArray(buttonPracticeLibraryProps.style)
                ? StyleSheet.flatten(buttonPracticeLibraryProps.style)
                : buttonPracticeLibraryProps.style),
              paddingVertical: transformVerticalStyle(16),
              paddingHorizontal: transformStyle(72),
              marginHorizontal: '10%',
            }}
            onPress={noOpFunction}
            isDisabled={false}
            textColor="white"
            textSize={transformStyle(18)}
          />
        </Formik>
      </ContainerPracticeLibraryButton>
    </FillAbsolute>
  );
};

export default HomeScreen;
