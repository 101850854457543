import React from 'react';
import { QuizAttribute } from 'components/constants';
import { Content } from './styled';
import { ContentProps } from '..';

export type ControlProps = {
  isDisabled?: boolean;
  isPressing?: boolean;
  isActive?: boolean;
  isSelected?: boolean;
  quiz?: QuizAttribute;
  highlight?: boolean;
} & ContentProps;

const Control = (props: ControlProps): React.ReactElement => (
  <Content {...props} />
);

export { Control };
