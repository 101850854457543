import React, { ReactElement, useMemo, useState } from 'react';
import { Dimensions, LayoutChangeEvent } from 'react-native';
import styled from 'styled-components/native';
import { roles } from 'cross-platform/utils/roleProps';

const initialWindowSize = Dimensions.get('window');

export type ScreenDimensions = {
  screenWidth: number;
  screenHeight: number;
};

const defaultContext: ScreenDimensions = {
  screenWidth: initialWindowSize.width,
  screenHeight: initialWindowSize.height,
};

const ScreenContainer = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const ScreenResponsiveLayoutContext = React.createContext(
  defaultContext
);

export const ScreenResponsiveLayoutProvider = (props: {
  children: ReactElement;
}): ReactElement => {
  const [windowSize, setWindowSize] = useState<ScreenDimensions>({
    screenWidth: initialWindowSize.width,
    screenHeight: initialWindowSize.height,
  });
  const handleWindowLayout = (layoutEvent: LayoutChangeEvent): void => {
    const newWindowSize: ScreenDimensions = {
      screenWidth: layoutEvent.nativeEvent.layout.width,
      screenHeight: layoutEvent.nativeEvent.layout.height,
    };
    setWindowSize(newWindowSize);
  };

  const { screenWidth, screenHeight } = windowSize;
  const memoedDimensions = useMemo(
    () => ({
      screenWidth,
      screenHeight,
    }),
    [screenWidth, screenHeight]
  );
  return (
    <ScreenContainer
      {...roles('ResponsiveScreenContainer')}
      onLayout={handleWindowLayout}
    >
      <ScreenResponsiveLayoutContext.Provider value={memoedDimensions}>
        {props.children}
      </ScreenResponsiveLayoutContext.Provider>
    </ScreenContainer>
  );
};
