import React, { ReactElement, useContext } from 'react';
import { ScreenResponsiveLayoutContext } from 'components/ResponsiveLayout';
import { NavBarStyles } from 'components/Navigation/constants';

import { Button, ButtonView } from './styled';
import { Chevron } from 'components/icons';
import { RoleProps, pass } from 'cross-platform/utils/roleProps';
import { CalendarTileContainerPadding } from '../styled';
import {
  NARROW_CALENDAR_CHART_MARGIN_LEFT,
  CALENDAR_CHART_MARGIN_LEFT,
} from 'components/SleepDiaryWeek/constants';

const PagingButton = ({
  disabled,
  onPress,
  icon,
  iconSize,
  ...rest
}: {
  disabled?: boolean;
  onPress: () => void;
  icon: 'right' | 'left';
  iconSize: number;
} & RoleProps): ReactElement => {
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  const isMobileWeb =
    screenWidth <= NavBarStyles.navBarCollapseMobileWebBreakPointPx;

  if (disabled) {
    return (
      <CalendarTileContainerPadding
        style={{
          width: isMobileWeb
            ? NARROW_CALENDAR_CHART_MARGIN_LEFT
            : CALENDAR_CHART_MARGIN_LEFT,
        }}
      />
    );
  }

  return (
    <Button
      {...pass(rest)}
      disabled={disabled}
      onPress={onPress}
      style={{
        width: isMobileWeb
          ? NARROW_CALENDAR_CHART_MARGIN_LEFT
          : CALENDAR_CHART_MARGIN_LEFT,
      }}
    >
      <ButtonView>
        <Chevron
          size={iconSize}
          direction={icon}
          style={{ color: '#003366' }}
        />
      </ButtonView>
    </Button>
  );
};

export { PagingButton };
