import { useEffect } from 'react';
import { initializeFacebookSDK } from './initializeFacebookSDK';

export const useInitializeFacebookSDK = ({
  onInit,
  appId,
}: {
  appId: string;
  onInit?: () => void;
}): void => {
  useEffect(() => {
    initializeFacebookSDK(appId, onInit);
  }, [appId, onInit]);
};
