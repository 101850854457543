import { useEffect, useState } from 'react';
import { setPlayerState } from 'state/player/actions';
import { PlaybackState } from 'state/player/reducer';
import { useDispatch } from 'react-redux';
import {
  buildMediaPlayerOrchestrator,
  mediaPlayerOrchestrator,
} from './MediaPlayerOrchestrator';
import {
  toggleVisibilityOrchestrator,
  buildToggleVisibilityOrchestrator,
} from '../ToggleVisibilityOrchestrator';

export const useInitializeTimedOrchestrators = (): boolean => {
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const lastOrchestratorWasLocked =
      mediaPlayerOrchestrator?.getPlayLock() ||
      toggleVisibilityOrchestrator?.getPlayLock();
    buildMediaPlayerOrchestrator();
    buildToggleVisibilityOrchestrator();
    if (lastOrchestratorWasLocked) {
      mediaPlayerOrchestrator.setPlayLock();
      toggleVisibilityOrchestrator.setPlayLock();
    }
    mediaPlayerOrchestrator.setOnPlayFailHandler(() => {
      dispatch(setPlayerState(PlaybackState.ERROR));
    });
    mediaPlayerOrchestrator.setOnPlaySuccessHandler(() => {
      dispatch(setPlayerState(PlaybackState.PLAYING));
    });
    mediaPlayerOrchestrator.setOnEndHandler(() => {
      dispatch(setPlayerState(PlaybackState.FINISHED));
    });
    mediaPlayerOrchestrator.setOnPauseHandler(() => {
      dispatch(setPlayerState(PlaybackState.PAUSED));
    });
    setIsReady(true);
  }, [dispatch]);

  // Make sure we teardown the orchestrator on unmount. If we don't, we'll have memory leaks not to mention
  // unexpected null/undefined'd player refs potentially hanging around which we may attempt to call and
  // crash the app.
  useEffect(() => {
    return () => {
      mediaPlayerOrchestrator.teardown();
      toggleVisibilityOrchestrator.teardown();
      setIsReady(false);
      dispatch(setPlayerState(PlaybackState.UNINITIALIZED));
    };
  }, [dispatch]);
  return isReady;
};
