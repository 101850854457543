import React, { ReactElement } from 'react';
import { ButtonTypes } from 'common/constants/enums';
import PressButton from './PressButton';
import LinkButton from './LinkButton';
import { useHistory } from 'cross-platform/react-router';
import useActionHandler from 'lib/player/useActionHandler';
import { CSSProperties } from 'styled-components';
import { StyleSheet } from 'react-native';
import { printableProps } from 'components/layout/lib/printableProps';
import { SceneActionTypes } from '@bighealth/types/dist/enums';
import { SceneAction } from '@bighealth/types/src/scene-components/client';

import { PressHandler } from './common';
import { getNextPath } from 'lib/player/sceneSetHelpers/getNextPath';

const flatten = StyleSheet.flatten;

type ButtonProps = {
  text: string;
  align?: string;
  type?: ButtonTypes;
  action: SceneAction;
  style?: CSSProperties;
  isDisabled?: boolean;
  onPress?: PressHandler;
};

/**
 * A button that takes an action and either converts that to a Link (in the case of
 * action.type being "NEXT" or an onPress handler in the case where the action is,
 * for instance, "SUBMIT"
 */
const ActionButton = ({
  text,
  type = ButtonTypes.PRIMARY_BUTTON,
  align,
  action,
  style,
  isDisabled = false,
  onPress,
  ...rest
}: ButtonProps): ReactElement => {
  const actionHandler = useActionHandler(action);
  const history = useHistory();
  const handlePress: PressHandler = (e): void => {
    if (typeof actionHandler === 'function') {
      actionHandler();
    }
    onPress?.(e);
  };
  if (action && action.type === SceneActionTypes.NEXT) {
    const linkTo = getNextPath(history.location.pathname);
    return (
      <LinkButton
        to={isDisabled ? undefined : linkTo}
        text={text}
        type={type}
        align={align}
        data-testid={`Button_${printableProps({ text, isDisabled })}`}
        style={flatten(style)}
        isDisabled={isDisabled}
        {...rest}
      />
    );
  }

  return (
    <PressButton
      onPress={isDisabled ? undefined : handlePress}
      text={text}
      type={type}
      align={align}
      data-testid={`Button_${printableProps({ text, isDisabled })}`}
      style={flatten(style)}
      disabled={isDisabled}
      {...rest}
    />
  );
};

export default ActionButton;
