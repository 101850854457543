import { MODAL_REFERENCE_DIMENSION, ModalStyles } from '../constants';

export const getModalWidth = (
  minWidth: number,
  maxWidth: number,
  screenWidth: number
): number => {
  if (screenWidth >= ModalStyles.largeScreenBreakpointPx) {
    return maxWidth;
  }
  if (screenWidth >= maxWidth) {
    return maxWidth;
  }
  if (screenWidth >= minWidth) {
    return screenWidth;
  }
  return minWidth;
};

/**
 *
 * Calculates the correct value for a dimension depending on if it's in content or default
 *
 * @param modalWidth
 * @param baseValue
 * @param value
 */
export const getScaledValueForWidth = (
  modalWidth: number,
  baseValue: number,
  value?: number
): number => {
  return (modalWidth * (value || baseValue)) / MODAL_REFERENCE_DIMENSION;
};
