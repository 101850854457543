import styled from 'styled-components/native';
import { Entry } from '../components';
import React, { ReactElement } from 'react';
import { Category } from '@bighealth/types';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { Link } from 'cross-platform/react-router/Link';
import { useHistory } from 'cross-platform/react-router';

export const LibraryContainer = styled.View`
  display: flex;
  width: 100%;
`;

export const Padding = styled.View`
  width: 1%;
`;

export const MissingContentContainer = styled.View`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`;

export const Heading = ({ heading }: { heading: string }): ReactElement => {
  const color = '#001948';
  const styles = useGetDynamicContentStyles();
  return (
    <View>
      <Text
        style={{
          color,
          fontSize: styles.libraryCategoryHeadingFontSize,
        }}
      >
        {heading}
      </Text>
    </View>
  );
};

export const LibraryContentEntry = ({
  heading,
  content,
}: Category): ReactElement => {
  const color = '#1a80a2';
  const styles = useGetDynamicContentStyles();
  const history = useHistory();

  return (
    <View>
      <a
        title={`Library section: ${heading}`}
        href={`#${heading.replace(/ /g, '_')}`}
        id={heading.replace(/ /g, '_')}
        ref={element => {
          const { hash } = window.location;
          if (hash !== '' && element !== null) {
            const id = hash.replace('#', '');
            if (element.id === id) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }
        }}
      />

      <Entry>
        <LibraryContainer>
          <Heading heading={heading} />

          {content.map(props => {
            // remove possible trailiing slash from current pathname
            let currentPathame = history.location.pathname;
            if (currentPathame.slice(-1) === '/') {
              currentPathame = currentPathame.slice(0, -1);
            }
            return (
              <View key={props.id}>
                <Link
                  to={`${currentPathame}/${props.id}`}
                  style={{
                    color: color,
                    fontSize: styles.libraryCategoryHeadingFontSize,
                    marginTop: styles.libraryArticleTitleMarginTop,
                  }}
                >
                  {props.title}
                </Link>
              </View>
            );
          })}
        </LibraryContainer>
      </Entry>
    </View>
  );
};

export const MissingContentEntry = ({
  heading,
  subHeading,
}: {
  heading: string;
  subHeading: string;
}): ReactElement => {
  const color = '#1a80a2';
  const styles = useGetDynamicContentStyles();
  return (
    <Entry>
      <MissingContentContainer>
        <Heading heading={heading} />
        <Padding />

        <Text
          style={{
            color: color,
            fontSize: styles.libraryCategoryHeadingFontSize,
          }}
        >
          <a
            title="Suggest a new expert article"
            style={{
              color: color,
              fontSize: styles.libraryCategoryHeadingFontSize,
            }}
            href={`mailto:hello@sleepio.com?subject=Suggest a new expert article`}
          >
            {subHeading}
          </a>
        </Text>
      </MissingContentContainer>
    </Entry>
  );
};
