import React from 'react';
import Svg, { Circle as SvgCircle } from 'react-native-svg';
import {
  Circle as CircleProps,
  ViewStylesObject,
} from '@bighealth/types/src/scene-components/client';
import { roles } from 'cross-platform/utils/roleProps';
import { useTransformStylesToContext } from 'components/ResponsiveLayout';

export default function Circle(props: CircleProps): React.ReactElement {
  const scaleValues = useTransformStylesToContext();
  // @TODO Make useTransformStylesToContext an optional generic
  // WHEN we find it annoying to destructure and remap values like this.
  const { width, height } = scaleValues({
    width: props.radius,
    height: props.radius,
  });
  const { height: strokeWidth } = scaleValues({
    height:
      typeof props.stroke?.weight === 'undefined' ? 10 : props.stroke.weight,
  });
  const transformedStyles = scaleValues(props.style as ViewStylesObject);

  if (props.style?.top || props.style?.left) {
    transformedStyles.position = 'absolute';
  }

  return (
    <Svg
      {...roles('Circle')}
      width={width}
      height={height}
      style={transformedStyles}
      pointerEvents={'none'}
    >
      <SvgCircle
        cx={width / 2}
        cy={height / 2}
        r={(width - strokeWidth) / 2}
        fill={props.fill}
        stroke={props.stroke?.color || 'red'}
        strokeWidth={strokeWidth}
        strokeOpacity={props.stroke?.opacity}
      />
    </Svg>
  );
}
