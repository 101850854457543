import React from 'react';
import { Value } from 'components/forms/types';
import { Dropdown, DropdownItem, DropdownOption } from '../Dropdown';
import { deriveTimeResponseOptions } from './helpers/getResponseOptions';
import { createValueChangeHandler } from './helpers/createValueChangeHandler';
import { getOutOfBoundsOptionsIfNeeded } from './helpers/getOutOfBoundsOptionsIfNeeded';
import { valueToDropdownItem } from './helpers/valueToDropdownItem';
import { getDisplayText } from './helpers/valueToDropdownItem/getDisplayText';

type DropdownTimePickerProps = {
  min?: Date;
  max?: Date;
  selectedValue?: Value;
  onValueChange?: (value: Date | null) => void;
  highlight?: boolean;
  value?: DropdownItem[];
};

const DropdownTimePicker = ({
  min,
  max,
  selectedValue,
  onValueChange,
  highlight,
}: DropdownTimePickerProps): React.ReactElement => {
  const derivedOptions = deriveTimeResponseOptions({ min, max });
  const outOfBoundsOptions = getOutOfBoundsOptionsIfNeeded(
    derivedOptions,
    selectedValue
  );
  const options = [...derivedOptions, ...outOfBoundsOptions];
  const selectedOption = options.find(
    option => `${selectedValue}` === `${option}`
  );
  const selectedOptionAsDropdownItem = valueToDropdownItem(selectedOption);

  return (
    <Dropdown
      value={selectedOptionAsDropdownItem}
      onValueChange={createValueChangeHandler(onValueChange)}
      highlight={highlight}
    >
      {options.map(date => {
        const displayText = getDisplayText(date);
        return (
          <DropdownOption
            key={`${date}`}
            value={`${date}`}
            displayText={displayText}
            label={displayText}
          />
        );
      })}
    </Dropdown>
  );
};
export { DropdownTimePicker };
