import { generateSetTimeoutWorkerCode } from './setTimeoutWorker';

export type SetTimeoutCallback = (response: {
  status?: 'complete';
  date?: number;
}) => void;

export class SetTimeout {
  private readonly setTimeoutWorker?: Worker = undefined;
  private readonly listener?: (params: {
    data: { status?: string; date?: string };
  }) => void = undefined;

  constructor(callback: SetTimeoutCallback, durationMs: number) {
    this.setTimeoutWorker = new Worker(generateSetTimeoutWorkerCode());
    this.setTimeoutWorker.postMessage({
      command: 'setTimeout',
      timeout: durationMs,
    });

    this.listener = ({ data }) => {
      const { status, date } = data;
      if (status === 'complete') {
        callback({ status: 'complete' });
      } else if (date) {
        callback({ date: parseInt(date, 10) });
      }
    };

    this.setTimeoutWorker.addEventListener('message', this.listener);
  }

  clearTimeout = (): void => {
    if (this.listener && this.setTimeoutWorker) {
      this.setTimeoutWorker.postMessage({
        command: 'clearTimeout',
      });
      this.setTimeoutWorker.removeEventListener('message', this.listener);
    }
  };
}
