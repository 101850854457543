import {
  AnalyticsContextReturnType,
  AnalyticsField,
  ScreenResourceEvent,
} from '@bighealth/analytics';
import { getAnalyticsDate } from './getAnalyticsDate';
import { getUserAgent } from './getUserAgent';
import { URLLike } from './useGetURL';

export const sendNonSessionAnalytics = async ({
  programId,
  productId,
  analytics,
  field,
  url,
}: {
  programId: number;
  productId: number;
  analytics: AnalyticsContextReturnType;
  url: URLLike;
  field: AnalyticsField;
}): Promise<void> => {
  analytics.resetPayload();
  const userAgent = await getUserAgent();
  const typedPayload: ScreenResourceEvent = {
    occurred_at: getAnalyticsDate(),
    product_id: productId as number,
    program_id: programId as number,
    action: 'viewed',
    name: `screen|viewed|${field?.area}`,
    resource: 'screen',
    type: 'ui',
    body: {
      fields: {
        feature: field,
        route: url.pathname,
      },
      meta: {
        http: {
          current_url: url.href,
          referrer: url.href,
          user_agent: userAgent,
        },
      },
    },
  };
  analytics.enhancePayload(typedPayload);
  return await analytics.sendAnalytics();
};
