import React from 'react';
import { addStory } from 'lib/story-books';
import { SleepDiaryWithDefaults } from './components/Form/stories/WithDefaults.story';
import { StoryPopupModalWithState } from 'components/PopupModal/index.story';

addStory('sleep-diary/Form WithDefaults PopupModal', () => (
  <StoryPopupModalWithState>
    <SleepDiaryWithDefaults />
  </StoryPopupModalWithState>
));
