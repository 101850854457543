import React, { ReactElement } from 'react';
import Svg, { G, Polygon } from 'react-native-svg';
import { IconStyles } from './types';

export const Check = ({
  size,
  style,
}: {
  size: number;
  style?: IconStyles;
}): ReactElement => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" style={style}>
      <G id="Material/Icons-black/check" stroke="none" fill="none">
        <Polygon
          id="Shape"
          fill={style?.color || 'currentColor'}
          points="8.9999939 16.2 4.7999939 12 3.3999939 13.4 8.9999939 19 20.9999939 7 19.5999939 5.6"
        />
      </G>
    </Svg>
  );
};
