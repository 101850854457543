import styled from 'styled-components/native';
import { TextPresentation } from 'components/sceneset-components/Text/TextPresentation';
import { LINK_DISPLAY_VALUE } from '../constants';
import { TEXT_FONT_SIZE } from '../../../styled';

export const Details = styled(TextPresentation)`
  color: #435fa9;
  font-weight: 400;
  display: ${LINK_DISPLAY_VALUE};
  font-size: ${TEXT_FONT_SIZE}px;
`;
Details.displayName = 'Details';

export const Paragraph = styled.View`
  margin-top: 0;
  display: ${LINK_DISPLAY_VALUE};
`;
Paragraph.displayName = 'Paragraph';
