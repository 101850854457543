import React, { ReactElement, ReactNode, useContext } from 'react';
import { View } from 'react-native';
import { NavBarStyles } from 'components/Navigation/constants';
import {
  ScreenResponsiveLayoutContext,
  useGetDynamicContentStyles,
} from 'components/ResponsiveLayout';
import { ScrollRow } from './styled';

export const CalendarChartContainer = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  const isMobileWeb =
    screenWidth <= NavBarStyles.navBarCollapseMobileWebBreakPointPx;

  if (isMobileWeb) {
    return (
      <ScrollRow>
        <View style={{ width: styles.sleepDiaryWeekWidthMobileWeb }}>
          {children}
        </View>
      </ScrollRow>
    );
  }

  return <>{children}</>;
};
