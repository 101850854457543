import { get_sleep_efficiency_trend_page_data_desc } from '@bighealth/api/SleepEfficiencyTrend/v1';

export const sleepEfficiencyScreenMockData: Record<
  string,
  get_sleep_efficiency_trend_page_data_desc.Result
> = {
  '0': {
    items: [
      { date: '2020/03/03', value: 50 },
      { date: '2020/02/02', value: 35 },
      { date: '2020/01/01', value: 100 },
    ],
    items_per_page: 6,
    page: 0,
    has_next: false,
    has_prev: true,
  },
  '1': {
    items: [
      { date: '2019/12/05', value: 45 },
      { date: '2019/11/04', value: 30 },
      { date: '2019/10/03', value: null },
      { date: '2019/09/02', value: 65 },
      { date: '2019/08/01', value: 55 },
      { date: '2019/07/01', value: 55 },
    ],
    items_per_page: 6,
    page: 1,
    has_next: true,
    has_prev: true,
  },
  '2': {
    items: [
      { date: '2019/06/05', value: 100 },
      { date: '2019/05/04', value: 90 },
      { date: '2019/04/03', value: 55 },
      { date: '2019/02/01', value: 25 },
    ],
    items_per_page: 6,
    page: 2,
    has_next: true,
    has_prev: false,
  },
};
