import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Answer, Response, ResponseOption } from '@bighealth/types';
import { getQuestions } from 'state/question-response/selectors';
import { useCallback } from 'react';
import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';
import { toValueObject } from 'lib/api/middleware/response/toJavaScriptDateWithRequest/utils/toValueObject';
import {
  clearQuestionResponseQuizAnswersByPath,
  clearQuestionResponsesToSubmit,
  FormikPersistableState,
  persistFormikStateByPath,
  QuestionId,
} from 'state/question-response/actions';
import { isHardCodedSSG } from 'developer/helpers';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { clone } from 'ramda';
import logger from 'lib/logger';
import { stringify } from 'lib/stringify';
import { useHistory } from 'cross-platform/react-router';
import {
  queryClientSubmitBulkResponses,
  useQueryProduct,
  useQueryProgram,
} from 'lib/api/reactQueryHelpers';

export type ActionHandlerCallback = Promise<void> | (() => void) | undefined;

export const useGetFormikStatePersistPath = (): string | undefined => {
  const { sceneSetGraphId, sceneSetId, sceneId } = useSafeParams<
    SceneSetParams
  >();
  if (
    typeof sceneSetGraphId === 'undefined' ||
    typeof sceneSetId === 'undefined'
  ) {
    // non-nonsensical persist path
    return undefined;
  }

  return `${sceneSetGraphId}-${sceneSetId}-${sceneId || 0}`;
};

export const useSubmitOnActionHandler = (): (() => Promise<void>) => {
  const formikContext = useFormikContext();
  const dispatch = useDispatch();
  const { setSubmitting, values: uncastValues, submitForm } = formikContext;
  const formikPersistPath = useGetFormikStatePersistPath();
  const { sceneSetGraphId, sceneSetId } = useSafeParams<SceneSetParams>();
  const history = useHistory();
  const productId = useQueryProduct()?.data?.result.id;
  const programId = useQueryProgram()?.data?.result.id;
  const questions = useSelector(getQuestions);
  const values = uncastValues as Record<QuestionId, DropdownItem[]>;

  return useCallback(async (): Promise<void> => {
    const state = (clone({
      errors: formikContext.errors,
      values: formikContext.values,
      touched: formikContext.touched,
    }) as unknown) as FormikPersistableState;

    if (typeof formikPersistPath !== 'string') {
      if (
        Object.keys(state?.errors || {}).length > 0 ||
        Object.keys(state?.values || {}).length > 0 ||
        Object.keys(state?.touched || {}).length > 0
      ) {
        // Warn and do not persist to avoid unexpected behavior
        const stateStr = stringify(state);
        const errorStr = `Tried to persist the following formik state in context without "safe params" (pathname ${history.location.pathname}): ${stateStr}`;
        logger(errorStr, Error(errorStr), { silentUnlessDevOnly: true });
      }
    } else {
      dispatch(persistFormikStateByPath(formikPersistPath, state));
    }
    const responses: Response[] = Object.entries(values).map(
      ([questionSemanticId, dropdownItems]) => {
        const question = questions.find(
          q => q.semantic_id === questionSemanticId
        );
        if (!question || typeof question.id === 'undefined') {
          throw TypeError(
            `No question.id for semanticId: ${questionSemanticId}`
          );
        }
        return {
          question_id: question.id,
          product_id: productId as number,
          program_id: programId as number,
          scene_set_graph_id: sceneSetGraphId,
          scene_set_id: sceneSetId,
          answers_list: dropdownItems
            .filter(dropdownItem => dropdownItem.isSelected)
            .map(
              (dropdownItem): Answer => {
                const responseOptionForDropdownItem = question.response_config.response_options.find(
                  responseOption => responseOption.id === dropdownItem.id
                ) as ResponseOption;
                return {
                  // the semantic ID is an identifier of the Question inside a SceneSet,
                  semantic_id: responseOptionForDropdownItem.semantic_id,
                  value:
                    question.response_type.toLowerCase() === 'number'
                      ? Number(dropdownItem.value)
                      : dropdownItem.value instanceof Date
                      ? toValueObject(
                          question.response_type,
                          dropdownItem.value
                        )
                      : dropdownItem.value,
                  // The multi_select_option_id is the identifier of the option in the response_config of that question.
                  // Example, you can have 2 Multi-select Questions in the SS. The first with 3 options, the second with 5 options:
                  // the two questions must have different Semantic ID
                  // The first question.response_config has multi_select_option_id  1,2,3,
                  // The second question.response_config has multi_select_option_id  = 1,2,3,4,5
                  multi_select_option_id: responseOptionForDropdownItem.id, // WARNING Starts at 1 (not 0)
                  score: responseOptionForDropdownItem.score,
                };
              }
            ),
        };
      }
    );
    if (responses?.length > 0 && !isHardCodedSSG(sceneSetGraphId)) {
      // We're try/catching  all this in useActionHandler
      setSubmitting(false);
      await submitForm();

      await queryClientSubmitBulkResponses({
        product_id: productId as number,
        program_id: programId as number,
        responses_list: responses,
        scene_set_graph_id: sceneSetGraphId,
        scene_set_id: sceneSetId,
      });
      dispatch(clearQuestionResponsesToSubmit());
      Object.entries(values).forEach(([semanticId]) =>
        dispatch(clearQuestionResponseQuizAnswersByPath(semanticId))
      );
    }
  }, [
    dispatch,
    formikContext.errors,
    formikContext.touched,
    formikContext.values,
    formikPersistPath,
    history.location.pathname,
    productId,
    programId,
    questions,
    sceneSetGraphId,
    sceneSetId,
    setSubmitting,
    submitForm,
    values,
  ]);
};
