import React, { ReactElement, useEffect } from 'react';
import styled from 'styled-components/native';
import { FillAbsolute } from 'components/Containers';
import { getTheme } from 'lib/styles/getTheme';
import { PlayerControls } from 'components/Media';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';
import * as contentErrors from 'components/SceneSetView/components/contentErrors';
import { useDispatch, useSelector } from 'react-redux';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { useQueryAllDataForSceneSet } from 'lib/api/reactQueryHelpers';
import { getPlayerStatus, getSceneSetRenderKey } from 'state/player/selectors';
import { getIsScenePlayable } from 'lib/player/sceneSetHelpers';
import { showControls } from 'state/player/actions';
import { addParentNodes } from 'lib/player/tree-utils';
import { PLAYER_STATUS } from 'state/player/reducer';
import Modal from 'components/Modal';
import { SceneSetSkeletonLoader } from 'components/SceneSetView/SceneSetSkeletonLoader';
import componentsDictionary from 'components/sceneset-components/sceneSetComponentsDictionary';
import Tree from '@bighealth/tree';
import { useHandleTimersForAppState } from 'lib/player/useHandleTimersForAppState';
import { IsInactiveStateProvider } from './providers/IsInactiveStateProvider';
import { useInitializeTimedOrchestrators } from 'lib/player/media/MediaPlayerOrchestrator/useInitializeTimedOrchestrators';

const TreeContainer = styled.View`
  display: flex;
  width: 100%;
  height: 100%;
`;

const FillAbsoluteWithBackground = styled(FillAbsolute)`
  background-color: ${getTheme('homeScreen.backgroundColor')};
`;

const KeyedSceneSetView = ({
  isInactiveState,
}: {
  isInactiveState: boolean;
}): ReactElement | null => {
  useUpdateTitle();
  const orchestratorsAreReady = useInitializeTimedOrchestrators();
  const dispatch = useDispatch();
  const { sceneId } = useSafeParams();
  const { isSuccess, data } = useQueryAllDataForSceneSet();
  const playerState = useSelector(getPlayerStatus);

  const currentSceneSet = data?.result?.sceneSet;
  const isPlayable = getIsScenePlayable(currentSceneSet, sceneId);
  useEffect(() => {
    if (isPlayable) {
      dispatch(showControls(true));
    }
  }, [dispatch, isPlayable]);

  if (typeof currentSceneSet === 'undefined') {
    return null;
  }
  const currentSceneSetWithParentNodes = addParentNodes(currentSceneSet);
  const currentScene =
    currentSceneSetWithParentNodes.childNodes?.[sceneId || 0];
  if (typeof currentScene === 'undefined') {
    return null;
  }
  if (
    !(playerState === PLAYER_STATUS.READY && isSuccess && orchestratorsAreReady)
  ) {
    return (
      <Modal>
        <SceneSetSkeletonLoader />
      </Modal>
    );
  }

  const sceneChildNodes = currentScene.childNodes;
  return (
    <contentErrors.Provider>
      <FillAbsoluteWithBackground>
        <contentErrors.ErrorBoundary>
          <TreeContainer>
            <Tree
              componentsDictionary={componentsDictionary}
              component={'Scene'}
              data={sceneChildNodes}
            />
          </TreeContainer>
        </contentErrors.ErrorBoundary>
        <IsInactiveStateProvider value={{ isInactiveState }}>
          <PlayerControls />
        </IsInactiveStateProvider>
      </FillAbsoluteWithBackground>
      <contentErrors.ErrorModal />
    </contentErrors.Provider>
  );
};

const SceneSetView = (): ReactElement => {
  const isInactiveState = useHandleTimersForAppState();
  const key = useSelector(getSceneSetRenderKey);
  return <KeyedSceneSetView key={key} isInactiveState={isInactiveState} />;
};

export default SceneSetView;
