import { useCallback, useContext } from 'react';
import { ScalingContext, ScalingContextContext } from '../providers';
import { useGetMediaDimensions } from './useGetMediaDimensions';
import { useScaleToModal } from './useScaleToModal';
import { useScaleToMedia } from './useScaleToMedia';
import {
  StyleObject,
  ScalingMode,
} from '@bighealth/types/src/scene-components/client';
import { ScalingModes } from '@bighealth/types/dist/enums';
import {
  scalableCSSPropertiesDictionary,
  ScalableCSSProperty,
} from 'components/ResponsiveLayout/hooks/scalableCSSProperties';

export type AbsolutedStyle = {
  width: number;
  height: number;
  position: 'absolute';
  top: number;
  left: number;
};

interface UseTransformStylesCallback {
  <S extends StyleObject>(style: S, scaling?: ScalingMode): S | AbsolutedStyle;
}

function assignValueToProperty<S extends StyleObject>(
  object: S,
  property: keyof S,
  value: number | string
): S {
  Object.assign(object, {
    [property]: value,
  });
  return object;
}

export const useTransformStylesToContext = (): UseTransformStylesCallback => {
  const scalingContext = useContext(ScalingContextContext);
  const scaleToModal = useScaleToModal();
  const scaleToMedia = useScaleToMedia();
  const { mediaHeight, mediaWidth } = useGetMediaDimensions();
  const callback = useCallback(
    function transformStyles<S extends StyleObject>(
      inputStyle: S,
      scaling: ScalingMode = ScalingModes.Relative
    ): S | AbsolutedStyle {
      const style: S = inputStyle || {};
      switch (scalingContext) {
        case ScalingContext.Modal: {
          const returnStyle = {} as S;
          // We're hard-coding all Modal behaviour to be of type ScalingMode.Relative
          for (const [uncastProperty, uncastValue] of Object.entries(style)) {
            const property = uncastProperty as keyof S;
            // TS won't infer-instantiated type https://github.com/microsoft/TypeScript/issues/32811#issuecomment-520448992
            const value = (uncastValue as unknown) as S[keyof S];
            if (
              !scalableCSSPropertiesDictionary[property as ScalableCSSProperty]
            ) {
              // Don't scale it. E.g. might be a property like "opacity" or "
              returnStyle[property] = value;
            } else if (typeof value === 'number') {
              // Potentially this property doesn't exist so TypeScript will complain. So we'll force it to exist
              assignValueToProperty(returnStyle, property, scaleToModal(value));
            } else {
              returnStyle[property] = value;
            }
          }
          return returnStyle;
        }
        case ScalingContext.MediaContainer: {
          switch (scaling) {
            case ScalingModes.FillHeight:
              return {
                ...style,
                width: mediaWidth,
                height: mediaHeight,
                position: 'absolute',
                top: 0,
                left: 0,
              };
            case ScalingModes.Absolute:
              return style;
            case ScalingModes.Relative:
            default: {
              const returnStyle = {} as S;
              for (const [uncastProperty, uncastValue] of Object.entries(
                style
              )) {
                const property = uncastProperty as keyof S;
                const value = (uncastValue as unknown) as S[keyof S];
                if (
                  !scalableCSSPropertiesDictionary[
                    property as ScalableCSSProperty
                  ]
                ) {
                  // Don't scale it. E.g. might be a property like "opacity" or "order"
                  returnStyle[property] = value;
                } else if (typeof value === 'number') {
                  // Potentially this property doesn't exist so TypeScript will complain. So we'll force it to exist
                  assignValueToProperty(
                    returnStyle,
                    property,
                    scaleToMedia(value)
                  );
                } else {
                  returnStyle[property] = value;
                }
              }
              returnStyle.position = 'absolute';
              return returnStyle;
            }
          }
        }
        case ScalingContext.Screen:
        default:
          return style;
      }
    },
    [mediaHeight, mediaWidth, scaleToMedia, scaleToModal, scalingContext]
  );
  return callback;
};
