import React, { ComponentProps } from 'react';
import { addStory } from 'lib/story-books';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { ProductReferences } from 'common/constants/enums';
import { EphemeralStateProvider } from 'components/EphemeralStateProvider';
import { CrossPlatformThemeProvider } from 'components/ProvidersContainer/ProductThemeProvider';
import { useFakeStorybookSceneSet } from 'lib/story-books/useFakeStorybookSceneSet';
import { FormTypes } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';
import { SleepDiaryForm } from 'components/SleepDiaryForm';

const Story = () => {
  type Props = ComponentProps<typeof SleepDiaryForm>;
  const props: Props = {
    renderRequest: {
      service_name: 'SleepDiary',
      service_version: '1',
      service_method: 'get_diary_entry_form',
      args: {
        diary_date: {
          $date: '2020-06-03',
        },
        form_type: 'create_single_day_entry' as FormTypes,
      },
    },
  };
  const sceneSet = useFakeStorybookSceneSet();
  if (!sceneSet) {
    return null;
  }
  return <SleepDiaryForm {...props} />;
};

addStory('sleep-diary/SleepDiaryForm', () => {
  return (
    <EphemeralStateProvider>
      <CrossPlatformThemeProvider
        theme={getThemeForProduct(ProductReferences.SLEEPIO)}
      >
        <Story />
      </CrossPlatformThemeProvider>
    </EphemeralStateProvider>
  );
});
