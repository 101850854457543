import React from 'react';
import { Content } from '.';
import { addStory } from 'lib/story-books';
import { QuizAttribute } from 'components/constants';
import { Control } from './Control';
import { getSetPermutations } from '../lib/getSetPermutations';
import { printableProps } from '../lib/printableProps';

addStory('content', () => (
  <>
    <Content>Plain Content</Content>
    <Content mono={true}>{printableProps({ mono: true })}</Content>
  </>
));

addStory('control', () => {
  // IDEA Hand-pick only those that make sense
  // WHEN Test suite  is big or unwieldy
  const isDisabled = [true, undefined];
  const quiz = [QuizAttribute.CORRECT, QuizAttribute.INCORRECT, undefined];
  const isPressing = [true, undefined];
  const isActive = [true, undefined];
  const isSelected = [true, undefined];
  return (
    <>
      <Content>Control</Content>
      {getSetPermutations([
        isDisabled,
        quiz,
        isPressing,
        isActive,
        isSelected,
      ]).map(([isDisabled, quiz, isPressing, isActive, isSelected]) => {
        const props = { isDisabled, quiz, isPressing, isActive, isSelected };
        return (
          <Control {...props} key={JSON.stringify(props)}>
            {printableProps(props)}
          </Control>
        );
      })}
    </>
  );
});
