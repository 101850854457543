import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';

export const get_settings = async (
  args: get_settings.Args
): Promise<get_settings.Response> => {
  return await api({
    service_name: 'UserSleepioMetadata',
    service_version: '1',
    service_method: 'get_settings',
    args: args,
  } as get_settings.Request);
};

get_settings.queryKey = 'UserSleepioMetadata/get_settings';

export declare namespace get_settings {
  export type Args = {
    product_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'UserSleepioMetadata';
    service_version: '1';
    service_method: 'get_settings';
    args: Args;
  }

  export type Result = {
    account_expiry_utc: {
      $datetime: string;
    };
    email_address: string;
    subscribed_to_emails: boolean;
  };

  export type Response = APIResponse<Result>;
}
