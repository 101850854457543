/* eslint-disable camelcase */
// IDEA package up selection state into hook
// WHEN ever decide to reuse OR this file gets to big
// HOW onSelect, onSelectValue, onChange, useState into useResponseOptionField
import React, { ReactElement, PropsWithChildren } from 'react';
import { roles } from 'cross-platform/utils/roleProps';
import { Question, ResponseOption } from '@bighealth/types';
import { ResponseInputComponentType } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { InputsAsArray } from 'components/generic-question/InputsAsArray';
import { useResponseOptionField } from '../hooks/useField';
import { setSelection, toggleItem, addItem } from './utils/queue';
import { useQuestionPathState } from 'lib/question-response/useQuestionPathState';
import { QuestionPathProperties } from 'state/question-path/actions';
import { Value } from 'components/forms/types';
import { InputFocusProvider } from 'components/forms/ResponseOptions/providers/InputFocusProvider';
import { useLatestFocusedQuestion } from 'lib/question-response/useLatestFocusedQuestion';

export type ResponseInputProps = PropsWithChildren<{
  questionProps: Question;
  component: ResponseInputComponentType;
  warning?: string;
  highlight?: boolean;
  initialValue?: Value;
}>;

const ResponseInput = (props: ResponseInputProps): ReactElement => {
  const {
    field,
    meta,
    helpers,
    quizForQuestion,
    initialValue,
  } = useResponseOptionField(
    props.questionProps,
    props.component,
    props.initialValue
  );
  if (typeof props.questionProps.id !== 'number') {
    throw TypeError(
      `Expected questionId to be number: ${props.questionProps.id}`
    );
  }
  const { setLatestFocusedQuestion } = useLatestFocusedQuestion();
  const [selectedQueue, setSelectedQueue] = useQuestionPathState(
    props.questionProps.id,
    QuestionPathProperties.selectionQueue,
    []
  );
  // IDEA Don't check if quizCompleted via attemptsLeft, check using correctSelection
  // WHY by creating new variable, allows data to get out of sync
  // HOW ensure quizForQuestion.assessment.correctSelection updates when
  // tryForceComplete() calls submitQuizAnswers()
  const isQuizCompletedComplete =
    quizForQuestion.assessment?.attemptsLeft === 0;

  const onSelect = (selected?: ResponseOption): void => {
    if (isQuizCompletedComplete) {
      return;
    }
    const intendedSelection = field.value.filter(
      e => e.isSelected || e.id === selected?.id
    );
    const toggledItems = toggleItem(
      props.questionProps.response_config.max_selections_required,
      field.value,
      selectedQueue,
      field.value.find(item => item.id === selected?.id),
      intendedSelection.filter(e =>
        quizForQuestion.assessment?.correctSelection.includes(e.id as number)
      ),
      intendedSelection.filter(e =>
        quizForQuestion.assessment?.incorrectSelection.includes(e.id as number)
      )
    );
    setSelectedQueue(toggledItems.selectedQueue);
    helpers.setTouched(true);
    helpers.setValue(toggledItems.all);
    setLatestFocusedQuestion(props.questionProps.id);
  };
  return (
    <InputFocusProvider questionId={props.questionProps.id}>
      <InputsAsArray
        {...roles(`ResponseInput:${props.questionProps.semantic_id}`)}
        onSelect={onSelect}
        onSelectValue={(selectedValue: string | Date | null): void => {
          let maybeCastValue: typeof selectedValue | number = selectedValue;
          if (
            props.questionProps.response_type === 'number' &&
            // We're always cool with empty string
            selectedValue !== ''
          ) {
            maybeCastValue = Number(selectedValue);
          }

          if (isQuizCompletedComplete) {
            return;
          }
          // INFO Only for Dropdown (Html select / Native picker)
          const selectedOption = props.questionProps.response_config.response_options.find(
            option => option.value === maybeCastValue
          );
          const foundItem: DropdownItem | undefined = field.value.find(
            item => item?.id === selectedOption?.id
          );
          helpers.setTouched(true);
          helpers.setValue(
            setSelection(
              props.questionProps.response_config.max_selections_required,
              field.value,
              foundItem ? [foundItem] : undefined
            )
          );
          setLatestFocusedQuestion(props.questionProps.id);
        }}
        onSetValue={(
          option: ResponseOption,
          value = initialValue[0]?.value
        ): void => {
          if (isQuizCompletedComplete) {
            return;
          }
          const itemsWithValue = field.value.map(
            (item: DropdownItem): DropdownItem =>
              item.id === option.id ? { ...item, value } : item
          );
          helpers.setTouched(true);
          helpers.setValue(
            addItem(
              props.questionProps.response_config.max_selections_required,
              itemsWithValue,
              itemsWithValue.find(item => item.id === option?.id)
            )
          );
          setLatestFocusedQuestion(props.questionProps.id);
        }}
        onBlur={(): void => {
          helpers.setTouched(true);
        }}
        questionProps={props.questionProps}
        component={props.component}
        selection={field.value || initialValue}
        isQuizCompletedComplete={isQuizCompletedComplete}
        correctSelection={quizForQuestion.assessment?.correctSelection}
        incorrectSelection={quizForQuestion.assessment?.incorrectSelection}
        attempts={quizForQuestion?.attempts}
        error={
          meta.error ||
          (quizForQuestion?.assessment?.isAllCorrect === true
            ? undefined
            : typeof quizForQuestion?.assessment?.attemptsLeft !== 'undefined'
            ? `You have ${quizForQuestion?.assessment?.attemptsLeft} attempts left`
            : undefined)
        }
        warning={props.warning}
        highlight={props.highlight}
        isTouched={meta.touched}
      />
    </InputFocusProvider>
  );
};

export { ResponseInput };
