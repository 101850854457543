import { TogglableRefNode } from './types';
import { Scene } from '@bighealth/types/src/scene-component';
type ToggleVisibilityProps = Scene.Utils.ToggleVisibility;

import { ToggleVisibilityWithTimer } from './ToggleVisibilityWithTimer';

export class ToggleVisibilityOrchestrator {
  private playLock = false;
  private nodes: ToggleVisibilityWithTimer[] = [];

  registerOrUpdateNode = (node: TogglableRefNode): void => {
    this.pruneNodes();
    const existingNode = this.nodes.find(
      storedNode => storedNode.getNode() === node
    );

    if (existingNode) {
      existingNode.setProps(
        ToggleVisibilityWithTimer.getPropsForNode(node) as ToggleVisibilityProps
      );
    } else {
      const newTimerNode = new ToggleVisibilityWithTimer(node);
      this.nodes.push(newTimerNode);
      if (!this.playLock) {
        newTimerNode.play();
      }
    }
  };

  getNodes = (): ToggleVisibilityWithTimer[] => {
    this.pruneNodes();
    return this.nodes;
  };

  setPlayLock = (): void => {
    this.playLock = true;
    this.nodes.forEach(node => node?.setPlayLock());
  };

  releasePlayLock = (): void => {
    this.playLock = false;
    this.nodes.forEach(node => node?.releasePlayLock());
  };

  play = async (): Promise<void> => {
    if (this.playLock) {
      return;
    }

    this.pruneNodes();
    this.getNodes().forEach(node => {
      node?.play();
    });
  };

  pause = (): void => {
    this.pruneNodes();
    this.nodes.forEach(node => node?.pause());
  };

  private pruneNodes = (): void => {
    this.nodes = this.nodes.filter(node => !!node.getNode()?.current);
  };

  /**
   *
   * Always call teardown at the end of a SceneSet
   */
  teardown = (): void => {
    this.nodes.forEach(node => node.reset());
    this.nodes = [];
  };

  /**
   * ----------------------------------------
   * For use in testing only
   */

  getPlayLock = (): boolean => this.playLock;
}

export let toggleVisibilityOrchestrator = new ToggleVisibilityOrchestrator();

export const buildToggleVisibilityOrchestrator = (): ToggleVisibilityOrchestrator => {
  toggleVisibilityOrchestrator?.teardown();
  toggleVisibilityOrchestrator = new ToggleVisibilityOrchestrator();
  return toggleVisibilityOrchestrator;
};
