import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { APIErrorResponse } from '@bighealth/api';
import { get_all_downloads } from '@bighealth/api/SleepioDownload/v1';

export const useQueryAllDownloads = (): UseQueryResult<
  get_all_downloads.Response,
  APIErrorResponse
> => {
  const result = useQuery({
    queryKey: get_all_downloads.queryKey,
    queryFn: () => get_all_downloads(),
    cacheTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });
  return result;
};
