import React, { ReactElement } from 'react';
import { ImageBackground, StyleSheet, View } from 'react-native';
import { roles } from 'cross-platform/utils/roleProps';
import { HEIGHT_PRACTICE_LIBRARY_CARD } from 'daylight/config';

import { CircleLockSvg } from 'daylight/components/CircleLockSvg';
import { useGetSvgDimensions } from 'daylight/components/ResponsiveLayout/useGetSvgDimensions';
import { useTransformContentStyle } from 'daylight/components/ResponsiveLayout/useTransformContentStyle';
import { PlayButton } from 'daylight/components/Screens/HomeScreen/PlayButton';
import { PracticeItemPayload } from 'daylight/lib/api/types/apiResponses';

// TODO: move the Text from the sceneset-components to the components folder as a shared folder
// WHEN: during Daylight Rebuild
// WHY: we are using the Scene Component Text because can be returned by the backend
import { Text } from 'components/sceneset-components/Text';

import { Formik } from 'formik';
import { noOpFunction } from 'lib/noOpFunction';

export const PracticeItem = ({
  practiceItem,
}: {
  practiceItem: PracticeItemPayload;
}): ReactElement => {
  const transformStyle = useTransformContentStyle();

  const srcBackgroundImage =
    practiceItem.preview_asset?.sourceProps.storage_url;

  // TODO: add a function to flatten the styles
  const titleStyle = Array.isArray(practiceItem.title.style)
    ? StyleSheet.flatten(practiceItem.title.style)
    : practiceItem.title.style;

  const subtitleStyle = Array.isArray(practiceItem.subtitle.style)
    ? StyleSheet.flatten(practiceItem.subtitle.style)
    : practiceItem.subtitle.style;

  const labelStyle = Array.isArray(practiceItem.lengthLabel.style)
    ? StyleSheet.flatten(practiceItem.lengthLabel.style)
    : practiceItem.lengthLabel.style;

  const dimensionProps = useGetSvgDimensions({
    width: transformStyle(62),
    height: transformStyle(62),
  });

  return (
    <View
      {...roles('practiceItem')}
      style={{
        height: transformStyle(HEIGHT_PRACTICE_LIBRARY_CARD),
        marginBottom: transformStyle(12),
        opacity: practiceItem.button ? 1 : 0.2,
      }}
    >
      <View
        style={{
          height: '65%',
          backgroundColor: '#FFBBBB',
          borderTopLeftRadius: 9,
          borderTopRightRadius: 9,
        }}
      >
        <ImageBackground
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            paddingBottom: transformStyle(21),
            paddingLeft: transformStyle(24),
            borderTopLeftRadius: 9,
            borderTopRightRadius: 9,
          }}
          imageStyle={{ borderTopLeftRadius: 9, borderTopRightRadius: 9 }}
          resizeMode={'cover'}
          source={{ uri: srcBackgroundImage }}
        >
          <View
            style={{
              width: transformStyle(62),
              alignItems: 'center',
            }}
          >
            <Text
              text={practiceItem.lengthLabel.text}
              style={{
                ...labelStyle,
                fontSize: transformStyle(labelStyle.fontSize || 14),
              }}
            />
          </View>
        </ImageBackground>
      </View>

      <View
        style={{
          height: '35%',
          backgroundColor: 'white',
          paddingTop: 16,
          paddingLeft: 24,
          borderBottomLeftRadius: 9,
          borderBottomRightRadius: 9,
        }}
      >
        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: '75%' }}>
            <Text
              text={practiceItem.title.text}
              style={{
                ...titleStyle,
                fontSize: transformStyle(titleStyle.fontSize || 21),
              }}
            />
            <Text
              text={practiceItem.subtitle.text}
              style={{
                ...subtitleStyle,
                fontSize: transformStyle(subtitleStyle.fontSize || 16),
              }}
            />
          </View>
          <View style={{ width: '25%' }}>
            {practiceItem.button ? (
              <Formik initialValues={[]} onSubmit={noOpFunction}>
                <PlayButton
                  {...practiceItem.button}
                  style={{
                    ...(Array.isArray(practiceItem.button?.style)
                      ? StyleSheet.flatten(practiceItem.button?.style)
                      : practiceItem.button?.style),
                    width: transformStyle(62),
                    height: transformStyle(62),
                  }}
                  onPress={noOpFunction}
                />
              </Formik>
            ) : (
              <CircleLockSvg
                {...dimensionProps}
                circleColor={'#000000'}
                lockColor={'#ffffff'}
              />
            )}
          </View>
        </View>
      </View>
    </View>
  );
};
