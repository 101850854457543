import * as React from 'react';
import styled from 'styled-components/native';
import { COPYRIGHT_TEXT } from 'lib/strings';
import { getTheme } from 'lib/styles/getTheme';
import { useSleepioVersion } from './hooks/useSleepioVersion';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import { Text as TextUnstyled } from 'components/sceneset-components/Text';
import { ScreenResponsiveLayoutContext } from 'components/ResponsiveLayout';
import { useNavigationBarContext } from 'components/Navigation/providers/NavigationBarProvider';

export enum FooterTheme {
  Dark = 'dark',
  Light = 'light',
}

type FooterState = {
  footerTheme?: FooterTheme;
};

const DOMAIN = 'Footer';

const Text = styled(TextUnstyled)`
  font-size: 20px;
  align-items: center;
  color: ${getTheme('footer[footerTheme].color')};
`;
Text.displayName = `${DOMAIN}.Text`;

const Wrapper = styled.View<WrapperProps>`
  width: 100%;
  height: 71px;
  padding-left: 48px;
  padding-right: 48px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${getTheme('footer[footerTheme].backgroundColor')};
`;
Wrapper.displayName = `${DOMAIN}.Wrapper`;

type FooterProps = React.ComponentProps<typeof Wrapper> &
  RoleProps &
  FooterState;
type WrapperProps = { color: string; footerTheme: string };

export const GenericFooter = (
  props: FooterProps
): React.ReactElement | null => {
  const { footerTheme = FooterTheme.Light } = props;
  const sleepioVersion = useSleepioVersion();
  const { isCollapsed } = useNavigationBarContext();
  const { screenWidth } = React.useContext(ScreenResponsiveLayoutContext);

  if (isCollapsed(screenWidth)) {
    return null;
  }

  return (
    <Wrapper
      {...props}
      footerTheme={footerTheme}
      {...roles.pass(props, DOMAIN)}
    >
      <Text text={sleepioVersion} footerTheme={footerTheme} />
      <Text text={COPYRIGHT_TEXT} footerTheme={footerTheme} />
    </Wrapper>
  );
};
