/* eslint-disable camelcase */
import { useReducer, useCallback } from 'react';
import { APIRequestBody } from '@bighealth/api';
import { PartialBy } from '@bighealth/types';
import { reducer, UseServiceCallState } from './reducer';
import { api } from 'lib/api';
import { fetching, error, success } from './actions';
import { ActionTypes } from './ActionTypes';
import { mergeRight } from 'ramda';

export type UseServiceMethodCallFetcher = (
  args?: APIRequestBody['args']
) => Promise<void>;

export type UseServiceMethodCall = (
  apiRequest: APIRequestBodyCamelCase
) => [UseServiceCallState, UseServiceMethodCallFetcher];

export type APIRequestBodyCamelCase = {
  serviceName: APIRequestBody['service_name'];
  serviceVersion: APIRequestBody['service_version'];
  serviceMethod: APIRequestBody['service_method'];
  args: APIRequestBody['args'];
};
const useServiceMethodCall: UseServiceMethodCall = ({
  serviceName: service_name,
  serviceVersion: service_version,
  serviceMethod: service_method,
  args,
}) => {
  const apiRequest: PartialBy<APIRequestBody, 'args'> = {
    service_name,
    service_version,
    service_method,
    args,
  };
  const [state, dispatch] = useReducer(reducer, { status: ActionTypes.IDLE });

  const makeServiceMethodCall: UseServiceMethodCallFetcher = useCallback(
    async args => {
      dispatch(fetching());
      try {
        const response = await api(
          mergeRight(apiRequest, { args }) as APIRequestBody
        );
        if (response.status_code >= 400) {
          throw response;
        }
        dispatch(success(response));
      } catch (e) {
        dispatch(error(e));
      }
    },
    [apiRequest]
  );

  return [state, makeServiceMethodCall];
};

export { useServiceMethodCall };
