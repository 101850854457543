import { FacebookAuthResponseWeb } from './handleFacebookSignin';

type HandleGetLoginStatusResponse = <T extends FacebookAuthResponseWeb>(
  response: T,
  onSuccess: (value: T) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFail?: (reason: any) => void
) => void; // Allow "any" for Promise-like API

export const handleGetLoginStatusResponse: HandleGetLoginStatusResponse = (
  response,
  onSuccess
) => {
  onSuccess(response);
};

export const handleLoginResponse: HandleGetLoginStatusResponse = (
  response,
  onSuccess
) => {
  onSuccess(response);
};
