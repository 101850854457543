import { SceneSetResponse } from '@bighealth/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';

export const get_interpolated_scene_set_with_id = async (
  args: get_interpolated_scene_set_with_id.Args
): Promise<get_interpolated_scene_set_with_id.Response> => {
  return await api({
    service_name: 'SceneSet',
    service_version: '1',
    service_method: 'get_interpolated_scene_set_with_id',
    args: args,
  } as get_interpolated_scene_set_with_id.Request);
};

get_interpolated_scene_set_with_id.queryKey =
  'SceneSet/get_interpolated_scene_set_with_id';

export declare namespace get_interpolated_scene_set_with_id {
  export type Args = { id: number };

  export interface Request extends APIRequestBody {
    service_name: 'SceneSet';
    service_version: '1';
    service_method: 'get_interpolated_scene_set_with_id';
    args: Args;
  }

  export type Result = SceneSetResponse;

  export type Response = APIResponse<Result>;
}
