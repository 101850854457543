import React, { ReactElement, ReactNode } from 'react';
import { Container } from '@bighealth/types/src/scene-components/client';
import { roles } from 'cross-platform/utils/roleProps';
import { useTransformStylesToContext } from 'components/ResponsiveLayout';
import { View, ViewStyle } from 'react-native';

export const GenericContainer = (
  props: Container & { children: ReactNode }
): ReactElement => {
  const scaleValues = useTransformStylesToContext();
  const scaledStyles = scaleValues(props.style || {});
  return (
    <View
      {...roles('GenericContainer')}
      pointerEvents={'box-none'}
      style={(scaledStyles as unknown) as ViewStyle}
    >
      {props.children}
    </View>
  );
};
