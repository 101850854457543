import { MediaContainer as MediaContainerType } from '@bighealth/types/src/scene-components/client';
import React, { ReactElement } from 'react';
import { MediaContainer } from 'components/ResponsiveLayout/components/MediaContainer';

const SceneSetMediaContainer = (props: MediaContainerType): ReactElement => {
  const { mediaProps, ...rest } = props;
  return (
    <MediaContainer
      {...rest}
      style={{ height: mediaProps.height, width: mediaProps.width }}
    />
  );
};

export default SceneSetMediaContainer;
