import React, { ReactElement } from 'react';
import { CommonPropType } from '../index.story';
import { DropdownDurationPicker } from '.';

export const DropdownDurationPickerStory = (
  props: CommonPropType
): ReactElement => (
  <DropdownDurationPicker
    onValueChange={console.log}
    highlight={props.highlight}
  />
);
