import React, { ReactElement } from 'react';
import { Video } from 'components/Media';
import { Video as VideoType } from '@bighealth/types/src/scene-components/client';
import { getIsAssetContinuous } from 'lib/player/assetContinuity';
import { SceneComponentWithParent } from 'lib/player/tree-utils';
import { WithBackupFormikContext } from 'components/WithBackupFormikContext';

const SceneSetVideoWithoutFallbackFormikContext = (
  props: VideoType
): ReactElement => {
  const { sourceProps, fromSeconds, toSeconds, delaySeconds, ...rest } = props;
  const src = sourceProps?.storage_url || '';
  const isContinuous = getIsAssetContinuous(
    (props as unknown) as SceneComponentWithParent
  );
  return (
    <Video
      {...rest}
      src={src}
      from={fromSeconds || 0}
      to={toSeconds || sourceProps.duration || 0}
      delay={delaySeconds || 0}
      isContinuous={isContinuous}
    />
  );
};

const SceneSetVideo = (props: VideoType): ReactElement => {
  return WithBackupFormikContext(
    props,
    SceneSetVideoWithoutFallbackFormikContext
  );
};

export default SceneSetVideo;
