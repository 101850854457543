import { HOUR } from 'lib/durations';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { QuestionId } from 'state/question-response/actions';
import { getSelected } from '../../helpers/getSelected';
import { Config } from '..';
import {
  TIME_FINAL_AWAKENING,
  TIME_GET_OUT_OF_BED,
} from 'components/SleepDiaryForm/constants';
import { createQuestionProps } from './helpers/createQuestionProps';
/**
 *
 * @param {Record<QuestionId, DropdownItem[]>} inputs from `<ResponseForm onValidate={inputs => {}} />`
 */
const getTimeOutOfBedConfigFromFinalAwakening = (
  inputs: Record<QuestionId, DropdownItem[]>
): Record<QuestionId, Config> | null => {
  const finalAwakening = getSelected(inputs[TIME_FINAL_AWAKENING])?.valueOf();
  if (typeof finalAwakening !== 'number') {
    return null;
  }
  const min = finalAwakening;
  const max = finalAwakening + 12 * HOUR;
  return {
    [TIME_GET_OUT_OF_BED]: createQuestionProps(min, max),
  };
};

export { getTimeOutOfBedConfigFromFinalAwakening };
