import { getQueryClient } from 'components/ProvidersContainer';
import { jump_to_specific_scene_set } from '@bighealth/api/SceneSetGraph/v1';
import { getWhichDeveloperSceneSet } from 'developer/helpers';

export const queryClientJumpToSceneSet = async (
  args: jump_to_specific_scene_set.Args
): Promise<jump_to_specific_scene_set.Response> => {
  const developerSceneSet = getWhichDeveloperSceneSet(
    args.current_graph_id,
    args.destination_scene_set_id
  );
  let result;
  if (developerSceneSet) {
    result = {
      status_code: 200,
      result: {
        id: args.destination_scene_set_id as number,
        reference: `${args.destination_scene_set_id}`,
        scene_set_json: developerSceneSet,
      },
    };
  } else {
    result = await getQueryClient().fetchQuery(
      // "-2" here is an arbitrary fallback. We could have chosen any number - or none at all - but picking a value
      // like this makes it clear in the react-query logs that something strange is happening
      ['SceneSet', args.destination_scene_set_id || -2],
      () => jump_to_specific_scene_set(args),
      {
        cacheTime: Infinity,
      }
    );
  }
  await getQueryClient().setQueryData(['SceneSet', result.result?.id], result);
  return result;
};
