import styled from 'styled-components/native';
import { Text } from 'components/primitives/cross-platform';
import { FillAbsolute } from 'components/Containers';

export const Box = styled.View`
  display: flex;
  min-width: 800px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 30px;
  padding-left: 30px;
  background-color: white;
  flex-grow: 1;
`;

export const Container = styled(FillAbsolute)`
  align-items: center;
  background-color: #003366;
`;

export const Heading = styled(Text)`
  font-size: 20px;
`;
