import { useQueryJumpToSceneSet } from './useQueryJumpToSceneSet';
import { useQuerySceneSetGraphsBulk } from './useQuerySceneSetGraphsBulk';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { useEffect, useMemo } from 'react';
import { setPlayerStatusReady } from 'state/player/actions';
import { useDispatch } from 'react-redux';
import { SceneSet } from '@bighealth/types';
import { useQueryDownloadAssetsForSceneSet } from './useQueryDownloadAssetsForSceneSet';

type QueryState =
  | {
      isLoading: true;
      isSuccess: false;
      isError: false;
      error: undefined;
    }
  | {
      isLoading: false;
      isSuccess: true;
      isError: false;
      error: undefined;
    }
  | {
      isLoading: false;
      isSuccess: false;
      isError: true;
      error: string;
    };

export type QueryAllDateForSceneSetResponse = QueryState & {
  data: {
    status_code: 200 | 500;
    result: {
      sceneSet: SceneSet;
    } | null;
  };
};

export const useQueryAllDataForSceneSet = (): QueryAllDateForSceneSetResponse => {
  const { sceneSetId } = useSafeParams();
  const dispatch = useDispatch();
  const sceneSetResponse = useQueryJumpToSceneSet(sceneSetId);
  const sceneSetGraphResponse = useQuerySceneSetGraphsBulk();
  const sceneSetJSON = sceneSetResponse.data?.result?.scene_set_json;
  const downloadResponse = useQueryDownloadAssetsForSceneSet({
    sceneSetJSON,
    enabled: !!sceneSetJSON,
  });
  const isSuccess =
    sceneSetGraphResponse.isSuccess &&
    sceneSetResponse.isSuccess &&
    downloadResponse.isSuccess;
  const isLoading =
    sceneSetGraphResponse.isLoading ||
    sceneSetResponse.isLoading ||
    downloadResponse.isLoading;
  const isError =
    sceneSetGraphResponse.isError ||
    sceneSetResponse.isError ||
    downloadResponse.isError;
  useEffect(() => {
    if (isSuccess) {
      dispatch(setPlayerStatusReady());
      return;
    }
  }, [dispatch, isSuccess]);
  return useMemo(
    () => ({
      isLoading,
      isSuccess,
      isError,
      error: isError
        ? 'Something went wrong downloading SceneSet data and assets'
        : undefined,
      data: sceneSetResponse.data
        ? {
            status_code: 200, // We're approximating a response here. It doesn't matter which currently
            result: {
              sceneSet: sceneSetJSON as SceneSet,
            },
          }
        : undefined,
    }),
    [isError, isLoading, isSuccess, sceneSetJSON, sceneSetResponse.data]
  ) as QueryAllDateForSceneSetResponse;
};
