import { createStore, Reducer, applyMiddleware } from 'redux';
import rootReducer from './rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  persistStore,
  persistReducer as newPersistReducer,
} from 'redux-persist';
import { rehydrateMiddleware } from './persistMiddleware';
import { persistConfig } from './persist/config';

const persistReducer = newPersistReducer<typeof rootReducer>(
  persistConfig,
  (rootReducer as unknown) as Reducer<typeof rootReducer>
);

export const store = createStore(
  persistReducer,
  composeWithDevTools(applyMiddleware(rehydrateMiddleware))
);
export const persistor = persistStore(store);
