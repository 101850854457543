import { Alert } from 'react-native';
import { DEBUG_SHOW_DEV_CONTENT_SELECTOR } from 'config/envVars';
import {
  DEVELOPER_AV_SCENESET_ID,
  DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID,
  DEVELOPER_COMPONENTS_SCENESET_ID,
  DEVELOPER_RESPONSIVE_SCENESET_ID,
} from 'developer/constants';
import { nativeCrash, javascriptCrash } from 'lib/crashReporter';

import { History, LocationState } from 'history';
import {
  setIsDebugThrowInSceneSet,
  getIsDebugThrowInSceneSet,
} from 'config/index';

const showQAMenu = (
  productReference: string,
  history: History<LocationState>
): void => {
  if (DEBUG_SHOW_DEV_CONTENT_SELECTOR) {
    Alert.alert(
      'Developer Menu',
      'Enable/Disable using Staff Web of current environment',
      [
        ...[undefined, 1, 2, 3].map(number => ({
          text: `Developer Components Test #${number || 'first'}`,
          onPress: (): void =>
            history.push(
              `/${productReference}/${DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID}/${DEVELOPER_COMPONENTS_SCENESET_ID}${
                number ? `/${number}` : ''
              }`
            ),
        })),
        {
          text: 'Responsive Layout Test',
          onPress: (): void =>
            history.push(
              `/${productReference}/${DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID}/${DEVELOPER_RESPONSIVE_SCENESET_ID}`
            ),
        },
        {
          text: 'AV Test',
          onPress: (): void =>
            history.push(
              `/${productReference}/${DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID}/${DEVELOPER_AV_SCENESET_ID}`
            ),
        },
        {
          text: 'Storybook',
          onPress: (): void =>
            history.push(
              `/${productReference}/${DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID}/storybook`
            ),
        },
        {
          text: 'QA',
          onPress: (): void => history.push(`/${productReference}/qa`),
        },
        {
          text: `${
            getIsDebugThrowInSceneSet() ? 'Disable' : 'Enable'
          } Throw in SceneSet button`,
          onPress: (): void => {
            setIsDebugThrowInSceneSet(!getIsDebugThrowInSceneSet());
          },
        },
        {
          text: 'goto',
          onPress: (): void => history.push(`/${productReference}/qa-ssg-ss`),
        },
        {
          text: 'Old menu',
          onPress: (): void => history.push(`/${productReference}/menu`),
        },
        {
          text: 'Trigger native app crash',
          onPress: (): void => nativeCrash(),
        },
        {
          text: 'Trigger JS App crash',
          onPress: (): void => javascriptCrash(),
        },
        {
          text: 'close menu',
          onPress: (): void => undefined,
        },
      ]
    );
  }
};

export { showQAMenu };
