import React, { ReactElement } from 'react';
import { OnAuthCallback } from 'components/AuthButtons';
import { FACEBOOK_APP_ID } from 'config/envVars';
import {
  handleFacebookSignInWeb,
  useInitializeFacebookSDK,
} from 'lib/auth/facebook/web';
import {
  UniversalButton,
  ButtonSizes,
  ButtonColorSchemes,
} from 'components/UniveralButtons';
import { roles } from 'cross-platform/utils/roleProps';

const FacebookSignInButtonWeb = ({
  onAuth,
}: {
  onAuth: OnAuthCallback;
}): ReactElement => {
  useInitializeFacebookSDK({
    appId: FACEBOOK_APP_ID,
  });
  const handleClick = async (): Promise<void> => {
    await handleFacebookSignInWeb(onAuth);
  };
  return (
    <UniversalButton
      {...roles('Facebook-signInButton')}
      text={'Login with Facebook'}
      onPress={handleClick}
      colorScheme={ButtonColorSchemes.Facebook}
      size={ButtonSizes.Small}
      isDisabled={false}
    />
  );
};

export default FacebookSignInButtonWeb;
