import React, { ReactElement, useState } from 'react';
import { addStory } from 'lib/story-books';
import { ResponseOption } from '@bighealth/types';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { ProductReferences } from 'common/constants/enums';
import { CrossPlatformThemeProvider } from 'components/ProvidersContainer/ProductThemeProvider';
import { EphemeralStateProvider } from 'components/EphemeralStateProvider';
import { Content } from 'components/layout/Content';
import { Form, SleepDiaryOptionalProps } from '..';
import { payload } from '.';
import { useFakeStorybookSceneSet } from 'lib/story-books/useFakeStorybookSceneSet';

export const SleepDiaryWithDefaults = ({
  onSubmit,
  onFormClose,
}: SleepDiaryOptionalProps): ReactElement | null => {
  const [submitted, setSubmitted] = useState<Record<string, ResponseOption[]>>(
    {}
  );
  const sceneSets = useFakeStorybookSceneSet();
  if (!sceneSets) {
    return null;
  }
  return (
    <>
      <Form
        {...payload}
        onSubmit={(values: Record<React.ReactText, ResponseOption[]>): void => {
          setSubmitted(values);
          onSubmit?.(values);
        }}
        onClose={onFormClose}
      />
      <Content mono>{JSON.stringify(submitted, null, 2)}</Content>
    </>
  );
};

export const SleepDiaryStory = ({
  onSubmit,
  onFormClose,
}: SleepDiaryOptionalProps): ReactElement => (
  <EphemeralStateProvider>
    <CrossPlatformThemeProvider
      theme={getThemeForProduct(ProductReferences.SLEEPIO)}
    >
      <SleepDiaryWithDefaults onSubmit={onSubmit} onFormClose={onFormClose} />
    </CrossPlatformThemeProvider>
  </EphemeralStateProvider>
);
addStory('sleep-diary/Form WithDefaults', () => <SleepDiaryStory />);
