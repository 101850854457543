import { useEffect, useRef } from 'react';
import { TogglableRefNode } from './types';
import { toggleVisibilityOrchestrator } from './ToggleVisibilityOrchestrator';
import { ToggleableVisibility } from './ToggleableVisibility';

/**
 * Attach this to any ToggleableVisibility you want to control with the orchestrator
 */

export const useToggleVisibilityOrchestrator = (): TogglableRefNode => {
  const node = useRef<ToggleableVisibility | null>(null);
  useEffect(() => {
    // Yes, we definitely want this every render or we won't get the new props set for each
    // node in the orchestrator
    toggleVisibilityOrchestrator.registerOrUpdateNode(node);
  });
  return node;
};
