import {
  AnalyticsContextReturnType,
  ScreenResourceEvent,
} from '@bighealth/analytics';
import { getAnalyticsDate } from './getAnalyticsDate';
import { getUserAgent } from './getUserAgent';
import { URLLike } from 'lib/api/analytics/useGetURL';

export const sendSessionAnalytics = async ({
  programId,
  productId,
  sceneSetGraphId,
  sceneSetId,
  sceneId,
  analytics,
  url,
}: {
  programId: number;
  productId: number;
  sceneSetGraphId: number;
  sceneSetId: number;
  sceneId: number;
  analytics: AnalyticsContextReturnType;
  url: URLLike;
}): Promise<void> => {
  analytics.resetPayload();
  const userAgent = await getUserAgent();
  const typedPayload: ScreenResourceEvent = {
    occurred_at: getAnalyticsDate(),
    product_id: productId as number,
    program_id: programId as number,
    action: 'viewed',
    name: 'screen|viewed|therapy',
    resource: 'screen',
    type: 'ui',
    body: {
      fields: {
        feature: {
          area: 'therapy',
          section: 'therapy',
        },
        route: url.pathname,
      },
      relations: {
        scene_set_graph: {
          id: sceneSetGraphId,
        },
        scene_set: {
          id: sceneSetId as number,
        },
        scene: {
          id: sceneId || 0,
        },
      },
      meta: {
        http: {
          current_url: url.href,
          referrer: url.href,
          user_agent: userAgent,
        },
      },
    },
  };
  analytics.enhancePayload(typedPayload);
  return await analytics.sendAnalytics();
};
