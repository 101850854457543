import React, {
  ComponentProps,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useFormikContext } from 'formik';
import { Question, ResponseOption } from '@bighealth/types/src/models';
import { SleepDiaryFormProps } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';
import { roles, RoleProps } from 'cross-platform/utils/roleProps';
import { Errors, OnValidateCallback } from 'components/forms';
import { ResponseForm } from 'components/forms/ResponseOptions/ResponseForm';
import {
  ResponseInput,
  ResponseInputProps,
} from 'components/forms/ResponseOptions/ResponseInput';
import { ButtonContainer, Wrapper } from './styled';
import {
  SectionWrapper,
  Button,
  DateLabel,
  Heading,
  SectionHeading,
} from '../../styled';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { QuestionId } from 'state/question-response/actions';
import { getFlowState } from '../FlowingForm/helpers/getFlowState';
import { mergeDeepAndByIndex } from 'lib/utils/mergeDeepAndByIndex';
import { useSleepDiaryActionHandlerFactory } from 'components/SleepDiaryForm/hooks/useSleepDiaryActionHandlerFactory';
import { transformBySemanticId } from './helpers/transformBySemanticId';
import { castResponseOptionByResponseType } from 'components/forms/ResponseOptions/ResponseForm/utils/castResponseOptionByResponseType';
import { castDropdownItemsByResponseType } from 'components/forms/ResponseOptions/ResponseForm/utils/castDropdownItemsByResponseType';
import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { ButtonSizes, UniversalButton } from 'components/UniveralButtons';
import { useSubmitCallbackContext } from '../NetworkedFlowingForm/providers/SubmitCallbackProvider';

export type SleepDiaryOptionalProps = {
  onSubmit?: (
    values: Record<React.ReactText, ResponseOption[]>,
    onSuccess?: () => void
  ) => void;
  onFormClose?: () => void;
};

type SectionGroupType = PropsWithChildren<{
  data: ResponseInputProps[];
  fieldProps: ReturnType<typeof getFlowState>;
}>;

const SectionGroup = ({ data, fieldProps }: SectionGroupType): ReactElement => (
  <>
    {data.map((el, i) => {
      if (el?.questionProps && el?.component) {
        const fieldProp = fieldProps[el.questionProps.semantic_id];
        const questionProps = fieldProp?.questionProps
          ? (mergeDeepAndByIndex(
              el.questionProps,
              fieldProp?.questionProps || {}
            ) as Question)
          : el.questionProps;
        return (
          <ResponseInput
            key={
              // IDEA Remove String() call below, WHEN id is non-optional
              el.questionProps.semantic_id + String(el.questionProps.id) + i
            }
            warning={fieldProp?.warning}
            highlight={fieldProp?.highlight}
            initialValue={fieldProp?.initialValue}
            component={el.component}
            questionProps={transformBySemanticId(questionProps)}
          />
        );
      }
      return null;
    })}
  </>
);

type ButtonFactoryType = {
  buttonProps: ComponentProps<typeof Button>;
  onPress: () => void;
};

type FormTypes = SleepDiaryFormProps &
  SleepDiaryOptionalProps &
  RoleProps &
  Partial<{
    onValidate: OnValidateCallback;
    fieldProps: ReturnType<typeof getFlowState>; // IDEA make ResponseInput type?
  }>;

const isSubmitAction = (actionType: string) => {
  return (
    actionType === 'sleep-diary/SUBMIT_AND_CONTINUE' ||
    actionType === 'sleep-diary/SUBMIT_AND_MODAL_CLOSE'
  );
};

/**
 * Creates a button from the button config passed in.
 *
 * The key difference between the buttons is that there are submit buttons and
 * non-submit buttons. Submit buttons have some extra functionality that need
 * to be handled.
 */
const ButtonFactory = ({
  buttonProps,
  onPress,
}: ButtonFactoryType): ReactElement => {
  const {
    submitState,
    isSubmitting,
    setSubmitState,
  } = useSubmitCallbackContext();
  const {
    isValid,
    submitForm,
    isSubmitting: isFormikSubmitting,
  } = useFormikContext();
  const isSubmitButton = isSubmitAction(buttonProps.action?.type);
  const submitAction = isSubmitButton ? submitForm : onPress;

  // FIXME: remove
  // WHEN: the submission of the form can be controlled
  // because now the form submission cannot be awaited
  // WHY: this code can cause infinite loops if onPress
  // is different on every re-render
  useEffect(() => {
    if (isSubmitButton && submitState.onSubmitDone !== onPress) {
      setSubmitState({ onSubmitDone: onPress });
    }
  }, [isSubmitButton, onPress, setSubmitState, submitState.onSubmitDone]);

  const isDisabled =
    isSubmitButton && (!isValid || isFormikSubmitting || isSubmitting);
  const buttonRole = isDisabled
    ? `${buttonProps.text}-disabled`
    : `${buttonProps.text}`;

  return (
    <UniversalButton
      {...buttonProps}
      {...roles(buttonRole)}
      onPress={submitAction}
      isDisabled={isDisabled}
      text={buttonProps.text}
      size={ButtonSizes.Small}
    />
  );
};

const CloseButton = ({
  buttonProps,
  onClose,
}: ButtonFactoryType['buttonProps'] & { onClose?: () => void }) => {
  const [closeForm, setCloseForm] = useState(false);
  useEffect(() => {
    if (closeForm && typeof onClose === 'function') {
      onClose();
    }
  }, [closeForm, onClose]);

  const closeFormCallback = useCallback(() => {
    setCloseForm(true);
  }, []);

  const sleepDiaryActionHandlerPartial = useSleepDiaryActionHandlerFactory({
    closeForm: closeFormCallback,
  });
  return (
    <ButtonFactory
      buttonProps={buttonProps}
      onPress={sleepDiaryActionHandlerPartial(buttonProps.action) as () => void}
    />
  );
};

/**
 * Just the form
 * @param props
 */
export const Form = (
  props: FormTypes & { onClose?: () => void }
): ReactElement => {
  const { onClose } = props;

  const initialAccumulator: Question[] = [];
  const questions = props.form.sections.reduce(
    (
      accumulator, // Previous value used for accumulation
      section // Current section
    ) => [
      ...accumulator,
      ...section.group.map(({ questionProps }) => questionProps),
    ],
    initialAccumulator
  );

  const styles = useGetDynamicContentStyles();

  return (
    <Wrapper>
      {typeof props.date_label !== 'undefined' ? (
        <DateLabel {...props.date_label} style={{ color: '#005270' }} />
      ) : null}
      <Heading
        {...props.form.heading}
        style={{
          color: '#005270',
          fontSize: styles.sleepDiaryFormHeadingFontSize,
        }}
      />
      <ResponseForm
        onSubmit={values => {
          props.onSubmit?.(castResponseOptionByResponseType(values, questions));
        }}
        onValidate={(items: Record<QuestionId, DropdownItem[]>): Errors =>
          props?.onValidate?.(
            castDropdownItemsByResponseType(items, questions)
          ) || {}
        }
        triggerValidateHash={Object.entries(props?.fieldProps || {})
          .map(([questionId, val]) => `${questionId}: ${val?.hidden}`)
          .join(', ')}
      >
        {props.form.sections.map(section => (
          <SectionWrapper key={section.heading.text}>
            <SectionHeading {...section.heading} style={{ color: '#00506b' }} />
            <SectionGroup
              data={section.group.filter(
                responseInput =>
                  props?.fieldProps?.[responseInput.questionProps.semantic_id]
                    ?.hidden !== true
              )}
              fieldProps={props.fieldProps || {}}
            />
          </SectionWrapper>
        ))}
        {props.form.buttons.map((button, i) => (
          <ButtonContainer key={i}>
            <CloseButton buttonProps={button} onClose={onClose} />
          </ButtonContainer>
        ))}
      </ResponseForm>
    </Wrapper>
  );
};
