// NOTE snake_case used to avoid aliasing a method named in backend code
import { SleepDiaryPayloads } from '@bighealth/types/src/services/SleepDiaryPayloads';
import { RequestArgs } from '@bighealth/types/src/services/SleepDiaryPayloads/get_sleep_diary_component_data_for_time_period';

const get_sleep_diary_component_data_for_time_period = (
  args: RequestArgs
): SleepDiaryPayloads['get_sleep_diary_component_data_for_time_period']['request'] => {
  return {
    service_name: 'SleepDiary',
    service_version: '1',
    service_method: 'get_sleep_diary_component_data_for_time_period',
    args,
  };
};

export { get_sleep_diary_component_data_for_time_period };
