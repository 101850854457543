import { useCallback } from 'react';
import useActionHandler from 'lib/player/useActionHandler';
import {
  createHandler,
  SleepDiaryToSceneHandler,
} from '../helpers/createHandler';

type useSleepDiaryActionHandlerFactoryType = (actions: {
  closeForm?: () => void;
}) => SleepDiaryToSceneHandler;

/**
 * Creates the composite set of actions required by the SleepDiaryForm by grouping individual effects
 *
 * The (semantic) set of actions are:
 *
 *  - Close (to close the modally presented SleepDiary Form
 *  - Close and Continue (to close the modally presented SleepDiary Form and continue to the next SceneSet
 *  - Submit and close (to submit a diary and close the modally presented SleepDiary Form and return to the presenting compontent)
 *  - Submit and continue (to submit a diary and continue to the next SceneSet)
 *
 * Passes the created composite actions to createHandler, which returns a function taking an action identifier
 *
 */
const useSleepDiaryActionHandlerFactory: useSleepDiaryActionHandlerFactoryType = (callbacks: {
  closeForm?: () => void;
}) => {
  const { closeForm } = callbacks;
  // Ability to go to next SceneSet
  const nextSceneSetHandler = useActionHandler({ type: 'next' });
  const closeInputFormHandler = useCallback(() => {
    if (typeof closeForm === 'function') {
      closeForm();
    }
  }, [closeForm]);

  const submitSleepDiaryFormAndCloseInputFormHandler = useCallback(() => {
    if (typeof closeForm === 'function') {
      closeForm();
    }
  }, [closeForm]);

  const submitSleepDiaryFormAndGoToNextSceneSet = useCallback(() => {
    if (typeof nextSceneSetHandler === 'function') {
      nextSceneSetHandler();
    }
  }, [nextSceneSetHandler]);

  // pass action
  return createHandler({
    closeInputFormHandler,
    submitSleepDiaryFormAndCloseInputFormHandler,
    submitSleepDiaryFormAndGoToNextSceneSet,
  });
};

export { useSleepDiaryActionHandlerFactory };
