import { useHistory } from 'cross-platform/react-router';
import getPathForParams from 'lib/player/getPathForParams';
import { useSelector } from 'react-redux';
import { getQuestionResponseToSubmit } from 'state/question-response/selectors';
import { QUIZ_FAILED_THROWABLE } from 'components/forms/ResponseOptions/ResponseForm';
import { useCallback, useEffect, useRef } from 'react';
import { SceneActionTypes } from '@bighealth/types/dist/enums';
import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';
import { useSubmitOnActionHandler } from './useSubmitOnActionHandler';
import { useNavigateToJumpedSceneSet } from 'lib/player/useNavigateToJumpedSceneSet';
import { stringify } from 'lib/stringify';
import logger from 'lib/logger';
import { useGoTo } from 'lib/api/hooks/useGoTo';
import {
  useLazyQueryJumpToSceneSetWithAssets,
  useLazyQueryTransitionSceneSetWithAssets,
  useQueryProduct,
  useQueryProgram,
} from 'lib/api/reactQueryHelpers';
import { SceneAction } from '@bighealth/types/src/scene-components/client';
import { SceneSet } from '@bighealth/types';
import { getIsSceneAtEndOfSceneSet } from 'lib/player/sceneSetHelpers';
import { getNextPath } from 'lib/player/sceneSetHelpers/getNextPath';
import { getQueryClient } from 'components/ProvidersContainer';
import { jump_to_specific_scene_set } from '@bighealth/api/SceneSetGraph/v1';

export type ActionHandlerCallback = Promise<void> | (() => void) | undefined;

const useActionHandler = (
  action?: Record<string, any> // @ TODO don't do this
): ActionHandlerCallback | undefined => {
  const {
    productReference,
    sceneSetGraphId,
    sceneSetId,
    sceneId,
  } = useSafeParams<SceneSetParams>();
  const history = useHistory();
  const navigateToSceneSet = useNavigateToJumpedSceneSet(action as SceneAction);
  const submitResponses = useSubmitOnActionHandler();
  const responseToSubmit = useSelector(getQuestionResponseToSubmit);
  const storedResponse = useRef(responseToSubmit);
  const programId = useQueryProgram()?.data?.result.id;
  const productId = useQueryProduct()?.data?.result.id;
  const goTo = useGoTo();
  const queryClient = getQueryClient();

  const currentPath = history.location.pathname;
  const jumpToSceneSetWithAssets = useLazyQueryJumpToSceneSetWithAssets();
  const transitionSceneSetWithAssets = useLazyQueryTransitionSceneSetWithAssets();
  const currentSceneSet = queryClient.getQueryData<
    jump_to_specific_scene_set.Response
  >(['SceneSet', sceneSetId])?.result?.scene_set_json;
  const isSceneAtEndOfSceneSet = getIsSceneAtEndOfSceneSet(
    currentSceneSet,
    sceneId
  );

  // Prevent issue with closures
  useEffect(() => {
    storedResponse.current = responseToSubmit;
  }, [responseToSubmit]);

  // --------------------------------------------------------------------------------
  //
  // Handle NEXT action
  //
  // This simply moves to the next Scene. It should almost certainly ONLY be used
  // for things like Audio/Video actions; if there's Questions on the screen the
  // SUBMIT action must be used instead
  //

  const handleNextAction = useCallback(() => {
    if (currentSceneSet) {
      const nextPath = getNextPath(currentPath);
      history.push(nextPath);
    }
  }, [currentPath, currentSceneSet, history]);

  // --------------------------------------------------------------------------------
  //
  // Handle GO_TO_HOME action
  //
  // This simply sends the user directly to home after submitting responses
  //

  const handleGoHomeAction = useCallback(async () => {
    try {
      await submitResponses(); // can throw QUIZ_FAILED_THROWABLE
      history.push(`/${productReference}/home`);
    } catch (err) {
      // only QUIZ_FAILED_THROWABLE errors are intended for subsequent control flow
      if (err !== QUIZ_FAILED_THROWABLE) {
        throw err;
      }
    }
  }, [history, productReference, submitResponses]);

  // --------------------------------------------------------------------------------
  //
  // Handle GO_TO action
  //
  // Nav user to destination url
  //

  const handleGoToAction = useCallback(async (): Promise<void> => {
    if (!action) {
      return undefined;
    }
    try {
      await submitResponses(); // can throw QUIZ_FAILED_THROWABLE
      await goTo(action?.payload);
    } catch (err) {
      // only QUIZ_FAILED_THROWABLE errors are intended for subsequent control flow
      if (err !== QUIZ_FAILED_THROWABLE) {
        throw err;
      }
    }
  }, [action, goTo, submitResponses]);

  // --------------------------------------------------------------------------------
  //
  // Handle REPEAT_CURRENT_SCENESET action
  //
  // Sends the user back to the start of the SceneSet
  //

  const handleRepeatAction = useCallback(async () => {
    // @ TODO bust cache to force sceneset to re-initialize
    const repeatedSceneSetResult = await jumpToSceneSetWithAssets({
      current_graph_id: sceneSetGraphId,
      current_product_id: productId as number,
      current_program_id: programId as number,
      current_scene_set_id: sceneSetId,
      destination_scene_set_id: sceneSetId,
    });
    const repeatedSceneSet = repeatedSceneSetResult?.result;
    if (repeatedSceneSet) {
      navigateToSceneSet({
        currentSceneSet: currentSceneSet as SceneSet,
        targetSceneSetId: currentSceneSet?.id as number,
      });
    }
  }, [
    currentSceneSet,
    jumpToSceneSetWithAssets,
    navigateToSceneSet,
    productId,
    programId,
    sceneSetGraphId,
    sceneSetId,
  ]);

  // --------------------------------------------------------------------------------
  //
  // Handle JUMP_TO_SCENESET action
  //
  // This does the following:
  // 1. Submits any responses
  // 2. Fetches SceneSet by sceneSetId AND sceneSetGraphId
  // 3. Attempts to jump to

  const handleJumpToSceneSetAction = useCallback(async (): Promise<void> => {
    try {
      await submitResponses(); // can throw QUIZ_FAILED_THROWABLE
      const jumpedSceneSetResult = await jumpToSceneSetWithAssets({
        current_graph_id: action?.payload.sceneSetGraphId,
        current_product_id: productId as number,
        current_program_id: programId as number,
        current_scene_set_id: sceneSetId,
        destination_scene_set_id: action?.payload.destinationSceneSetId,
      });
      const jumpedSceneSet = jumpedSceneSetResult?.result;
      if (jumpedSceneSet) {
        const path = getPathForParams({
          productReference: productReference as string,
          sceneSetGraphId: action?.payload.sceneSetGraphId as number,
          sceneSetId: action?.payload.destinationSceneSetId as number,
          sceneId: 0,
        });
        history.push(path);
      } else {
        const payloadStr = stringify(action?.payload);
        const paramsStr = stringify({
          productReference,
          sceneSetGraphId,
          sceneSetId,
          sceneId,
        });
        const errorStr = `JUMP_TO_SCENESET failed with payload ${payloadStr} from ${paramsStr}`;
        logger(errorStr, Error(errorStr), { silent: true });
      }
    } catch (err) {
      // only QUIZ_FAILED_THROWABLE errors are intended for subsequent control flow
      if (err !== QUIZ_FAILED_THROWABLE) {
        throw err;
      }
    }
  }, [
    action,
    history,
    jumpToSceneSetWithAssets,
    productId,
    productReference,
    programId,
    sceneId,
    sceneSetGraphId,
    sceneSetId,
    submitResponses,
  ]);

  // --------------------------------------------------------------------------------
  //
  // Handle JUMP_TO_SCENESET_BY_ID action
  //
  // This does the following:
  // 1. Submits any responses
  // 2. Fetches SceneSet by sceneSetId
  // 3. Attempts to jump to
  //
  // NOTE Very similar to JUMP_TO_SCENESET

  const handleJumpToSceneByIdSetAction = useCallback(async (): Promise<
    void
  > => {
    try {
      await submitResponses(); // can throw QUIZ_FAILED_THROWABLE
      const jumpToId = action?.payload;
      const jumpedSceneSetResult = await jumpToSceneSetWithAssets({
        current_graph_id: sceneSetGraphId,
        current_product_id: productId as number,
        current_program_id: programId as number,
        current_scene_set_id: sceneSetId,
        destination_scene_set_id: jumpToId,
      });
      const jumpedSceneSet = jumpedSceneSetResult?.result;
      if (jumpedSceneSet) {
        navigateToSceneSet({
          currentSceneSet: currentSceneSet as SceneSet,
          targetSceneSetId: jumpToId,
        });
      } else {
        history.push(`/${productReference}/home`);
      }
    } catch (err) {
      // only QUIZ_FAILED_THROWABLE errors are intended for subsequent control flow
      if (err !== QUIZ_FAILED_THROWABLE) {
        throw err;
      }
    }
  }, [
    action,
    currentSceneSet,
    history,
    jumpToSceneSetWithAssets,
    navigateToSceneSet,
    productId,
    productReference,
    programId,
    sceneSetGraphId,
    sceneSetId,
    submitResponses,
  ]);

  // --------------------------------------------------------------------------------
  //
  // SUBMIT action
  //
  // This does the following:
  // 1. submits data to the API in order to return the next SceneSet with correct data
  // 2. if the SceneSet is empty (strictly, "null") it sends the app back to "/home"

  const handleSubmitAction = useCallback(async (): Promise<void> => {
    try {
      await submitResponses(); // can throw QUIZ_FAILED_THROWABLE
      // Fetch next SceneSet if at the end.
      if (isSceneAtEndOfSceneSet) {
        const nextSceneSetResult = await transitionSceneSetWithAssets({
          current_graph_id: sceneSetGraphId,
          current_product_id: productId as number,
          current_program_id: programId as number,
          current_scene_set_id: sceneSetId,
        });

        const nextSceneSet = nextSceneSetResult?.result;
        if (nextSceneSet) {
          // We're still in the same SSG here so we can keep that in the path
          const path = getPathForParams({
            productReference,
            sceneSetGraphId,
            sceneSetId: nextSceneSet.id,
            sceneId: 0,
          });
          history.push(path);
        } else {
          history.push(`/${productReference}/home`);
          return;
        }
      } else {
        // Just move to the next Scene
        if (currentSceneSet) {
          const nextPath = getNextPath(currentPath);
          history.push(nextPath);
        }
      }
    } catch (err) {
      // can throw QUIZ_FAILED_THROWABLE so only log those that aren't that
      if (err !== QUIZ_FAILED_THROWABLE) {
        throw err;
      }
    }
  }, [
    submitResponses,
    isSceneAtEndOfSceneSet,
    currentSceneSet,
    sceneSetId,
    transitionSceneSetWithAssets,
    sceneSetGraphId,
    productId,
    programId,
    productReference,
    history,
    currentPath,
  ]);

  switch (action?.type) {
    case SceneActionTypes.NEXT:
      return handleNextAction;

    case SceneActionTypes.GO_TO_HOME:
      return handleGoHomeAction;

    case SceneActionTypes.GO_TO:
      return handleGoToAction;

    case SceneActionTypes.REPEAT_CURRENT_SCENESET:
      return handleRepeatAction;

    case SceneActionTypes.JUMP_TO_SCENESET:
      return handleJumpToSceneSetAction;

    case SceneActionTypes.JUMP_TO_SCENESET_BY_ID:
      return handleJumpToSceneByIdSetAction;

    case SceneActionTypes.SUBMIT:
      return handleSubmitAction;

    default:
      return undefined;
  }
};

export default useActionHandler;
