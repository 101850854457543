import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import {
  ArgPeriod,
  SleepDiaryResultContent,
} from '@bighealth/types/src/services/SleepDiaryPayloads/get_sleep_diary_component_data_for_time_period';

export const get_sleep_diary_component_data_for_time_period = async (
  args: get_sleep_diary_component_data_for_time_period.Args
): Promise<get_sleep_diary_component_data_for_time_period.Response> => {
  return await api({
    service_name: 'SleepDiary',
    service_version: '1',
    service_method: 'get_sleep_diary_component_data_for_time_period',
    args: args,
  } as get_sleep_diary_component_data_for_time_period.Request);
};

get_sleep_diary_component_data_for_time_period.queryKey =
  'SleepDiary/get_sleep_diary_component_data_for_time_period';

export declare namespace get_sleep_diary_component_data_for_time_period {
  export type Args = {
    user_timezone: string;
    is_read_only: boolean;
    include_metadata: boolean;
    product_id: number;
    program_id?: number;
  } & (
    | {
        period: ArgPeriod.calendar_week;
        calendar_week_offset?: number;
      }
    | {
        period: ArgPeriod.last_seven_days;
      }
  );

  export interface Request extends APIRequestBody {
    service_name: 'SleepDiary';
    service_version: '1';
    service_method: 'get_sleep_diary_component_data_for_time_period';
    args: Args;
  }

  export type Result = SleepDiaryResultContent;

  export type Response = APIResponse<Result>;
}
