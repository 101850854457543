import styled from 'styled-components/native';
import React, { ReactElement, useEffect, useState } from 'react';
import { Linking, Switch } from 'react-native';
import { Text } from 'components/Text';
import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import {
  Container,
  Content,
  ContentColumn,
  PageTitle,
  ScrollColumn,
  SettingsEntry,
} from './components';
import { toJavasScriptDate } from 'lib/api/middleware/response/toJavaScriptDateWithRequest/utils/toJavaScriptDate';
import { format } from 'date-fns';
import logger from 'lib/logger';
import { roles } from 'cross-platform/utils/roleProps';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';
import {
  queryClientUnsubscribe,
  useQuerySettings,
  queryClientSubscribe,
  useQueryProduct,
} from 'lib/api/reactQueryHelpers';
import { LoadingBlankScreen } from 'components/LoadingBlankScreen';

export const Padding = styled.View`
  width: 1%;
`;

export const EntryTextRowContainer = styled.View`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`;

export const TextContainer = styled.View`
  display: flex;
  width: 66%;
`;
export const SwitchContainer = styled.View`
  display: flex;
  align-items: flex-end;
  width: 33%;
`;

const EntryText = ({
  text,
  titleEmailAnchor,
}: {
  text: string;
  titleEmailAnchor?: string;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();

  return typeof titleEmailAnchor === 'undefined' ? (
    <Text
      style={{
        fontSize: styles.settingsEntryTextFontSize,
        color: '#003366',
      }}
    >
      {text}
    </Text>
  ) : (
    <Text
      style={{
        fontSize: styles.settingsEntryTextFontSize,
        color: '#1a80a2',
      }}
      onPress={() => Linking.openURL('mailto:hello@sleepio.com')}
    >
      {text}
    </Text>
  );
};

const AccountStatus = (): ReactElement | null => {
  const { isLoading, data } = useQuerySettings();
  if (isLoading) {
    return null;
  }
  if (!data) {
    return null;
  }
  return (
    <SettingsEntry
      heading={'Account Status'}
      subheading={`Full course access expires on ${format(
        toJavasScriptDate(data.result.account_expiry_utc),
        'd MMMM yyyy'
      )}.`}
    >
      <EntryTextRowContainer>
        <EntryText text={'Questions about your access? Ask us at'} />
        <Padding />
        <EntryText
          text={'hello@sleepio.com'}
          titleEmailAnchor={'Question about access'}
        />
      </EntryTextRowContainer>
    </SettingsEntry>
  );
};

const AccountDetails = (): ReactElement | null => {
  const { isLoading, data } = useQuerySettings();
  if (isLoading || !data) {
    return null;
  }
  return (
    <SettingsEntry
      heading={'Your Email'}
      subheading={data.result.email_address}
    >
      <EntryText text={'Want to disconnect your social login?'} />
      <EntryTextRowContainer>
        <EntryText text={'Let us know at'} />
        <Padding />
        <EntryText
          text={'hello@sleepio.com'}
          titleEmailAnchor={'Disconnect social login'}
        />
      </EntryTextRowContainer>
    </SettingsEntry>
  );
};

const EmailSettings = (): ReactElement | null => {
  const { data } = useQuerySettings();
  const styles = useGetDynamicContentStyles();
  const isUserSubscribedViaApi = data?.result?.subscribed_to_emails || false;
  const [isUserSubscribed, setIsUserSubscribed] = useState(false);
  const productResponse = useQueryProduct();

  useEffect(() => {
    setIsUserSubscribed(isUserSubscribedViaApi);
  }, [isUserSubscribedViaApi]);

  const subscribe = async () => {
    try {
      // We could do this with setQueryCache but this is terser
      setIsUserSubscribed(true);
      await queryClientSubscribe({
        product_id: productResponse.data?.result.id as number,
      });
    } catch (e) {
      setIsUserSubscribed(isUserSubscribedViaApi);
      logger('Could not call the subscription endpoint', e);
    }
  };

  const unsubscribe = async () => {
    try {
      setIsUserSubscribed(false);
      await queryClientUnsubscribe({
        product_id: productResponse.data?.result.id as number,
      });
    } catch (e) {
      setIsUserSubscribed(isUserSubscribedViaApi);
      logger('Could not call the unsubscription endpoint', e);
    }
  };

  const toggleSwitch = async (): Promise<void> => {
    if (!isUserSubscribed) {
      await subscribe();
    } else {
      await unsubscribe();
    }
  };

  return (
    <SettingsEntry heading={'Email Settings'}>
      <EntryTextRowContainer>
        <TextContainer>
          <EntryText
            text={'Get updates on sessions, sleep diaries, and the community'}
          />
        </TextContainer>
        <SwitchContainer>
          <Switch
            trackColor={{ false: '#ddefff', true: '#ddefff' }}
            thumbColor={'#1a80a2'}
            ios_backgroundColor="#ddefff"
            onValueChange={toggleSwitch}
            value={isUserSubscribed}
            style={{
              width: styles.settingsSwitchWidth,
              height: styles.settingsSwitchHeight,
            }}
            {...roles('EmailSubscriptionSwitch')}
          />
          <Text
            style={{
              fontSize: styles.settingsSwitchText,
              paddingTop: styles.settingsEntrySubHeadingPadding,
              color: '#1a80a2',
            }}
          >
            {isUserSubscribed ? 'Subscribed' : 'Unsubscribed'}
          </Text>
        </SwitchContainer>
      </EntryTextRowContainer>
    </SettingsEntry>
  );
};

const SettingsScreen = (): ReactElement | null => {
  useUpdateTitle('Account Settings');
  const { isLoading, data } = useQuerySettings();

  if (isLoading) {
    // the global throbber will be rendered
    return <LoadingBlankScreen />;
  }

  if (typeof data?.result === 'undefined') {
    return null;
  }
  return (
    <Container>
      <PageTitle>{'Account settings'}</PageTitle>
      <ContentColumn>
        <ScrollColumn contentContainerStyle={{ flex: 1 }}>
          <Content>
            <AccountStatus />
            <AccountDetails />
            <EmailSettings />
          </Content>
        </ScrollColumn>
      </ContentColumn>
    </Container>
  );
};

export default SettingsScreen;
