import React, { ReactElement } from 'react';
import { PauseButtonIcon, PlayButtonIcon } from './icons';
import { PressButton } from './components';
import { useGetDynamicPlayerControlsStyles } from 'components/ResponsiveLayout';
import { roles } from 'cross-platform/utils/roleProps';
import { useSelector } from 'react-redux';
import { getControlsAreVisible, getPlayerState } from 'state/player/selectors';
import { SceneSetParams, useSafeParams } from 'components/Routes/useSafeParams';
import { useQueryJumpToSceneSet } from 'lib/api/reactQueryHelpers';
import { getIsScenePlayable } from 'lib/player/sceneSetHelpers';
import { PlaybackState } from 'state/player/reducer';
import { useIsInactiveStateContext } from 'components/SceneSetView/providers/IsInactiveStateProvider';
import { usePlayPauseMedia } from 'lib/player/media/usePlayPauseMedia';

export const MediaPlayerControls = (): ReactElement | null => {
  const { isInactiveState } = useIsInactiveStateContext();
  const { sceneSetId, sceneId } = useSafeParams<SceneSetParams>();
  const showControls = useSelector(getControlsAreVisible);
  const playerState = useSelector(getPlayerState);
  const playPauseMedia = usePlayPauseMedia();
  const sceneSet = useQueryJumpToSceneSet(sceneSetId)?.data?.result
    ?.scene_set_json;
  const sceneHasMediaContent = getIsScenePlayable(sceneSet, sceneId);

  const { playPauseIconSize } = useGetDynamicPlayerControlsStyles();
  return (
    <PressButton
      {...roles('PlayPauseButton')}
      style={{ height: playPauseIconSize }}
      disabled={
        !sceneHasMediaContent ||
        playerState === PlaybackState.UNINITIALIZED ||
        playerState === PlaybackState.FINISHED ||
        !showControls ||
        !!isInactiveState
      }
      onPress={async () => {
        if (sceneHasMediaContent) {
          if (playerState === PlaybackState.PLAYING) {
            playPauseMedia.pause();
          } else {
            // It doesn't matter which order these are called in. Just happens set state before the await is easier to test
            await playPauseMedia.play();
          }
        }
      }}
    >
      {playerState === PlaybackState.PLAYING ? (
        <PauseButtonIcon />
      ) : (
        <PlayButtonIcon />
      )}
    </PressButton>
  );
};
