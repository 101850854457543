import { useContext, useMemo } from 'react';
import {
  ScreenResponsiveLayoutContext,
  MediaResponsiveLayoutContext,
} from '../providers';

interface Dimension {
  mediaWidth: number;
  mediaHeight: number;
}

export const useGetMediaDimensions = (): Dimension => {
  const { screenHeight } = useContext(ScreenResponsiveLayoutContext);
  const { mediaHeight, mediaWidth } = useContext(MediaResponsiveLayoutContext);

  const memod = useMemo(() => {
    const scale = screenHeight / mediaHeight;
    const adjustedHeight = screenHeight;
    // We're going to round this up to the nearest px to avoid any potential fractional lines
    // which may expose the content underneath
    const adjustedWidth = Math.ceil(mediaWidth * scale);
    return {
      mediaWidth: adjustedWidth,
      mediaHeight: adjustedHeight,
    };
  }, [mediaHeight, mediaWidth, screenHeight]);
  return memod;
};
