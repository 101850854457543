import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useState,
} from 'react';
import { Presentation } from '../Presentation';
import { SleepDiaryWeekTypes } from '@bighealth/types/src/scene-components/sleep-diary';

/**
 * Connects to non-networked state
 * @param props
 */

type StateProps = SleepDiaryWeekTypes['PreNetworkCallConfiguration'] &
  SleepDiaryWeekTypes['NetworkResponse'];

const StatefulSleepDiaryWeek: FunctionComponent<StateProps & {
  pageForwardPress: () => void;
  pageBackwardPress: () => void;
  onDiarySubmit: Dispatch<SetStateAction<number | undefined>>;
  setIsLoading: (loading: boolean) => void;
}> = props => {
  const [diaryDateForModal, setDiaryDateForModal] = useState<
    undefined | string
  >(undefined);

  const setDiaryDateForModalPresentation = (diary_date: undefined | string) => {
    setDiaryDateForModal(diary_date);
  };

  return (
    <Presentation
      {...props}
      onPressCalendarTile={setDiaryDateForModalPresentation}
      onCloseDiaryModal={setDiaryDateForModalPresentation}
      diaryDateForModal={diaryDateForModal}
    />
  );
};

export { StatefulSleepDiaryWeek };
