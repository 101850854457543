import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useGetNavigationConfig } from './useGetNavigationConfig';
import styled from 'styled-components/native';
import {
  isCategoryNavItem,
  isLinkNavItem,
  isPressableNavItem,
  NavEntry,
  NavItemDisplayType,
} from './types';
import { Text } from 'components/Text';
import { MenuPortal } from './MenuPortal';
import { ScreenResponsiveLayoutContext } from 'components/ResponsiveLayout';
import { CloseButtonIcon } from 'components/Media/PlayerControls/icons';
import { Linking, View } from 'react-native';
import { Link, useHistory } from 'cross-platform/react-router';
import { useDispatch } from 'react-redux';
import { SleepioLogoOutline, SleepioLogoWhite } from 'components/icons';
import { NavBarStyles } from './constants';
import { LogoButton } from './LogoButton';
import { NavFooter } from 'components/Footer';
import { MenuDismisser } from './MenuDismisser';
import AvailabilityIndicator from 'components/SessionAvailabilityIndicator';
import { useNavigationBarContext } from './providers/NavigationBarProvider';

const NavBar = styled.View`
  height: ${NavBarStyles.navBarHeightPx}px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 32px;
  padding-right: 32px;
  z-index: ${NavBarStyles.navBarZIndex} !important;
`;

const PressableNavItem = styled.TouchableOpacity``;

const Label = styled(Text)<{ type?: NavItemDisplayType }>`
  font-size: ${({ type }) => (type === 'subcategory' ? '16px' : '20px')};
  color: ${({ type }) => (type === 'subcategory' ? '#bfe2f0' : 'white')};
`;

const CategoryTitle = styled(Text)<{ type?: NavItemDisplayType }>`
  font-size: 20px;
  color: white;
  margin-bottom: 16px;
`;

const ItemContainer = styled.View<{ type?: NavItemDisplayType }>`
  margin-bottom: ${({ type }) => (type === 'subcategory' ? '20px' : '26px')};
`;

const ItemIconContainer = styled.View<{ type?: NavItemDisplayType }>`
  margin-bottom: ${({ type }) => (type === 'subcategory' ? '20px' : '26px')};
  flex-direction: row;
  align-items: center;
`;

const ItemContainerGroup = styled.View`
  margin-bottom: 6px;
`;

const MenuContainer = styled.ScrollView`
  background-color: #002141;
  top: 0;
  right: 0;
  position: absolute;
  min-width: 300px;
`;
MenuContainer.displayName = 'MenuContainer';

const MenuItemsContainer = styled.View`
  flex: 1;
  padding: 40px;
  min-height: auto;
`;
MenuItemsContainer.displayName = 'MenuItemsContainer';

const PortalStretcher = styled.View``;

const OpenButton = styled.TouchableOpacity``;

const CloseButton = styled.TouchableOpacity`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const HamBurgerIcon = ({ color }: { color: string }): ReactElement => {
  return (
    <View>
      {Array(3)
        .fill(null)
        .map((_, index) => (
          <View
            key={index}
            style={{
              backgroundColor: color,
              height: 3,
              width: 24,
              marginVertical: 3,
            }}
          />
        ))}
    </View>
  );
};

const SessionIcon = (): ReactElement => {
  return (
    <View style={{ paddingLeft: 10 }}>
      <AvailabilityIndicator
        iconSize={11}
        testID={'sessionAvailabilityIndicator'}
      />
    </View>
  );
};

const LogoContainer = styled.View`
  margin-right: auto;
`;

type ActiveMenu = {
  items: NavEntry[];
};

export const CollapsedNav = (): ReactElement => {
  const dispatch = useDispatch();
  const config = useGetNavigationConfig();
  const { enableMobileMode } = useNavigationBarContext();
  const { screenHeight, screenWidth } = useContext(
    ScreenResponsiveLayoutContext
  );
  // NOTE: we are moving to a responsive layout not dependant device detection for navigation
  const isTransparentNav = enableMobileMode;
  const [menu, setMenu] = useState<ActiveMenu | null>(null);
  const history = useHistory();
  const path = history.location.pathname;
  const cachedPath = useRef(path);

  useEffect(() => {
    // Lazy way of dismissing the menu without having to intercept click events on links etc
    setMenu(null);
    cachedPath.current = path;
  }, [dispatch, path]);

  const generateItems = (items: NavEntry[]): (ReactElement | null)[] => {
    return items
      .map((item): ReactElement | null => {
        const { label } = item;
        if (isPressableNavItem(item)) {
          return (
            <PressableNavItem
              onPress={item.onPress}
              key={label}
              testID={`Pressable-${label}`}
            >
              <Label type={item.type}>{label}</Label>
            </PressableNavItem>
          );
        } else if (isLinkNavItem(item)) {
          if (item.label === 'Sessions') {
            return (
              <ItemIconContainer key={label}>
                <Link
                  to={item.to}
                  title={`Go To ${label}`}
                  // Annoyingly testing-library/react-native can't select links via title property
                  // so we use testID instead
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  testID={`Go To ${label}`}
                >
                  <Label type={item.type}>{label}</Label>
                </Link>
                <SessionIcon />
              </ItemIconContainer>
            );
          }
          return (
            <ItemContainer key={label}>
              {item.external ? (
                <Label
                  type={item.type}
                  onPress={() => Linking.openURL(item.to)}
                >
                  {label}
                </Label>
              ) : (
                <Link
                  to={item.to}
                  title={`Go To ${label}`}
                  // Annoyingly testing-library/react-native can't select links via title property
                  // so we use testID instead
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  testID={`Go To ${label}`}
                >
                  <Label type={item.type}>{label}</Label>
                </Link>
              )}
            </ItemContainer>
          );
        } else if (isCategoryNavItem(item)) {
          return (
            <ItemContainerGroup key={label}>
              <CategoryTitle type={'category'}>{label}</CategoryTitle>
              {generateItems(item.subMenu)}
            </ItemContainerGroup>
          );
        }
        return null;
      })
      .filter(Boolean);
  };

  return (
    <>
      <NavBar
        testID={'CollapsedNav'}
        style={{
          backgroundColor: isTransparentNav ? 'transparent' : '#003366',
        }}
      >
        <LogoContainer>
          <LogoButton>
            {isTransparentNav ? (
              <SleepioLogoOutline height={39} />
            ) : (
              <SleepioLogoWhite height={39} />
            )}
          </LogoButton>
        </LogoContainer>
        <OpenButton
          testID={'HamburgerButton'}
          onPress={() => {
            if (!menu && config) {
              if (config) {
                setMenu({
                  items: config,
                });
              }
            }
          }}
        >
          <HamBurgerIcon color={isTransparentNav ? '#003366' : 'white'} />
        </OpenButton>
      </NavBar>
      {menu ? (
        <MenuPortal>
          <PortalStretcher style={{ width: screenWidth }}>
            <MenuDismisser
              onDismiss={() => {
                setMenu(null);
              }}
            />
            <MenuContainer
              style={{ height: screenHeight }}
              contentContainerStyle={{ flexGrow: 1 }}
            >
              <MenuItemsContainer>
                {generateItems(menu.items)}
              </MenuItemsContainer>
              <NavFooter />
            </MenuContainer>
            <CloseButton
              testID={'MenuCloseButton'}
              onPress={() => {
                setMenu(null);
              }}
            >
              <CloseButtonIcon size={20} color={'white'} />
            </CloseButton>
          </PortalStretcher>
        </MenuPortal>
      ) : null}
    </>
  );
};
