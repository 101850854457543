import { equals, reject, uniq } from 'ramda';
import {
  FormikPersistableState,
  QUESTION_RESPONSE_ACTIONS,
  QuestionId,
  QuestionResponseAction,
} from './actions';

import { QuizInfo } from 'components/forms/InputField';
import { Question, Response, ResponseOption } from '@bighealth/types';

export interface QuestionResponseState {
  responseOptionsToSubmit: Record<QuestionId, Response>;
  questions: Question[];
  correctAnswers: Record<QuestionId, QuizInfo>;
  quizAttemptsByPath: { path: string; attempt: ResponseOption[] }[];
  formikState: Record<string, FormikPersistableState>;
  latestQuestionId: number | undefined;
}

export const initialQuestionResponseState: QuestionResponseState = {
  responseOptionsToSubmit: {},
  questions: [],
  correctAnswers: {},
  quizAttemptsByPath: [],
  formikState: {},
  latestQuestionId: undefined,
};

export const questionResponseReducer = (
  state = initialQuestionResponseState,
  action: QuestionResponseAction
): QuestionResponseState => {
  switch (action.type) {
    case QUESTION_RESPONSE_ACTIONS.ADD_TO_SUBMIT: {
      return {
        ...state,
        responseOptionsToSubmit: {
          ...state.responseOptionsToSubmit,
          ...action.payload,
        },
      };
    }
    case QUESTION_RESPONSE_ACTIONS.CLEAR_TO_SUBMIT:
      return {
        ...state,
        responseOptionsToSubmit: {},
      };
    case QUESTION_RESPONSE_ACTIONS.ADD_CORRECT_ANSWERS: {
      return {
        ...state,
        correctAnswers: { ...state.correctAnswers, ...action.payload },
      };
    }
    case QUESTION_RESPONSE_ACTIONS.CLEAR_CORRECT_ANSWERS:
      return {
        ...state,
        correctAnswers: {},
      };
    case QUESTION_RESPONSE_ACTIONS.PUSH_QUIZ_ANSWER_BY_SCENE_SET: {
      if (state.quizAttemptsByPath.find(el => equals(el, action.payload))) {
        return state; // Skip;
      }
      return {
        ...state,
        quizAttemptsByPath: [...state.quizAttemptsByPath, action.payload],
      };
    }
    case QUESTION_RESPONSE_ACTIONS.CLEAR_QUIZ_ANSWER_BY_SCENE_SET: {
      const pathToClear = action.payload;
      return {
        ...state,
        quizAttemptsByPath: reject(
          el => el.path === pathToClear,
          state.quizAttemptsByPath
        ),
      };
    }
    case QUESTION_RESPONSE_ACTIONS.PUSH: {
      return {
        ...state,
        questions: uniq([...state.questions, action.payload]),
      };
    }
    case QUESTION_RESPONSE_ACTIONS.CLEAR: {
      return {
        ...state,
        questions: [],
      };
    }
    case QUESTION_RESPONSE_ACTIONS.STORE_FORMIK_STATE_BY_PATH: {
      return {
        ...state,
        formikState: {
          ...state.formikState,
          [action.payload.path]: action.payload.state,
        },
      };
    }
    case QUESTION_RESPONSE_ACTIONS.SET_LATEST_QUESTION: {
      return {
        ...state,
        latestQuestionId: action.payload,
      };
    }
    default:
      return state;
  }
};
