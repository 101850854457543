import { get_sleep_diary_component_data_for_time_period } from '@bighealth/api/SleepDiary/v1';

export const mockPresentationPropsCalendarWeek: get_sleep_diary_component_data_for_time_period.Result = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  title: {
    text: 'Sleep Diary',
    fontSize: 30,
    color: '#000000',
  },
  titleAlignment: {
    horizontal: 'left',
  },
  subtitle: {
    fontSize: 24,
    color: '#003366',
  },
  subtitleAlignment: {
    horizontal: 'middle',
  },
  shouldShowContinueButton: true,
  isContinueButtonEnabled: true,
  minCountOfCompleteDiariesForContinueButtonToExtrapolate: 5,
  maxCountOfCompleteDiariesForContinueButtonToExtrapolate: 7,
  continueButtonText: 'Continue',
  diaries: {
    date_range_label: {
      text: 'July 7 - July 13',
    },
    has_prev: true,
    has_next: true,
    days: [
      {
        date: '2020-07-07',
        day_of_month: { text: '7', color: '#ffffff' },
        day_name: { text: 'Tue', color: '#ffffff' },
        status: 'COMPLETE',
        metrics: [
          { label: { text: 'Sleep Efficiency' }, value: { text: '66%' } },
        ],
        click_action: {
          type: 'sleep-diary/ENTRY_MODAL_OPEN',
          payload: {
            service_name: 'SleepDiary',
            service_version: '1',
            service_method: 'get_diary_entry_form',
            args: {
              form_type: 'update_single_day_entry_with_date',
              diary_date: '2020-07-07',
            },
          },
        },
      },
      {
        date: '2020-07-08',
        day_of_month: { text: '8', color: '#ffffff' },
        day_name: { text: 'Wed', color: '#ffffff' },
        status: 'COMPLETE',
        metrics: [
          { label: { text: 'Sleep Efficiency' }, value: { text: '66%' } },
        ],
        click_action: {
          type: 'sleep-diary/ENTRY_MODAL_OPEN',
          payload: {
            service_name: 'SleepDiary',
            service_version: '1',
            service_method: 'get_diary_entry_form',
            args: {
              form_type: 'update_single_day_entry_with_date',
              diary_date: '2020-07-08',
            },
          },
        },
      },
      {
        date: '2020-07-09',
        day_of_month: { text: '9', color: '#ffffff' },
        day_name: { text: 'Thu', color: '#ffffff' },
        status: 'MISSING',
        click_action: {
          type: 'sleep-diary/ENTRY_MODAL_OPEN',
          payload: {
            service_name: 'SleepDiary',
            service_version: '1',
            service_method: 'get_diary_entry_form',
            args: {
              form_type: 'create_single_day_entry',
              diary_date: '2020-07-09',
            },
          },
        },
      },
      {
        date: '2020-07-10',
        day_of_month: { text: '10', color: '#ffffff' },
        day_name: { text: 'Fri', color: '#ffffff' },
        status: 'MISSING',
        click_action: {
          type: 'sleep-diary/ENTRY_MODAL_OPEN',
          payload: {
            service_name: 'SleepDiary',
            service_version: '1',
            service_method: 'get_diary_entry_form',
            args: {
              form_type: 'create_single_day_entry',
              diary_date: '2020-07-10',
            },
          },
        },
      },
      {
        date: '2020-07-11',
        day_of_month: { text: '11', color: '#00326a' },
        day_name: { text: 'Sat', color: '#00326a' },
        status: 'LOCKED',
      },
      {
        date: '2020-07-12',
        day_of_month: { text: '12', color: '#00326a' },
        day_name: { text: 'Sun', color: '#00326a' },
        status: 'LOCKED',
      },
      {
        date: '2020-07-13',
        day_of_month: { text: '13', color: '#00326a' },
        day_name: { text: 'Mon', color: '#00326a' },
        status: 'LOCKED',
      },
    ],
  },
  meta: {
    chart: {
      days: [
        {
          label: { text: '0/10', background_color: '#d4b5ff' },
          data: [
            { color: '#e6f0ff', label: 'Time in Bed', y_value: 570 },
            { color: '#003366', label: 'Time Asleep', y_value: 375 },
          ],
        },
        {
          label: { text: '1/10', background_color: '#d4b5ff' },
          data: [
            { color: '#e6f0ff', label: 'Time in Bed', y_value: 570 },
            { color: '#003366', label: 'Time Asleep', y_value: 375 },
          ],
        },
        {
          label: { text: '', background_color: '#e5eaef' },
          data: [
            {
              color: '#00a3be',
              opacity: 0.5,
              label: 'Add missing data',
              y_value: 600,
            },
          ],
        },
        {
          label: { text: '', background_color: '#e5eaef' },
          data: [
            {
              color: '#00a3be',
              opacity: 0.5,
              label: 'Add missing data',
              y_value: 600,
            },
          ],
        },
        null,
        null,
        null,
      ],
      upper_y_value: 600,
      guides: [
        { color: '#1a80a2', label: '10 hr', y_value: 600 },
        { color: '#1a80a2', label: '5 hr', y_value: 300 },
        { color: '#1a80a2', label: '0 hr', y_value: 0 },
      ],
    },
    metrics: {
      heading: { text: 'Your average sleep for July 7 - July 13' },
      items: [
        {
          label: { text: 'Time Asleep' },
          value: { text: '6 hr 15 min', color: '#003366' },
        },
        {
          label: { text: 'Time in Bed' },
          value: { text: '9 hr 30 min', color: '#1a80a2' },
        },
        {
          label: { text: 'Sleep Efficiency' },
          value: { text: '66%', color: '#f64400' },
        },
        {
          label: { text: 'Sleep Quality' },
          value: { text: '0/10', color: '#984cff' },
        },
      ],
    },
  },
};
