import React, { ReactElement, useContext } from 'react';
import styled from 'styled-components/native';
import { FillAbsoluteCenter } from 'components/Containers';
import { SleepDiaryWeekTypes } from '@bighealth/types/src/scene-components/sleep-diary';

import {
  ScreenResponsiveLayoutContext,
  useGetDynamicContentStyles,
} from 'components/ResponsiveLayout';

import { SleepDiaryWeek } from 'components/SleepDiaryWeek';

const SceneComponentWrapper = styled(FillAbsoluteCenter)``;

const Container = styled.ScrollView``;

export const SleepDiaryWeekSceneComponent = (
  props: SleepDiaryWeekTypes['PreNetworkCallConfiguration']
): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  const isNarrow = screenWidth <= styles.sleepDiaryWeekWidth;

  let widthContainer = styles.sleepDiaryWeekWidthNarrow;
  if (!isNarrow) {
    widthContainer = styles.sleepDiaryWeekWidth;
  }
  return (
    <SceneComponentWrapper pointerEvents={'box-none'}>
      <Container
        contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
        style={{ width: widthContainer }}
      >
        <SleepDiaryWeek {...props} />
      </Container>
    </SceneComponentWrapper>
  );
};
