import { format } from 'date-fns';
import { getTimeString } from 'lib/api/middleware/response/toJavaScriptDateWithRequest/utils/toValueObject/utils/getTimeString';
import { DatePickerDisplayFormat } from '../types';

type GetDateShownOptions = {
  date: Date | null;
  mode: 'time' | 'date' | 'datetime';
  displayFormat: DatePickerDisplayFormat;
};
// Just match what is seen in the component
const getDateShown = ({
  date,
  mode,
  displayFormat,
}: GetDateShownOptions): string | undefined => {
  let dateShown: string | undefined;
  if (date) {
    if (mode === 'time') {
      dateShown = getTimeString(date);
    } else {
      dateShown = format(date, displayFormat);
    }
  }
  return dateShown;
};

export { getDateShown };
