import React, { ReactElement, useContext } from 'react';
import ReactMarkdown from 'react-markdown';

import {
  LeftColumnItems,
  AboutUs,
  USAddressData,
  UKAddressData,
  ContactUsData,
  AboutUsNarrow,
  DisclaimerData,
} from './data';
import {
  CONTENT_MIN_REFERENCE_DIMENSION,
  ScreenResponsiveLayoutContext,
} from 'components/ResponsiveLayout';
import { Linking } from 'react-native';
import {
  AboutUsSubtitle,
  AboutUsTitle,
  AddressContainer,
  BodyText,
  LeftColumn,
  LeftColumnTextLink,
  LeftColumnTitle,
  RightColumn,
  RightColumnBodyContainer,
  RightColumnTitleContainer,
  View,
  ColumnContainer,
  SMALL_BREAK_POINT,
} from 'components/Screens/ContentScreens/Resources/styled';
import { roles } from 'cross-platform/utils/roleProps';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';

// @TODO Figure out how to access the types for a link element
// WHY: So we can add typing to props for CustomLinkRender and remove optional accessing.
// WHEN: someone has the time, motivation and knowledge to dig in.
//
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const CustomLinkRenderer = (props): ReactElement => {
  return (
    <BodyText
      onPress={() => Linking.openURL(props?.href)}
      style={{
        color: '#1a80a2',
        textDecorationLine: 'underline',
      }}
    >
      {props?.children}
    </BodyText>
  );
};

// Note: resources page doesn't require auth.
// So in useInitializeAPIClient if the user doesn't successfully auth
// the app will ignore the redirect to login page specifically for this route.
const ResourcesScreen = (): ReactElement => {
  useUpdateTitle('Resources');
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  const isSmall = screenWidth <= SMALL_BREAK_POINT;
  const isMedium = screenWidth <= CONTENT_MIN_REFERENCE_DIMENSION && !isSmall;

  return (
    <ColumnContainer>
      <LeftColumn style={{ paddingBottom: '25px' }}>
        <LeftColumnTitle>Resources</LeftColumnTitle>
        <View>
          {LeftColumnItems.map((columnItem, index) => (
            <LeftColumnTextLink
              {...roles(`resourcesLink${index}`)}
              key={index}
              onPress={() => Linking.openURL(columnItem.destination)}
            >
              {columnItem.text}
            </LeftColumnTextLink>
          ))}
        </View>
      </LeftColumn>
      <RightColumn
        style={{
          paddingTop: isMedium ? 90 : 0,
          paddingLeft: isSmall ? 0 : 25,
          width: isSmall ? '100%' : '70%',
        }}
      >
        <RightColumnTitleContainer>
          <AboutUsTitle
            {...roles('About Us')}
            style={{
              marginBottom: '10px',
              fontSize: isSmall ? 18 : 30,
            }}
          >
            {isMedium ? AboutUsNarrow.title : AboutUs.title}
          </AboutUsTitle>
          <AboutUsSubtitle style={{ fontSize: isSmall ? 18 : 30 }}>
            {isMedium ? AboutUsNarrow.subtitle : AboutUs.subtitle}
          </AboutUsSubtitle>
        </RightColumnTitleContainer>
        <RightColumnBodyContainer>
          <AddressContainer
            style={{
              flexDirection: isMedium || isSmall ? 'column' : 'row',
              justifyContent: 'space-between',
            }}
          >
            <BodyText>
              <ReactMarkdown>{USAddressData}</ReactMarkdown>
            </BodyText>
            <BodyText style={{ marginTop: '-5px' }}>
              <ReactMarkdown>{UKAddressData}</ReactMarkdown>
            </BodyText>
          </AddressContainer>
          <View style={{ paddingRight: isMedium ? 0 : 18 }}>
            <BodyText>
              <ReactMarkdown
                renderers={{
                  link: CustomLinkRenderer,
                }}
              >
                {ContactUsData}
              </ReactMarkdown>
            </BodyText>
          </View>
          <View
            style={{
              paddingRight: isMedium ? 0 : 18,
            }}
          >
            <BodyText>
              <ReactMarkdown
                renderers={{
                  link: CustomLinkRenderer,
                }}
              >
                {DisclaimerData}
              </ReactMarkdown>
            </BodyText>
          </View>
        </RightColumnBodyContainer>
      </RightColumn>
    </ColumnContainer>
  );
};

export { ResourcesScreen };
