import { useQuerySendNonSessionAnalytics } from 'lib/api/reactQueryHelpers/useQueryAnalogs/useQuerySendNonSessionAnalytics';
import { AnalyticsField } from '@bighealth/analytics';

export const NonSessionAnalyticsSender = ({
  field,
}: {
  field: AnalyticsField;
}): null => {
  useQuerySendNonSessionAnalytics(field);
  return null;
};
