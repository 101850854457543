import React, { ReactElement, useEffect } from 'react';
import { useFormikContext, Formik, FormikHelpers } from 'formik';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import { roles } from 'cross-platform/utils/roleProps';
import { Container } from 'components/Containers';
import Ajv from 'ajv';
import AjvErrors from 'ajv-errors';
import { JSONObject } from '@bighealth/types';
import { ErrorText } from 'components/Text/ErrorText';
import { SimpleInputField } from 'components/forms/InputField/SimpleInputField';

enum Names {
  EMAIL = 'Email',
  NEW_PASSWORD_1 = 'New password',
  NEW_PASSWORD_2 = 'Confirm new password',
}

enum ChangePasswordFormRole {
  'email' = 'email',
  'new-password1' = 'new-password1',
  'new-password2' = 'new-password2',
  'submit-reset-password' = 'submit-reset-password',
  'form-error' = 'form-error',
}

export type FormValues = {
  [Names.EMAIL]: string;
  [Names.NEW_PASSWORD_1]: string;
  [Names.NEW_PASSWORD_2]: string;
};

export type ValidationSchema = {
  type: 'object';
  required: Names[];
  properties: {
    [Names.EMAIL]: JSONObject;
    [Names.NEW_PASSWORD_1]: JSONObject;
    [Names.NEW_PASSWORD_2]: JSONObject;
  };
  errorMessage: {
    required: string;
  };
};

/**
 *
 */
const Form = (): ReactElement => {
  const {
    handleSubmit,
    isValid,
    isSubmitting,
    validateForm,
  } = useFormikContext();
  useEffect(() => {
    validateForm();
  }, [validateForm]);
  const disabled = Boolean(!isValid || isSubmitting);
  return (
    <>
      <SimpleInputField
        {...roles(ChangePasswordFormRole['email'])}
        name={Names.EMAIL}
        label={Names.EMAIL}
        fieldTypeProps={{ fieldType: 'TEXT' }}
      />
      <SimpleInputField
        {...roles(ChangePasswordFormRole['new-password1'])}
        name={Names.NEW_PASSWORD_1}
        label={Names.NEW_PASSWORD_1}
        fieldTypeProps={{ fieldType: 'TEXT', secureTextEntry: true }}
      />
      <SimpleInputField
        {...roles(ChangePasswordFormRole['new-password2'])}
        name={Names.NEW_PASSWORD_2}
        label={Names.NEW_PASSWORD_2}
        fieldTypeProps={{ fieldType: 'TEXT', secureTextEntry: true }}
      />
      <PrimaryButton
        {...roles(ChangePasswordFormRole['submit-reset-password'])}
        disabled={disabled}
        data-disabled={disabled}
        onPress={async (): Promise<void> => {
          await handleSubmit();
        }}
      >
        Reset password
      </PrimaryButton>
    </>
  );
};
type AJVError = { dataPath: string; message: string };
type ErrorObject = Record<string, string>;
// "any" depends on schema structure. Possible improvement define types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createDoValidate = (schema: ValidationSchema) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: Record<string, any>
): ErrorObject => {
  const errors: ErrorObject = {};
  const ajv = new Ajv({
    schemaId: 'auto',
    allErrors: true,
    jsonPointers: true,
  });
  AjvErrors(ajv);
  const validate = ajv.compile(schema);
  validate(values);
  const avjErrors = validate.errors as AJVError[];
  if (avjErrors) {
    // Add AJV errors to ErrorObject
    avjErrors.forEach((ajvError: AJVError): void => {
      const name = ajvError.dataPath.replace(/^./, '') as Names;
      errors[name] = ajvError.message.replace('$name', name);
    });
  }
  // Check New passwords match
  if (!errors[Names.NEW_PASSWORD_1] && !errors[Names.NEW_PASSWORD_2]) {
    if (values[Names.NEW_PASSWORD_1] !== values[Names.NEW_PASSWORD_2]) {
      errors[Names.NEW_PASSWORD_2] = 'New passwords must match';
    }
  }
  return errors;
};

type ChangePasswordFormProps = {
  schema?: ValidationSchema;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any; // "any" truthy value means error
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => // eslint-disable-next-line @typescript-eslint/no-explicit-any
  void | Promise<any>; // "any" response from apis
};
/**
 *
 */
const ChangePasswordForm = ({
  schema,
  onSubmit,
  error,
}: ChangePasswordFormProps): ReactElement => {
  return (
    <Container>
      {schema ? (
        <Formik
          initialValues={{
            [Names.EMAIL]: '',
            [Names.NEW_PASSWORD_1]: '',
            [Names.NEW_PASSWORD_2]: '',
          }}
          validate={createDoValidate(schema)}
          onSubmit={onSubmit}
        >
          <Form />
        </Formik>
      ) : null}
      {error ? (
        <ErrorText {...roles(ChangePasswordFormRole['form-error'])}>
          Sorry there was an error, try again
        </ErrorText>
      ) : null}
    </Container>
  );
};

export { ChangePasswordForm, ChangePasswordFormRole, Names };
