import React, { ReactElement, ReactNode } from 'react';
import { Form, OnValidateCallback } from 'components/forms';
import {
  onSubmitWithResponseOptionFactory,
  getSelectedResponseOptions,
} from './utils/onSubmitWithResponseOptionFactory';
import { Question, ResponseOption } from '@bighealth/types';
import { useSelector } from 'react-redux';
import {
  getFormikState,
  getFormikStateByPathFromFormikState,
  getQuestions,
} from 'state/question-response/selectors';
import { useQuizForCurrentScene } from 'lib/question-response/useQuizForCurrentScene';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { QuestionId } from 'state/question-response/actions';
import { FormikHelpers } from 'formik';

import { useGetFormikStatePersistPath } from 'lib/player/useActionHandler/useSubmitOnActionHandler';
import { castResponseOptionByResponseType } from './utils/castResponseOptionByResponseType';
import { castDropdownItemsByResponseType } from './utils/castDropdownItemsByResponseType';
import { questionsAreLoaded } from './utils/questionsAreLoaded';
import { ValidateIfDataChanges } from 'components/forms/utils/ValidateIfDataChanges';

export const QUIZ_FAILED_THROWABLE = { message: 'Quiz failed' };

export type OnSubmitCallback = (
  values: Record<QuestionId, ResponseOption[]>,
  items: Record<QuestionId, DropdownItem[]>,
  helpers: FormikHelpers<Record<QuestionId, DropdownItem[]>>
) => void;

export type SetSubmitting = (val: boolean) => void;

export type OnSubmitOptions = { setSubmitting: SetSubmitting };

export type ResponseFormProps = {
  children: ReactNode;
  onSubmit: (
    values: Record<QuestionId, ResponseOption[]>,
    { setSubmitting }: OnSubmitOptions
  ) => void;
  onValidate?: OnValidateCallback;
  triggerValidateHash?: string;
};

/**
 *
 * @param {string} options.triggerValidateHash Changes will trigger form validation using ValidateIfDataChanges
 *
 */
const ResponseForm = ({
  onSubmit,
  children,
  onValidate,
  triggerValidateHash,
}: ResponseFormProps): ReactElement => {
  const questions: Question[] = useSelector(getQuestions);
  const quiz = useQuizForCurrentScene();
  const formikPersistPath = useGetFormikStatePersistPath();
  const formikState = useSelector(getFormikState);
  const { values, errors } = getFormikStateByPathFromFormikState(
    formikState,
    formikPersistPath
  );

  const handleSubmit: OnSubmitCallback = (options, items, helpers) => {
    quiz.submitQuizAnswers(options);
    if (!quiz.checkQuizAnswers(options)) {
      // If failed, try force complete...
      const forcedItems = quiz.tryForceComplete(items, helpers.setValues);
      const forcedOptions = getSelectedResponseOptions(questions, forcedItems);
      if (quiz.checkQuizAnswers(forcedOptions)) {
        options = forcedOptions;
      }
    }
    if (quiz.checkQuizAnswers(options)) {
      // quiz correct
      onSubmit(castResponseOptionByResponseType(options, questions), helpers);
      helpers.setSubmitting(false);
    } else {
      helpers.setSubmitting(false);
      throw QUIZ_FAILED_THROWABLE;
    }
  };
  return (
    <Form
      // Absolutely definitely force this to be a new Formik
      key={formikPersistPath}
      onSubmit={onSubmitWithResponseOptionFactory({
        onSubmit: handleSubmit,
        questions,
      })}
      onValidate={
        onValidate && questions.length !== 0
          ? items => {
              if (!questionsAreLoaded(items, questions)) {
                return {}; // no-op if questions not in state
              }
              return onValidate(
                castDropdownItemsByResponseType(items, questions)
              );
            }
          : undefined
      }
      initialValues={values}
      initialErrors={errors}
    >
      <ValidateIfDataChanges data={triggerValidateHash} />
      {children}
    </Form>
  );
};

export { ResponseForm };
