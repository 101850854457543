import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { SleepEfficiencyTrendPageDataDescResponse } from '@bighealth/types';

export const get_sleep_efficiency_trend_page_data_desc = async (
  args: get_sleep_efficiency_trend_page_data_desc.Args
): Promise<get_sleep_efficiency_trend_page_data_desc.Response> => {
  return await api({
    service_name: 'SleepEfficiencyTrend',
    service_version: '1',
    service_method: 'get_sleep_efficiency_trend_page_data_desc',
    args: args,
  } as get_sleep_efficiency_trend_page_data_desc.Request);
};

get_sleep_efficiency_trend_page_data_desc.queryKey =
  'SleepEfficiencyTrend/get_sleep_efficiency_trend_page_data_desc';

export declare namespace get_sleep_efficiency_trend_page_data_desc {
  export type Args = {
    page: number;
    items_per_page: number;
    user_timezone: string;
    product_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'SleepEfficiencyTrend';
    service_version: '1';
    service_method: 'get_sleep_efficiency_trend_page_data_desc';
    args: Args;
  }

  export type Result = SleepEfficiencyTrendPageDataDescResponse;

  export type Response = APIResponse<Result>;
}
