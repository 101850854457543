import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import React from 'react';
import { Presentation } from '../Presentation';
import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

export type Props = RoleProps & {
  data?: get_user_session_screen_data.Result;
};
/**
 * SessionProgress: Catch-all for ephemeral state
 *
 * FIXME remove. Will do after initial CR to avoid huge diff
 * WHY Initially boiler-plated without specific need
 *
 * @param {Props} props
 */
const WithState = ({ data, ...rest }: Props): React.ReactElement | null => {
  return data ? <Presentation {...roles.pass(rest)} data={data} /> : null;
};

export { WithState };
