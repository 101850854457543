import React, { ReactElement, ReactNode, useContext, useState } from 'react';
import ScreenOrientation, {
  OrientationType,
  useOrientationChange,
} from 'react-native-orientation-locker';

export const ScreenOrientationContext = React.createContext<OrientationType>(
  ScreenOrientation.getInitialOrientation()
);

export const ScreenOrientationProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [screenOrientation, setScreenOrientation] = useState(
    ScreenOrientation.getInitialOrientation()
  );
  useOrientationChange((o: OrientationType) => {
    setScreenOrientation(o);
  });
  return (
    <ScreenOrientationContext.Provider value={screenOrientation}>
      {children}
    </ScreenOrientationContext.Provider>
  );
};

export const useScreenOrientation = (): OrientationType =>
  useContext(ScreenOrientationContext);
