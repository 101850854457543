import React, { ReactElement, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBlurQuestion } from 'components/Modal/useBlurQuestion';
import { getControlsAreVisible } from 'state/player/selectors';
import { RoleProps } from 'cross-platform/utils/roleProps';
import { showControls } from 'state/player/actions';
import { CSSProperties } from 'styled-components';

/**
 * Web component for the dismisser: we differentiate between Native and Web
 * because the TouchableOpacity (used in Native) blocks the
 * Input components to receive spaces.
 */
export const ControlsDismisser = ({
  role,
  style,
  children,
}: {
  role: RoleProps;
  style: CSSProperties;
  children: ReactNode;
}): ReactElement => {
  const dispatch = useDispatch();
  const blurQuestion = useBlurQuestion();
  const controlsAreVisible = useSelector(getControlsAreVisible);

  return (
    <div
      {...role}
      style={{
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
      onClick={() => {
        if (controlsAreVisible) {
          dispatch(showControls(false));
        }
        blurQuestion();
      }}
    >
      {children}
    </div>
  );
};
