import React, { ReactElement, useEffect, useState } from 'react';
import { getAssetsForProduct, getTextForProduct } from 'config';
import styled from 'styled-components/native';
import Image, { ResizeMode } from 'cross-platform/Image';
import { Text } from 'components/Text';
import {
  FacebookSignInButton,
  GoogleSignInButton,
  OnAuthCallback,
} from 'components/AuthButtons';
import {
  LoginPhases,
  useBigHealthLogin,
} from 'lib/api/hooks/useBigHealthLogin';
import { Redirect, useHistory, useParams } from 'cross-platform/react-router';
import logger from 'lib/logger';
import { showQAMenu } from 'lib/showQAMenu';
import { FillAbsolute, ScrollableContainer } from 'components/Containers';
import { TouchableOpacity } from 'components/primitives/cross-platform';
import {
  UniversalLinkButton,
  ButtonSizes,
  ButtonColorSchemes,
} from 'components/UniveralButtons';
import { FontFamilies } from 'components/sceneset-components/Text/fonts';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';
import { useRedirectIsAuthenticated } from 'lib/api/hooks/useRedirectIsAuthenticated';

const Container = styled(FillAbsolute)`
  align-items: center;
`;

const LimitWidth = styled.View`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  max-width: 380px;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
`;

const FormattedText = styled(Text)`
  font-size: 19px;
  color: white;
  text-align: center;
  font-family: '${FontFamilies.Regular}';
`;

const Logo = styled(Image)`
  width: 170px;
  height: 100px;
  align-self: center;
`;

const ButtonContainer = styled.View`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  align-items: stretch;
`;

const Spacer = styled.View`
  margin-bottom: 10px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const resIsError = (res: any): res is Error => {
  return !res.token;
};

type Params = {
  productReference: string;
};

const TitleScreen = (): ReactElement => {
  useUpdateTitle();
  useRedirectIsAuthenticated();

  const [thirdPartyError, setThirdPartyError] = useState<Error | null>(null);
  const [showThirdParty, setShowThirdParty] = useState(false);
  const { productReference } = useParams<Params>();
  const { splashLogoUrl } = getAssetsForProduct(productReference);
  const { splashText } = getTextForProduct(productReference);
  const { login, error: bigHealthAuthError, status } = useBigHealthLogin();
  const history = useHistory();

  const error = bigHealthAuthError || thirdPartyError;
  useEffect(() => {
    if (error) {
      logger(error.message, error);
    }
  }, [error]);

  useEffect(() => {
    // The Google and Facebook buttons slow down the initial page load a LOT so we'll hide
    // them until mounted
    setShowThirdParty(true);
  }, []);

  if (status === LoginPhases.SUCCESS) {
    return <Redirect to={`/${productReference}/home`} />;
  }
  const handleFacebookAuth: OnAuthCallback = async res => {
    try {
      if (resIsError(res)) {
        throw res;
      }
      await login({ facebookToken: res.token });
    } catch (e) {
      setThirdPartyError(e);
    }
  };

  const handleGoogleAuth: OnAuthCallback = async res => {
    try {
      if (resIsError(res)) {
        throw res;
      }
      await login({ googleToken: res.token });
    } catch (e) {
      setThirdPartyError(e);
    }
  };

  const onLogoPress = (): void => {
    showQAMenu(productReference, history);
  };

  return (
    <Container>
      <LimitWidth>
        <TouchableOpacity testID={'DeveloperButton'} onPress={onLogoPress}>
          <Logo src={splashLogoUrl} fit={ResizeMode.contain} />
        </TouchableOpacity>
        <FormattedText>{splashText}</FormattedText>
        <ButtonContainer>
          <ScrollableContainer>
            <Spacer>
              <UniversalLinkButton
                to={`/${productReference}/login`}
                title={'Login with Email'}
                text={'Login with Email'}
                colorScheme={ButtonColorSchemes.Login}
                size={ButtonSizes.Small}
              />
            </Spacer>
            {showThirdParty ? (
              <>
                <Spacer>
                  <GoogleSignInButton onAuth={handleGoogleAuth} />
                </Spacer>
                <FacebookSignInButton onAuth={handleFacebookAuth} />
              </>
            ) : null}
          </ScrollableContainer>
        </ButtonContainer>
      </LimitWidth>
    </Container>
  );
};

export default TitleScreen;
