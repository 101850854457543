import React, { FunctionComponent, useContext } from 'react';
import { CalendarProps } from '@bighealth/types/src/services/SleepDiaryPayloads/get_sleep_diary_component_data_for_time_period/';
import { getTileBackgroundColor } from './utils/getTileBackgroundColor';
import {
  CalendarContainer,
  CalendarTileContainer,
  CalendarTile,
  CalendarTileDayOfWeek,
  CalendarTileDayOfMonth,
  CalendarViewContainer,
  CalendarTileButtonLabel,
  CalendarTileInner,
  CalendarExtrapolateButtonContainer,
} from './styled';

import { ExtrapolateButton } from './ContinueButton';

import { PagingButton } from './PagingButton';
import { roles } from 'cross-platform/utils/roleProps';
import { Scene } from '@bighealth/types/dist/scene-component';
import {
  ScreenResponsiveLayoutContext,
  useGetDynamicContentStyles,
  useTransformContentStyle,
} from 'components/ResponsiveLayout';
import { NavBarStyles } from 'components/Navigation/constants';

type CalendarPropsWithActions = CalendarProps & {
  title: {
    text: string;
    fontSize: number;
    color: string;
  };
  titleAlignment: Scene.Presentational.Alignment;
  subtitle: {
    fontSize: number;
    color: string;
  };
  subtitleAlignment: Scene.Presentational.Alignment;
  pageForwardPress: () => void;
  pageBackwardPress: () => void;
  onPressCalendarTile: (diary_date: string | undefined) => void;
  shouldShowContinueButton: boolean;
  isContinueButtonEnabled?: boolean;
  continueButtonText?: string;
  shouldContinueButtonExtrapolate: boolean;
  setIsLoading: (loading: boolean) => void;
};

/**
 * Component for Web.
 * Based on the sceen width we differentiate between Mobile and Desktop.
 */
const Calendar: FunctionComponent<CalendarPropsWithActions> = (
  props: CalendarPropsWithActions
) => {
  const pageBackButtonEnabled = props.has_prev;
  const pageBackwardPress = props.pageBackwardPress;
  const pageForwardButtonEnabled = props.has_next;
  const pageForwardPress = props.pageForwardPress;

  const scaleFn = useTransformContentStyle();
  const styles = useGetDynamicContentStyles();
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  const isMobileWeb =
    screenWidth <= NavBarStyles.navBarCollapseMobileWebBreakPointPx;

  const calendarTileButtonLabelCompleted = scaleFn(30);
  const dayOfMonthFontSize = scaleFn(60);

  return (
    <CalendarContainer
      {...roles(`sleepDiaryCalendarContainer`)}
      style={{
        paddingBottom: styles.sleepDiaryWeekCalendarContainerPaddingBottom,
      }}
    >
      <CalendarViewContainer
        style={{
          marginTop: styles.sleepDiaryWeekCalendarViewContainerMarginTop,
        }}
      >
        <PagingButton
          {...roles('sleepDiaryCalendarPagingButtonLeft')}
          disabled={!pageBackButtonEnabled}
          onPress={pageBackwardPress}
          icon={'left'}
          iconSize={styles.sleepDiaryWeekCalendarIconSize}
        />
        <CalendarTileContainer>
          {props.days.map((day, index) => (
            <CalendarTile
              {...roles(`sleepDiaryCalendarTile${index}`)}
              disabled={day.status === 'LOCKED' || !day.click_action}
              key={index}
              style={{
                backgroundColor: getTileBackgroundColor(day.status),
                paddingBottom: 9,
              }}
              onPress={() => props.onPressCalendarTile(day.date)}
            >
              <CalendarTileInner>
                <CalendarTileDayOfWeek
                  {...day.day_name}
                  style={{
                    fontSize: isMobileWeb ? scaleFn(24) : scaleFn(18),
                    marginTop:
                      styles.sleepDiaryWeekCalendarTileDayOfWeekMarginTop,
                  }}
                />
                <CalendarTileDayOfMonth
                  {...day.day_of_month}
                  style={{
                    fontSize: dayOfMonthFontSize,
                  }}
                />
                {day.metrics && day.status == 'COMPLETE' ? (
                  <CalendarTileButtonLabel
                    {...day.metrics[0].value}
                    {...roles(`sleepDiaryCalendarTileEditButton${index}`)}
                    fontSize={calendarTileButtonLabelCompleted}
                    color={'#ff6c00'}
                    style={{ fontWeight: 700 }}
                  />
                ) : null}
                {day.status == 'MISSING' ? (
                  <CalendarTileButtonLabel
                    {...roles(`sleepDiaryCalendarTileCreateButton${index}`)}
                    text={'+Add'}
                    fontSize={isMobileWeb ? scaleFn(26) : scaleFn(19)}
                    color={'#ffffff'}
                    style={{ fontWeight: 700 }}
                  />
                ) : null}
              </CalendarTileInner>
            </CalendarTile>
          ))}
        </CalendarTileContainer>
        <PagingButton
          {...roles('sleepDiaryCalendarPagingButtonRight')}
          disabled={!pageForwardButtonEnabled}
          onPress={pageForwardPress}
          icon={'right'}
          iconSize={styles.sleepDiaryWeekCalendarIconSize}
        />
      </CalendarViewContainer>
      {props.shouldShowContinueButton ? (
        <CalendarExtrapolateButtonContainer
          style={{
            marginTop: styles.sleepDiaryWeekCalendarExtrapolateButtonMarginTop,
          }}
        >
          <ExtrapolateButton
            disabled={!props.isContinueButtonEnabled}
            setIsLoading={props.setIsLoading}
            text={props.continueButtonText || 'Continue'}
            shouldExtrapolate={props.shouldContinueButtonExtrapolate}
          />
        </CalendarExtrapolateButtonContainer>
      ) : null}
    </CalendarContainer>
  );
};

export { Calendar };
