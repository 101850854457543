import { QueryObserverResult, useQuery } from 'react-query';
import { APIErrorResponse } from '@bighealth/api';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { read_bulk } from '@bighealth/api/SceneSetGraph/v1';
import { getWhichHardCodedSceneSetGraph } from 'developer/helpers';

type Caller = typeof read_bulk;

export type ReadBulkResponseType = QueryObserverResult<
  read_bulk.Response,
  APIErrorResponse
>;

export const useQuerySceneSetGraphsBulk = (): ReadBulkResponseType => {
  const { sceneSetGraphId } = useSafeParams();
  const hardCodedSSG = getWhichHardCodedSceneSetGraph(sceneSetGraphId);
  const result = useQuery({
    queryKey: ['SceneSetGraph', sceneSetGraphId],
    queryFn: () => {
      if (hardCodedSSG) {
        const caller = () => ({
          status_code: 200,
          result: [hardCodedSSG],
        });
        return (caller as unknown) as ReturnType<Caller>;
      }
      return read_bulk({
        entity_ids: [sceneSetGraphId],
      });
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: false,
  });
  return result;
};
