import { isDevMode } from 'lib/isDevMode';
import { Scene } from '@bighealth/types';

/**
 * Debug data
 */
const getSceneToThrowInModal = (): Scene => ({
  component: 'Scene',
  childNodes: [
    {
      component: 'Modal',
      childNodes: isDevMode()
        ? [
            {
              component: 'DebugThrow',
            },
          ]
        : [],
    },
  ],
});

export { getSceneToThrowInModal };
