import React from 'react';
import { Text } from 'react-native';
import { addStory } from 'lib/story-books';
import { Grid, Row, Col } from 'components/layout/Grid';
import { iconsDictionary } from '.';

addStory('iconsDictionary', () => (
  <Grid>
    {Object.entries(iconsDictionary).map(([name, Component]) => (
      <Row key={name}>
        <Col>
          <Text>{name}</Text>
          <Component />
        </Col>
      </Row>
    ))}
  </Grid>
));
