import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { APIErrorResponse } from '@bighealth/api';
import { get_assigned_program_with_user_and_product } from '@bighealth/api/UserAccountAuthorization/v1';
import { useQueryProduct } from './useQueryProduct';

export const useQueryProgram = (): UseQueryResult<
  get_assigned_program_with_user_and_product.Response,
  APIErrorResponse
> => {
  const productId = useQueryProduct().data?.result?.id;

  const result = useQuery({
    queryKey: get_assigned_program_with_user_and_product.queryKey,
    queryFn: () => {
      return get_assigned_program_with_user_and_product({
        product_id: productId as number,
      });
    },
    enabled: typeof productId === 'number',
    cacheTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });
  return result;
};
