import styled from 'styled-components/native';
import { TextPresentation } from 'components/sceneset-components/Text/TextPresentation';

import { TouchableOpacity as TouchableOpacityUnstyled } from 'components/primitives/cross-platform';
import { LINK_DISPLAY_VALUE } from '../../constants';
import { TEXT_FONT_SIZE } from '../../../../styled';

export const LinkText = styled(TextPresentation).attrs({ underline: true })`
  color: #368bae;
  display: ${LINK_DISPLAY_VALUE};
  font-weight: 400;
  font-size: ${TEXT_FONT_SIZE}px;
`;
LinkText.displayName = 'LinkText';

export const TouchableOpacity = styled(TouchableOpacityUnstyled)`
  display: ${LINK_DISPLAY_VALUE};
`;
TouchableOpacity.displayName = 'TouchableOpacity';
