// NOTE: This file is only for 📖 Storybook & ✅ Tests
import React from 'react';
import { Button, View, Text } from 'react-native';
import { FallbackProps } from 'lib/error/ErrorBoundary/types';
import { roles } from 'cross-platform/utils/roleProps';

const CustomErrorFallback: React.ComponentType<FallbackProps> = ({
  error,
  resetErrorBoundary,
}: FallbackProps) => {
  return (
    <View>
      <Text>{`Custom error fallback: ${error.message}`}</Text>
      <Button
        {...roles('CustomErrorFallback.Button')}
        onPress={resetErrorBoundary}
        title="try-again"
      >
        Try again
      </Button>
    </View>
  );
};

export { CustomErrorFallback };
