import React, { ReactElement } from 'react';
import { Svg, G, Circle, Path } from 'react-native-svg';
import { useGetDynamicPlayerControlsStyles } from 'components/ResponsiveLayout';
import { StyleProp, ViewProps } from 'react-native';
import { roles } from 'cross-platform/utils/roleProps';

export const PauseButtonIcon = ({
  style,
}: {
  style?: StyleProp<ViewProps>;
}): ReactElement => {
  const { playPauseIconSize } = useGetDynamicPlayerControlsStyles();
  return (
    <Svg
      {...roles('PauseButtonIcon')}
      width={playPauseIconSize}
      height={playPauseIconSize}
      style={style}
      viewBox="0 0 100 100"
    >
      <G fill="none" fillRule="evenodd">
        <Circle cx="49.668" cy="49.668" r="49.668" fill="#FFF" opacity=".3" />
        <Circle cx="49.668" cy="49.668" r="41.083" fill="#FFF" />
        <Path fill="#00506B" d="M46 29v43h-8V29h8zm16 0v43h-8V29h8z" />
      </G>
    </Svg>
  );
};

export const PlayButtonIcon = ({
  style,
}: {
  style?: StyleProp<ViewProps>;
}): ReactElement => {
  const { playPauseIconSize } = useGetDynamicPlayerControlsStyles();
  return (
    <Svg
      {...roles('PlayButtonIcon')}
      width={playPauseIconSize}
      height={playPauseIconSize}
      style={style}
      viewBox="0 0 100 100"
    >
      <G fill="none" fillRule="evenodd">
        <Circle cx="49.668" cy="49.668" r="49.668" fill="#FFF" opacity=".3" />
        <Circle cx="49.668" cy="49.668" r="41.083" fill="#FFF" />
        <Path
          fill="#00506B"
          d="M52.663 35.911L72.624 64.604 32.703 64.604z"
          transform="rotate(90 52.663 50.257)"
        />
      </G>
    </Svg>
  );
};

export const CloseButtonIcon = ({
  style,
  size,
  color,
}: {
  style?: StyleProp<ViewProps>;
  color?: string;
  size: number;
}): ReactElement => {
  return (
    <Svg width={size} height={size} style={style} viewBox="0 0 40 40">
      <Path
        fill={color || '#036'}
        fillRule="evenodd"
        d="M100 79L96 75 80 91 64 75 60 79 76 95 60 111 64 115 80 99 96 115 100 111 84 95z"
        transform="translate(-60 -75)"
      />
    </Svg>
  );
};

export const CloseButtonIconResponsive = ({
  style,
  color,
}: {
  style?: StyleProp<ViewProps> & { color?: string };
  color?: string;
}): ReactElement => {
  const { closeButtonSize } = useGetDynamicPlayerControlsStyles();
  return <CloseButtonIcon size={closeButtonSize} style={style} color={color} />;
};
