import React, { ReactElement, CSSProperties } from 'react';
import styled from 'styled-components';
import { ButtonTypes } from 'common/constants/enums';
import { StyleSheet } from 'react-native';
import {
  ButtonInternals,
  EnhancedButtonProps,
  linkStyles,
  PressHandler,
} from './common';
import { SizeAttribute, QuizAttribute } from 'components/constants';
import { Button as ButtonWeb } from 'components/primitives/web-only';
import { roles } from 'cross-platform/utils/roleProps';
import { getTestIdValue } from 'cross-platform/utils/roleProps/getTestId';
const flatten = StyleSheet.flatten;

const Touchable = styled(ButtonWeb)<EnhancedButtonProps>`
  ${linkStyles}
  appearance: none;
  padding: 0;
  background-color: transparent;
  border: 0;
  margin-top: ${({ align }: { align?: string }): string =>
    align === 'bottom' ? 'auto' : '10px'};
  opacity: ${({ disabled }): number => (disabled ? 0.5 : 1)};
  cursor: pointer;
`;

export type PressButtonProps = {
  text: string;
  align?: string;
  type?: ButtonTypes;
  onPress: PressHandler;
  disabled?: boolean;
  active?: boolean;
  pressed?: boolean;
  style?: CSSProperties;
  testID?: string;
  size?: SizeAttribute;
  quiz?: QuizAttribute;
};

const PressButton = ({
  text,
  type = ButtonTypes.PRIMARY_BUTTON,
  align,
  onPress,
  disabled,
  style,
  ...rest
}: PressButtonProps): ReactElement => {
  // TODO: we switched this from TouchableOpacity to DOM button as a last-minute fix
  // to avoid duplicate clicks. However, DOM button has a concept of `type` which
  // is not consistent with ours. So we need to refactor the style `type` everywhere
  // to something like "buttonType" to avoid clash.
  // Additionally, onClick and onPress are now incompatible, hence the `any`
  return (
    <Touchable
      {...rest}
      {...roles(getTestIdValue(rest) || text || 'PressButton')}
      title={text}
      onClick={disabled ? undefined : (onPress as (event: unknown) => void)}
      type={type}
      align={align}
      disabled={disabled}
      style={flatten(style)}
    >
      <ButtonInternals type={type} text={text} />
    </Touchable>
  );
};

export default PressButton;
export { PressButton };
