import React, { ReactElement } from 'react';
import { clone } from 'ramda';
import { addStory } from 'lib/story-books';
import { getThemeForProduct } from 'config/getThemeForProducts';
import { ProductReferences } from 'common/constants/enums';
import { useFakeStorybookSceneSet } from 'lib/story-books/useFakeStorybookSceneSet';
import { CrossPlatformThemeProvider } from 'components/ProvidersContainer/ProductThemeProvider';
import { EphemeralStateProvider } from 'components/EphemeralStateProvider';
import { mockRes17Sep2020 } from 'components/SleepDiaryForm/__mocks__/17Sep2020';
import { FlowingForm } from '..';

const payload = mockRes17Sep2020.result;

export const createConditions = (val: typeof payload): typeof val => {
  const newVal = clone(val);
  const tryToSleep = newVal.form.sections[0].group.find(
    comp =>
      comp.questionProps.semantic_id === 'sleep_efficiency_time_try_to_sleep'
  );
  if (!tryToSleep) {
    throw Error('Expected to find sleep_efficiency_time_try_to_sleep in mock');
  }
  // IDEA set conditions to show warning
  // WHEN ResponseIInput's prop "initialValue" works
  return newVal;
};
export const SleepDiaryWithDefaults = (): ReactElement | null => {
  const sceneSets = useFakeStorybookSceneSet();
  if (!sceneSets) {
    return null;
  }
  return <FlowingForm {...createConditions(payload)} />;
};

export const SleepDiaryStory = (): ReactElement => (
  <EphemeralStateProvider>
    <CrossPlatformThemeProvider
      theme={getThemeForProduct(ProductReferences.SLEEPIO)}
    >
      <SleepDiaryWithDefaults />
    </CrossPlatformThemeProvider>
  </EphemeralStateProvider>
);
addStory('sleep-diary/FlowingForm warningsAndHighlights', () => (
  <SleepDiaryStory />
));
