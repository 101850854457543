import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { APIErrorResponse } from '@bighealth/api';
import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';
import { getTimezone } from 'lib/timezone';
import { useQueryProgram } from './useQueryProgram';

export const useQueryAllForSessionScreen = (): UseQueryResult<
  get_user_session_screen_data.Response,
  APIErrorResponse
> => {
  const programId = useQueryProgram().data?.result?.id;
  const result = useQuery({
    queryKey: get_user_session_screen_data.queryKey,
    queryFn: () =>
      get_user_session_screen_data({
        program_id: programId as number,
        user_timezone: getTimezone(),
      }),
    enabled: typeof programId === 'number',
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
  });
  return result;
};
