import { useEffect, useRef } from 'react';
import { mediaPlayerOrchestrator } from './MediaPlayerOrchestrator';
import { AVRefNode } from './types';
import { SceneAction } from '@bighealth/types/src/scene-components/client';
import useActionHandler from 'lib/player/useActionHandler';

/**
 * Attach this to any AVPlayer you want to control with the orchestrator
 */

export const useMediaNodeOrchestrator = (action?: SceneAction): AVRefNode => {
  const actionHandler = useActionHandler(action);
  const node = useRef(null);
  useEffect(() => {
    // Yes, we definitely want this every render or we won't get the new props set for each
    // node in the orchestrator or MediaNode
    const update = async () => {
      await mediaPlayerOrchestrator.registerOrUpdateNode(node, {
        onAction: actionHandler,
      });
    };
    update();
  });
  return node;
};
