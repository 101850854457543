import React from 'react';
import { Linking } from 'react-native';
import * as roleProps from 'cross-platform/utils/roleProps';
import { RoleProps } from 'cross-platform/utils/roleProps';
import { LinkText, TouchableOpacity } from '../components/Link/styled';

type LinkProps = {
  href: string;
  children: React.ReactNode;
} & RoleProps;

/**
 * Used by Markdown component
 *
 * IDEA use generic util (see other linkRenderers)
 * @param {LinkProps} props
 */
const linkRenderer: React.FC<LinkProps> = ({
  children,
  href,
  ...restProps
}: LinkProps) => {
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      /**
       * IDEA use <a href=""/>
       * WHEN react-router-dom supports mailto
       * @see {@link https://stackoverflow.com/a/63819003}
       */
      return <LinkText {...child.props} />;
    }
    return child;
  });

  return (
    <TouchableOpacity
      {...roleProps.pass(restProps)}
      onPress={() => Linking.openURL(href)}
    >
      {childrenWithProps}
    </TouchableOpacity>
  );
};

export { linkRenderer };
