import React, { ReactElement, useContext } from 'react';
import { View } from 'react-native';
import { Grid, TopRow, BottomRow } from './styled';
import { SessionStatus, SessionStatusProps } from './components/SessionStatus';
import { DistributionPlatformList } from 'components/DistributionPlatformList';
import { NavBarStyles } from 'components/Navigation/constants';
import {
  ScreenResponsiveLayoutContext,
  useTransformContentStyleVertical,
} from 'components/ResponsiveLayout';
import { SessionList } from './components/SessionList';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

type Props = RoleProps & {
  data: get_user_session_screen_data.Result;
};
/**
 * SessionScreen: Simple render of payload of User session screen
 *
 * @param {Props} props
 */
const Presentation = (props: Props): ReactElement => {
  const scaleYFn = useTransformContentStyleVertical();
  const scaleYValue = scaleYFn(1);
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);

  if (screenWidth <= NavBarStyles.navBarCollapseMobileWebBreakPointPx) {
    let mobileWebButtons: typeof props.data.session_status.buttons = [];
    if (props.data.session_status.buttons.length > 1) {
      mobileWebButtons = [props.data.session_status.buttons[1]];
    } else if (props.data.session_status.buttons.length > 0) {
      mobileWebButtons = [props.data.session_status.buttons[0]];
    }
    const newSessionStatus = {
      heading: { text: 'Welcome back' },
      buttons: mobileWebButtons,
    };
    return (
      <Grid {...roles.pass(props)}>
        <TopRow>
          <SessionStatus {...(newSessionStatus as SessionStatusProps)} />
        </TopRow>
        <BottomRow scaleYValue={scaleYValue}>
          <View
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DistributionPlatformList
              productName={'Sleepio'}
              showCaption={true}
            />
          </View>
        </BottomRow>
      </Grid>
    );
  }

  return (
    <Grid {...roles.pass(props)}>
      <TopRow>
        <SessionStatus {...(props.data.session_status as SessionStatusProps)} />
      </TopRow>
      <BottomRow scaleYValue={scaleYValue}>
        <SessionList data={props.data.session_list} />
      </BottomRow>
    </Grid>
  );
};

export { Presentation };
