import { ComponentProps } from 'react';
import { SleepDiaryForm } from '..';

type Props = ComponentProps<typeof SleepDiaryForm>;

const SleepDiaryFormMocks: Record<string, Props> = {
  create: {
    diary_date: '2020-06-03',
    form_type: 'create_single_day_entry',
  },

  update: {
    diary_date: '2020-06-03',
    form_type: 'update_single_day_entry_with_date',
  },
  'weekly average': {
    form_type: 'create_from_weekly_average_estimate',
  },
};

export { SleepDiaryFormMocks };
