import { useCallback } from 'react';
import { useHistory } from 'cross-platform/react-router';
import { queryClientLogout } from '../queryClientAnalogs/queryClientLogout';
import { logger } from 'lib/logger';
import { GENERAL_ERROR_MESSAGE } from 'lib/strings';
import { STATE_RESET } from 'state/store/actions';
import { useDispatch } from 'react-redux';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { getQueryClient } from 'components/ProvidersContainer';

type Callback = () => Promise<void>;

export const useLazyQueryLogout = (): Callback => {
  const { productReference } = useSafeParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = getQueryClient();
  return useCallback(async () => {
    try {
      await queryClientLogout();
    } catch (e) {
      logger(`${GENERAL_ERROR_MESSAGE} logging out`, e);
      // NOOP
    } finally {
      queryClient.clear();
      dispatch({ type: STATE_RESET });
      history.push(`/${productReference}/login`);
    }
  }, [dispatch, history, productReference, queryClient]);
};
