import styled from 'styled-components/native';
import { UniversalButton } from 'components/UniveralButtons';
import { ErrorText as ErrorTextUnstyled } from 'components/Text/ErrorText';
import { PageTitle as PageTitleUnstyled } from 'components/Screens/ContentScreens';

// IDEA Remove TEXT_FONT_SIZE
// HOW Styles that use TEXT_FONT_SIZE replaced with style object
// WHEN Want to sort out types of Text
export const TEXT_FONT_SIZE = 24; // Variable to avoid type issues.

export const Wrapper = styled.View`
  flex-direction: column;
  background-color: white;
`;
Wrapper.displayName = 'Wrapper';

export const PageTitle = styled(PageTitleUnstyled)`
  color: #003366;
  font-size: 46px;
`;
PageTitle.displayName = 'PageTitle';

export const DevModeErrorText = styled(ErrorTextUnstyled)`
  margin-bottom: 24px;
`;
DevModeErrorText.displayName = 'DevModeErrorText';

export const MarkdownWrapper = styled.View`
  margin-bottom: 24px;
`;
MarkdownWrapper.displayName = 'MarkdownWrapper';

export const Button = styled(UniversalButton)`
  max-width: 353px;
  margin-bottom: 24px;
`;
Button.displayName = 'Button';

export const Line = styled.View`
  display: flex;
`;
Line.displayName = 'Line';
