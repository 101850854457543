import { useCallback } from 'react';
import { useGetDynamicModalStyles } from './useGetDynamicModalStyles';
import { UseScaleToContextCallback } from './useScaleToScalingContext';
import { ModalStyles } from '../constants';

export const useScaleToModal = (): UseScaleToContextCallback => {
  const { width } = useGetDynamicModalStyles();
  return useCallback(
    valueToScale => {
      const scale = ModalStyles.maxWidthPx / width;
      return valueToScale / scale;
    },
    [width]
  );
};
