import { DatesAndTimesValueObject, Question } from '@bighealth/types';
import { getTimeString } from './utils/getTimeString';

// IDEA Can  offset to local
// const tzoffset = new Date().getTimezoneOffset() * MINUTE; //offset in milliseconds
// const localISOTime = new Date(date.valueOf() + tzoffset);

const toValueObject = (
  responseType: Question['response_type'],
  date?: Date
): DatesAndTimesValueObject | never => {
  // the backend uses dateutil to parse dates and times. According to the documentation,
  // the precedence  by default is the US format
  // http://niemeyer.net/python-dateutil#head-b95ce2094d189a89f80f5ae52a05b4ab7b41af47
  // so we use that because MURICA
  const unambiguousLocale = 'en-US';
  if (date instanceof Date) {
    switch (responseType) {
      case 'datetime':
        return {
          $datetime: date.toISOString(),
        };
      case 'date':
        //  If a user answers a date question we should send back the time they
        // actually input. Since the DatePicker will the input as a Date, it will
        // automatically be stored as ms from 1970/1/1 UTC (that's just how JS stores times).
        // Therefore we convert it to the LOCAL date string, such that we get that UTC date back
        // to the user's selection
        //
        // The user locale may be ambiguous
        return { $date: date.toLocaleDateString(unambiguousLocale) };
      case 'time':
        return {
          $time: getTimeString(date),
        };
      default:
        throw TypeError(
          `Unknown responseType: was expecting 'datetime', 'date' or 'time', instead got "${responseType}" (${typeof responseType})`
        );
    }
  } else {
    throw TypeError(
      `Unknown date: was expecting instance of Date, instead got "${date}" (${typeof date})`
    );
  }
};
export { toValueObject };
