import { MINUTE, HOUR } from 'lib/durations';

const INTERVAL_MS = 15 * MINUTE;

const deriveDurationResponseOptions = (options: {
  min?: number;
  max?: number;
}): number[] => {
  const min = options?.min || 0;
  const max = options?.max || 24 * HOUR;

  const entries = Math.ceil(max / INTERVAL_MS);

  const generated = [...Array<number>(entries + 1)]
    // increment by position
    .map((_, i) => INTERVAL_MS * i);
  return (
    [
      // presets
      1 * MINUTE,
      5 * MINUTE,
      10 * MINUTE,
      // Generated
      ...generated,
    ]
      // Filter min
      .filter(stepMs => stepMs >= min)
      .filter(stepMs => stepMs < 2 * HOUR || stepMs % (0.5 * HOUR) === 0)
      .filter(stepMs => stepMs <= max)
      .sort((a, b) => a - b)
  );
};
export { deriveDurationResponseOptions };
