/* eslint-disable camelcase */
import { APIResponse } from '@bighealth/api';
import { SceneSetResponse, DeepWritable, SceneSet } from '@bighealth/types';
import { transformToJavaScriptDate } from '../transformToJavaScriptDate';

/**
 * Transform only the scene_set_json (for performance reasons)
 *
 * Usually client code is expected to treat response payloads as read-only.
 *
 * But this special "middleware" code mimics backend logic
 * @see {@link https://expressjs.com/en/guide/using-middleware.html}
 *
 * It thus has a higher "permission" than normal client-app code,
 * which is why we use DeepWritable to allow changes to the payloads.
 *
 * IDEA remove, and just transform entire payload
 * WHY less code
 * WHEN confident perf will not be an issue
 * BACKGROUND I guess when I wrote this, I figured scene_set_json
 * was the only field to have data types. A few new cases:
 * - mock data
 * - Custom non-content features e.g. SleepDiary
 * @param response
 */
const transformResponseWithSceneSetJSON = (
  response: APIResponse
): APIResponse => {
  const responseWritable = response as DeepWritable<
    APIResponse<SceneSetResponse[] | SceneSetResponse>
  >;
  if (responseWritable.result === null) {
    return responseWritable as APIResponse;
  }

  responseWritable.result = Array.isArray(responseWritable.result)
    ? responseWritable.result.map((sceneSetResponse: SceneSetResponse) => ({
        ...sceneSetResponse,
        scene_set_json: transformToJavaScriptDate<SceneSet>(
          sceneSetResponse.scene_set_json
        ),
      }))
    : ({
        ...responseWritable.result,
        scene_set_json: transformToJavaScriptDate<SceneSet>(
          responseWritable.result.scene_set_json
        ),
      } as SceneSetResponse);
  return responseWritable as APIResponse;
};

export { transformResponseWithSceneSetJSON };
