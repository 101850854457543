import { ErrorInfo } from 'react';
import { isDevMode } from './isDevMode';
import { isIE11OrLess } from './isIE11OrLess';

type LoggerOptions = {
  silent?: boolean;
  silentUnlessDevOnly?: boolean;
  errorInfo?: ErrorInfo;
};

const logToSentry = isIE11OrLess ? () => undefined : console?.info;

export const logger = (
  message: string,
  error?: Error,
  { silent, silentUnlessDevOnly, errorInfo }: LoggerOptions = {}
): void => {
  // Just arbitrary functions for now. Will build out with Sentry etc later
  if (!silent || (silentUnlessDevOnly && isDevMode())) {
    alert(message);
  }
  if (silent === true) {
    console.info(message, error, errorInfo);
  } else {
    console.error(message, error, errorInfo);
  }
  logToSentry();
};

export default logger;
