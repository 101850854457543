import React, { ReactElement } from 'react';
import { Text as TextProps } from '@bighealth/types/src/scene-components/client';
import { View } from 'react-native';
import { TextPresentation } from './TextPresentation';

export const Text = ({
  text,
  children,
  ...props
}: React.PropsWithChildren<TextProps>): ReactElement => {
  return (
    <View style={{ display: 'flex' }}>
      <TextPresentation {...props}>{text}</TextPresentation>
      {children}
    </View>
  );
};
