/* eslint-disable react/prop-types */ // AC: For onClose type; I don't think its needed
import React, { FunctionComponent, ReactElement, useContext } from 'react';
import { Platform, View, ViewStyle } from 'react-native';

import { CloseButton, OverlayTint, Panel, Wrapper, ScrollView } from './styled';
import { roles } from 'cross-platform/utils/roleProps';
import { Modal } from '../Modal';
import {
  ScalingContext,
  ScalingContextProvider,
  ScreenResponsiveLayoutContext,
  useGetDynamicContentStyles,
} from 'components/ResponsiveLayout';
import { NavBarStyles } from 'components/Navigation/constants';

const Presentational: FunctionComponent<{
  visible?: boolean;
  onClose: () => void;
  overlayTintColor?: ViewStyle['backgroundColor'];
}> = (props): ReactElement => {
  const styles = useGetDynamicContentStyles();

  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  const isDesktopWeb =
    screenWidth > NavBarStyles.navBarCollapseMobileWebBreakPointPx &&
    Platform.OS === 'web';

  const stylePanel = {
    paddingVertical: styles.sleepDiaryFormHeadingVerticalPadding,
    paddingHorizontal: styles.sleepDiaryFormHeadingHorizontalPadding,
    ...(isDesktopWeb
      ? {
          marginTop: 54,
          marginBottom: 104,
        }
      : {
          flexGrow: 1,
        }),
  };

  return (
    <ScalingContextProvider scalingContext={ScalingContext.Modal}>
      <Wrapper {...roles('Wrapper')}>
        <Modal
          visible={!!props.visible}
          transparent={true}
          supportedOrientations={['portrait', 'landscape']}
        >
          <OverlayTint
            {...roles('OverlayTint')}
            overlayTintColor={props.overlayTintColor}
          >
            <Panel {...roles('Panel')} style={stylePanel}>
              <ScrollView>
                <ScrollView horizontal={true}>
                  <View>{props.children || null}</View>
                </ScrollView>
              </ScrollView>
              <CloseButton
                {...roles('CloseButton')}
                onPress={() => {
                  props.onClose();
                }}
              />
            </Panel>
          </OverlayTint>
        </Modal>
      </Wrapper>
    </ScalingContextProvider>
  );
};
export { Presentational };
