import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';

import {
  ButtonStates,
  getStylesForButtonStates,
} from 'lib/styles/inputStateStyles';
import { getBorderStyles } from './helpers';
import { HorizontalScaleItemProps } from './types';
import { getControlThemes } from 'components/layout/Content/Control/styled';
import { ControlProps } from 'components/layout/Content/Control';
import { ThemeProp } from 'config/getThemeForProducts';
import { roles } from 'cross-platform/utils/roleProps';
import { printableProps } from 'components/layout/lib/printableProps';
import { useGetDynamicInputStyles } from 'components/ResponsiveLayout';
import { GenericInputIcon } from 'components/generic-question/GenericInputButton';
import { getThemeFont } from 'lib/styles/getFont';
import { useInputFocusContext } from 'components/forms/ResponseOptions/providers/InputFocusProvider';

const LabelStatic = styled.label<ButtonStates>`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  color: ${getStylesForButtonStates('color')};
  border-style: solid;
  border-color: white;
  overflow: hidden;
  &:hover {
    color: ${(): string =>
      getStylesForButtonStates('color')({ isHovering: true })};
  }
`;

const ButtonStatic = styled.button<ButtonStates & ControlProps & ThemeProp>`
  ${getControlThemes}
  font-family: ${getThemeFont};
  appearance: none;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  overflow: hidden;
  margin: 0;
  border: 0;
  &:hover:not([disabled]) {
    color: ${(props): string =>
      getStylesForButtonStates('color')({ ...props, isHovering: true })};
    background-color: ${(props): string =>
      getStylesForButtonStates('backgroundColor')({
        ...props,
        isHovering: true,
      })};
  }
`;

const LabelText = styled.span`
  font-family: ${getThemeFont};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HorizontalScaleItem = ({
  location,
  selected,
  value,
  onSelect,
  quiz,
  displayText,
}: HorizontalScaleItemProps): ReactElement => {
  const [isFocused, setIsFocused] = useState(false);
  const {
    height,
    borderRadius,
    fontSize,
    iconSize,
    inputIconMarginRight,
    paddingRight,
    paddingLeft,
  } = useGetDynamicInputStyles();
  const { onFocus, onBlur } = useInputFocusContext();

  const handleBlur = () => {
    onBlur();
    if (quiz) {
      setIsFocused(false);
    }
  };

  const handleFocus = () => {
    onFocus();
    if (quiz) {
      setIsFocused(true);
    }
  };

  return (
    <LabelStatic
      {...roles('HorizontalScaleItem')}
      htmlFor={value as string}
      isSelected={selected}
      style={{
        ...getBorderStyles(borderRadius, location),
        fontSize,
      }}
      isHovering={isFocused}
    >
      <ButtonStatic
        {...roles(
          `HorizontalScaleItem-input-${printableProps({ value, selected })}`
        )}
        style={{
          ...getBorderStyles(borderRadius, location),
          height,
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight,
          paddingLeft,
          fontSize,
        }}
        quiz={quiz}
        disabled={typeof quiz !== 'undefined'}
        id={value as string}
        isSelected={selected}
        name={`${value}`}
        onBlur={handleBlur}
        onClick={quiz ? undefined : (): void => onSelect(value)}
        onFocus={handleFocus}
        type={'button'}
        value={`${value}`}
      >
        <LabelText>{displayText}</LabelText>
      </ButtonStatic>
      <GenericInputIcon
        quiz={quiz}
        style={{
          position: 'absolute',
          top: 0.5 * (height - iconSize),
          right: inputIconMarginRight,
        }}
        size={iconSize}
      />
    </LabelStatic>
  );
};
