import { useCallback, useContext } from 'react';
import {
  MediaResponsiveLayoutContext,
  ScreenResponsiveLayoutContext,
} from '../providers';
import { UseScaleToContextCallback } from './useScaleToScalingContext';

export const useScaleToMedia = (): UseScaleToContextCallback => {
  const { mediaHeight } = useContext(MediaResponsiveLayoutContext);
  const { screenHeight } = useContext(ScreenResponsiveLayoutContext);
  const callback: UseScaleToContextCallback = useCallback(
    dimensionToScale => {
      const scale = screenHeight / mediaHeight;
      return dimensionToScale * scale;
    },
    [mediaHeight, screenHeight]
  );
  return callback;
};
