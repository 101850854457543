import { mergeAll } from 'ramda';
import { DeepPartial, Question } from '@bighealth/types/src';
import { Value } from 'components/forms/types';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { QuestionId } from 'state/question-response/actions';
import { getTryToSleepConfigFromIntoBed } from './rules/getTryToSleepConfigFromIntoBed';
import { getFinalAwakeningFromTryToSleep } from './rules/getFinalAwakeningFromTryToSleep';
import { getTimeOutOfBedConfigFromFinalAwakening } from './rules/getTimeOutOfBedConfigFromFinalAwakening';
import { getInitialValues } from 'components/SleepDiaryForm/components/FlowingForm/helpers/getFlowState/getInitialValuesAndOptions/rules/getInitialValues';

// IDEA rename getInitialValuesAndOptions to interFieldValues
// WHY getInitialValuesAndOptions is name of storybook. interFieldValues is more descriptive of the flow state
// WHEN code stabilizes

export type Config = Partial<{
  initialValue: Value;
  // NOTE: named return field "questionProp" as more generic (for other components/domains)
  //       but also considered "questionPropOverride" (how its used within SleepDiary AND its type)
  questionProps: DeepPartial<Question>;
}>;

/**
 * Custom rules to set selected and selectable values based on values
 *
 * @param {Record<QuestionId, DropdownItem[]>} inputs from `<ResponseForm onValidate={inputs => {}} />`
 * @param {Record<QuestionId, Question>} initialQuestionProps question props to generate initial values and props from
 */
const getInitialValuesAndOptions = (
  inputs: Record<QuestionId, DropdownItem[]>,
  initialQuestionProps?: Record<QuestionId, Question | undefined>
): Record<QuestionId, Config> => {
  if (initialQuestionProps) {
    return getInitialValues(initialQuestionProps);
  }
  const timeTryToSleep = getTryToSleepConfigFromIntoBed(inputs) || {};
  const finalAwakening = getFinalAwakeningFromTryToSleep(inputs) || {};
  const outOfBed = getTimeOutOfBedConfigFromFinalAwakening(inputs) || {};
  return mergeAll([timeTryToSleep, finalAwakening, outOfBed]);
};

export { getInitialValuesAndOptions };
