/* eslint-disable camelcase */
import { APIResponse, APIRequestBody } from '@bighealth/api';
import { DeepWritable } from '@bighealth/types';
import { ResponsePipe } from '../../index';
import { transformResponseResult } from './utils/transformResponseResult';
import { transformResponseWithSceneSetJSON } from './utils/transformResponseWithSceneSetJSON';

const toJavaScriptDateWithRequest = (
  readOnlyBody: APIRequestBody,
  isForce = false
): ResponsePipe => {
  if (isForce) {
    return transformResponseResult;
  }
  const body: DeepWritable<APIRequestBody> = readOnlyBody;
  switch (body?.service_name) {
    case 'SceneSet':
      switch (body?.service_method) {
        case 'get_interpolated_scene_set_with_reference':
          return transformResponseWithSceneSetJSON;
      }
      break;
    case 'SleepDiary':
      switch (body?.service_method) {
        case 'get_diary_entry_form':
          return transformResponseResult;
      }
      break;
    case 'SceneSetGraph':
      switch (body?.service_method) {
        case 'jump_to_specific_scene_set':
        case 'transition_to_next_scene_set':
          return transformResponseWithSceneSetJSON;
      }
  }
  return (response: APIResponse): APIResponse => response;
};

export { toJavaScriptDateWithRequest };
