import { DAY, HOUR } from 'lib/durations';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { QuestionId } from 'state/question-response/actions';
import { getSelected } from '../../helpers/getSelected';
import { WarningAndHighlight } from '..';

/**
 *
 * @param {Record<QuestionId, DropdownItem[]>} inputs from `<ResponseForm onValidate={inputs => {}} />`
 */
const getFallAsleepTimeGreaterThan12Hours = (
  inputs: Record<QuestionId, DropdownItem[]>
): Record<QuestionId, Partial<WarningAndHighlight>> | null => {
  const timeInBed = getSelected(
    inputs['sleep_efficiency_time_into_bed']
  )?.valueOf();
  const tryToSleep = getSelected(
    inputs['sleep_efficiency_time_try_to_sleep']
  )?.valueOf();
  if (typeof timeInBed === 'number' && typeof tryToSleep === 'number') {
    // Crosses over midnight 🕛
    const tryToSleepFuture =
      tryToSleep < timeInBed ? tryToSleep + DAY : tryToSleep;
    if (tryToSleepFuture - timeInBed > 12 * HOUR) {
      return {
        sleep_efficiency_time_try_to_sleep: {
          highlight: true,
          warning:
            'That’s a very long time to be in bed before trying to sleep. Are you sure?',
        },
        sleep_efficiency_time_into_bed: { highlight: true },
      };
    }
  }
  return null;
};

export { getFallAsleepTimeGreaterThan12Hours };
