import React from 'react';
import { Linking } from 'react-native';
import { TouchableOpacity } from 'components/primitives/cross-platform';
import { PLATFORM_ASSETS_URL } from 'config/envVars/assets';
import { roles } from 'cross-platform/utils/roleProps';
import Image from 'cross-platform/Image';

export const GooglePlayButton = ({
  height,
  width,
  url,
}: {
  height: number;
  width: number;
  url: string;
}): React.ReactElement => {
  const urlImage = `${PLATFORM_ASSETS_URL}assets/google-play-image.png`;
  return (
    <TouchableOpacity
      {...roles('GooglePlayButton')}
      onPress={() => {
        Linking.openURL(url);
      }}
    >
      <Image src={urlImage} style={{ height: height, width: width }} />
    </TouchableOpacity>
  );
};
