import { Program, SceneSet, SceneSetGraph } from '@bighealth/types';
import { PlaybackState } from 'state/player/reducer';

export enum PLAYER_ACTIONS {
  SET_PLAYER_STATUS_READY = 'player/SET_PLAYER_STATUS_READY',
  SHOW_CONTROLS = 'player/SHOW_CONTROLS',
  SET_PLAYER_STATE = 'player/SET_PLAYER_STATE',
  RESET = 'player/RESET',
  INCREMENT_SCENESET_RENDER_KEY = 'player/INCREMENT_SCENESET_RENDER_KEY',
}

export type PlayerAction = {
  type: PLAYER_ACTIONS;
  payload?:
    | SceneSet
    | Program
    | SceneSetGraph[]
    | PlaybackState
    | boolean
    | number;
};
// It seems weird we don't have a "player not ready" state but it works fine. Possibly I've forgotten something.
// @TODO: Find out why we don't have a "not ready" state and fix it if needed
// @WHEN: When we work on the player next
export const setPlayerStatusReady = (): PlayerAction => ({
  type: PLAYER_ACTIONS.SET_PLAYER_STATUS_READY,
});

export const showControls = (isVisible: boolean): PlayerAction => ({
  type: PLAYER_ACTIONS.SHOW_CONTROLS,
  payload: isVisible,
});

export const setPlayerState = (payload: PlaybackState): PlayerAction => ({
  type: PLAYER_ACTIONS.SET_PLAYER_STATE,
  payload,
});

export const incrementSceneSetRenderKey = (): PlayerAction => ({
  type: PLAYER_ACTIONS.INCREMENT_SCENESET_RENDER_KEY,
});
