import { AppState, AppStateStatus } from 'react-native';
import { useEffect, useRef, useState, useCallback } from 'react';
import { setPlayerState } from 'state/player/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getPlayerState } from 'state/player/selectors';
import { PlaybackState } from 'state/player/reducer';
import { toggleVisibilityOrchestrator } from 'lib/player/media/ToggleVisibilityOrchestrator';
import { mediaPlayerOrchestrator } from 'lib/player/media/MediaPlayerOrchestrator';

export const useHandleTimersForAppState = (): boolean => {
  const dispatch = useDispatch();
  const playerState = useSelector(getPlayerState);
  const [appState, setAppState] = useState(AppState.currentState);

  const appStateRef = useRef(appState);
  const playerStateRef = useRef(playerState);

  appStateRef.current = appState;
  playerStateRef.current = playerState;

  const handleAppStateChange = useCallback(
    (nextAppState: AppStateStatus) => {
      if (
        appStateRef.current === 'active' &&
        nextAppState.match(/inactive|background/)
      ) {
        mediaPlayerOrchestrator.pause();
        toggleVisibilityOrchestrator.pause();
        // avoiding unnecessary dispatches
        if (playerStateRef.current !== PlaybackState.PAUSED) {
          dispatch(setPlayerState(PlaybackState.PAUSED));
        }
        // setting locks for scene transitions
        mediaPlayerOrchestrator.setPlayLock();
        toggleVisibilityOrchestrator.setPlayLock();
      } else {
        mediaPlayerOrchestrator.releasePlayLock();
        toggleVisibilityOrchestrator.releasePlayLock();
      }

      setAppState(nextAppState);
    },
    [dispatch]
  );

  useEffect(() => {
    AppState.addEventListener('change', handleAppStateChange);
    return () => {
      AppState.removeEventListener('change', handleAppStateChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // "change" eventListener is slow on iOS and for this reason player's locks release
  // is delayed, giving a user 1 second window to press play button when locks are on
  // this prop is used at MediaPlayerControls to disable play button
  return appState !== 'active';
};
