import React, { ReactElement } from 'react';
import { GoogleLogin } from 'react-google-login';
import { GOOGLE_CLIENT_ID_LONG_FORM } from 'config/envVars';
import { handleGoogleAuthResponse } from 'lib/auth/google/web';
import { OnAuthCallback } from 'components/AuthButtons';
import {
  UniversalButton,
  ButtonSizes,
  ButtonColorSchemes,
} from 'components/UniveralButtons';
import { roles } from 'cross-platform/utils/roleProps';

const GoogleSignInButtonWeb = ({
  onAuth,
}: {
  onAuth: OnAuthCallback;
}): ReactElement => {
  const handleResponse = handleGoogleAuthResponse(onAuth);
  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID_LONG_FORM}
      buttonText="Login"
      onSuccess={handleResponse}
      onFailure={handleResponse}
      cookiePolicy={'single_host_origin'}
      render={({ onClick }): ReactElement => (
        <UniversalButton
          {...roles('Google-signInButton')}
          text={'Login with Google'}
          onPress={onClick}
          colorScheme={ButtonColorSchemes.Google}
          size={ButtonSizes.Small}
          isDisabled={false}
        />
      )}
    />
  );
};

export default GoogleSignInButtonWeb;
