import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';

export const authenticate_with_facebook = async (
  args: authenticate_with_facebook.Args
): Promise<authenticate_with_facebook.Response> => {
  return await api({
    service_name: 'UserAccountAuthentication',
    service_version: '1',
    service_method: 'authenticate_with_facebook',
    args: args,
  } as authenticate_with_facebook.Request);
};

authenticate_with_facebook.queryKey =
  'UserAccountAuthentication/authenticate_with_facebook';

export declare namespace authenticate_with_facebook {
  export type Args = {
    facebook_access_token: string;
    device_platform: string;
    product_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'UserAccountAuthentication';
    service_version: '1';
    service_method: 'authenticate_with_facebook';
    args: Args;
  }

  export type Result = {};

  export type Response = APIResponse<Result>;
}
