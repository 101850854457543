import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components/native';
import {
  useGetDynamicPlayerControlsStyles,
  useGetMediaDimensions,
} from 'components/ResponsiveLayout';
import { Animated } from 'react-native';
import { FillAbsolute } from 'components/Containers';
import { CloseButtonIconResponsive } from 'components/Media/PlayerControls/icons';
import { roles } from 'cross-platform/utils/roleProps';
import { TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import { getControlsAreVisible, getPlayerState } from 'state/player/selectors';
import { useFadingAnimation } from './useFadingAnimation';
import { useHistory } from 'cross-platform/react-router';
import { useSafeParams } from 'components/Routes/useSafeParams';

export const PressButton = styled.TouchableOpacity<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const FadeInOut = styled(Animated.View)``;

const MediaControlsFullWidthContainerBase = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;
const Background = styled(FillAbsolute)`
  opacity: 0.9;
  background-color: #00506b;
`;

const PlayerWidthContainerBase = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  position: absolute;
  justify-content: flex-end;
  top: 0;
`;

const Touchable = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const CloseButton = (): ReactElement => {
  const {
    closeButtonLeft,
    closeButtonTop,
  } = useGetDynamicPlayerControlsStyles();
  const history = useHistory();
  const { productReference } = useSafeParams();

  const handlePress = async (): Promise<void> => {
    history.push(`/${productReference}/home`);
  };

  return (
    <Touchable
      {...roles('CloseButton')}
      onPress={handlePress}
      style={{
        position: 'absolute',
        top: closeButtonTop,
        left: closeButtonLeft,
      }}
    >
      <CloseButtonIconResponsive />
    </Touchable>
  );
};

export const MediaBar = ({
  children,
  ...rest
}: {
  children: ReactNode;
}): ReactElement | null => {
  const {
    horizontalPadding,
    controlsHeight,
    outerHorizontalPadding,
  } = useGetDynamicPlayerControlsStyles();
  const { mediaWidth } = useGetMediaDimensions();
  const playerState = useSelector(getPlayerState);
  const controlsAreVisible = useSelector(getControlsAreVisible);
  const { fadeAnim, hidden } = useFadingAnimation({
    controlsAreVisible,
    playerState,
  });

  if (!controlsAreVisible && hidden) {
    return null;
  }

  return (
    <FadeInOut
      style={{
        opacity: fadeAnim,
      }}
      testID={'Animated-Wrapper'}
    >
      <MediaControlsFullWidthContainerBase
        {...roles('MediaBar')}
        {...rest}
        style={{
          height: controlsHeight,
        }}
      >
        <Background />

        <PlayerWidthContainerBase
          style={{
            width: mediaWidth,
            left: outerHorizontalPadding,
            right: outerHorizontalPadding,
            paddingLeft: horizontalPadding,
            paddingRight: horizontalPadding,
          }}
        >
          {children}
        </PlayerWidthContainerBase>
      </MediaControlsFullWidthContainerBase>
    </FadeInOut>
  );
};
