import { DiaryEntry } from '@bighealth/types/src/services/SleepDiaryPayloads/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';

export const update_single_day_entry_with_date = async (
  args: update_single_day_entry_with_date.Args
): Promise<update_single_day_entry_with_date.Response> => {
  return await api({
    service_name: 'SleepDiary',
    service_version: '1',
    service_method: 'update_single_day_entry_with_date',
    args: args,
  } as update_single_day_entry_with_date.Request);
};

update_single_day_entry_with_date.queryKey =
  'SleepDiary/update_single_day_entry_with_date';

export declare namespace update_single_day_entry_with_date {
  export type Args = DiaryEntry;

  export interface Request extends APIRequestBody {
    service_name: 'SleepDiary';
    service_version: '1';
    service_method: 'update_single_day_entry_with_date';
    args: Args;
  }

  export type Result = DiaryEntry;

  export type Response = APIResponse<Result>;
}
