import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';

export const refresh_access_token = async (): Promise<refresh_access_token.Response> => {
  return await api({
    service_name: 'UserAccountAuthentication',
    service_version: '1',
    service_method: 'refresh_access_token',
    args: {},
  } as refresh_access_token.Request);
};

refresh_access_token.queryKey =
  'UserAccountAuthentication/refresh_access_token';

export declare namespace refresh_access_token {
  export type Args = {};

  export interface Request extends APIRequestBody {
    service_name: 'UserAccountAuthentication';
    service_version: '1';
    service_method: 'refresh_access_token';
    args: Args;
  }

  export type Result = {};

  export type Response = APIResponse<Result>;
}
