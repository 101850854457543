/**
 * The Sleep Diary is unique in our application as a component that does all of the following:
 *  - Can be used as a SceneComponent
 *  - Can be used a regular, "non-Scene" component
 *  - Presents another component, which itself can be used as a standalone SceneComponent
 *  - By virtue of that, can present Question Forms both inside and outside of a Scene Context.
 *
 *  Almost all of our questions are presented inside a Scene Context, so the state storage mechanisms
 *  around that leverage things like the SceneSetGraphId and SceneSetId to build state keys.
 *
 *  Those are not always present for the Sleep Diary. So, we do some creative accounting around what these
 *  ids are, when things are not presented in a Scene context.
 *
 *  This file declares the route parameter at which this component can be viewed. For more information, see where it is
 *  used.
 */

export const SLEEP_DIARY_ROUTE_PARAMETER = 'sleep-diary';
export const CALENDAR_CHART_MARGIN_LEFT = '9%';
export const NARROW_CALENDAR_CHART_MARGIN_LEFT = '5%';
