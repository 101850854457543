import { GlobalState } from '../store/initialState';
import { DomainIDs } from 'common/constants/enums';
import { Asset, LocalURI } from './reducer';
import { RemoteURI } from 'lib/player/getAssetsFromSceneSet';

export const getAssetsLeftToDownload = (state: GlobalState): Asset[] =>
  state[DomainIDs.DOWNLOADER].remoteAssetsToDownload;

export const getLocalURIForRemoteURL = (remoteURIToTest: RemoteURI) => (
  state: GlobalState
): LocalURI | undefined => {
  const cachedAssets = state[DomainIDs.DOWNLOADER].locallyCachedAssets;
  const asset = cachedAssets.find(
    ({ remoteURI }) => remoteURI === remoteURIToTest
  );
  if (asset) {
    return asset.localURI;
  }
  return undefined;
};

export const getIsAssetInCache = (remoteURIToTest: RemoteURI) => (
  state: GlobalState
): boolean => {
  const cachedAssets = state[DomainIDs.DOWNLOADER].locallyCachedAssets;
  return !!cachedAssets.find(({ remoteURI }) => remoteURI === remoteURIToTest);
};

export const getAssetsInCache = (state: GlobalState): Asset[] => {
  return state[DomainIDs.DOWNLOADER].locallyCachedAssets;
};
