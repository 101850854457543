import React from 'react';
import { FlexBoxProps, FlexBox } from '../../FlexBox';

type Props = Pick<
  FlexBoxProps,
  'flexGrow' | 'flexBasis' | 'children' | 'style' | 'justifyContent'
>;
const Row = ({ flexGrow = 1, ...props }: Props): React.ReactElement => (
  <FlexBox flexDirection="row" width="100%" {...{ flexGrow, ...props }} />
);

export { Row };
