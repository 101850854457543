import { FontFamilies } from 'components/sceneset-components/Text/fonts';

export * from './ErrorText';
export * from './NullText';
export * from './Text';
import styled from 'styled-components/native';

const Text = styled.Text`
  font-family: '${FontFamilies.Regular}';
`;

export { Text };
