import * as React from 'react';
import { StyleProp, ViewStyle, StyleSheet } from 'react-native';
import {
  Touchable,
  Wrapper,
  HeadingText,
  StatusText,
  IconByStatus,
  Grid,
  FirstCol,
  SecondCol,
  Row,
} from './styled';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import { ScaleValueProps } from 'components/Screens/SessionsScreen/utils/scaler';
import { stringify } from 'lib/stringify';
import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

export type CardProps = get_user_session_screen_data.Result['session_list'][number];

/**
 * Clicked to access a session
 *
 * @param {CardProps} param0
 */
const CardPresentation = ({
  heading,
  status,
  status_text,
  play_action,
  onPress,
  scaleValue,
  style,
  ...rest
}: CardProps &
  RoleProps & {
    onPress: () => void;
  } & ScaleValueProps & {
    style: StyleProp<ViewStyle>;
  }): React.ReactElement => (
  <Touchable
    {...roles.pass(rest)}
    onPress={onPress}
    disabled={!play_action?.type}
    data-play_action={stringify(play_action)}
  >
    <Wrapper
      scaleValue={scaleValue}
      status={status}
      style={Array.isArray(style) ? StyleSheet.flatten(style) : style}
    >
      <Grid>
        <Row>
          <FirstCol>
            <HeadingText
              scaleValue={scaleValue}
              {...(heading as React.ComponentProps<typeof HeadingText>)}
            />
            <StatusText
              scaleValue={scaleValue}
              status={status}
              {...(status_text as React.ComponentProps<typeof StatusText>)}
            />
          </FirstCol>
          <SecondCol>
            <IconByStatus status={status} scaleValue={scaleValue} />
          </SecondCol>
        </Row>
      </Grid>
    </Wrapper>
  </Touchable>
);

export { CardPresentation };
