import * as React from 'react';
import { ReactElement } from 'react';
import styled from 'styled-components';
import { Dimensions } from 'react-native';

const FullScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;
export const MenuDismisser = ({
  onDismiss,
}: {
  onDismiss: () => void;
}): ReactElement => {
  const { height, width } = Dimensions.get('window');
  return <FullScreen style={{ height, width }} onMouseEnter={onDismiss} />;
};
