import {
  ScaleValueProps,
  scaler,
} from 'components/Screens/SessionsScreen/utils/scaler';
import styled from 'styled-components/native';

export const LoadingWrapper = styled.View<ScaleValueProps>`
  flex-grow: 1;
  min-height: ${scaler(780)}px;
`;
LoadingWrapper.displayName = `LoadingWrapper`;
