import { useCallback } from 'react';
import { useGetScalingContext } from 'components/ResponsiveLayout/hooks/useGetScalingContext';
import { useScaleToModal } from 'components/ResponsiveLayout/hooks/useScaleToModal';
import { useGetScreenDimensions } from 'components/ResponsiveLayout/hooks/useGetScreenDimensions';
import { ScalingContext } from 'components/ResponsiveLayout/providers/ScalingContextProvider';

// width of the iPhone 11 Pro as Daylight reference
const MIN = 414;

/**
 * We use this other hook for responsiveness in Daylight because
 * existing FE responsiveness refers to Web as default width for minimum reference
 */
export const useTransformContentStyle = (): ((dimension: number) => number) => {
  const scalingContext = useGetScalingContext();
  const scaleToModal = useScaleToModal();
  const { screenWidth } = useGetScreenDimensions();

  return useCallback(
    (dimension: number) => {
      if (scalingContext === ScalingContext.Modal) {
        return scaleToModal(dimension);
      }

      if (screenWidth < MIN) {
        return (dimension * screenWidth) / MIN;
      }
      return dimension;
    },
    [scaleToModal, scalingContext, screenWidth]
  );
};
