import React, { ReactElement } from 'react';
import RawLink from 'components/Link';
import styled from 'styled-components/native';
import { ButtonTypes } from 'common/constants/enums';
import { ButtonInternals, EnhancedButtonProps, linkStyles } from './common';
import { CSSProperties } from 'styled-components';
import { StyleSheet } from 'react-native';
const flatten = StyleSheet.flatten;

const Link = styled(RawLink)<EnhancedButtonProps>`
  ${linkStyles}
  margin-top: ${({ align }: { align?: string }): string =>
    align === 'bottom' ? 'auto' : '10px'};
  opacity: ${({ disabled }): number => (disabled ? 0.5 : 1)}
`;

const LinkButton = ({
  to,
  text,
  type = ButtonTypes.PRIMARY_BUTTON,
  align,
  style,
  isDisabled = false,
  ...rest
}: {
  to?: string;
  text: string;
  align?: string;
  type?: ButtonTypes;
  style?: CSSProperties;
  isDisabled?: boolean;
}): ReactElement => {
  return (
    <Link
      to={to || '/'}
      title={text}
      type={type}
      align={align}
      data-testid={`Link_${text}`}
      style={flatten(style)}
      disabled={isDisabled}
      {...rest}
    >
      <ButtonInternals type={type} text={text} />
    </Link>
  );
};

export default LinkButton;
