export const initializeFacebookSDK = (
  appId: string,
  onInit?: () => void
): void => {
  if (window.fbAsyncInit) {
    // Don't re-add it.
    return;
  }
  window.fbAsyncInit = (): void => {
    if (typeof window.FB !== 'undefined') {
      window.FB.init({
        version: `v2.3`,
        appId,
        xfbml: false,
        cookie: true,
      });
    }
    if (typeof onInit === 'function') {
      onInit();
    }
  };
  // @see https://developers.facebook.com/docs/php/howto/example_access_token_from_javascript
  ((doc, tagName, id): void => {
    if (doc.getElementById(id)) {
      // Don't add the script to the DOM a second time
      return;
    }
    const js = doc.createElement(tagName) as HTMLScriptElement;
    js.id = id;
    js.src = `https://connect.facebook.net/en_US/sdk.js`;
    const fjs = doc.getElementsByTagName(tagName)[0];
    fjs.parentNode?.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
};
