import { MINUTE } from 'lib/durations';

/**
 * Take date, and offset it by the timezone
 *
 * @param date
 * @returns
 */
const createTimezoneOffsetDate = (date: Date): Date => {
  const tzOffset = date.getTimezoneOffset() * MINUTE;
  return new Date(date.getTime() + tzOffset);
};

/**
 * Take date, and *NEGATIVELY* offset it by the timezone
 *
 * @param date
 * @returns
 */
const createTimezoneOffsetDateNegated = (date: Date): Date => {
  const tzOffset = date.getTimezoneOffset() * MINUTE;
  return new Date(date.getTime() - tzOffset);
};

/* NOTE: Opposite of createTimezoneOffsetDate */
createTimezoneOffsetDate.not = createTimezoneOffsetDateNegated;

export { createTimezoneOffsetDate };
