import { isDevMode } from 'lib/isDevMode';

const INTRO = 'There was an error';
const getErrorString = (error?: Error): string => {
  if (isDevMode() === false) {
    return INTRO;
  }
  const maybeString = error?.message
    ? `${error.message}`
    : error
    ? `${error}`
    : undefined;
  const messageTuple: [string, string | undefined] = [INTRO, maybeString];
  return messageTuple.filter(val => val).join(': ');
};

export { getErrorString };
