import React, { ReactElement, ReactNode } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { useHistory } from 'cross-platform/react-router';
import styled from 'styled-components/native';
import { roles } from 'cross-platform/utils/roleProps';
import { TodayIcon } from './icons/TodayIcon';
import { ProgressIcon } from './icons/ProgressIcon';
import { navigationBarConfig } from './navigationBarConfig';
import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';

const Wrapper = styled.View``;
const ContainerTabs = styled.View``;
const ContainerTab = styled.View``;

const HeightWithNav = styled.View`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
`;

const Tab = ({
  text,
  color,
  onPress,
  children,
}: {
  text: string;
  color: string;
  onPress: (() => void) | (() => Promise<void>);
  children: ReactNode;
}) => {
  return (
    <ContainerTab
      style={{
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <TouchableOpacity
        {...roles(`${text}Tab`)}
        onPress={onPress}
        style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
      >
        {children}
        <Text style={{ color: color, fontSize: 16 }}>{text}</Text>
      </TouchableOpacity>
    </ContainerTab>
  );
};

export const WithGlobalNavigation = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const transformStyle = useTransformContentStyleVertical();
  const HEIGHT_NAVIGATION_BAR = transformStyle(110);
  const history = useHistory();

  return (
    <Wrapper {...roles('GlobalNavigation')} style={{ flex: 1 }}>
      <HeightWithNav
        style={{
          bottom: HEIGHT_NAVIGATION_BAR,
        }}
      >
        {children}
      </HeightWithNav>

      <ContainerTabs
        style={{
          position: 'absolute',
          flexDirection: 'row',
          bottom: 0,
          width: '100%',
          height: HEIGHT_NAVIGATION_BAR,
          backgroundColor: 'white',
          justifyContent: 'space-evenly',
        }}
      >
        <Tab
          text={'Today'}
          color={
            history.location.pathname === navigationBarConfig.tabs.Today.path
              ? navigationBarConfig.tabs.Today.activeColor
              : navigationBarConfig.tabs.Today.inactiveColor
          }
          onPress={() => {
            history.push(navigationBarConfig.tabs.Today.path);
          }}
        >
          <TodayIcon
            size={transformStyle(40)}
            color={
              history.location.pathname === navigationBarConfig.tabs.Today.path
                ? navigationBarConfig.tabs.Today.activeColor
                : navigationBarConfig.tabs.Today.inactiveColor
            }
          />
        </Tab>
        <Tab
          text={'Progress'}
          color={
            history.location.pathname === navigationBarConfig.tabs.Progress.path
              ? navigationBarConfig.tabs.Progress.activeColor
              : navigationBarConfig.tabs.Progress.inactiveColor
          }
          onPress={() => {
            history.push(navigationBarConfig.tabs.Progress.path);
          }}
        >
          <ProgressIcon
            size={transformStyle(40)}
            color={
              history.location.pathname ===
              navigationBarConfig.tabs.Progress.path
                ? navigationBarConfig.tabs.Progress.activeColor
                : navigationBarConfig.tabs.Progress.inactiveColor
            }
          />
        </Tab>
      </ContainerTabs>
    </Wrapper>
  );
};
