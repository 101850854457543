import React, { ComponentProps, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import ButtonDefault from 'components/Button';
import { roles } from 'cross-platform/utils/roleProps';
import { ButtonTypes } from 'common/constants/enums';
import { SceneAction } from '@bighealth/types/dist/scene-components/client';
// TODO Remove this noOp action used to render ActionButton, not LinkButton
// WHEN We sort out buttons
const NO_OP = ({ type: 'noOp' } as unknown) as SceneAction;
/**
 * Attempt at a "unified" button API.
 *
 * - props.action is dispatched to redux
 */
const SuperButton = (
  props: ComponentProps<typeof ButtonDefault>
): ReactElement => {
  const dispatch = useDispatch();
  return (
    <ButtonDefault
      {...roles('SuperButton')}
      {
        ...props // IDEA: AC: Remove spread WHEN "button API" has been designed and is stable
      }
      action={NO_OP}
      onPress={value => {
        if (props.action) {
          dispatch(props.action);
        }
        props.onPress?.(value);
      }}
    />
  );
};

SuperButton.defaultProps = {
  type: ButtonTypes.SECONDARY_BUTTON,
};

export { SuperButton };
