import React from 'react';
import { Markdown as MarkdownWithoutProps } from 'components/Markdown/index.web';
import { Details, Paragraph } from './styled';
import { linkRenderer } from './utils/linkRenderer.web';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';

/**
 * Markdown for WEB, configured for DefaultErrorFallback
 *
 * IDEA perhaps make this the standard ./src/components/Markdown component?
 */
const Markdown = ({
  children,
  ...restProps
}: {
  children: string;
} & RoleProps): React.ReactNode => {
  return (
    <MarkdownWithoutProps
      {...roles.pass(restProps)}
      renderers={{
        paragraph: Paragraph,
        text: Details,
        link: linkRenderer,
      }}
    >
      {children}
    </MarkdownWithoutProps>
  );
};
export { Markdown };
