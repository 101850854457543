import {
  getQuestionResponsesQuizAttemptsByPath,
  getQuestionResponsesCorrectAnswers,
} from 'state/question-response/selectors';
import { useSelector } from 'react-redux';
import { QuizAssessment, getAssessment } from './utils/getAssessment';
import { ResponseOption } from '@bighealth/types';

export type UseQuizForCurrentQuestionReturn = {
  attempts: ResponseOption[][];
  assessment?: QuizAssessment;
};

export const useQuizForCurrentQuestion = (
  name: string
): UseQuizForCurrentQuestionReturn => {
  const attempts = useSelector(getQuestionResponsesQuizAttemptsByPath(name));
  const quizInfo = useSelector(getQuestionResponsesCorrectAnswers)[name];
  const assessment = getAssessment(attempts, quizInfo);
  return { attempts, assessment };
};
