import { Scene } from '@bighealth/types';
import { isDevMode } from 'lib/isDevMode';

/**
 * Debug data
 */
const getSceneToThrowInScene = (): Scene => ({
  component: 'Scene',
  childNodes: [
    {
      component: 'Modal',
      alignment: {
        horizontal: 'middle',
      },
      childNodes: isDevMode()
        ? [
            {
              component: 'DebugThrow',
            },
          ]
        : [],
    },
  ],
});

export { getSceneToThrowInScene };
