// We do want to allow "any" as it's possible the developer would want to put anything on state
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useCallback, useState } from 'react';

type EphemeralStateContextType = {
  setValuesByKey: (key: string, values: any) => void;
  getValuesByKey: (key: string) => any;
  getValues: () => any;
};

export type Values = {
  [key: string]: any;
};

const defaultContext: EphemeralStateContextType = {
  setValuesByKey: () => undefined,
  getValuesByKey: () => undefined,
  getValues: () => undefined,
};

/**
 *  DO NOT USE THIS DIRECTLY! Use the hooks from ./hooks.tsx
 */
export const EphemeralStateContext = React.createContext(defaultContext);

export type SceneSetFormStateIdentifiers = {
  sceneSetGraphId: number;
  sceneSetId: number;
  questionId: number;
};

export const EphemeralStateProvider = (props: {
  children: ReactElement;
}): ReactElement => {
  const [values, setValues] = useState<Values>({});
  const memodSetValuesByKey = useCallback(
    (key, newValues: any) => {
      setValues({
        ...values,
        [key]: newValues,
      });
    },
    [values]
  );
  const memodGetValuesByKey = useCallback(key => values[key], [values]);

  const memodGetValues = useCallback(() => values, [values]);

  return (
    <EphemeralStateContext.Provider
      value={{
        getValuesByKey: memodGetValuesByKey,
        setValuesByKey: memodSetValuesByKey,
        getValues: memodGetValues,
      }}
    >
      {props.children}
    </EphemeralStateContext.Provider>
  );
};
