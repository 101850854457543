import { api } from '@bighealth/api';
import { stringify } from 'lib/stringify';
import { APIRequestBody, HttpStatusCode, APIResponse } from '@bighealth/api';

//
// WHY: written because  didn't see a throw in

/**
 * FIXME: Check if needed
 * WHY: written because  didn't see a throw in `api()`, but Jof pointed out
 *  there is some error handling in `useWithResponseHooks()`
 * HOW: Carefully check control flow is the same
 * WHEN: Soon
 * @see {@link src/lib/api/index.tsx@api} Didn't see throw here
 * @see {@link src/lib/api/hooks/useWithResponseHooks.tsx@useWithResponseHooks} didn't
 */
const apiAndThrow = async (body: APIRequestBody): Promise<APIResponse> => {
  const response = await api(body);

  if (response.status_code >= 400) {
    if (response.status_code !== HttpStatusCode.UNAUTHORIZED) {
      throw Error(`Network error ${response.status_code}: ${stringify(body)}`);
    } else {
      // Handled elsewhere
      // IDEA token handling within the middleware, without coupling to the component
    }
  }
  return response;
};

export { apiAndThrow };
