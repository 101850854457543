import { RemoteURI } from '../player/getAssetsFromSceneSet';
import { Asset, LocalURI } from 'state/downloader/reducer';
import network from '../api/fetch';

const downloadRemoteAssets = async (
  assets: RemoteURI[],
  onComplete: (asset: Asset) => void
): Promise<void> => {
  const promises = assets.map(async remoteURI => {
    const res = await network(remoteURI, {
      mode: 'cors',
    });
    if (res.status >= 400) {
      // @todo - add detail to this error message such as res.status value
      // @when - when we have a proper error logging (e.g. Sentry) and user facing messages
      throw Error('Could not download file');
    }
    const assetBlob = await res.blob();
    const { size, type } = assetBlob;
    if (!(size > 0 && type !== '')) {
      // E.g. blob size is zero because of a CORS issue
      // {@link https://developer.mozilla.org/en-US/docs/Web/API/Body/blob}
      // @todo - add detail to this error message such "Invalid blob size or type"
      // @when - when we have a proper error logging (e.g. Sentry) and user facing messages
      throw Error('Could not download file');
    }
    const url = URL.createObjectURL(assetBlob);
    const newAsset: Asset = {
      localURI: url as LocalURI,
      remoteURI: remoteURI,
    };
    onComplete(newAsset);
  });
  await Promise.all(promises);
};

export default downloadRemoteAssets;
