import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { getLocalURIForRemoteURL } from 'state/downloader/selectors';
import { AVPlayer } from '@bighealth/avplayer';
import { ComponentMediaProps } from '../types';
import { useMediaNodeOrchestrator } from 'lib/player/media/MediaPlayerOrchestrator';
import { View, ViewStyle } from 'react-native';

export const CommonPlayer = ({
  src,
  from,
  delay,
  to,
  action,
  isContinuous,
  style,
}: ComponentMediaProps & {
  style?: ViewStyle;
}): ReactElement => {
  const node = useMediaNodeOrchestrator(action);
  const localURL = useSelector(getLocalURIForRemoteURL(src));

  return (
    <View pointerEvents={'none'}>
      <AVPlayer
        style={style}
        action={action}
        testID={`AVPlayer-${src}`}
        from={from}
        to={to}
        delay={delay}
        src={localURL as string}
        ref={node}
        isContinuous={isContinuous}
      />
    </View>
  );
};
