import React, { ReactElement } from 'react';
import { View } from 'react-native';
import { useTransformContentStyle } from 'daylight/components/ResponsiveLayout/useTransformContentStyle';
import { roles } from 'cross-platform/utils/roleProps';
import { Text } from 'components/Text';

export type ProgressText = {
  text: string;
  color: string;
  fontSize: number;
};

export type ProgressCardType = {
  upperText?: ProgressText;
  lowerText: ProgressText;
  boldText?: ProgressText;
  width?: number | string;
};

export const ProgressCard = ({
  upperText,
  lowerText,
  boldText,
  width,
}: ProgressCardType): ReactElement => {
  const transformStyle = useTransformContentStyle();

  return (
    <View
      {...roles('progressCard')}
      style={{
        backgroundColor: 'white',
        width: width || '100%',
        padding: transformStyle(21),
        borderRadius: 9,
      }}
    >
      {upperText && upperText.text !== '' ? (
        <Text
          style={{
            fontSize: upperText.fontSize,
            color: upperText.color,
            marginBottom: transformStyle(12),
          }}
        >
          {upperText.text}
        </Text>
      ) : null}

      <Text>
        <Text
          style={{
            fontSize: lowerText.fontSize,
            color: lowerText.color,
          }}
        >
          {lowerText.text}
        </Text>
        {boldText ? (
          <Text
            style={{
              fontSize: boldText.fontSize,
              color: boldText.color,
              fontWeight: '700',
            }}
          >
            {boldText.text}
          </Text>
        ) : null}
      </Text>
    </View>
  );
};
