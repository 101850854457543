import React, { ReactElement } from 'react';
import { TouchableOpacity } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

/**
 * Today's icon is a sun
 * @param size: the size of the square where the icon lives
 * @param color: the color of the icon
 */
export const SettingsIcon = ({
  size,
  color,
  onPress,
}: {
  size: number;
  color: string;
  onPress?: () => void;
}): ReactElement => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Svg
        width={`${size}px`}
        height={`${size}px`}
        fill="none"
        viewBox="0 0 40 40"
      >
        <G>
          <Path
            id="Stroke 1"
            clipRule="evenodd"
            fillRule="evenodd"
            d="M23.7806 9H16.3623C15.5749 9 14.8391 9.39508 14.3981 10.0549L8.97937 18.1538C8.43547 18.9671 8.43547 20.0325 8.97937 20.8458L14.3981 28.9447C14.8391 29.6045 15.5749 30 16.3623 30H23.7806C24.5675 30 25.3034 29.6045 25.7448 28.9447L31.1635 20.8458C31.7074 20.0325 31.7074 18.9671 31.1635 18.1538L25.7448 10.0549C25.3034 9.39508 24.5675 9 23.7806 9Z"
            stroke={color}
            strokeWidth={2.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            id="Stroke 3"
            clipRule="evenodd"
            fillRule="evenodd"
            d="M24.5714 19.5C24.5714 21.9852 22.5571 24 20.0714 24C17.5862 24 15.5714 21.9852 15.5714 19.5C15.5714 17.0148 17.5862 15 20.0714 15C22.5571 15 24.5714 17.0148 24.5714 19.5Z"
            stroke={color}
            strokeWidth={2.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </G>
      </Svg>
    </TouchableOpacity>
  );
};
