import { ReactElement, useEffect } from 'react';
import ScreenOrientation from 'react-native-orientation-locker';

export const LandscapeOrientationLock = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  // Lock to landscape on mount, and unlock on unmount
  useEffect(() => {
    ScreenOrientation.lockToLandscape();

    return () => {
      ScreenOrientation.unlockAllOrientations();
    };
  }, []);

  return children;
};
