import { getQueryClient } from 'components/ProvidersContainer';
import { subscribe_to_emails } from '@bighealth/api/UserSleepioMetadata/v1';

export const queryClientSubscribe = async (args: {
  product_id: number;
}): Promise<subscribe_to_emails.Response> => {
  return await getQueryClient().fetchQuery(
    subscribe_to_emails.queryKey,
    () =>
      subscribe_to_emails({
        product_id: args.product_id,
      }),
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );
};
