// TODO add android props
// Platform.OS === 'android' ?
//     { accessible: true, accessibilityLabel: id } :
//     { testID: id }
// IDEA: prefix all generated strings
// WHY: so when devs see the data attribute:
//    data-testid="role(cta)"
// they do not search for it directly, instead search for:
//    role('cta')

import { getTestId } from './getTestId';

export const addIfExists = (
  props: Record<string, string | number | undefined | boolean>,
  key: string
): Record<string, string | number | undefined | boolean> | null =>
  typeof props[key] !== 'undefined' ? { [key]: props[key] } : null;

export type RoleOptions = {
  isLoading?: boolean;
  progressBar?: {
    value: string;
    min: number;
    max: number;
    text?: string;
  };
};

export type RoleProps = {
  'data-testid'?: string;
  testID?: string;
  'aria-valuenow'?: number;
  'aria-valuemin'?: number;
  'aria-valuemax'?: number;
  'aria-valuetext'?: string;
  'aria-busy'?: boolean | 'true' | 'false';
};

const DELIM = '--';

const join = (...strings: (string | undefined)[]): string => {
  if (strings.filter(Boolean).length > 0) {
    const result = [];
    for (const i in strings) {
      if (strings[i]) {
        result.push(strings[i]);
      }
    }
    return result.join(DELIM);
  }
  return '';
};

type PassOptions = {
  default: string;
};
/**
 *
 * @param props Props passed to component
 * @param {string} postFix Props passed to component
 * @param {PassOptions} options more options
 * @return only props related to role
 * @example
 * ```ts
 *
 *     props => <div {...roleProps.pass<React.HTMLProps<>(props)} />
 * ```
 */

function pass<T>(
  props: RoleProps & T,
  postFix?: string,
  options?: PassOptions
): RoleProps {
  return {
    ...getTestId({
      'data-testid':
        join(props['data-testid'] || '', postFix || '') || options?.default,
      testID: join(props['testID'] || '', postFix || '') || options?.default,
    }),
    ...addIfExists(props, 'aria-valuenow'),
    ...addIfExists(props, 'aria-valuemin'),
    ...addIfExists(props, 'aria-valuemax'),
    ...addIfExists(props, 'aria-valuetext'),
    ...addIfExists(props, 'aria-busy'),
  };
}

/**
 * @param {string} role the role of a React component
 * @return various role-based attributes in React component prop form
 * @example
 * ```ts
 *     props => <button {...roleProps.roles('Call-to-action')} />
 * ```
 *
 */

function roles(role: string, options: RoleOptions = {}): RoleProps {
  const progressBarText =
    typeof options.progressBar?.text !== 'undefined'
      ? { 'aria-valuetext': options.progressBar.text }
      : null;
  const progressBarProps: Record<string, string | number> | null =
    typeof options?.progressBar === 'object'
      ? {
          'aria-valuenow': options.progressBar.value,
          'aria-valuemin': options.progressBar.min,
          'aria-valuemax': options.progressBar.max,
          ...progressBarText,
        }
      : null;
  return {
    ...getTestId({
      'data-testid': role,
      testID: role,
    }),
    ...(options.isLoading
      ? {
          role: 'progressbar', // eslint-disable-line prettier/prettier
          'aria-valuetext': 'Loading…', // eslint-disable-line prettier/prettier
          'aria-busy': 'true', // eslint-disable-line prettier/prettier
        } // eslint-disable-line prettier/prettier
      : null),
    ...progressBarProps,
  };
}

/**
 * To allow:
 *    import role from './role';
 *
 *    <Img {...role('hero')} />
 *
 *    const Img = ({ src, ...props }) =>
 *      <div {...role.pass(props)}>
 *        <img src={src} />
 *      </div>;
 */
roles.pass = pass;
roles.join = join;

export { roles, pass, join };
