import { CARD_HEIGHT_PX, CARD_SPACING_PX, CARD_WIDTH_PX } from './Card';
import { ScrollView } from 'react-native';
import styled from 'styled-components/native';
import React, { ReactElement } from 'react';
import { MenuProps } from '../index';
import { Text } from 'components/Text';
import { getScrollOffsetLeft, getScrollOffsetRight } from './getScrollOffset';

const CardSpacing = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${CARD_WIDTH_PX + 2 * CARD_SPACING_PX}px;
  height: ${CARD_HEIGHT_PX + 2 * CARD_SPACING_PX}px;
  margin-left: ${CARD_SPACING_PX}px;
  margin-right: ${CARD_SPACING_PX}px;
  margin-bottom: ${CARD_SPACING_PX}px;
  margin-top: ${CARD_SPACING_PX}px;
`;

const Container = styled.View`
  width: 100%;
`;

const StyledScrollView = styled(ScrollView)``;

const CardTitle = styled(Text)`
  font-size: 24px;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  color: #4a4a4a;
`;

const CarouselMenu = ({ items }: MenuProps): ReactElement => {
  const cardOffsetLeft = getScrollOffsetLeft();
  const cardOffsetRight = getScrollOffsetRight();
  return (
    <Container>
      <StyledScrollView
        horizontal={true}
        contentContainerStyle={{
          flexGrow: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          marginLeft: cardOffsetLeft,
          paddingRight: cardOffsetRight,
        }}
      >
        {items.map((item, index) => (
          <CardSpacing key={index}>
            <CardTitle>{item.sceneSetGraph.name}</CardTitle>
          </CardSpacing>
        ))}
      </StyledScrollView>
    </Container>
  );
};

export default CarouselMenu;
