import {
  createTheme,
  ThemeValuesType,
  ControlAttrs,
} from '../utils/createTheme';
import { HeadingLevel } from 'components/Heading';
import { googleColors, facebookColors } from './common';
import { QuizAttribute } from 'components/constants';
import { FooterTheme } from 'components/Footer';

// prettier-ignore
// NOTE: Names and color "groups" are very experimental and will hopefully evolve
export const sleepioColors = {
  veryDarkBlue: '#001748',
  // Blues from Sleepio V2 Zeplin
  blackBlue: '#00212c',
  darkBlue: '#003366',
  blue: '#00506b',
  lightBlue: '#1a80a2',
  veryLightBlue: '#bfe2f0',
  veryLightBlueDim: '#A9C8D6',
  // gray Blue (very loose color group)
  grayBlue: '#144D7E',
  // Mono (very loose color group)
  black: '#282828',
  grey: '#757575',
  lightGrey: '#d6d6d6',
  veryLightGrey: '#f2f2f2',
  white: '#ffffff',
  whiteTint: 'rgba(255, 255, 255, 0.3)',
  // semantic (very loose color group)
  error: '#d0021b',
  warning: '#ffba44',
  success: '#008769',
};

export const sleepioValues: ThemeValuesType = {
  color: {
    primary: sleepioColors.blue,
    primaryLight: sleepioColors.lightBlue,
    primaryDark: sleepioColors.veryDarkBlue,
    primaryGrey: sleepioColors.grayBlue,
    error: sleepioColors.error,
    hint: sleepioColors.lightBlue,
    success: sleepioColors.success,
    text: {
      primary: sleepioColors.black,
      primaryInverse: sleepioColors.white,
    },
    textGoogle: {
      primary: googleColors.white,
      secondary: googleColors.red,
    },
    textFacebook: {
      primary: facebookColors.white,
      secondary: facebookColors.blue,
    },
  },
  heading: {
    [HeadingLevel.LEVEL1]: { fontSize: 38, paddingBottom: 13 },
    [HeadingLevel.LEVEL2]: { fontSize: 30, paddingBottom: 13 },
    [HeadingLevel.LEVEL3]: { fontSize: 27, paddingBottom: 13 },
    [HeadingLevel.LEVEL4]: { fontSize: 24, paddingBottom: 13 },
    [HeadingLevel.LEVEL5]: { fontSize: 20, paddingBottom: 13 },
    [HeadingLevel.LEVEL6]: { fontSize: 16, paddingBottom: 13 },
  },
  footer: {
    [FooterTheme.Light]: {
      color: sleepioColors.darkBlue,
      backgroundColor: sleepioColors.white,
    },
    [FooterTheme.Dark]: {
      color: sleepioColors.white,
      backgroundColor: sleepioColors.darkBlue,
    },
  },
  controls: {
    player: {
      color: sleepioColors.white,
      backgroundColorDim: sleepioColors.whiteTint,
    },
    color: sleepioColors.black,
    backgroundColor: sleepioColors.veryLightBlue,
    borderRadius: 4,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: sleepioColors.veryLightBlue,
    paddingHorizontal: 30,
    paddingHorizontalLarge: 45,
    paddingVertical: 15,
    paddingVerticalLarge: 20,
    fontSize: 15,
    fontSizeLarge: 17,
    opacity: 1,
    ctaMinWidth: 220,
    ctaMaxWidth: 343,
    ctaFixedWidth: 220,
    isDisabled: {
      opacity: 0.4,
    },
    quiz: {
      [QuizAttribute.CORRECT]: {
        color: 'white',
        backgroundColor: sleepioColors.success,
        borderColor: sleepioColors.success,
      },
      [QuizAttribute.INCORRECT]: {
        color: 'white',
        backgroundColor: sleepioColors.error,
        borderColor: sleepioColors.error,
      },
    },
    [ControlAttrs.isPressing]: {
      color: 'white',
      backgroundColor: sleepioColors.blackBlue,
      borderColor: sleepioColors.blackBlue,
    },
    [ControlAttrs.isActive]: {
      color: 'white',
      backgroundColor: sleepioColors.blue,
      borderColor: sleepioColors.blue,
    },
    [ControlAttrs.isSelected]: {
      color: 'white',
      backgroundColor: sleepioColors.lightBlue,
      borderColor: sleepioColors.lightBlue,
    },
    [ControlAttrs.highlight]: {
      color: sleepioColors.black,
      backgroundColor: sleepioColors.warning,
      borderColor: sleepioColors.warning,
    },
  },
};

const sleepioTheme = createTheme(sleepioValues);

export { sleepioTheme };
