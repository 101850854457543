import React, { FunctionComponent } from 'react';
import { ChartProps } from '@bighealth/types/src/services/SleepDiaryPayloads/get_sleep_diary_component_data_for_time_period/';
import {
  BarChartGuide,
  BarChartGuideLine,
  BarChartGuideLabel,
  BarChartGuideContainer,
  BarChartColumn,
  BarChartColumnFill,
  BarChartLabelText,
  BarChartLabelView,
  BarChartColumnFillContainer,
} from './styled';
import { roles } from 'cross-platform/utils/roleProps';
import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { ChartContainer } from './ChartContainer';
import { BarChartColumnContainer } from './BarChartColumnContainer';
import { BarChartLabelContainer } from './BarChartLabelContainer';

const Chart: FunctionComponent<ChartProps> = (props: ChartProps) => {
  const { guides, days, upper_y_value } = props;

  const styles = useGetDynamicContentStyles();
  const guideLabelSize = styles.sleepDiaryWeekChartGuideLabelSize;

  return (
    <ChartContainer>
      {/* The padding at the top must be the size of the guide label + 1px of space and 1 px of guide line */}
      <BarChartColumnContainer paddingTop={guideLabelSize + 2}>
        {days.map((day, index) => (
          <BarChartColumn
            {...roles(`sleepDiaryChartColumn${index}`)}
            key={index}
          >
            <BarChartColumnFillContainer
              {...roles(`sleepDiaryChartColumnFillContainer${index}`)}
            >
              {day?.data
                ? day.data.map((dataPoint, innerIndex) => (
                    <BarChartColumnFill
                      {...dataPoint}
                      {...roles(
                        `sleepDiaryChartColumnFill${index}_${innerIndex}`
                      )}
                      key={innerIndex}
                      upperYValue={upper_y_value}
                    />
                  ))
                : null}
            </BarChartColumnFillContainer>
          </BarChartColumn>
        ))}
      </BarChartColumnContainer>

      <BarChartGuideContainer {...roles('sleepDiaryChartGuideContainer')}>
        {guides.map((guide, index) => (
          <BarChartGuide key={index}>
            <BarChartGuideLabel
              text={guide.label}
              style={{ color: guide.color, fontSize: guideLabelSize }}
            />
            <BarChartGuideLine />
          </BarChartGuide>
        ))}
      </BarChartGuideContainer>

      <BarChartLabelContainer>
        {days.map((day, index) => {
          return day ? (
            <BarChartLabelView {...day.label} key={index}>
              <BarChartLabelText
                {...day.label}
                style={{
                  fontSize: styles.sleepDiaryWeekBarChartLabelTextFontSize,
                }}
              />
            </BarChartLabelView>
          ) : (
            <BarChartLabelView
              key={index}
              text={''}
              background_color={'transparent'}
            />
          );
        })}
      </BarChartLabelContainer>
    </ChartContainer>
  );
};

export { Chart };
