import React, { ReactElement, useRef } from 'react';
import { RawGenericInputProps } from './types';
import styled from 'styled-components';
import {
  getStylesForInputStates,
  InputStates,
} from 'lib/styles/inputStateStyles';
import { useFocusStateHandler } from './hooks';
import { getHTMLTextInputPropsFromInputType } from './helpers';
import { ResponseType } from '@bighealth/types';
import {
  GenericButtonEditText,
  GenericButtonText,
  GenericButtonTextContainer,
  GenericInputButton,
} from 'components/generic-question/GenericInputButton';
import { roles, join } from 'cross-platform/utils/roleProps';
import { useGetDynamicInputStyles } from 'components/ResponsiveLayout';
import { Input as InputWeb } from 'components/primitives/web-only';
import { useInputFocusContext } from 'components/forms/ResponseOptions/providers/InputFocusProvider';

const InputStatic = styled(InputWeb)<InputStates>`
  appearance: none;
  background-color: ${getStylesForInputStates('backgroundColor')};
  border: ${getStylesForInputStates('border')};
  box-sizing: border-box;
  color: ${getStylesForInputStates('color')};
  display: flex;
  outline: 0;
  padding-bottom: 0;
  width: 100%;
  &:focus {
    color: ${(): string =>
      getStylesForInputStates('color')({ isActive: true })};
    background-color: ${(): string =>
      getStylesForInputStates('backgroundColor')({ isActive: true })};
    border: ${(): string =>
      getStylesForInputStates('border')({ isActive: true })};
  }
  &:hover {
    color: ${(): string =>
      getStylesForInputStates('color')({ isHovering: true })};
    background-color: ${(): string =>
      getStylesForInputStates('backgroundColor')({ isHovering: true })};
    border: ${(): string =>
      getStylesForInputStates('border')({ isHovering: true })};
  }
  &::placeholder {
    color: ${getStylesForInputStates('color')({ isFilled: false })};
    font-style: ${(): string =>
      getStylesForInputStates('fontStyle')({ isFilled: false })};
  }
`;

export const GenericInput = ({
  dataPath,
  isSelected = true, // Defaults to "true" in order to act like the $input type value
  onClick,
  onValueChange,
  type = ResponseType.TEXT,
  value,
}: RawGenericInputProps): ReactElement => {
  const { onFocus, onBlur } = useInputFocusContext();
  const inputNode = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useFocusStateHandler(inputNode, !value);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onValueChange(e.currentTarget.value);
  };

  const handleButtonClick = (): void => {
    setIsEditing(true);
  };

  const handleBlur = (): void => {
    onBlur();
    if (value) {
      setIsEditing(false);
    }
  };

  const handleFocus = (): void => {
    onFocus();
  };

  const {
    height,
    borderRadius,
    paddingLeft,
    paddingRight,
    fontSize,
  } = useGetDynamicInputStyles();
  return isEditing ? (
    <InputStatic
      {...getHTMLTextInputPropsFromInputType(type)}
      {...roles(join('GenericInput-input', dataPath))}
      value={value || ''}
      onChange={handleValueChange}
      placeholder={'Type your answer here'}
      ref={inputNode}
      onBlur={handleBlur}
      onFocus={handleFocus}
      style={{
        minHeight: height,
        borderRadius,
        paddingLeft,
        paddingRight,
        paddingTop: 0,
        paddingBottom: 0,
        fontSize,
      }}
    />
  ) : (
    <GenericInputButton
      {...roles(join('GenericInput-button', dataPath))}
      isSelected={isSelected}
      isInput={true}
    >
      {(): ReactElement => (
        <GenericButtonTextContainer>
          <GenericButtonText isSelected={isSelected} onClick={onClick}>
            {value}
          </GenericButtonText>
          <GenericButtonEditText
            data-testid={'GenericInput-editButton'}
            isSelected={isSelected}
            onClick={handleButtonClick}
          >
            Edit
          </GenericButtonEditText>
        </GenericButtonTextContainer>
      )}
    </GenericInputButton>
  );
};
