import { Question } from '@bighealth/types';
import logger from 'lib/logger';

export const getIsToggleable = (questionProps: Question): boolean => {
  const { response_config: responseConfig } = questionProps;
  const {
    min_selections_required: minSelections,
    max_selections_required: maxSelections,
    response_options: responseOptions,
  } = responseConfig;
  if (
    responseOptions.length === 1 &&
    minSelections !== 1 &&
    maxSelections !== 1
  ) {
    logger(
      `Content creator error in "response_options" for question id "${questionProps.id}"; when there is just one response option, both "max_selections_required" and "min_selections_required" must be set to 1.`
    );
  }
  return responseOptions.length !== 1;
};
