import { UNIQUE_DEVICE_IDENTIFICATION } from 'lib/strings';

export const LeftColumnItems = [
  {
    text: 'Sleepio FAQs',
    destination: 'https://info.sleepio.com/faq',
  },
  {
    text: 'Further Resources',
    destination: 'https://info.sleepio.com/further-resources',
  },
  {
    text: 'Suitability',
    destination: 'https://info.sleepio.com/suitability',
  },
  {
    text: 'Our Team',
    destination: 'https://www.bighealth.com/team',
  },
  {
    text: 'Blog',
    destination: 'https://blog.bighealth.com/',
  },
  {
    text: 'Jobs',
    destination: 'https://www.bighealth.com/careers',
  },
  {
    text: 'Privacy Policy',
    destination: 'https://info.sleepio.com/privacy',
  },
  {
    text: 'Terms',
    destination: 'https://www.sleepio.com/terms/',
  },
];

export const AboutUs = {
  title: 'About Us',
  subtitle: 'Sleepio by Big Health, Inc.',
};

export const AboutUsNarrow = {
  title: 'About Us',
  subtitle: 'Sleepio\nby Big Health, Inc.',
};

export const USAddressData = `
  **US Address**
  461 Bush St #200
  San Francisco, CA 94108
`;

export const UKAddressData = `
  **UK Address**
  c/o Simkins LLP
  Lynton House
  7-12 Tavistock Square
  London WC1H 9LT
`;

export const ContactUsData = `**Contact Us**
  You can email us at [hello@sleepio.com](mailto:hello@sleepio.com) and we'll respond as soon as possible.
  Please note that we are unable to give individual advice relating to health problems.

  Follow us on [Twitter](https://www.twitter.com/sleepio) and [Facebook](https://www.facebook.com/sleepio) to get all the latest updates on new developments.

  Interested in improving your employees' sleep? Contact us at [workplace@sleepio.com](mailto:workplace@sleepio.com).

  Have a question or concern about our system security? Send a message to [security@sleepio.com](mailto:security@sleepio.com) and we'll be in touch.
  `;

// FIXME: replace the stream version with the correct UDI when it is ready
export const UDIData =
  UNIQUE_DEVICE_IDENTIFICATION.length > 0
    ? `**Unique Device Identification System (UDI)**
  ${UNIQUE_DEVICE_IDENTIFICATION}
` : '';
export const DisclaimerData = `**Disclaimer**
  Disclaimer: In the US, Sleepio has not been cleared by the FDA for use in diagnosis or treatment of a disease or condition, such as insomnia disorder, generalized anxiety disorder, or major depressive disorder.
`;
