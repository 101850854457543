import network from 'lib/api/fetch';
import logger from 'lib/logger';

import { API_ENDPOINT_FULL_URL } from 'config/envVars';
import { getTimezone } from 'lib/timezone';

import {
  APIResponse,
  DaylightCheckinSceneSet,
  DaylightHomeScreenPayload,
  DaylightPracticeLibraryPayload,
  DaylightProgressScreenPayload,
} from 'daylight/lib/api/types/apiResponses';
import { APIRequestBody } from 'daylight/lib/api/types/apiRequests';

export const api = async (body: APIRequestBody): Promise<APIResponse> => {
  const jsonBody: string = JSON.stringify(body);
  const init: RequestInit = {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Bh-User-Timezone': getTimezone(),
    },
    body: jsonBody,
  };
  const res = await network(API_ENDPOINT_FULL_URL, init);
  if (res.status >= 400) {
    return {
      status_code: res.status,
      result: null,
    };
  }
  const resJson: APIResponse = await res.json().catch(
    (error: Error): Error => {
      logger(`Error when parsing json response`, error);
      return error;
    }
  );

  if (resJson instanceof Error) {
    return {
      // 422 is unprocessable entity.
      // AC: Why Client code generating server errors?
      status_code: 422,
      result: null,
    };
  }
  return resJson;
};

// Definition of the API functions
// TODO: move and refactor this function.
export type FetchDaylightHomeScreenPayload = ({
  productId,
  programId,
}: {
  productId: number;
  programId: number;
}) => Promise<APIResponse<DaylightHomeScreenPayload>>;

export const fetchHomeScreenData: FetchDaylightHomeScreenPayload = async ({
  productId,
  programId,
}: {
  productId: number;
  programId: number;
}) => {
  const result = await api({
    service_name: 'DaylightHomeScreen',
    service_version: '1',
    service_method: 'get_home_screen_data',
    args: {
      product_id: productId,
      program_id: programId,
    },
  });
  return result as APIResponse<DaylightHomeScreenPayload>;
};

export type FetchProgressScreenPayload = ({
  programId,
}: {
  programId: number;
}) => Promise<APIResponse<DaylightProgressScreenPayload>>;

export const fetchProgressScreenData: FetchProgressScreenPayload = async ({
  programId,
}: {
  programId: number;
}) => {
  const result = await api({
    service_name: 'DaylightSessionProgress',
    service_version: '1',
    service_method: 'get_progress_data',
    args: {
      program_id: programId,
    },
  });
  return result as APIResponse<DaylightProgressScreenPayload>;
};

export type FetchPracticeLibraryScreenPayload = ({
  programId,
}: {
  programId: number;
}) => Promise<APIResponse<DaylightPracticeLibraryPayload>>;

export const fetchPracticeLibraryScreenData: FetchPracticeLibraryScreenPayload = async ({
  programId,
}: {
  programId: number;
}) => {
  const result = await api({
    service_name: 'DaylightPracticeLibrary',
    service_version: '1',
    service_method: 'get_practice_library_data',
    args: {
      program_id: programId,
    },
  });
  return result as APIResponse<DaylightPracticeLibraryPayload>;
};

export type startCheckinPayload = ({
  productId,
  programId,
  sceneSetGraphId,
  sceneSetId,
}: {
  productId: number;
  programId: number;
  sceneSetGraphId: number;
  sceneSetId: number;
}) => Promise<APIResponse<DaylightCheckinSceneSet>>;

export const startCheckin: startCheckinPayload = async ({
  productId,
  programId,
  sceneSetGraphId,
  sceneSetId,
}: {
  productId: number;
  programId: number;
  sceneSetGraphId: number;
  sceneSetId: number;
}) => {
  const result = await api({
    service_name: 'DaylightCheckin',
    service_version: '1',
    service_method: 'start_checkin',
    args: {
      product_id: productId,
      program_id: programId,
      scene_set_graph_id: sceneSetGraphId,
      scene_set_id: sceneSetId,
    },
  });
  return result as APIResponse<DaylightCheckinSceneSet>;
};
