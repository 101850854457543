import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { ScheduleScreen } from '@bighealth/types';

export const get_schedule = async (
  args: get_schedule.Args
): Promise<get_schedule.Response> => {
  return await api({
    service_name: 'SleepioSchedule',
    service_version: '1',
    service_method: 'get_schedule',
    args: args,
  } as get_schedule.Request);
};

get_schedule.queryKey = 'SleepioSchedule/get_schedule';

export declare namespace get_schedule {
  export type Args = {
    program_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'SleepioSchedule';
    service_version: '1';
    service_method: 'get_schedule';
    args: Args;
  }

  export type Result = ScheduleScreen;

  export type Response = APIResponse<Result>;
}
