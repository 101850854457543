import React, { ReactElement, ReactNode } from 'react';
import { View } from 'react-native';
import { useScaleToScalingContext } from 'components/ResponsiveLayout';
import { pass } from 'cross-platform/utils/roleProps';
import { UniversalButton } from 'components/UniveralButtons/UniversalButton';
import { ButtonColorSchemes } from 'components/UniveralButtons/buttonColorSchemes';
import styled from 'styled-components/native';
import { Text as SceneComponentText } from 'components/sceneset-components/Text';

export const WrapperForm = styled(View)``;

export const ControlWrapper = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const scaleToContext = useScaleToScalingContext();
  return (
    <View
      style={{
        paddingTop: scaleToContext(24),
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </View>
  );
};

export const SubmitButtonContainer = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const scaleToContext = useScaleToScalingContext();
  return (
    <View
      style={{
        width: scaleToContext(219),
        height: scaleToContext(55),
      }}
    >
      {children}
    </View>
  );
};

export const SubmitButton = ({
  title,
  onPress,
  ...rest
}: {
  title: string;
  onPress: () => void;
}): ReactElement => {
  return (
    <UniversalButton
      text={title}
      colorScheme={ButtonColorSchemes.Standard}
      onPress={onPress}
      {...pass(rest)}
    />
  );
};

export const Title = ({ text }: { text: string | undefined }): ReactElement => {
  const scaleToContext = useScaleToScalingContext();
  return (
    <SceneComponentText
      text={text}
      style={{
        fontSize: scaleToContext(24),
        color: '#282828',
      }}
    />
  );
};
