import {
  AVRefNode,
  OrchestratorEndCallback,
  OrchestratorFailCallback,
  OrchestratorSuccessCallback,
} from './types';
import { AVProps } from '@bighealth/avplayer';
import { PlatformAVPrimitive } from '@bighealth/avplayer/dist/AVPlayer/platform';
import { clone, equals } from 'ramda';
import { ActionHandlerCallback } from 'lib/player/useActionHandler';
import { SetTimeout } from 'lib/SetTimeout';
import { SceneAction } from '@bighealth/types/src/scene-components/client';

export enum State {
  UNINITIALIZED = 'uninitialized',
  PLAYING = 'playing',
  PAUSED = 'paused',
}

export enum TimerId {
  mediaStartTimer = 'mediaStartTimer',
  mediaEndTimer = 'mediaEndTimer',
}

const TIMERS = [TimerId.mediaStartTimer, TimerId.mediaEndTimer];

type SubsetProps = Pick<
  AVProps,
  'from' | 'to' | 'delay' | 'action' | 'isContinuous'
>;

export class MediaNodeWithTimer {
  private playLock = false;
  private readonly refNode: AVRefNode;
  private lastPlayedAtMs?: number = undefined;
  private lastPausedAtMs?: number = undefined;
  private timerTimeElapsedMs = 0;
  private props: AVProps;
  private onAction?: ActionHandlerCallback = undefined;
  private onPlaySuccess?: OrchestratorSuccessCallback = undefined;
  private onPlayFail?: OrchestratorFailCallback = undefined;
  private onEnd?: OrchestratorEndCallback = undefined;
  private timers: {
    [TimerId.mediaStartTimer]?: SetTimeout;
    [TimerId.mediaEndTimer]?: SetTimeout;
  } = {
    [TimerId.mediaStartTimer]: undefined,
    [TimerId.mediaEndTimer]: undefined,
  };
  private triggeredState = {
    [TimerId.mediaStartTimer]: false,
    [TimerId.mediaEndTimer]: false,
  };

  constructor(refNode: AVRefNode) {
    this.refNode = refNode;
    this.props = MediaNodeWithTimer.getPropsForNode(this.refNode);
  }

  /**
   * ----------------------------------------
   * External controls
   */

  play = async (): Promise<void> => {
    if (this.playLock) {
      return;
    }

    const seekTime = this.getSeekToTimeMs();
    this.seekToMs(seekTime);
    if (this.isPlayable()) {
      // Skip all the timer stuff if the assets are just a freeze frame
      await this.startTimers();
    }
  };

  pause = (): void => {
    if (this.getState() === State.PAUSED) {
      return;
    }
    this.clearTimers();
    const timeElapsedPlaying = Date.now() - this.getLastPlayedAt();
    this.setTimerElapsedTimeMs(this.timerTimeElapsedMs + timeElapsedPlaying);
    this.setLastPausedAtMs(Date.now());
    this.pauseMedia();
  };

  reset = (): void => {
    this.clearTimers();
    this.lastPlayedAtMs = undefined;
    this.lastPausedAtMs = undefined;
    this.timerTimeElapsedMs = 0;

    for (const id of TIMERS) {
      this.triggeredState[id] = false;
    }
  };

  /**
   * ----------------------------------------
   * Component internals
   */

  private playMedia = async (): Promise<void> => {
    try {
      await this.getNode().current?.play();
      await this.handlePlaySuccess();
    } catch (error) {
      this.handlePlayFail(error);
    }
  };

  pauseMedia = (): void => {
    this.getPlayer()?.pause();
  };

  private seekToMs = (toMs: number): void => {
    this.getPlayer()?.setCurrentTime(toMs / 1000);
  };

  /**
   * ----------------------------------------
   * Event handlers
   */

  private handlePlaySuccess = (): void => {
    if (typeof this.onPlaySuccess === 'function') {
      this.onPlaySuccess();
    }
  };

  private handlePlayFail = (error: DOMException): void => {
    this.clearTimers();
    // Store this value as we've probably interrupted the timers
    this.lastPlayedAtMs = Date.now();
    this.pause();
    if (typeof this.onPlayFail === 'function') {
      this.onPlayFail(error);
    }
  };

  private handleEnd = (): void => {
    if (typeof this.onEnd === 'function') {
      this.onEnd(this);
    }
  };

  private handleMediaEndTimerEnd = async (): Promise<void> => {
    this.clearTimers();
    try {
      this.pauseMedia();
      this.handleEnd();
    } catch (e) {
      // @TODO: try to determine why exactly this happens
      // @WHEN: when it becomes important
    }
    if (typeof this.onAction === 'function') {
      await this.onAction();
    }
  };

  /**
   * ----------------------------------------
   * Timers
   *
   */

  private startTimers = async (): Promise<void> => {
    const state = this.getState();
    if (state === State.PLAYING) {
      return; // Don't play again
    }
    this.clearTimers();

    // Re-initiate play/pause times
    this.setLastPlayedAtMs(Date.now());
    this.setLastPausedAtMs(undefined);

    await Promise.all(TIMERS.map(id => this.startTimer(id)));
  };

  private startTimer = async (id: TimerId): Promise<void> => {
    const remainingTime = this.getEndAtMsForTimer(id) - this.getTimeElapsed();

    if (remainingTime <= 0) {
      await this.triggerTimerAction(id);
      return;
    }
    this.clearTimer(id);
    this.timers[id] = new SetTimeout(async () => {
      await this.triggerTimerAction(id);
      this.clearTimer(id);
    }, remainingTime);
  };

  private triggerTimerAction = async (id: TimerId): Promise<void> => {
    if (id === TimerId.mediaStartTimer) {
      // We could get to this when the delay is zero and the user has play/paused/played
      // but we're beyond the end of the video so we don't want to restart
      if (
        this.getEndAtMsForTimer(TimerId.mediaEndTimer) > this.getTimeElapsed()
      ) {
        await this.playMedia();
      }
    } else {
      if (!this.triggeredState[id]) {
        // We shouldn't need this check but it's safer this way
        await this.handleMediaEndTimerEnd();
        this.triggeredState[id] = true;
      }
    }
  };

  private clearTimers = (): void => {
    this.clearTimer(TimerId.mediaEndTimer);
    this.clearTimer(TimerId.mediaStartTimer);
  };

  private clearTimer = (id: TimerId): void => {
    const timer = this.timers[id];
    if (timer) {
      timer.clearTimeout();
      this.timers[id] = undefined;
    }
  };

  /**
   * ----------------------------------------
   * Public setters
   *
   */

  setOnPlayFailHandler = (onPlayFail: OrchestratorFailCallback): void => {
    this.onPlayFail = onPlayFail;
  };

  setOnPlaySuccessHandler = (
    onPlaySuccess: OrchestratorSuccessCallback
  ): void => {
    this.onPlaySuccess = onPlaySuccess;
  };

  setOnEndHandler = (onEnd: OrchestratorEndCallback): void => {
    this.onEnd = onEnd;
  };

  setOnActionHandler = (onAction: ActionHandlerCallback): void => {
    this.onAction = onAction;
  };

  setPlayLock = (): void => {
    this.playLock = true;
  };

  releasePlayLock = (): void => {
    this.playLock = false;
  };

  setProps = async (nextProps: AVProps): Promise<void> => {
    const newSubsetProps = MediaNodeWithTimer.getSubsetOfProps(nextProps);
    const oldSubsetProps = MediaNodeWithTimer.getSubsetOfProps(this.props);
    if (equals(oldSubsetProps, newSubsetProps)) {
      // Don't do anything
      return;
    }

    const nextTo = nextProps.to;
    const nextFrom = nextProps.from;
    const nextDelay = nextProps.delay;
    const isFreezeFrame = nextFrom === nextTo;
    const isContinuous = this.props.isContinuous;
    const doesJump = this.props.to !== nextProps.from;

    this.props = clone(nextProps);
    this.reset();
    if (isContinuous) {
      // Let the media keep playing but reset everything else and start the timers
      // again for the next actions
      await this.startTimers();
      return;
    }
    if (nextDelay > 0) {
      // Pause the media and then make the timers restart using the new values
      this.pauseMedia();
      await this.play();

      return;
    }
    if (isFreezeFrame) {
      if (doesJump) {
        this.seekToMs(nextFrom * 1000);
      }
      this.pauseMedia();
      return;
    }
    // Else just play
    await this.play();

    return;
  };

  /**
   * ----------------------------------------
   * Private setters
   *
   */

  private setLastPlayedAtMs = (time: number | undefined): void => {
    this.lastPlayedAtMs = time;
  };

  private setLastPausedAtMs = (time: number | undefined): void => {
    this.lastPausedAtMs = time;
  };

  private setTimerElapsedTimeMs = (time: number): void => {
    this.timerTimeElapsedMs = time;
  };

  /**
   * ----------------------------------------
   * Public getters
   */

  getProps = (): AVProps => this.props;

  static getPropsForNode = (node: AVRefNode): AVProps =>
    (node.current as PlatformAVPrimitive).props;

  static getSubsetOfProps = (props: AVProps): SubsetProps => {
    return {
      delay: props.delay as number,
      from: props.from as number,
      to: props.to as number,
      action: props.action as SceneAction,
      isContinuous: props.isContinuous,
    };
  };

  getNode = (): AVRefNode => this.refNode;

  getPlayer = (): PlatformAVPrimitive =>
    this.getNode()?.current as PlatformAVPrimitive;

  /**
   * ----------------------------------------
   * Private getters
   */

  private getMediaStartAtMs = (): number => (this.props.delay || 0) * 1000;

  private getMediaEndAtMs = (): number =>
    (this.props.delay + this.props.to - this.props.from) * 1000;

  private getEndAtMsForTimer = (id: TimerId): number => {
    if (id === TimerId.mediaStartTimer) {
      return this.getMediaStartAtMs();
    } else {
      return this.getMediaEndAtMs();
    }
  };

  private isPlayable = (): boolean => this.props.from !== this.props.to;

  private getTimeElapsed = (): number => {
    return this.timerTimeElapsedMs;
  };

  private getLastPlayedAt = (): number =>
    this.lastPlayedAtMs || -10000000000000;

  private getState = (): State => {
    // Important: we literally want `this.lastPlayedAtMs` here and not the getter value
    if (!this.lastPlayedAtMs) {
      return State.UNINITIALIZED;
    }
    if (this.lastPausedAtMs) {
      return State.PAUSED;
    }
    return State.PLAYING;
  };

  private getSeekToTimeMs = (): number => {
    const { delay, from, to } = this.getProps();
    const delayMs = delay * 1000;
    const fromMs = from * 1000;
    const toMs = to * 1000;
    const totalMediaTimeMs = delayMs + toMs - fromMs;
    if (this.timerTimeElapsedMs >= totalMediaTimeMs) {
      // I.e. we're done
      return toMs;
    }
    if (this.timerTimeElapsedMs >= delayMs) {
      // I.e. we're still playing the video
      return fromMs + this.timerTimeElapsedMs - delayMs;
    }
    // I.e. we've either not started or not got into the playing phase yet
    return fromMs;
  };

  /**
   * ----------------------------------------
   * For use in testing only
   */

  __getState = (): State => this.getState();

  __getTimerTimeElapsed = (): number => this.timerTimeElapsedMs;

  __getTimerStartAtMs = (): number | undefined => this.lastPlayedAtMs;

  __getTimerPausedAtTimeMs = (): number | undefined => this.lastPausedAtMs;

  __getCurrentTime = (): number => Date.now();

  __getPlayLock = (): boolean => this.playLock;
}
