import styled from 'styled-components/native';
import { Animated, View } from 'react-native';
import React, { ReactElement, ReactNode, useContext } from 'react';
import { usePulsingOpacityAnimation } from 'lib/animations';
import {
  CONTENT_MIN_REFERENCE_DIMENSION,
  ScreenResponsiveLayoutContext,
  useGetDynamicContentStyles,
} from 'components/ResponsiveLayout';
import { Text } from 'components/Text';
import { roles } from 'cross-platform/utils/roleProps';
import { Footer } from 'components/Footer';
import { ContentContainer, ScreenScroll } from '../ContentScreens';

// IDEA: create a screen-componenents.tsx file that is in common to all the Screens
// WHY: ContentScreens and SettingsScreen share some of the components.
// Now we are duplicating them (in this file).

// --- section with Components that are in common with other Screens
const ContentStatic = styled.View`
  background-color: #ddefff;
`;
ContentStatic.displayName = 'ContentStatic';

const ContainerView = styled.ScrollView`
  flex: 1;
`;
ContainerView.displayName = 'ContainerView';

export const Content = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <ContentStatic
      style={{
        paddingLeft: styles.contentHorizontalPadding,
        paddingRight: styles.contentHorizontalPadding,
        paddingTop: styles.contentVerticalPadding,
        paddingBottom: 0,
        borderRadius: styles.contentBorderRadius,
      }}
    >
      {children}
    </ContentStatic>
  );
};

export const ScrollColumn = styled.ScrollView``;
ScrollColumn.displayName = 'ScrollColumn';

export const ContentColumnStatic = styled.View``;
ContentColumnStatic.displayName = 'ContentColumnStatic';

export const PageTitle = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <View
      style={{
        marginBottom: styles.pageTitlePaddingBottom,
        marginTop: styles.pageTitlePaddingTop,
      }}
    >
      <Text style={{ fontSize: styles.pageTitleFontSize, color: '#003366' }}>
        {children}
      </Text>
    </View>
  );
};

const SkeletonOuterContainerStatic = styled(Animated.View)`
  background-color: #ddefff;
  min-height: 200px;
`;

export const SkeletonOuterContainerAnimated = (): ReactElement => {
  const fadeAnimation = usePulsingOpacityAnimation();
  const styles = useGetDynamicContentStyles();
  return (
    <SkeletonOuterContainerStatic
      {...roles('SkeletonOuterContainerAnimated')}
      style={{
        opacity: fadeAnimation,
        paddingLeft: styles.contentHorizontalPadding,
        paddingRight: styles.contentHorizontalPadding,
        paddingTop: styles.contentVerticalPadding,
        bottom: styles.contentVerticalPadding,
        borderRadius: styles.contentBorderRadius,
        marginBottom: styles.contentMarginBottom,
      }}
    />
  );
};

const EntryStatic = styled.View`
  background-color: white;
  flex-direction: column;
  align-items: flex-start;
`;

export const Entry = ({ children }: { children: ReactNode }): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <EntryStatic
      style={{
        paddingLeft: styles.entryHorizontalPadding,
        paddingRight: styles.entryHorizontalPadding,
        paddingTop: styles.entryVerticalPadding,
        paddingBottom: styles.entryVerticalPadding,
        borderRadius: styles.entryBorderRadius,
        marginBottom: styles.entryMarginBottom,
      }}
    >
      {children}
    </EntryStatic>
  );
};

// --- section with ad-hoc SettingsScreen components

export const Container = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  const isNarrow = screenWidth <= CONTENT_MIN_REFERENCE_DIMENSION;

  let paddingLeft = styles.settingsPaddingLeftNarrow;
  if (!isNarrow) {
    paddingLeft = styles.settingsPaddingLeft;
  }
  return (
    <ScreenScroll showsHorizontalScrollIndicator={false}>
      <ContentContainer
        style={{
          flex: 1,
          width: '100%',
          backgroundColor: 'white',
          paddingLeft: paddingLeft,
          paddingBottom: styles.pagePaddingBottom,
          paddingRight: styles.settingsPaddingLeftNarrow,
        }}
      >
        {children}
      </ContentContainer>
      <Footer />
    </ScreenScroll>
  );
};

export const ContentColumn = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <ContentColumnStatic
      style={{
        maxWidth: styles.settingsMaxWidth,
      }}
    >
      {children}
    </ContentColumnStatic>
  );
};

export const SettingsEntry = ({
  heading,
  subheading,
  children,
}: {
  heading: string;
  subheading?: string;
  children?: ReactNode;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  return (
    <Entry>
      <Text
        style={{
          fontSize: styles.settingsEntryHeadingFontSize,
          paddingBottom: styles.settingsEntrySubHeadingPadding,
          color: '#003366',
        }}
      >
        {heading}
      </Text>
      {typeof subheading !== 'undefined' ? (
        <Text
          style={{
            fontSize: styles.settingsEntryTextFontSize,
            paddingBottom: styles.settingsEntryTextPadding,
            color: '#003366',
          }}
        >
          {subheading}
        </Text>
      ) : null}
      {children}
    </Entry>
  );
};
