import React, { ReactElement } from 'react';
import { roles } from 'cross-platform/utils/roleProps';
import { FillAbsoluteCenter } from 'components/Containers';
import { Text } from 'components/Text';
import { ProgressCard } from './ProgressCard';

import styled from 'styled-components/native';
import { View } from 'components/Screens/ContentScreens/Resources/styled';
import { useTransformContentStyle } from 'daylight/components/ResponsiveLayout/useTransformContentStyle';
import { ScrollView } from 'components/PopupModal/components/Presentational/styled';
import { useQueryDaylightProgressData } from 'lib/api/reactQueryHelpers';

const TEXT_COLOR = '#231F20';
const PADDING_VERTICAL_PERCENTAGE = 1;

const ContainerProgressCard = styled.View`
  padding-vertical: ${PADDING_VERTICAL_PERCENTAGE}%;
`;

export const ProgressScreen = (): ReactElement => {
  const { status, data } = useQueryDaylightProgressData();

  const transformStyle = useTransformContentStyle();

  let message = undefined;
  let payload = undefined;

  switch (status) {
    case 'loading':
      message = 'Loading...';
      break;
    case 'error':
      message = 'Could not fetch progress data';
      break;
    case 'success': {
      if (typeof data?.result.warning_message === 'string') {
        message = data.result.warning_message;
      } else {
        payload = data?.result;
      }
    }
  }

  if (typeof payload === 'undefined') {
    return (
      <FillAbsoluteCenter
        {...roles('progressScreenMessage')}
        style={{
          backgroundColor: 'white',
        }}
      >
        <Text>{message}</Text>
      </FillAbsoluteCenter>
    );
  }

  const titleSize = transformStyle(24);
  const bigTextSize = transformStyle(60);
  const textSize = transformStyle(21);
  const smallSize = transformStyle(16);

  const MINUTES_IN_DAY = 24 * 60;
  const remainingDays = Math.ceil(
    payload.time_until_next_checkin_minutes / MINUTES_IN_DAY
  );

  return (
    <ScrollView
      {...roles('progressScreen')}
      style={{
        backgroundColor: '#EDEDED',
      }}
    >
      {/*
       // IDEA: evaluate the possibility to have also a Top Navigation Bar
       // togethet with the current Navigation Bar at the bottom 
      */}
      <View
        style={{
          backgroundColor: 'white',
          height: 157,
          paddingBottom: '5%',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Text style={{ fontSize: titleSize, color: TEXT_COLOR }}>
          My progress
        </Text>
      </View>

      <View
        style={{
          padding: '2%',
        }}
      >
        <ContainerProgressCard>
          <ProgressCard
            upperText={{
              text: 'your top goal',
              fontSize: smallSize,
              color: TEXT_COLOR,
            }}
            lowerText={{
              text: `Manage your anxiety in order to `,
              fontSize: textSize,
              color: TEXT_COLOR,
            }}
            boldText={{
              text: payload.goal_answer,
              fontSize: textSize,
              color: TEXT_COLOR,
            }}
          />
        </ContainerProgressCard>

        <ContainerProgressCard
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <ProgressCard
            upperText={{
              text: `${payload.number_completed_checkins}`,
              fontSize: bigTextSize,
              color: '#66D2FF',
            }}
            lowerText={{
              text: `Check-in completed`,
              fontSize: textSize,
              color: TEXT_COLOR,
            }}
            width={`${50 - PADDING_VERTICAL_PERCENTAGE}%`}
          />

          <ProgressCard
            upperText={{
              text: `${payload.completed_exercises}`,
              fontSize: bigTextSize,
              color: '#4CA009',
            }}
            lowerText={{
              text: `Practices completed`,
              fontSize: textSize,
              color: TEXT_COLOR,
            }}
            width={`${50 - PADDING_VERTICAL_PERCENTAGE}%`}
          />
        </ContainerProgressCard>

        <ContainerProgressCard>
          {payload.time_until_next_checkin_minutes === 0 ? (
            <ProgressCard
              lowerText={{
                text: 'Your next Check-in is ready.',
                fontSize: textSize,
                color: TEXT_COLOR,
              }}
            />
          ) : (
            <ProgressCard
              lowerText={{
                text: `Your next Check-in is in `,
                fontSize: textSize,
                color: TEXT_COLOR,
              }}
              boldText={{
                text:
                  `${remainingDays}` +
                  `${
                    remainingDays > 1 || remainingDays == 0 ? ' days' : ' day'
                  }`,
                fontSize: textSize,
                color: TEXT_COLOR,
              }}
            />
          )}
        </ContainerProgressCard>

        {payload.progress_exercises.map((exercise, index) => (
          <Text
            key={index}
            style={{
              marginTop: 10,
              fontSize: textSize,
              color: TEXT_COLOR,
              opacity: exercise.times_ssg_completion > 0 ? 1 : 0.4,
            }}
          >
            Exercise: {exercise.name_ssg}
          </Text>
        ))}
      </View>
    </ScrollView>
  );
};
