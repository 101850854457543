import React, { ReactElement, ReactNode, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { PressHandler } from 'components/Button';
import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { pass } from 'cross-platform/utils/roleProps';
import { Link } from 'react-router-dom';

const CircleButton = ({
  children,
  onPress,
  ...rest
}: {
  children: ReactNode;
  onPress: PressHandler;
  rest?: string;
}): ReactElement => {
  const styles = useGetDynamicContentStyles();
  const [backgroundColor, setBackgroundColor] = useState<string>('#1a80a2');

  const handleMouseEnter = () => {
    setBackgroundColor('#00212c');
  };

  const handleMouseLeave = () => {
    setBackgroundColor('#1a80a2');
  };
  return (
    <View>
      <Link
        to=""
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={event => event.preventDefault()}
      >
        <TouchableOpacity
          activeOpacity={1}
          style={{
            opacity: 1,
            backgroundColor: backgroundColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: styles.circleButtonSetSleepWindowDiameter / 2,
            width: styles.circleButtonSetSleepWindowDiameter,
            height: styles.circleButtonSetSleepWindowDiameter,
            marginLeft: styles.circleButtonSetSleepWindowMarginLeft,
          }}
          onPress={onPress}
          {...pass(rest)}
        >
          {children}
        </TouchableOpacity>
      </Link>
    </View>
  );
};

export { CircleButton };
