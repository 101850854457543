import React, { ReactElement, ReactNode } from 'react';
import { ScalingContext } from '../providers';
import { ContainerWithContext } from './common';
import styled from 'styled-components/native';
import { roles } from 'cross-platform/utils/roleProps';
import { ToggleControlsHandler } from 'components/ShowHideControlsHandler';

const BaseContainer = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const MediaContainer = ({
  children,
  style,
}: {
  children?: ReactNode;
  style: {
    height: number;
    width: number;
  };
}): ReactElement => {
  return (
    <ContainerWithContext
      {...roles('MediaCenterClipContainer')}
      scalingContext={ScalingContext.MediaContainer}
      style={style}
    >
      <BaseContainer {...roles('MediaResponsiveLayoutContainer')}>
        <ToggleControlsHandler />
        {children}
      </BaseContainer>
    </ContainerWithContext>
  );
};
