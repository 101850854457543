import React, { ReactElement } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { useDispatchPathToParent } from 'developer/hooks/useDispatchPathToParent';
import { StoryBook } from './StoryBook';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { useBackButtonTriggerForPath } from './useBackButtonTriggerForPath';
import { DaylightSwitch } from './DaylightSwitch';
import { SleepioSwitch } from './SleepioSwitch';
import { ProductReferences } from 'common/constants/enums';
import { getDefaultRoute } from 'components/Routes/getDefaultRoute';
import { createErrorBoundaryForDomain } from 'lib/error/utils/createErrorBoundaryForDomain';
import { withContentPlaceholderWrapper } from 'lib/error/ErrorBoundary/utils/withContentPlaceholderWrapper/withContentPlaceholderWrapper';

const ErrorBoundaryForDaylightSwitch = createErrorBoundaryForDomain(
  'components/Routes'
);

import { useQueryProgram } from 'lib/api/reactQueryHelpers';
import { useInitializeAPIClient } from 'lib/api/hooks/useInitializeAPIClient';

export const ProductRoutesWithProgram = (): ReactElement => {
  const { productReference } = useSafeParams();
  useQueryProgram();
  if (productReference === ProductReferences.DAYLIGHT) {
    return <DaylightSwitch />;
  }
  return <SleepioSwitch />;
};

export const ProductRoutes = (): ReactElement => {
  useBackButtonTriggerForPath();
  const clientInitialized = useInitializeAPIClient();
  if (!clientInitialized) {
    return <></>;
  }
  return <ProductRoutesWithProgram />;
};

const Routes = (): ReactElement => {
  const history = useHistory();
  useDispatchPathToParent();
  if (['', '/'].includes(history.location.pathname)) {
    // Redirect only works satisfactorily on first load and not subsequent page reloaded. Because
    // of the way this works in tests this presents a problem of the app redirecting back to
    // the root page when we don't . So we test for
    return <Redirect exact from="/" to={getDefaultRoute()} />;
  }

  return (
    <ErrorBoundaryForDaylightSwitch
      fallbackRenderWithWrapper={withContentPlaceholderWrapper}
    >
      <Switch>
        <Route path={'/:productReference'}>
          <ProductRoutes />
        </Route>
        <Route path={'/storybook'} component={StoryBook} />
        <Route path={'/storybook/:story(.*)'} component={StoryBook} />
      </Switch>
    </ErrorBoundaryForDaylightSwitch>
  );
};

export default Routes;
