import {
  CorrectAnswersByQuestion,
  CheckQuizAnswersCallback,
} from 'lib/question-response/useQuizForCurrentScene';
import { checkResponseOption } from './checkResponseOption';

const isCorrectCallbackWithCorrectResponseIds = (
  correctResponseIds: CorrectAnswersByQuestion
): CheckQuizAnswersCallback => {
  /**
   *
   * @param values
   */
  return (values): boolean => {
    for (const nameKey in values) {
      // TS infers string: https://github.com/microsoft/TypeScript/issues/32811#issuecomment-520448992
      const name = (nameKey as unknown) as keyof typeof values;
      const attemptValue = values[name];

      if (correctResponseIds[name]?.correct_response_ids?.length) {
        if (
          checkResponseOption(
            attemptValue,
            correctResponseIds[name].correct_response_ids
          ) === false
        ) {
          return false;
        }
      }
    }
    return true;
  };
};

export { isCorrectCallbackWithCorrectResponseIds };
