import { ActionTypes } from './ActionTypes';
import { UseServiceCallActionPayload } from './actions';
import { APIResponse } from '@bighealth/api';

export type UseServiceMethodCallIdle = { status: ActionTypes.IDLE };
export type UseServiceMethodCallFetching = { status: ActionTypes.FETCHING };
export type UseServiceMethodCallError = {
  status: ActionTypes.ERROR;
  response: unknown;
};
export type UseServiceMethodCallSuccess = {
  status: ActionTypes.SUCCESS;
  response: APIResponse;
};

export type UseServiceCallState =
  | UseServiceMethodCallIdle
  | UseServiceMethodCallFetching
  | UseServiceMethodCallError
  | UseServiceMethodCallSuccess;

export const initialState = {
  status: ActionTypes.IDLE,
};

const reducer = (
  state: UseServiceCallState,
  action: UseServiceCallActionPayload
): UseServiceCallState => {
  switch (action.type) {
    case ActionTypes.FETCHING:
      return { ...initialState, status: ActionTypes.FETCHING };
    case ActionTypes.SUCCESS:
      return {
        ...state,
        status: ActionTypes.SUCCESS,
        response: action.response,
      };
    case ActionTypes.ERROR:
      return { ...state, status: ActionTypes.ERROR, response: action.response };
    case ActionTypes.IDLE:
      return { ...state, status: ActionTypes.IDLE };
    default:
      return state;
  }
};

export { reducer };
