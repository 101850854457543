// @TODO: delete me
// WHEN: when we merge the state stuff (keyword: STATE_REFACTOR)
import React, { ReactElement } from 'react';
import { View } from 'react-native';
import { ControlButton } from 'components/ControlButton';
import { QuizAttribute } from 'components/constants';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { pass } from 'cross-platform/utils/roleProps';
import { Value } from 'components/forms/types';
import { ResponseOption } from '@bighealth/types';

export type SingleSelectProps = {
  value?: Value | DropdownItem[];
  onChange: (value: Value) => void;
  items?: DropdownItem[];
  correctSelection?: ResponseOption['id'][];
  incorrectSelection?: ResponseOption['id'][];
};

export const SingleSelect = ({
  value,
  onChange,
  items = [],
  correctSelection = [],
  incorrectSelection = [],
  ...rest
}: SingleSelectProps): ReactElement => {
  return (
    <View {...pass(rest)}>
      {items.map(item => {
        return (
          <ControlButton
            key={`${item.value}`}
            isSelected={
              value instanceof Array
                ? value.includes(item)
                : value === item.value
            }
            quiz={
              correctSelection.find(id => id === item.id)
                ? QuizAttribute.CORRECT
                : incorrectSelection.find(id => id === item.id)
                ? QuizAttribute.INCORRECT
                : undefined
            }
            onPress={(): void => onChange(item.value)}
            {...pass(rest, `${item.value}`)}
          >
            {item.displayText}
          </ControlButton>
        );
      })}
    </View>
  );
};
