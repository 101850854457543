import React from 'react';
import { fetchSubmitDataAsync } from '../../helpers/fetchSubmitDataAsync';
import { useWithResponseHooks } from 'lib/api/hooks';
import { SleepDiaryService } from 'lib/api/SleepDiaryService';
import { ResponseOption } from '@bighealth/types';
import { asDiaryEntry } from '../../helpers/asDiaryEntry';
// TODO: move the hook outside of the SessionScreen to
// make it avaialable in the whole codebase
import { useQueryProduct } from 'lib/api/reactQueryHelpers';
import { NetworkedFlowingFormProps } from '../..';
import { SleepDiaryPayloads } from '@bighealth/types/src/services/SleepDiaryPayloads';
import { SubmitState } from '../../providers/SubmitCallbackProvider';

export type Args = {
  renderRequest: SleepDiaryPayloads['get_diary_entry_form']['request'];
  onSuccessfulSubmit: NetworkedFlowingFormProps['onSuccessfulSubmit'];
  programId: number | undefined;
  submitState?: SubmitState;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>;
};

type SubmitHandler = (
  values: Record<React.ReactText, ResponseOption[]>
) => Promise<void>;

const useSubmitHandler = ({
  renderRequest,
  onSuccessfulSubmit,
  programId,
  setIsSubmitting,
  setError,
  submitState,
}: Args): SubmitHandler => {
  const productId = useQueryProduct()?.data?.result.id;
  const fetchSubmit = useWithResponseHooks(
    SleepDiaryService[renderRequest.args.form_type]
  );
  const diaryDate = renderRequest.args?.diary_date?.$date; // variable created to allow static analysis in hook deps
  const submitHandler = React.useCallback(
    (values: Record<React.ReactText, ResponseOption[]>): Promise<void> => {
      // IDEA fix formik wiring so submitHandler is awaited when submitForm() is called
      return fetchSubmitDataAsync({
        fetchData: fetchSubmit,
        fetchDataArgs: [
          asDiaryEntry({
            values: values,
            diaryDate,
            formType: renderRequest.args.form_type,
            productId: productId,
            programId: programId,
          }),
        ],
        onLoadingChange: setIsSubmitting,
        // cast handler "as" param fn, as handler doesn't use param args
        onSuccess: () => {
          if (typeof onSuccessfulSubmit === 'function') {
            onSuccessfulSubmit(Date.now());
          }
          if (typeof submitState?.onSubmitDone === 'function') {
            submitState?.onSubmitDone();
          }
        },
        onError: error => setError(error),
      });
    },
    [
      fetchSubmit,
      diaryDate,
      renderRequest.args.form_type,
      productId,
      programId,
      setIsSubmitting,
      onSuccessfulSubmit,
      submitState,
      setError,
    ]
  );
  return submitHandler;
};

export { useSubmitHandler };
