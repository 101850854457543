import { ReactNode } from 'react';
import { SceneAction } from '@bighealth/types/src/scene-components/client';
import { ViewStyle } from 'react-native';
import { buttonColorSchemes } from 'components/UniveralButtons/buttonColorSchemes';

export type ButtonDimensions = {
  paddingTop: number | string;
  paddingLeft: number | string;
  paddingBottom: number | string;
  paddingRight: number | string;
  borderRadius: number;
  minWidth: number | string;
  lineHeight: number;
  fontSize: number;
  minHeight: number | string;
  borderWidth: number;
};

// Strictly we do allow content creators to create other styles, but we'll restrict it
// here just to make the types a little easier to handle.
export type NumericalCSSStyles = {
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  marginBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingTop?: number;
  paddingBottom?: number;
};

export type ButtonStyles = {
  color: string;
  backgroundColor: string;
} & ButtonDimensions;

export type InteractionHandler = () => void;

export enum ButtonSizes {
  Small = 'small',
  Mini = 'mini',
  LegacySecondary = 'SECONDARY_BUTTON',
  SmallText = 'small_text',
}

export type ButtonProps = {
  testID?: string; // This gets converted to `data-testid` for you on web
  action?: SceneAction;
  align?: string;
  isDisabled?: boolean;
  onPress?: () => void;
  style?: ViewStyle & { fontSize?: number };
  text: ReactNode;
  size?: ButtonSizes;
  colorScheme?: keyof typeof buttonColorSchemes;
};

export type ButtonInteractionColors = {
  color: string;
  backgroundColor: string;
  borderColor: string;
};

export type ButtonInteractionColorScheme = {
  disabled: ButtonInteractionColors;
  pressed: ButtonInteractionColors;
  default: ButtonInteractionColors;
};
