import { useEffect, useRef, useState } from 'react';
import { getQueryClient } from 'components/ProvidersContainer';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { API_ENDPOINT_FULL_URL } from 'config/envVars';
import { getTimezone } from 'lib/timezone';
import { middleware } from 'lib/api/middleware';
import { STATE_RESET } from 'state/store/actions';

import logger from 'lib/logger';
import { APIClient, APIRequestBody } from '@bighealth/api';
import {
  matchesLogin,
  matchesSceneSet,
  matchesResourcesScreen,
} from 'components/Routes/matchesRoutes';

export const useInitializeAPIClient = (): boolean => {
  const [clientInitialized, setInitialized] = useState(false);
  const queryClient = getQueryClient();
  const history = useHistory();
  const dispatch = useDispatch();
  const { productReference } = useSafeParams();
  const loggingOut = useRef(false);

  useEffect(() => {
    APIClient.configure({
      url: API_ENDPOINT_FULL_URL,
      headers: {
        'Content-Type': 'application/json',
        'Bh-User-Timezone': getTimezone(),
        'Access-Control-Allow-Origin': '*',
      },
      withCredentials: true,
      method: 'post',
      onError: error => {
        let service_name;
        try {
          const data = JSON.parse(error?.toJSON()?.config?.data);
          ({ service_name } = data || {});
        } catch (err) {
          // Could legitimately not be JSON
        }
        // FIXME do not hard-code behavior based on service name
        if (matchesSceneSet(history) && service_name !== 'Event') {
          // For all other screens boot the user back to home.
          history.push(`/${productReference}/home`);
        }
        logger(error.message, error, { silent: true });
      },
      onTokenRefreshFail: error => {
        (async function handleTokenRefreshFail() {
          // We can't trust this function to be called just once so we're going to lock the handling of logout
          // until we're done so it's only done once

          // The resources page doesn't require authentication although it does include
          // some components like the session dot which _do_ require authentication.
          // For the sake of expediency (i.e. a temporary solution) we bypass auth checking
          // for that path here.
          // @TODO remove this temporary fix and instead create a provider that can turn on and off auth requirements
          // @WHEN when we add more unauthenticated routes
          if (
            !matchesResourcesScreen(history) &&
            !matchesLogin(history) &&
            !loggingOut.current
          ) {
            loggingOut.current = true;
            try {
              await queryClient.cancelQueries();
            } catch (e) {
              // We don't need to do anything with cancelQueries error
            } finally {
              queryClient.clear();
              dispatch({ type: STATE_RESET });
              history.push(`/${productReference}/login`);
              logger('Logged out', Error(error?.result?.user_message), {
                silent: true,
              });
              // We do genuinely want this
              // eslint-disable-next-line require-atomic-updates
              loggingOut.current = false;
            }
          }
        })();
      },
      middleware: async (request, response) => {
        return middleware.responses(request as APIRequestBody)(response);
      },
    });
    setInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return clientInitialized;
};
