import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { Link, useHistory } from 'cross-platform/react-router';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { isDevMode } from 'lib/isDevMode';
import { TouchableOpacity } from 'react-native';
import { showQAMenu } from 'lib/showQAMenu';

export const LogoButton = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const { productReference } = useSafeParams();
  const history = useHistory();

  return isDevMode() ? (
    <TouchableOpacity onPress={() => showQAMenu(productReference, history)}>
      {children}
    </TouchableOpacity>
  ) : (
    <Link to={`/${productReference}/home`} title={'Home'}>
      {children}
    </Link>
  );
};
