// NOTE Why snake case? As named in backend code and don't want to alias
import { get_sleep_diary_component_data_for_time_period } from './get_sleep_diary_component_data_for_time_period/index';
import { get_diary_entry_form } from './get_diary_entry_form/index';
import { create_single_day_entry } from './create_single_day_entry/index';
import { update_single_day_entry_with_date } from './update_single_day_entry_with_date/index';
import { create_from_weekly_average_estimate } from './create_from_weekly_average_estimate/index';

export const Payloads = {
  // render weekly view
  get_sleep_diary_component_data_for_time_period,
  // render entry form
  get_diary_entry_form,
  // submit diary entry form
  create_single_day_entry,
  update_single_day_entry_with_date,
  create_from_weekly_average_estimate,
  // submit extrapolation
  // extrapolate_missing_diaries_from_past_seven_days: SleepDiaryPayloads['extrapolate_missing_diaries_from_past_seven_days']['request'];
};
