import * as React from 'react';
import { TextStyle } from 'react-native';
import { Text } from './components/Text';
import { COPYRIGHT_TEXT } from 'lib/strings';
import styled from 'styled-components/native';
import { Link } from 'cross-platform/react-router';
import { useScreenWidth } from './hooks/useScreenWidth';
import { useSleepioVersion } from './hooks/useSleepioVersion';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import { CONTENT_MIN_REFERENCE_DIMENSION } from 'components/ResponsiveLayout';

const DOMAIN = 'LoginFooter';

const Wrapper = styled.View`
  width: 100%;
  height: 90px;
  margin-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  flex-direction: column;
  justify-content: center;
`;
Wrapper.displayName = `${DOMAIN}.Wrapper`;

const MobileWrapper = styled(Wrapper)`
  height: 120px;
  padding-left: 37px;
  padding-right: 37px;
`;
Wrapper.displayName = `${DOMAIN}.MobileWrapper`;

const GeneralInfo = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;
GeneralInfo.displayName = `${DOMAIN}.GeneralInfo`;

const MobileGeneralInfo = styled.View`
  flex-direction: column;
  justify-content: space-between;
`;
MobileGeneralInfo.displayName = `${DOMAIN}.MobileGeneralInfo`;

const AboutUsText = styled.Text`
  color: white;
  font-size: 16px;
  text-decoration: underline;
  text-decoration-color: white;
`;
AboutUsText.displayName = `${DOMAIN}.AboutUs`;

type FooterProps = React.ComponentProps<typeof Wrapper> & RoleProps;

const SPACE_BETWEEN_LINES = 8;
export const AboutUsLink = ({
  style = {},
}: {
  style?: TextStyle;
}): React.ReactElement => {
  const { productReference } = useSafeParams();
  const aboutUsUrl = `/${productReference}/resources`;

  return (
    <Link
      to={aboutUsUrl}
      title={`Go To Resources`}
      style={{ marginBottom: SPACE_BETWEEN_LINES }}
    >
      <AboutUsText style={style}>About Us</AboutUsText>
    </Link>
  );
};

// This footer is used only on native because web is handled by
// onboarding. It has a breakpoint for landscape and portrait styling
export const LoginFooter = (props: FooterProps): React.ReactElement => {
  const screenWidth = useScreenWidth();
  const sleepioVersion = useSleepioVersion();

  const isNarrow = screenWidth <= CONTENT_MIN_REFERENCE_DIMENSION;

  if (isNarrow) {
    return (
      <MobileWrapper {...props} {...roles.pass(props, DOMAIN)}>
        <AboutUsLink />
        <MobileGeneralInfo>
          <Text
            text={sleepioVersion}
            style={{ marginBottom: SPACE_BETWEEN_LINES }}
          />
          <Text text={COPYRIGHT_TEXT} />
        </MobileGeneralInfo>
      </MobileWrapper>
    );
  }

  const fontSize = 18;
  return (
    <Wrapper {...props} {...roles.pass(props, DOMAIN)}>
      <AboutUsLink style={{ fontSize }} />
      <GeneralInfo>
        <Text text={sleepioVersion} style={{ fontSize }} />
        <Text text={COPYRIGHT_TEXT} style={{ fontSize }} />
      </GeneralInfo>
    </Wrapper>
  );
};
