import React from 'react';
import { FlexBox } from '.';
import { addStory } from 'lib/story-books';
import { Text } from 'components/primitives/cross-platform';

addStory('flex-box', () => (
  <FlexBox>
    <Text>Item 1</Text>
  </FlexBox>
));
addStory('flex-box-complicated', () => (
  <FlexBox>
    <FlexBox flexGrow={1}>
      <Text>Item 1</Text>
    </FlexBox>
    <FlexBox flexGrow={1}>
      <Text>Item 2</Text>
    </FlexBox>
  </FlexBox>
));
