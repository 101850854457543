import { Dimensions } from 'react-native';
import { CARD_SPACING_PX, CARD_WIDTH_PX } from './Card';

const screenWidth = Dimensions.get('window').width;

/**
 * Returns the offset necessary to make the first carousel card centered for
 * the initial screen size
 * @TODO Make this change dynamically for web
 * WHEN When it becomes important
 */
export const getScrollOffsetLeft = (): number => {
  return (screenWidth - CARD_WIDTH_PX) / 2 - 2 * CARD_SPACING_PX;
};
export const getScrollOffsetRight = (): number => {
  return (screenWidth - CARD_WIDTH_PX) / 2 - 2 * CARD_SPACING_PX;
};
