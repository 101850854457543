import { DAY, HOUR } from 'lib/durations';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { QuestionId } from 'state/question-response/actions';
import { getSelected } from '../../helpers/getSelected';
import { WarningAndHighlight } from '..';
import {
  AWAKENINGS_TOTAL_TIME,
  TIME_FINAL_AWAKENING,
  TIME_TRY_TO_SLEEP,
  AWAKENINGS_IN_NIGHT,
  TO_FALL_ASLEEP_TOTAL_TIME,
} from 'components/SleepDiaryForm/constants';
import { Value } from 'components/forms/types';

const getAwakeningsTotalTime = (awakenings: Value, totalTime: Value): Value => {
  if (typeof awakenings === 'number' && awakenings === 0) {
    return 0;
  }
  return totalTime || 0;
};

/**
 *
 * @param {Record<QuestionId, DropdownItem[]>} inputs from `<ResponseForm onValidate={inputs => {}} />`
 */
const getSleepGreaterThan12Hours = (
  inputs: Record<QuestionId, DropdownItem[]>
): Record<QuestionId, Partial<WarningAndHighlight>> | null => {
  const tryToSleep = getSelected(inputs[TIME_TRY_TO_SLEEP])?.valueOf();
  const finalAwakening = getSelected(inputs[TIME_FINAL_AWAKENING])?.valueOf();
  const awakeningsTotalTime = getAwakeningsTotalTime(
    getSelected(inputs[AWAKENINGS_IN_NIGHT]),
    getSelected(inputs[AWAKENINGS_TOTAL_TIME])
  );

  const toFallAsleepTotalTime =
    getSelected(inputs[TO_FALL_ASLEEP_TOTAL_TIME]) || 0;

  if (
    typeof tryToSleep === 'number' &&
    typeof awakeningsTotalTime === 'number' &&
    typeof toFallAsleepTotalTime === 'number' &&
    typeof finalAwakening === 'number'
  ) {
    // Crosses over midnight 🕛
    const finalAwakeningFuture =
      finalAwakening < tryToSleep ? finalAwakening + DAY : finalAwakening;
    if (
      finalAwakeningFuture -
        tryToSleep -
        awakeningsTotalTime -
        toFallAsleepTotalTime >
      12 * HOUR
    ) {
      return {
        [TIME_FINAL_AWAKENING]: {
          highlight: true,
          warning:
            'That’s over 12 hours of sleep based on your other responses. Are you sure?',
        },
        [TIME_TRY_TO_SLEEP]: { highlight: true },
        [TO_FALL_ASLEEP_TOTAL_TIME]: { highlight: true },
        [AWAKENINGS_TOTAL_TIME]: { highlight: true },
      };
    }
  }
  return null;
};

export { getSleepGreaterThan12Hours };
