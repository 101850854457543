import React, { ReactElement, ReactNode, useContext } from 'react';
import { NavBarStyles } from 'components/Navigation/constants';
import { ScreenResponsiveLayoutContext } from 'components/ResponsiveLayout';
import { BarChartLabelCont } from './styled';
import { roles } from 'cross-platform/utils/roleProps';
import {
  NARROW_CALENDAR_CHART_MARGIN_LEFT,
  CALENDAR_CHART_MARGIN_LEFT,
} from 'components/SleepDiaryWeek/constants';

export const BarChartLabelContainer = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  const isMobileWeb =
    screenWidth <= NavBarStyles.navBarCollapseMobileWebBreakPointPx;

  return (
    <BarChartLabelCont
      {...roles('sleepDiaryChartLabelContainer')}
      style={{
        marginLeft: isMobileWeb
          ? NARROW_CALENDAR_CHART_MARGIN_LEFT
          : CALENDAR_CHART_MARGIN_LEFT,
      }}
    >
      {children}
    </BarChartLabelCont>
  );
};
