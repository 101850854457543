import { ButtonStates, InputStates, InputStatesStyles } from './types';
import { QuizAttribute } from 'components/constants';

export const getStylesForButtonStates = (key: keyof InputStatesStyles) => {
  return ({
    isSelected,
    isActive,
    isHovering,
    quiz,
    highlight,
  }: ButtonStates): string => {
    // Also used for "pressed" on native
    const highlightStyle = {
      backgroundColor: '#ffba44',
      color: '#282828',
      labelColor: '#555555',
      editColor: '#ffba44',
      border: '1px solid #ffba44',
    };
    const correctStyle = {
      backgroundColor: '#008769',
      color: 'white',
      labelColor: 'white',
      editColor: 'white',
      border: '1px solid #008769',
    };
    const incorrectStyle = {
      backgroundColor: '#d4321a',
      color: 'white',
      labelColor: 'white',
      editColor: 'white',
      border: '1px solid #d4321a',
    };
    const isHoveringStyle = {
      backgroundColor: '#00506b',
      color: 'white',
      labelColor: 'white',
      editColor: 'white',
      border: '1px solid #00506b',
    };
    // Also used for "focused"
    const isActiveStyle = {
      backgroundColor: '#00506b',
      color: 'white',
      labelColor: 'white',
      editColor: 'white',
      border: '1px solid #00506b',
    };
    // Has a value
    const isSelectedStyle = {
      backgroundColor: '#1a80a2',
      color: 'white',
      labelColor: 'white',
      editColor: 'white',
      border: '1px solid #1a80a2',
    };
    const defaultStyle = {
      backgroundColor: '#bfe2f0',
      color: '#282828',
      labelColor: '#555555',
      editColor: '#00506b',
      border: '1px solid #bfe2f0',
    };

    const dictionary: InputStatesStyles = {
      backgroundColor:
        //
        highlight
          ? highlightStyle.backgroundColor
          : isHovering
          ? isHoveringStyle.backgroundColor
          : quiz === QuizAttribute.CORRECT
          ? correctStyle.backgroundColor
          : quiz === QuizAttribute.INCORRECT
          ? incorrectStyle.backgroundColor
          : isActive
          ? isActiveStyle.backgroundColor
          : isSelected
          ? isSelectedStyle.backgroundColor
          : defaultStyle.backgroundColor,
      color:
        //

        highlight
          ? highlightStyle.color
          : isHovering
          ? isHoveringStyle.color
          : quiz === QuizAttribute.CORRECT
          ? correctStyle.color
          : quiz === QuizAttribute.INCORRECT
          ? incorrectStyle.color
          : isActive
          ? isActiveStyle.color
          : isSelected
          ? isSelectedStyle.color
          : defaultStyle.color,
      border:
        //

        highlight
          ? highlightStyle.border
          : isHovering
          ? isHoveringStyle.border
          : quiz === QuizAttribute.CORRECT
          ? correctStyle.border
          : quiz === QuizAttribute.INCORRECT
          ? incorrectStyle.border
          : isActive
          ? isActiveStyle.border
          : isSelected
          ? isSelectedStyle.border
          : defaultStyle.border,

      editColor:
        //

        highlight
          ? highlightStyle.editColor
          : isHovering
          ? isHoveringStyle.editColor
          : quiz === QuizAttribute.CORRECT
          ? correctStyle.editColor
          : quiz === QuizAttribute.INCORRECT
          ? incorrectStyle.editColor
          : isActive
          ? isActiveStyle.editColor
          : isSelected
          ? isSelectedStyle.editColor
          : defaultStyle.editColor,
      labelColor:
        //

        highlight
          ? highlightStyle.labelColor
          : isHovering
          ? isHoveringStyle.labelColor
          : quiz === QuizAttribute.CORRECT
          ? correctStyle.labelColor
          : quiz === QuizAttribute.INCORRECT
          ? incorrectStyle.labelColor
          : isActive
          ? isActiveStyle.labelColor
          : isSelected
          ? isSelectedStyle.labelColor
          : defaultStyle.labelColor,
    };
    return dictionary[key] || '';
  };
};

export const getStylesForInputStates = (key: keyof InputStatesStyles) => {
  return ({ isActive, isHovering, highlight }: InputStates): string => {
    const highlightStyle = {
      backgroundColor: 'white',
      color: '#757575',
      border: '1px solid #ffba44',
      fontStyle: 'italic',
    };

    const isHoveringStyle = {
      backgroundColor: '#f2f2f2',
      color: '#757575',
      border: '1px solid #f2f2f2',
      fontStyle: 'normal',
    };
    // Also used for "focused"
    const isActiveStyle = {
      backgroundColor: '#f2f2f2',
      color: '#282828',
      border: '1px solid #f2f2f2',
      fontStyle: 'normal',
    };

    const defaultStyle = {
      backgroundColor: 'white',
      color: '#757575',
      border: '1px solid #d6d6d6',
      fontStyle: 'italic',
    };

    const dictionary: InputStatesStyles = {
      backgroundColor:
        //

        highlight
          ? highlightStyle.backgroundColor
          : isHovering
          ? isHoveringStyle.backgroundColor
          : isActive
          ? isActiveStyle.backgroundColor
          : defaultStyle.backgroundColor,
      color:
        //
        highlight
          ? highlightStyle.color
          : isHovering
          ? isHoveringStyle.color
          : isActive
          ? isActiveStyle.color
          : defaultStyle.color,
      fontStyle:
        //

        highlight
          ? highlightStyle.fontStyle
          : isHovering
          ? isHoveringStyle.fontStyle
          : isActive
          ? isActiveStyle.fontStyle
          : defaultStyle.fontStyle,
      border:
        //
        highlight
          ? highlightStyle.border
          : isHovering
          ? isHoveringStyle.border
          : isActive
          ? isActiveStyle.border
          : defaultStyle.border,
    };
    return dictionary[key] || '';
  };
};
