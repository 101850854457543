import React, { ReactElement } from 'react';
import { View } from 'react-native';
import { StatusIndicator } from 'components/icons';
import { useQueryAllForSessionScreen } from 'lib/api/reactQueryHelpers';

const SessionAvailabilityIndicator = ({
  iconSize,
  testID,
  ...rest
}: {
  iconSize: number;
  testID?: string;
}): ReactElement => {
  const { data } = useQueryAllForSessionScreen();
  const hasNewSession = data?.result.has_new_session_available || false;
  const hasInProgressSession =
    data?.result.has_in_progress_session_available || false;
  const isAvailable = hasInProgressSession || hasNewSession;
  const color = isAvailable ? '#57D6A5' : 'transparent';

  return (
    <View testID={testID} {...rest}>
      <StatusIndicator size={iconSize} style={{ color }} />
    </View>
  );
};

export default SessionAvailabilityIndicator;
