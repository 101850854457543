import React, { ReactElement, ReactNode, useContext } from 'react';
import { NavBarStyles } from 'components/Navigation/constants';
import { ScreenResponsiveLayoutContext } from 'components/ResponsiveLayout';
import { BarChartColumnCont } from './styled';
import { roles } from 'cross-platform/utils/roleProps';
import {
  CALENDAR_CHART_MARGIN_LEFT,
  NARROW_CALENDAR_CHART_MARGIN_LEFT,
} from 'components/SleepDiaryWeek/constants';

export const BarChartColumnContainer = ({
  paddingTop,
  children,
}: {
  paddingTop: number;
  children: ReactNode;
}): ReactElement => {
  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  const isMobileWeb =
    screenWidth <= NavBarStyles.navBarCollapseMobileWebBreakPointPx;

  return (
    <BarChartColumnCont
      {...roles('sleepDiaryChartColumnContainer')}
      style={{
        paddingTop: paddingTop,
        marginLeft: isMobileWeb
          ? NARROW_CALENDAR_CHART_MARGIN_LEFT
          : CALENDAR_CHART_MARGIN_LEFT,
      }}
    >
      {children}
    </BarChartColumnCont>
  );
};
