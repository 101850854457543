import {
  ButtonDimensions,
  ButtonProps,
  ButtonSizes,
  NumericalCSSStyles,
} from './types';
import { useMemo } from 'react';
import { assertUnreachable } from 'lib/type-guarded';
import { StyleSheet } from 'react-native';
import { StyleObject } from '@bighealth/types/dist/scene-components/client';
import { useTransformStylesToContext } from 'components/ResponsiveLayout/hooks/useTransformStylesToContext';

type CombinedStyles = NumericalCSSStyles & ButtonDimensions;

export const useDynamicButtonDimensions = ({
  size = ButtonSizes.LegacySecondary,
  style = {},
}: ButtonProps): CombinedStyles => {
  const transformStyles = useTransformStylesToContext();
  let unscaledDimensions: ButtonDimensions;
  const flattedStyle = StyleSheet.flatten(style);

  switch (size) {
    case ButtonSizes.LegacySecondary:
    case ButtonSizes.Small:
      unscaledDimensions = {
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 32,
        paddingRight: 32,
        minWidth: 220,
        borderRadius: 32,
        fontSize: 20,
        lineHeight: 24,
        minHeight: 24 + 16 + 16,
        borderWidth: 2,
        // Spreading after to allow Content Creators to customize
        ...flattedStyle,
      };
      break;
    case ButtonSizes.Mini:
      unscaledDimensions = {
        paddingTop: 6,
        paddingLeft: 16,
        paddingBottom: 6,
        paddingRight: 16,
        minHeight: 24 + 16 + 16,
        minWidth: 104,
        borderRadius: 32,
        fontSize: 20,
        lineHeight: 24,
        borderWidth: 2,
        // Spreading after to allow Content Creators to customize
        ...flattedStyle,
      };
      break;
    case ButtonSizes.SmallText:
      unscaledDimensions = {
        paddingTop: 0,
        paddingLeft: 0,
        paddingBottom: 0,
        paddingRight: 0,
        minHeight: 24,
        minWidth: 104,
        borderRadius: 0,
        fontSize: 20,
        lineHeight: 24,
        borderWidth: 0,
        // Spreading after to allow Content Creators to customize
        ...flattedStyle,
      };
      break;
    default:
      assertUnreachable(size);
  }

  // @TODO: Remove this `as`
  // @WHEN: no rush as it's internal but the reason we need it is due to mixing up
  // ViewStyle, StyleProp<ViewProps> and our own definitions in various places.
  const scaledDimensions = transformStyles(
    unscaledDimensions as StyleObject
  ) as ButtonDimensions; // Button is never used in Absolute mode so we'll just assert ButtonDimensions here

  return useMemo(() => scaledDimensions as CombinedStyles, [scaledDimensions]);
};
