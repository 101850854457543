/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { OnAuthCallback } from 'components/AuthButtons';

type ErrorResponse = {
  error: string;
};

export function isErrorResponse(response: any): response is ErrorResponse {
  return !!response.error;
}

export function isOfflineResponse(
  response: any
): response is GoogleLoginResponseOffline {
  return !!response.code;
}

export const handleGoogleAuthResponse = (onAuth: OnAuthCallback) => (
  response:
    | GoogleLoginResponse
    | GoogleLoginResponseOffline
    | {
        error: string;
      }
): void => {
  // It can also error here on first load if there's an issue. E.g. client id
  if (isErrorResponse(response)) {
    onAuth(Error(response.error));
    return;
  }
  if (isOfflineResponse(response)) {
    onAuth(Error(response.code));
    return;
  }
  onAuth({
    token: response.accessToken,
  });
};
