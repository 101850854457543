import * as React from 'react';
import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { Keyboard, KeyboardEventListener } from 'react-native';

type KeyboardInfo = {
  height: number;
  isShowing: boolean;
};

const initialValue: KeyboardInfo = {
  height: 0,
  isShowing: false,
};

export const KeyboardDimensionsContext = React.createContext(initialValue);

/**
 *
 * WARNING: KeyboardDimensionsProvider is not a universally accurate solution. The current implementation will
 * work only on iOS. Android requires more finessed handling of status bars etc as per Daylight. Additionally
 * there is nothing in the way of animations so whatever view consumes this will jump.
 *
 * @TODO: return an Animated.Value for animations
 * @WHEN: when we start to finesse the user interactions a bit
 *
 * @param children
 * @constructor
 */
export const KeyboardDimensionsProviders = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [isShowing, setIsShowing] = useState(false);
  const [height, setHeight] = useState(0);

  const handleShow: KeyboardEventListener = event => {
    setHeight(event.endCoordinates.height);
    setIsShowing(true);
  };

  const handleHide: KeyboardEventListener = () => {
    setIsShowing(false);
    setHeight(0);
  };

  useEffect(() => {
    Keyboard.addListener('keyboardDidShow', handleShow);
    Keyboard.addListener('keyboardDidHide', handleHide);

    return () => {
      Keyboard.removeListener('keyboardDidShow', handleShow);
      Keyboard.removeListener('keyboardDidHide', handleHide);
    };
  }, []);

  const value = useMemo(
    () => ({
      height,
      isShowing,
    }),
    [height, isShowing]
  );

  return (
    <KeyboardDimensionsContext.Provider value={value}>
      {children}
    </KeyboardDimensionsContext.Provider>
  );
};
