import * as React from 'react';
import { ReactElement } from 'react';
import { StyleSheet } from 'react-native';
import styled from 'styled-components';
import { ButtonProps } from './types';
import { printableProps } from 'components/layout/lib/printableProps';
import { useButtonInteractionStates } from './useButtonInteractionStates';
import { useDynamicButtonDimensions } from './useDynamicButtonDimensions';
import { Text } from 'components/Text';
import { roles } from 'cross-platform/utils/roleProps';
import { getTestIdValue } from 'cross-platform/utils/roleProps/getTestId';

const ButtonStatic = styled.button`
  align-items: center;
  appearance: none;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
`;

export const UniversalButton = (props: ButtonProps): ReactElement => {
  const {
    isDisabled = false,
    text,
    onPress,
    colorScheme,
    style,
    ...rest
  } = props;
  const interactionStates = useButtonInteractionStates({
    ...props,
    colorScheme,
  });
  const dimensions = useDynamicButtonDimensions(props);

  const {
    style: { backgroundColor, color, borderColor },
    webEvents: {
      onFocus,
      onBlur,
      onMouseEnter,
      onMouseLeave,
      onMouseUp,
      onMouseDown,
    },
  } = interactionStates;

  const { fontSize, lineHeight, ...viewDimensions } = dimensions;
  const flatStyles = StyleSheet.flatten(style || {});

  return (
    <ButtonStatic
      {...rest}
      {...roles(
        getTestIdValue(rest) || `Button_${printableProps({ text, isDisabled })}`
      )}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={isDisabled}
      onClick={onPress}
      style={{
        ...(flatStyles as Record<string, unknown>), // Work around TypeScript bug https://github.com/Microsoft/TypeScript/issues/10727
        ...StyleSheet.flatten(viewDimensions),
        backgroundColor,
        borderColor,
        borderStyle: 'solid',
      }}
    >
      <Text style={{ color, fontSize, lineHeight }}>{text}</Text>
    </ButtonStatic>
  );
};
