import React, { ReactElement, useState } from 'react';
import { addStory } from 'lib/story-books';
import { useFakeStorybookSceneSet } from 'lib/story-books/useFakeStorybookSceneSet';
import { Presentation } from '../components/Presentation';
import { mockPresentationPropsCalendarWeek } from './helpers/mockPresentationPropsCalendarWeek';

const pageForwardPress = () => {
  console.log('SleepDiaryWeekly: PageForward pressed');
};

const pageBackwardPress = () => {
  console.log('SleepDiaryWeekly: PageBackward pressed.');
};

const setDiaryDateForModal = (diary_date: string | undefined) => {
  console.log(`SleepDiaryWeekly: Set diary date for modal ${diary_date}`);
};

const PresentationStory = (): ReactElement | null => {
  const sceneSet = useFakeStorybookSceneSet();
  const [, setLastSubmit] = useState<number | undefined>(undefined);
  const [, setIsLoading] = useState<boolean>(false);
  if (!sceneSet) {
    return null;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Presentation
      {...mockPresentationPropsCalendarWeek}
      pageForwardPress={pageForwardPress}
      pageBackwardPress={pageBackwardPress}
      onPressCalendarTile={setDiaryDateForModal}
      onCloseDiaryModal={setDiaryDateForModal}
      diaryDateForModal={undefined}
      shouldShowContinueButton={true}
      isContinueButtonEnabled={true}
      minCountOfCompleteDiariesForContinueButtonToExtrapolate={4}
      maxCountOfCompleteDiariesForContinueButtonToExtrapolate={7}
      continueButtonText={'Continue'}
      setIsLoading={setIsLoading}
      isReadOnly={true}
      includeMetadata={true}
      period={'calendar_week'}
      onDiarySubmit={setLastSubmit}
    />
  );
};

addStory('SleepDiaryWeek/Presentation', () => <PresentationStory />);
