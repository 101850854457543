import { DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID } from 'developer/constants';
import { useSafeParams, PathParams } from './useSafeParams';
import { logger } from 'lib/logger';
import { SLEEP_DIARY_ROUTE_PARAMETER } from 'components/SleepDiaryWeek/constants';

type TryReturnHardCodedParamsType = ReturnType<typeof useSafeParams>;

const visitsCache: string[] = [];
const isTestingEnvironment = process.env.NODE_ENV === 'test';

/**
 * This function checks if the route we are currently on requires some hard
 * coded params to be returned, rather than the dynamic one in the URL.
 *
 * It is not especially easy to see the broader context from just here, but the
 * use cases for this include:
 *  - When we present a question outside of a player context. For example in a
 *    storybook, or in the Sleep Diary out of player exeprience.
 *    In these cases, (Correct as of 2020-12-17), our internal state keys for
 *    these questions are built using params that are not always present in the
 *    route. (For example SceneSetId). We return hard-coded values to facilitate
 *    the creation of those keys.
 *
 * @param params the params pulled out by useSafeParams. Used to check against
 *  a small set of routes that require hard-coded params.
 */
const tryReturnHardCodedParams = (
  params?: PathParams
): TryReturnHardCodedParamsType | undefined => {
  if (!params) {
    // If params are null, return early.
    return;
  }
  const isStorybookSceneSetGraph =
    params.sceneSetGraphId === String(DEVELOPER_COMPONENTS_SCENESET_GRAPH_ID);
  const isStorybookSceneSet = params.sceneSetId === 'storybook';
  const key = JSON.stringify(params);
  if (isStorybookSceneSetGraph && isStorybookSceneSet) {
    if (!visitsCache.includes(key) && !isTestingEnvironment) {
      visitsCache.push(key);
      logger(
        `Path params: ${key} were found.
        tryReturnHardCodedParams determined that you are trying to view a Storybook, so
        hardcoded params have been returned instead.`,
        undefined,
        { silent: true }
      );
    }
    return {
      productReference: params.productReference,
      sceneSetGraphId: -1,
      sceneSetId: -1,
      sceneId: -1,
    };
  }

  const isNonPlayerSleepDiaryRoute =
    params?.sceneSetGraphId === SLEEP_DIARY_ROUTE_PARAMETER;

  if (isNonPlayerSleepDiaryRoute) {
    if (!visitsCache.includes(key) && !isTestingEnvironment) {
      visitsCache.push(key);
      logger(
        `Path params: ${key} were found.
        tryReturnHardCodedParams determined that you are trying to view the out of
        player SleepDiary. So hardcoded params have been returned instead.`,
        undefined,
        { silent: true }
      );
    }
    return {
      productReference: params.productReference,
      sceneSetGraphId: -1,
      sceneSetId: -1,
      sceneId: -1,
    };
  }
  return;
};

export { tryReturnHardCodedParams };
