import { useQueryProduct, useQueryProgram } from 'lib/api/reactQueryHelpers';
import { AnalyticsField, useAnalytics } from '@bighealth/analytics';
import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { UseQueryResult } from 'react-query/types/react/types';
import { APIErrorResponse } from '@bighealth/api';
import { sendNonSessionAnalytics } from 'lib/api/analytics/sendNonSessionAnalytics';
import { fire_ui_events } from '@bighealth/api/Event/v1/fire_ui_events';
import { useGetURL } from 'lib/api/analytics/useGetURL';
import { useHistory } from 'cross-platform/react-router';

export const useQuerySendNonSessionAnalytics = (
  field: AnalyticsField
): UseQueryResult<void, APIErrorResponse> => {
  const programId = useQueryProgram().data?.result.id;
  const productId = useQueryProduct().data?.result.id;
  const analytics = useAnalytics();
  const getUrl = useGetURL();
  const pathname = useHistory().location.pathname;
  const memodField = useMemo<AnalyticsField>(
    () => ({
      area: field.area,
      section: field.section,
    }),
    [field.area, field.section]
  );
  const result = useQuery({
    queryKey: [fire_ui_events.queryKey, pathname],
    queryFn: async () => {
      const url = await getUrl();
      return sendNonSessionAnalytics({
        analytics,
        field: memodField,
        productId: productId as number,
        programId: programId as number,
        url,
      });
    },
    enabled: typeof productId === 'number' && typeof programId === 'number',
    cacheTime: 0,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnReconnect: false,
    staleTime: 0,
  });
  return result;
};
