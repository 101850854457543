import * as React from 'react';
import { BackgroundImage } from './styled';
import { useQueryAllForSessionScreen } from 'lib/api/reactQueryHelpers/useQueryAnalogs/useQueryAllForSessionScreen';

/**
 * Grabs background image from request, which is stored in redux
 */
export const ImageFromResponse = (
  props: React.ComponentProps<typeof BackgroundImage>
): React.ReactElement | null => {
  const storageUrl = useQueryAllForSessionScreen()?.data?.result
    ?.background_image_asset?.storage_url;
  return storageUrl ? (
    <BackgroundImage {...props} src={storageUrl} data-asset={storageUrl} />
  ) : null;
};
