import * as React from 'react';
import { ReactElement, ReactNode, useState } from 'react';
import styled from 'styled-components/native';
import {
  PageSubTitle,
  PageTitle,
  ScreenScroll,
  ContentContainer,
} from '../components';
import { useGetPageDimensions } from '../hooks';
import { SleepEfficiencyScreenStyles } from './styles';
import { LineChart } from './LineChart';
import { roles } from 'cross-platform/utils/roleProps';
import { Chevron } from 'components/icons';
import {
  convertXToUnits,
  convertYToUnits,
  getPaddedChartDataFromAPIData,
} from 'components/Screens/ContentScreens/ProgressScreen/utils/dataUtils';
import { Goals } from 'components/Goals';
import {
  ScalingContext,
  ScalingContextProvider,
  useGetDynamicContentStyles,
  useTransformContentStyle,
} from 'components/ResponsiveLayout';
import { View } from 'react-native';
import { Footer } from 'components/Footer';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';
import { useQuerySleepEfficiency } from 'lib/api/reactQueryHelpers';
import { PLATGEN_FF_USE_MOCK_PROGRESS_DATA } from 'config/envVars';
import { LoadingBlankScreen } from 'components/LoadingBlankScreen';
import { sleepEfficiencyScreenMockData } from './__mockData__/sleepEfficiencyScreenMockData';

const ChartContainer = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const transformStyle = useTransformContentStyle();
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'stretch',
        marginTop: transformStyle(40),
        marginBottom: transformStyle(90),
      }}
    >
      {children}
    </View>
  );
};

const Button = styled.TouchableOpacity`
  background-color: #1a80a222;
`;

const ButtonView = styled.View`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const REFERENCE_WIDTH = SleepEfficiencyScreenStyles.referenceWidth;

const DynamicButton = ({
  testID,
  marginDirection,
  disabled,
  onPress,
  icon,
}: {
  disabled?: boolean;
  testID: string;
  onPress: () => void;
  marginDirection: 'right' | 'left';
  icon: 'right' | 'left';
}) => {
  const { pageContentWidth } = useGetPageDimensions();
  const buttonWidth =
    (SleepEfficiencyScreenStyles.buttonWidth * pageContentWidth) /
    REFERENCE_WIDTH;
  const buttonMargin =
    (SleepEfficiencyScreenStyles.buttonOffset * pageContentWidth) /
    REFERENCE_WIDTH;
  const iconSize =
    (SleepEfficiencyScreenStyles.buttonIconSize * pageContentWidth) /
    REFERENCE_WIDTH;

  const style = {
    width: buttonWidth,
    opacity: disabled ? 0 : 1,
    marginRight: marginDirection === 'right' ? buttonMargin : 0,
    marginLeft: marginDirection === 'left' ? buttonMargin : 0,
  };
  return (
    <Button
      {...roles(testID)}
      style={style}
      disabled={disabled}
      onPress={onPress}
    >
      <ButtonView>
        <Chevron
          size={iconSize}
          direction={icon}
          style={{ color: '#003366' }}
        />
      </ButtonView>
    </Button>
  );
};

const LineContainer = styled.View`
  flex: 1;
`;

export const ProgressScreen = (): ReactElement => {
  useUpdateTitle('Progress');
  const contentStyles = useGetDynamicContentStyles();
  const { pageContentWidth } = useGetPageDimensions();
  const [page, setPage] = useState(0);
  const { isLoading, data } = useQuerySleepEfficiency({
    items_per_page: 6,
    page: page,
  });

  const canGoForwardInTime = data?.result.has_next;
  const canGoBackInTime = data?.result.has_prev;
  const itemsPerPage = data?.result.items_per_page;

  let progressData = data?.result;

  if (isLoading) {
    // the global throbber will be rendered
    return <LoadingBlankScreen />;
  }

  if (PLATGEN_FF_USE_MOCK_PROGRESS_DATA) {
    progressData = sleepEfficiencyScreenMockData[page];
  }

  const chartData = getPaddedChartDataFromAPIData(progressData);

  return (
    <ScalingContextProvider scalingContext={ScalingContext.ContentScreen}>
      <ScreenScroll
        contentContainerStyle={{
          alignItems: 'center',
        }}
      >
        <ContentContainer
          style={{
            flex: 1,
            width: pageContentWidth,
            paddingBottom: contentStyles.pagePaddingBottom,
          }}
        >
          <PageTitle>Progress</PageTitle>
          <PageSubTitle>Your Average Sleep Efficiency</PageSubTitle>
          <ChartContainer>
            <DynamicButton
              testID="PrevButton"
              onPress={() => setPage(page + 1)}
              disabled={!data?.result || !canGoBackInTime}
              marginDirection={'right'}
              icon={'left'}
            />
            <LineContainer>
              <LineChart
                maxY={100}
                data={chartData}
                convertXToUnits={convertXToUnits}
                convertYToUnits={convertYToUnits}
                lineColor={'#ff6c00'}
                lineStrokeWidth={2}
                ruleColor={'#1a80a2'}
                ruleStrokeWidth={1}
                xTicksCount={itemsPerPage || 6}
                yTicksCount={5}
                markerRadius={6}
                horizontalPadding={100}
                baseWidth={REFERENCE_WIDTH}
                baseHeight={300}
              />
            </LineContainer>
            <DynamicButton
              testID="NextButton"
              onPress={() => setPage(page - 1)}
              disabled={page === 0 || !canGoForwardInTime}
              marginDirection={'left'}
              icon={'right'}
            />
          </ChartContainer>
          <PageSubTitle>Your Goal Progress Since Starting</PageSubTitle>
          <Goals />
        </ContentContainer>
        <Footer />
      </ScreenScroll>
    </ScalingContextProvider>
  );
};
