import styled from 'styled-components/native';
import { Text } from 'components/Text';
import Image from 'cross-platform/Image';
import Link from 'components/Link';
import PressButton from 'components/Button/PressButton';

export const LimitWidth = styled.View`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  max-width: 380px;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const FormattedText = styled(Text)`
  font-size: 19px;
  color: white;
  text-align: center;
`;

export const Logo = styled(Image)`
  width: 170px;
  height: 100px;
`;

export const LogoLink = styled(Link)`
  align-self: center;
`;

export const ButtonContainer = styled.View`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  align-items: stretch;
`;

// HOW search for "don't style touchable" and move to Button theme
export const ButtonWithMargin = styled(PressButton)`
  margin-bottom: 20px;
`;

export const PaddingContainer = styled.View`
  flex-direction: column;
  margin-bottom: 20px;
  display: flex;
  align-items: stretch;
`;
