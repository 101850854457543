import { createWithTimes } from 'lib/api';
import logger from 'lib/logger';
import { create_with_times } from '@bighealth/api/SleepWindow/v1';

async function submitSleepWindow({
  fromTime,
  toTime,
  sceneSetGraphId,
}: {
  fromTime: Date;
  toTime: Date;
  sceneSetGraphId: number;
}): Promise<[create_with_times.Response | null, Error | null]> {
  try {
    const response = await createWithTimes({
      fromTime,
      toTime,
      sceneSetGraphId,
    });
    if (response.status_code !== 200) {
      logger('Error in the creation of the sleep window');
      return [null, new Error('Error in the creation of the sleep window')];
    }
    return [response, null];
  } catch (e) {
    logger(e);
    return [null, e];
  }
}

export { submitSleepWindow };
