import { QueryObserverResult, useQuery } from 'react-query';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { APIErrorResponse } from '@bighealth/api';
import { jump_to_specific_scene_set } from '@bighealth/api/SceneSetGraph/v1';
import { useQueryProduct } from './useQueryProduct';
import { useQueryProgram } from './useQueryProgram';
import { getWhichDeveloperSceneSet } from 'developer/helpers';

type Options = {
  enabled?: boolean;
};

export type JumpResponseType = QueryObserverResult<
  jump_to_specific_scene_set.Response,
  APIErrorResponse
>;

type Caller = typeof jump_to_specific_scene_set;

export const useQueryJumpToSceneSet = (
  destinationId: number | undefined,
  options?: Options
): JumpResponseType => {
  const { sceneSetGraphId, sceneSetId } = useSafeParams();
  const productId = useQueryProduct().data?.result.id;
  const programId = useQueryProgram().data?.result.id;
  const developerSceneSet = getWhichDeveloperSceneSet(
    sceneSetGraphId,
    destinationId
  );

  const result = useQuery({
    queryKey: ['SceneSet', destinationId || 'noId'],
    queryFn: () => {
      if (developerSceneSet) {
        const caller = () => ({
          status_code: 200,
          result: {
            id: destinationId as number,
            reference: `${destinationId}`,
            scene_set_json: developerSceneSet,
          },
        });
        return (caller as unknown) as ReturnType<Caller>;
      }
      return jump_to_specific_scene_set({
        current_graph_id: sceneSetGraphId,
        current_product_id: productId as number,
        current_program_id: programId as number,
        current_scene_set_id: sceneSetId,
        destination_scene_set_id: destinationId,
      });
    },
    enabled:
      options?.enabled !== false &&
      typeof productId === 'number' &&
      typeof programId === 'number',
    cacheTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: false,
  });
  return result;
};
