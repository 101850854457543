import React, { ReactElement, useState } from 'react';
import styled from 'styled-components/native';
import { useParams } from 'cross-platform/react-router';
import { FillAbsolute } from 'components/Containers';
import { DEBUG_SHOW_DEV_CONTENT_SELECTOR } from 'config/envVars';

import {
  ButtonColorSchemes,
  ButtonSizes,
  UniversalLinkButton,
} from 'components/UniveralButtons';
import TextInput from 'components/TextInput';

const DebugInstructions = styled.Text`
  color: white;
`;
DebugInstructions.displayName = 'DebugInstructions';

const Container = styled(FillAbsolute)`
  align-items: center;
`;

const LimitWidth = styled.View`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  max-width: 380px;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
`;

const ButtonContainer = styled.View`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  align-items: stretch;
  margin-bottom: 30px;
`;

const ForQATeamSSGbySS = (): ReactElement => {
  const { productReference } = useParams<{ productReference: string }>();
  const [urlSegment, setUrlSegment] = useState<string>('');
  const goToUrl = `/${productReference}/${urlSegment}`.toLowerCase();
  return (
    <Container>
      <LimitWidth>
        {DEBUG_SHOW_DEV_CONTENT_SELECTOR ? (
          <ButtonContainer>
            <DebugInstructions>{`String below creates url:`}</DebugInstructions>
            <DebugInstructions>{`"${goToUrl}"`}</DebugInstructions>
            <TextInput
              value={urlSegment}
              onChangeText={(val: string) => {
                setUrlSegment(val);
              }}
            />
            <UniversalLinkButton
              to={goToUrl}
              title={'Go to url'}
              text={'Go to url'}
              colorScheme={ButtonColorSchemes.Login}
              size={ButtonSizes.Small}
            />
            <DebugInstructions>{`WARNING: Does not seem to work with multiple "/"`}</DebugInstructions>
          </ButtonContainer>
        ) : null}
        <ButtonContainer>
          <UniversalLinkButton
            text={'Back home'}
            to={`/${productReference}`}
            title={'Back Home'}
            colorScheme={ButtonColorSchemes.Login}
            size={ButtonSizes.Small}
          />
        </ButtonContainer>
      </LimitWidth>
    </Container>
  );
};

export { ForQATeamSSGbySS };
