import { HOUR } from 'lib/durations';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { QuestionId } from 'state/question-response/actions';
import { getSelected } from '../../helpers/getSelected';
import { Config } from '..';
import {
  AWAKENINGS_TOTAL_TIME,
  TIME_FINAL_AWAKENING,
  TIME_TRY_TO_SLEEP,
  TO_FALL_ASLEEP_TOTAL_TIME,
} from 'components/SleepDiaryForm/constants';
import { createQuestionProps } from './helpers/createQuestionProps';
import { getSelectedAsNumber } from '../../helpers/getSelectedAsNumber';

/**
 *
 * @param {Record<QuestionId, DropdownItem[]>} inputs from `<ResponseForm onValidate={inputs => {}} />`
 */
const getFinalAwakeningFromTryToSleep = (
  inputs: Record<QuestionId, DropdownItem[]>
): Record<QuestionId, Config> | null => {
  const timeTryToSleep = getSelected(inputs[TIME_TRY_TO_SLEEP])?.valueOf();
  if (typeof timeTryToSleep !== 'number') {
    return null;
  }

  const selected: Record<QuestionId, number> = {
    [TIME_TRY_TO_SLEEP]: timeTryToSleep,
    [TO_FALL_ASLEEP_TOTAL_TIME]: getSelectedAsNumber(
      inputs,
      TO_FALL_ASLEEP_TOTAL_TIME
    ),
    [AWAKENINGS_TOTAL_TIME]: getSelectedAsNumber(inputs, AWAKENINGS_TOTAL_TIME),
  };

  const min = selected[TIME_TRY_TO_SLEEP];
  const max = min + 24 * HOUR;
  return {
    [TIME_FINAL_AWAKENING]: createQuestionProps(min, max),
  };
};

export { getFinalAwakeningFromTryToSleep };
