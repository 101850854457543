import React, { ReactElement, useState, useEffect } from 'react';
import { SleepDiaryFormProps } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';
import { ErrorText } from 'components/Text/ErrorText';
import { roles } from 'cross-platform/utils/roleProps';
import { DateLabel, Heading } from '../../styled';
import { FlowingForm } from '../FlowingForm';
import { fetchRenderDataAsync } from './helpers/fetchRenderDataAsync';
import { Wrapper, ErrorWrapper, LoadingWrapper, ErrorButton } from './styled';
import { OnValidateCallback } from 'components/forms';
import { APIRequestBody } from '@bighealth/api';
import { SleepDiaryPayloads } from '@bighealth/types/src/services/SleepDiaryPayloads';
// TODO: move the hook outside of the SessionScreen to make it avaialable in the whole codebase
import { apiAndThrow } from 'lib/api/helpers/apiAndThrow';
import { useQueryProgram } from 'lib/api/reactQueryHelpers';
import { useSubmitHandler } from './helpers/useSubmitHandler';
import {
  SubmitState,
  SubmitCallbackProvider,
} from './providers/SubmitCallbackProvider';

type FetchData = Parameters<typeof fetchRenderDataAsync>[0]['fetchData'];

export type NetworkedFlowingFormProps = {
  renderRequest: APIRequestBody;
  initialPayload?: SleepDiaryFormProps; // As of Sep5 2020, only for storybook
  onValidate?: OnValidateCallback;
  onClose?: () => void;
  onSuccessfulSubmit?: (stamp: number) => void;
};
/**
 * NetworkedFlowingForm
 * onLoad: call API to get form data
 * onSubmit: call API to save responses
 *
 * @param renderRequest
 * @param props.initialPayload for testing
 */
const NetworkedFlowingForm = (
  props: NetworkedFlowingFormProps
): ReactElement | null => {
  const [payload, setPayload] = useState<SleepDiaryFormProps | undefined>(
    props?.initialPayload
  );
  const [isLoading, setIsLoading] = useState<boolean>(
    !!props?.initialPayload || true
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [submitState, setSubmitState] = useState<SubmitState>({});

  const programId = useQueryProgram()?.data?.result.id;

  const renderRequest = props.renderRequest as SleepDiaryPayloads['get_diary_entry_form']['request'];
  const fetchRenderData = (React.useCallback(() => apiAndThrow(renderRequest), [
    renderRequest,
  ]) as unknown) as FetchData;

  const fetchRenderDataCallback = React.useCallback(() => {
    fetchRenderDataAsync({
      fetchData: fetchRenderData,
      onLoadingChange: setIsLoading,
      onSuccess: setPayload,
      onError: setError,
    });
  }, [fetchRenderData]);

  useEffect(() => {
    if (typeof programId !== 'undefined') {
      fetchRenderDataCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  const { onSuccessfulSubmit } = props;

  const submitHandler = useSubmitHandler({
    renderRequest,
    onSuccessfulSubmit,
    programId,
    setError,
    setIsSubmitting,
    submitState,
  });

  return (
    <Wrapper>
      {isLoading ? (
        <LoadingWrapper {...roles('NetworkedFlowingForm.Loading')}>
          <DateLabel />
          <Heading text="Sleep Diary is loading" />
        </LoadingWrapper>
      ) : error ? (
        <ErrorWrapper {...roles('NetworkedFlowingForm.Error')}>
          <DateLabel />
          <Heading text="Sleep Diary could not load" />
          <ErrorText>{`${error}`}</ErrorText>
          <ErrorButton
            {...roles('NetworkedFlowingForm.TryAgain')}
            text="Try again"
            onPress={fetchRenderDataCallback}
          />
        </ErrorWrapper>
      ) : payload ? (
        <SubmitCallbackProvider
          value={{ setSubmitState, submitState, isSubmitting }}
        >
          <FlowingForm
            {...payload}
            {...roles('NetworkedFlowingForm.Rendered')}
            onSubmit={submitHandler}
            onClose={props.onClose}
            onValidate={props?.onValidate}
          />
        </SubmitCallbackProvider>
      ) : null // Note: Should never get here
      }
    </Wrapper>
  );
};

export { NetworkedFlowingForm };
