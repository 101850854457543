import { SceneChild } from '@bighealth/types';
import { clone as deepClone } from 'ramda';

export interface SceneComponentWithParent extends SceneChild {
  childNodes?: SceneComponentWithParent[];
  component: string;
  __index__?: number;
  __scenePath__?: string;
  __parent__?: SceneComponentWithParent;
  __component__?: string;
}

export function addParentNodes(
  input: SceneComponentWithParent,
  parent?: SceneComponentWithParent
): SceneComponentWithParent {
  // Deep clone because we are mutating the SceneSet otherwise which causes all sorts of potential
  // problems with the redux store, redux-persist etc
  const clone = deepClone(input);
  const { childNodes } = clone;
  if (childNodes) {
    const rootSceneKey = parent?.__scenePath__ || '0';
    const inputSceneKey = clone.__scenePath__ || rootSceneKey;
    const childNodesWithParent = clone.childNodes
      ?.map((childNode, index: number) => ({
        ...childNode,
        __index__: index,
        __parent__: parent,
        __scenePath__: `${inputSceneKey}.${index}`,
        __component__: childNode.component,
      }))
      .map(childNodeWithParent => addParentNodes(childNodeWithParent, clone));
    return Object.assign(clone, {
      ...clone,
      childNodes: childNodesWithParent,
      __parent__: parent,
      __scenePath__: inputSceneKey,
      __component__: clone.component,
    });
  }
  return Object.assign(clone, { __parent__: parent });
}
