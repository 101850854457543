import { useDispatch } from 'react-redux';
import { Animated, Platform } from 'react-native';
import { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { showControls as setControlsAreVisible } from 'state/player/actions';
import { PlaybackState } from 'state/player/reducer';

export const FADE_OUT_DELAY = 3000;
export const DEFAULT_DURATION = 500;
export const USE_NATIVE_DRIVER = Platform.OS !== 'web';

let animationTimeout: ReturnType<typeof setTimeout>;

export type UseFadingAnimationProps = {
  playerState: PlaybackState;
  controlsAreVisible: boolean;
};

export type UseFadingAnimationReturn = {
  fadeAnim: Animated.Value;
  hidden: boolean;
};

export const getRequiresFadeOut = (
  controlsAreVisible: boolean,
  playerState: PlaybackState
): boolean =>
  (playerState === PlaybackState.PLAYING ||
    playerState === PlaybackState.FINISHED ||
    playerState === PlaybackState.UNINITIALIZED) &&
  controlsAreVisible;

export const useFadingAnimation = ({
  playerState,
  controlsAreVisible,
}: UseFadingAnimationProps): UseFadingAnimationReturn => {
  const dispatch = useDispatch();
  const [hidden, setHidden] = useState(false);
  const fadeAnim = useRef(new Animated.Value(1)).current;

  const playerStateRef = useRef(playerState);
  const controlsAreVisibleRef = useRef(controlsAreVisible);
  playerStateRef.current = playerState;
  controlsAreVisibleRef.current = controlsAreVisible;

  const delayedFadeOutAnimation = useCallback(() => {
    // Using setTimeout instead of Animated.delay
    // to make sure the state is correct after delay finishes
    clearTimeout(animationTimeout);
    animationTimeout = setTimeout(() => {
      if (
        !getRequiresFadeOut(
          controlsAreVisibleRef.current,
          playerStateRef.current
        )
      ) {
        return;
      }
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: DEFAULT_DURATION,
        useNativeDriver: USE_NATIVE_DRIVER,
      }).start(() => {
        setHidden(true);
        dispatch(setControlsAreVisible(false));
      });
    }, FADE_OUT_DELAY);
  }, [dispatch, fadeAnim]);

  const fadeOutAnimation = useCallback(() => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: DEFAULT_DURATION,
      useNativeDriver: USE_NATIVE_DRIVER,
    }).start(({ finished }) => {
      if (finished && !controlsAreVisible) {
        setHidden(true);
      }
    });
  }, [controlsAreVisible, fadeAnim]);

  const fadeInAnimation = useCallback(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: DEFAULT_DURATION,
      useNativeDriver: USE_NATIVE_DRIVER,
    }).start(() => {
      setHidden(false);
      if (getRequiresFadeOut(controlsAreVisible, playerState)) {
        delayedFadeOutAnimation();
      }
    });
  }, [controlsAreVisible, delayedFadeOutAnimation, fadeAnim, playerState]);

  useEffect(() => {
    const listenerId = fadeAnim.addListener(() => {
      // @TODO Discover why we needed addListener to make fadeIn animation work
      // when toggling player controls very quickly on iOS and fix/remove it
      // @WHEN When testing animation behaviour during fast clicking the video container on pause/finished
      // @WHY We think that the fadeAnim value is not updated correctly or fast enough by native driver
      // fixes iOS animation bug
    });
    return () => {
      fadeAnim.stopAnimation();
      clearTimeout(animationTimeout);
      fadeAnim.removeListener(listenerId);
      dispatch(setControlsAreVisible(true));
    };
  }, [dispatch, fadeAnim]);

  useEffect(() => {
    clearTimeout(animationTimeout);
    if (controlsAreVisible) {
      fadeInAnimation();
    } else {
      fadeOutAnimation();
    }
    // fadeInAnimation and fadeOutAnimation
    // as dependencies triggers endless animation loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlsAreVisible, playerState]);

  return useMemo(() => ({ hidden, fadeAnim }), [fadeAnim, hidden]);
};
