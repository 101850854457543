import { useEffect } from 'react';
import {
  queryClientProduct,
  queryClientProgram,
} from 'lib/api/reactQueryHelpers';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { useHistory } from 'react-router-dom';
import {
  matchesLogin,
  matchesTitleScreen,
} from 'components/Routes/matchesRoutes';

export const useRedirectIsAuthenticated = (): void => {
  const history = useHistory();
  const { productReference } = useSafeParams();
  useEffect(() => {
    (async function fetchProgram() {
      const isRedirectable =
        matchesLogin(history) || matchesTitleScreen(history);
      if (!isRedirectable) {
        return;
      }
      try {
        const response = await queryClientProduct({
          product_reference: productReference,
        });
        await queryClientProgram({
          product_id: response.result.id,
        });
        history.push(`/${productReference}/home`);
      } catch (e) {
        // do nothing - let the APIClient error configuration handle this
      }
    })();
  }, [history, productReference]);
};
