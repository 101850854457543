import { middleware } from 'lib/api/middleware';
import { SceneSetResponse, SceneChild } from '@bighealth/types';
import { APIRequestBody, APIResponse } from '@bighealth/api';
import { ResponseInputProps } from 'components/forms/ResponseOptions/ResponseInput';
import { HOUR } from 'lib/durations';
import { ResponseInputComponentType } from '@bighealth/types/dist/scene-components/sleep-diary/entry-form';
import { QuestionId } from 'state/question-response/actions';

const mockQuestionListRaw = [
  {
    created_utc: { $datetime: '2020-05-12T14:31:40.623001' },
    updated_utc: { $datetime: '2020-05-12T14:31:40.623001' },
    deleted_utc: null,
    id: 8001,
    semantic_id: 'sd1',
    uuid: 'a8f8d0c9-5041-457c-a053-21a8052c4c3c',
    language: 'en',
    version: 1,
    question_pre_text: '',
    question_title: 'sd1: What time did you go to bed',
    response_pre_text: '',
    visual_representation_json: {},
    visual_representation_markup: '',
    optional: true,
    response_config: {
      response_options: [
        {
          id: 1,
          label: 'label',
          value: '$input',
          semantic_id: 'bed_time',
          display_text: '',
          max_number_lines: 3,
          min_characters_limit: 120,
          min_response: { $time: `00:00:00` },
          max_response: { $time: `4:30:00` },
        },
      ],
      validation_schema: {
        type: 'object',
        title: 'answers',
        $schema: 'http://json-schema.org/draft-07/schema#',
        properties: {
          '1': { type: 'number' },
          selections: {
            type: 'array',
            maxItems: 1,
            minItems: 1,
          },
        },
        description: 'answer option values',
      },
      correct_response_ids: [],
      default_response_ids: [],
      max_selections_required: 1,
      min_selections_required: 1,
    },
    response_type: { $ResponseType: 'TIME' },
    display_name: '8001',
    primary_key: 8001,
  },

  {
    created_utc: { $datetime: '2020-05-12T14:31:40.623001' },
    updated_utc: { $datetime: '2020-05-12T14:31:40.623001' },
    deleted_utc: null,
    id: 8002,
    semantic_id: 'sd2',
    uuid: 'a8f8d0c9-5041-457c-a053-21a8052c4c3c',
    language: 'en',
    version: 1,
    question_pre_text: '',
    question_title: 'sd2: What time did you try to fall asleep',
    response_pre_text: '',
    visual_representation_json: {},
    visual_representation_markup: '',
    optional: true,
    response_config: {
      response_options: [
        {
          id: 1,
          label: 'label',
          value: '$input',
          semantic_id: 'sd2_1',
          display_text: '',
          max_number_lines: 3,
          min_characters_limit: 120,
          min_response: { $time: `00:00:00` },
          max_response: { $time: `00:30:00` },
        },
      ],
      validation_schema: {
        type: 'object',
        title: 'answers',
        $schema: 'http://json-schema.org/draft-07/schema#',
        properties: {
          '1': { type: 'number' },
          selections: {
            type: 'array',
            maxItems: 1,
            minItems: 1,
          },
        },
        description: 'answer option values',
      },
      correct_response_ids: [],
      default_response_ids: [],
      max_selections_required: 1,
      min_selections_required: 1,
    },
    response_type: { $ResponseType: 'TIME' },
    display_name: '8002',
    primary_key: 8002,
  },

  {
    created_utc: { $datetime: '2020-05-12T14:31:40.623001' },
    updated_utc: { $datetime: '2020-05-12T14:31:40.623001' },
    deleted_utc: null,
    id: 8003,
    semantic_id: 'sd3',
    uuid: 'a8f8d0c9-5041-457c-a053-21a8052c4c3c',
    language: 'en',
    version: 1,
    question_pre_text: '',
    question_title: 'sd3: How long did it take you to fall asleep',
    response_pre_text: '',
    visual_representation_json: {},
    visual_representation_markup: '',
    optional: true,
    response_config: {
      response_options: [
        {
          id: 1,
          label: 'label',
          value: '1',
          semantic_id: 'sd3_1',
          display_text: '1hr',
          max_number_lines: 1,
          min_characters_limit: 120,
        },
        {
          id: 2,
          label: 'label',
          value: '2',
          semantic_id: 'sd3_1',
          display_text: '2hr',
          max_number_lines: 2,
          min_characters_limit: 120,
        },
      ],
      validation_schema: {
        type: 'object',
        title: 'answers',
        $schema: 'http://json-schema.org/draft-07/schema#',
        properties: {
          '1': { type: 'string' },
          '2': { type: 'string' },
          selections: {
            type: 'array',
            maxItems: 1,
            minItems: 1,
          },
        },
        description: 'answer option values',
      },
      correct_response_ids: [],
      default_response_ids: [],
      max_selections_required: 1,
      min_selections_required: 1,
    },
    response_type: { $ResponseType: 'TEXT' },
    display_name: '8003',
    primary_key: 8003,
  },

  {
    created_utc: { $datetime: '2020-05-12T14:31:40.623001' },
    updated_utc: { $datetime: '2020-05-12T14:31:40.623001' },
    deleted_utc: null,
    id: 8004,
    semantic_id: 'sd4',
    uuid: 'a8f8d0c9-5041-457c-a053-21a8052c4c3c',
    language: 'en',
    version: 1,
    question_pre_text: '',
    question_title: 'sd4: How many times did you wake up during the night',
    response_pre_text: '',
    visual_representation_json: {},
    visual_representation_markup: '',
    optional: true,
    response_config: {
      response_options: [
        {
          id: 1,
          label: 'label1',
          value: '1',
          semantic_id: 'sd4_1',
          display_text: 'sd41',
          max_number_lines: 1,
          min_characters_limit: 120,
        },
        {
          id: 1,
          label: 'label2',
          value: '2',
          semantic_id: 'sd4_2',
          display_text: 'sd42',
          max_number_lines: 1,
          min_characters_limit: 120,
        },
      ],
      validation_schema: {},
      correct_response_ids: [],
      default_response_ids: [],
      max_selections_required: 1,
      min_selections_required: 1,
    },
    response_type: { $ResponseType: 'TEXT' },
    display_name: '8004',
    primary_key: 8004,
  },

  {
    created_utc: { $datetime: '2020-05-12T14:31:40.623001' },
    updated_utc: { $datetime: '2020-05-12T14:31:40.623001' },
    deleted_utc: null,
    id: 8005,
    semantic_id: 'sd5',
    uuid: 'a8f8d0c9-5041-457c-a053-21a8052c4c3c',
    language: 'en',
    version: 1,
    question_pre_text: '',
    question_title: 'sd5: How much time did these awakenings last?',
    response_pre_text: '',
    visual_representation_json: {},
    visual_representation_markup: '',
    optional: true,
    response_config: {
      response_options: [
        {
          id: 1,
          label: 'Sup',
          value: '$input',
          semantic_id: 'first_number_input_1',
          display_text: 'Hello',
          max_response: 5 * HOUR,
          min_response: 0,
        },
      ],
      validation_schema: {},
      correct_response_ids: [],
      default_response_ids: [],
      max_selections_required: 1,
      min_selections_required: 1,
    },
    response_type: { $ResponseType: 'NUMBER' },
    display_name: '8005',
    primary_key: 8005,
  },

  {
    created_utc: { $datetime: '2020-05-12T14:31:40.623001' },
    updated_utc: { $datetime: '2020-05-12T14:31:40.623001' },
    deleted_utc: null,
    id: 8006,
    semantic_id: 'sd6',
    uuid: 'a8f8d0c9-5041-457c-a053-21a8052c4c3c',
    language: 'en',
    version: 1,
    question_pre_text: '',
    question_title: 'sd6: What time was your final awakening',
    response_pre_text: '',
    visual_representation_json: {},
    visual_representation_markup: '',
    optional: true,
    response_config: {
      response_options: [
        {
          id: 1,
          label: 'label',
          value: '$input',
          semantic_id: 'sd6_1',
          display_text: 'sd61',
          max_number_lines: 1,
          min_characters_limit: 120,
          min_response: { $time: `00:00:00` },
          max_response: { $time: `00:30:00` },
        },
      ],
      validation_schema: {},
      correct_response_ids: [],
      default_response_ids: [],
      max_selections_required: 1,
      min_selections_required: 1,
      min_response: { $time: `00:00:00` },
      max_response: { $time: `00:30:00` },
    },
    response_type: { $ResponseType: 'TIME' },
    display_name: '8006',
    primary_key: 8006,
  },

  {
    created_utc: { $datetime: '2020-05-12T14:31:40.623001' },
    updated_utc: { $datetime: '2020-05-12T14:31:40.623001' },
    deleted_utc: null,
    id: 8007,
    semantic_id: 'sd7',
    uuid: 'a8f8d0c9-5041-457c-a053-21a8052c4c3c',
    language: 'en',
    version: 1,
    question_pre_text: '',
    question_title: 'sd7: What time did you get out of bed for the day',
    response_pre_text: '',
    visual_representation_json: {},
    visual_representation_markup: '',
    optional: true,
    response_config: {
      response_options: [
        {
          id: 1,
          label: 'label',
          value: '$input',
          semantic_id: 'sd7_1',
          display_text: 'sd71',
          max_number_lines: 1,
          min_characters_limit: 120,
          min_response: { $time: `00:00:00` },
          max_response: { $time: `00:30:00` },
        },
      ],
      validation_schema: {
        type: 'object',
        title: 'answers',
        $schema: 'http://json-schema.org/draft-07/schema#',
        properties: {
          '1': {},
          selections: {
            type: 'array',
            maxItems: 1,
            minItems: 1,
          },
        },
        description: 'answer option values',
      },
      correct_response_ids: [],
      default_response_ids: [],
      max_selections_required: 1,
      min_selections_required: 1,
    },
    response_type: { $ResponseType: 'TIME' },
    display_name: '8007',
    primary_key: 8007,
  },

  {
    created_utc: { $datetime: '2020-05-12T14:31:40.623001' },
    updated_utc: { $datetime: '2020-05-12T14:31:40.623001' },
    deleted_utc: null,
    id: 8008,
    semantic_id: 'sd8',
    uuid: 'a8f8d0c9-5041-457c-a053-21a8052c4c3c',
    language: 'en',
    version: 1,
    question_pre_text: '',
    question_title: 'sd8: What would yo rate the quality of your sleep',
    response_pre_text: '',
    visual_representation_json: {},
    visual_representation_markup: '',
    optional: true,
    response_config: {
      response_options: [
        {
          id: 1,
          label: 'label',
          value: '$input',
          semantic_id: 'sd8_1',
          display_text: 'sd81',
          max_number_lines: 1,
          min_characters_limit: 120,
          min_response: { $time: `00:00:00` },
          max_response: { $time: `00:30:00` },
        },
      ],
      validation_schema: {},
      correct_response_ids: [],
      default_response_ids: [],
      max_selections_required: 1,
      min_selections_required: 1,
    },
    response_type: { $ResponseType: 'TIME' },
    display_name: '8008',
    primary_key: 8008,
  },

  {
    created_utc: { $datetime: '2020-05-12T14:31:40.623000' },
    updated_utc: { $datetime: '2020-05-12T14:31:40.623000' },
    deleted_utc: null,
    id: 1949,
    semantic_id: 'time',
    uuid: 'a8f8d0c9-5041-457c-a053-21a8052c4c3c',
    language: 'en',
    version: 1,
    question_pre_text: 'Birth date',
    question_title: 'When were you born?',
    response_pre_text: '',
    visual_representation_json: { test: 'test' },
    visual_representation_markup: 'This is some markup.',
    optional: true,
    response_config: {
      response_options: [
        {
          id: 1,
          label: 'Sup',
          value: '$input',
          semantic_id: 'time',
          display_text: 'Hello',
          max_number_lines: 3,
          min_characters_limit: 120,
        },
      ],
      validation_schema: {
        type: 'object',
        title: 'answers',
        $schema: 'http://json-schema.org/draft-07/schema#',
        properties: {
          '1': { type: 'number' },
          selections: {
            type: 'array',
            maxItems: 1,
            minItems: 0,
          },
        },
        description: 'answer option values',
      },
      correct_response_ids: [],
      default_response_ids: [],
      max_selections_required: 1,
      min_selections_required: 0,
    },
    response_type: { $ResponseType: 'TIME' },
    display_name: '1949',
    primary_key: 1949,
  },
  {
    created_utc: { $datetime: '2020-05-12T14:04:34.525159' },
    updated_utc: { $datetime: '2020-05-12T14:04:34.525167' },
    deleted_utc: null,
    id: 1947,
    semantic_id: 'datetime',
    uuid: '49ca3ef7-6282-41c3-801c-c1befb9b5b7c',
    language: 'en',
    version: 1,
    question_pre_text: 'Enter a date and time',
    question_title: 'When would you like to be reminded?',
    response_pre_text: '',
    visual_representation_json: { test: 'test' },
    visual_representation_markup: 'This is some markup.',
    optional: true,
    response_config: {
      response_options: [
        {
          id: 1,
          label: 'Sup',
          value: '$input',
          semantic_id: 'date-time',
          display_text: 'Hello',
          max_number_lines: 3,
          min_characters_limit: 120,
        },
      ],
      validation_schema: {
        type: 'object',
        title: 'answers',
        $schema: 'http://json-schema.org/draft-07/schema#',
        properties: {
          '1': { type: 'number' },
          selections: {
            type: 'array',
            maxItems: 1,
            minItems: 0,
          },
        },
        description: 'answer option values',
      },
      correct_response_ids: [],
      default_response_ids: [],
      max_selections_required: 1,
      min_selections_required: 0,
    },
    response_type: { $ResponseType: 'DATETIME' },
    display_name: '1947',
    primary_key: 1947,
  },
  {
    created_utc: '2020-07-23T00:40:16.864',
    updated_utc: '2020-07-23T00:40:16.864',
    deleted_utc: null,
    id: 3,
    semantic_id: 'none_of_the_above_test',
    uuid: 'e1e6860d-f51e-4bd6-b67f-5cd9fa197363',
    language: 'en',
    version: 1,
    question_pre_text: 'pretext',
    question_title: 'Please select',
    response_pre_text: 'response pretext',
    visual_representation_json: '{}',
    visual_representation_markup: '<markup/>',
    optional: false,
    response_config: {
      response_options: [
        {
          id: 0,
          label: 'n/a',
          value: 'None of the above',
          semantic_id: 'none_of_the_above',
          display_text: 'None of the above',
          max_number_lines: 1,
          min_characters_limit: 1,
        },
        {
          id: 1,
          label: 'one',
          value: 'One',
          semantic_id: 'none_of_the_above_test_1',
          display_text: 'Entry one ',
          max_number_lines: 1,
          min_characters_limit: 1,
        },
        {
          id: 2,
          label: 'two',
          value: 'Two',
          semantic_id: 'none_of_the_above_test_2',
          display_text: 'Entry two',
          max_number_lines: 1,
          min_characters_limit: 1,
        },
        {
          id: 3,
          label: 'three',
          value: 'Three',
          semantic_id: 'none_of_the_above_test_3',
          display_text: 'Entry three',
          max_number_lines: 1,
          min_characters_limit: 1,
        },
      ],
      validation_schema: {
        type: 'object',
        title: 'answers',
        $schema: 'http://json-schema.org/draft-07/schema#',
        properties: {
          '0': {
            type: 'string',
            pattern: 'None of the above',
          },
          '1': {
            type: 'string',
            pattern: 'One',
          },
          '2': {
            type: 'string',
            pattern: 'Two',
          },
          '3': {
            type: 'string',
            pattern: 'Three',
          },
          selections: {
            type: 'array',
            maxItems: 3,
            minItems: 1,
          },
        },
        description: 'answer option values',
      },
      correct_response_ids: [],
      default_response_ids: [],
      max_selections_required: 3,
      min_selections_required: 1,
    },
    response_type: {
      $ResponseType: 'TEXT',
    },
    display_name: '3',
    primary_key: 3,
  },
];

export const semanticIdToComponent: Record<
  QuestionId,
  ResponseInputComponentType
> = {
  sd1: 'DropdownTimePicker',
  sd2: 'DropdownTimePicker',
  sd3: 'Dropdown',
  //
  sd4: 'Dropdown',
  sd5: 'DropdownDurationPicker',
  sd6: 'DropdownTimePicker',
  //
  sd7: 'DropdownTimePicker',
  sd8: 'DropdownTimePicker',
};
const mockQuestionComponentList = mockQuestionListRaw.map(questionProps => {
  const component = questionProps.semantic_id
    ? semanticIdToComponent[questionProps.semantic_id]
    : 'TextInput';
  return {
    component,
    questionProps,
  };
});

const mockRawResponse = {
  status_code: 200,
  result: {
    scene_set_json: {
      component: 'SceneSet',
      childNodes: [
        {
          component: 'Scene',
          childNodes: mockQuestionComponentList,
        },
      ],
    },
  },
};

const mockReq = (null as unknown) as APIRequestBody;
const transformed = middleware.responses(mockReq, true)(mockRawResponse);
const mockRes = transformed as APIResponse<SceneSetResponse>;
const mockSceneList: SceneChild[] =
  mockRes.result.scene_set_json.childNodes[0].childNodes || [];
const mockQuestionList: ResponseInputProps[] = mockSceneList.filter(
  (e: SceneChild) => !!e.questionProps
) as ResponseInputProps[];
export { mockQuestionList };
