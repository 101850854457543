import styled from 'styled-components/native';
import { Text as TextUnstyled } from 'components/sceneset-components/Text';

const DOMAIN = 'GenericFooter';

export const Text = styled(TextUnstyled)`
  color: white;
  font-size: 16px;
  font-weight: normal;
`;
Text.displayName = `${DOMAIN}.Text`;
