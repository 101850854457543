import React, { createContext, useContext, useCallback } from 'react';
import { Platform } from 'react-native';
import { NavBarStyles } from '../constants';
import { MobileOptions } from 'components/Navigation/WithGlobalNavigation';

type NavigationBarState = MobileOptions & {
  isCollapsed: (screenWidth: number) => boolean;
};

const initialState: NavigationBarState = {
  enableMobileMode: false,
  isCollapsed: () => false,
};

export const NavigationBarContext = createContext(initialState);
export const useNavigationBarContext = (): NavigationBarState =>
  useContext(NavigationBarContext);

export const NavigationBarProvider = ({
  children,
  mobileOptions,
}: {
  children: React.ReactElement;
  mobileOptions: MobileOptions;
}): React.ReactElement => {
  const { enableMobileMode } = mobileOptions;

  // this function is used to determine what kind of menu to show
  // and to check if footer should be presented
  // isCollapsed() = true then show mobile/tablet/native menu and no footer on pages
  // isCollapsed() = false then show web menu with footer on every page
  const isCollapsed = useCallback(
    (screenWidth: number): boolean => {
      const isBeforeBreakpoint =
        screenWidth <= NavBarStyles.navBarCollapseTabletWebBreakPointPx;
      const isMobileMode = !!enableMobileMode && Platform.OS !== 'web';
      return isBeforeBreakpoint || isMobileMode;
    },
    [enableMobileMode]
  );

  return (
    <NavigationBarContext.Provider value={{ ...mobileOptions, isCollapsed }}>
      {children}
    </NavigationBarContext.Provider>
  );
};
