import React, { ReactElement } from 'react';
import Svg, { G, Rect, Polygon } from 'react-native-svg';
import { IconStyles } from './types';
import styled from 'styled-components/native';

const Container = styled.View<{ pointerEvents?: 'none' }>``;

export const DropdownCaret = ({
  size,
  style,
  pointerEvents,
}: {
  size: number;
  style?: IconStyles;
  pointerEvents?: 'none';
}): ReactElement => {
  return (
    <Container pointerEvents={pointerEvents}>
      <Svg width={size} height={size} viewBox="0 0 40 40" style={style}>
        <G id="Symbols" stroke="none" fill="currentColor">
          <G>
            <Rect
              id="Rectangle"
              stroke="currentColor"
              fill="currentColor"
              opacity="0"
              x="0"
              y="0"
              width="40"
              height="40"
              rx="8"
            />
            <Polygon
              fill={style?.color || 'currentColor'}
              points="24.6 16 20 20.6 15.4 16 14 17.4 20 23.4 26 17.4"
            />
          </G>
        </G>
      </Svg>
    </Container>
  );
};
