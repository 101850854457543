import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';

export const adjust_with_change_action_and_increment = async (
  args: adjust_with_change_action_and_increment.Args
): Promise<adjust_with_change_action_and_increment.Response> => {
  return await api({
    service_name: 'SleepWindow',
    service_version: '1',
    service_method: 'adjust_with_change_action_and_increment',
    args: args,
  } as adjust_with_change_action_and_increment.Request);
};

adjust_with_change_action_and_increment.queryKey =
  'SleepWindow/adjust_with_change_action_and_increment';

export declare namespace adjust_with_change_action_and_increment {
  export type Args = {
    change_action: string;
    minutes_increment: number;
    scene_set_graph_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'SleepWindow';
    service_version: '1';
    service_method: 'adjust_with_change_action_and_increment';
    args: Args;
  }

  export type Result = {};

  export type Response = APIResponse<Result>;
}
