import { DaylightHomeScreenPayload } from '@bighealth/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';

export const get_home_screen_data = async (
  args: get_home_screen_data.Args
): Promise<get_home_screen_data.Response> => {
  return await api({
    service_name: 'DaylightHomeScreen',
    service_version: '1',
    service_method: 'get_home_screen_data',
    args: args,
  } as get_home_screen_data.Request);
};

get_home_screen_data.queryKey = 'DaylightHomeScreen/get_progress_data';

export declare namespace get_home_screen_data {
  export type Args = {
    productId: number;
    programId: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'DaylightHomeScreen';
    service_version: '1';
    service_method: 'get_home_screen_data';
    args: Args;
  }

  export type Result = DaylightHomeScreenPayload;

  export type Response = APIResponse<Result>;
}
