import { ProductReferences } from 'common/constants/enums';
import { Assets } from '.';
import { PLATFORM_ASSETS_URL } from 'config/envVars/assets';

const getAssetsForProduct = (productReference: string): Assets => {
  const isDaylight = productReference === ProductReferences.DAYLIGHT;
  return {
    splashLogoUrl: isDaylight
      ? `${PLATFORM_ASSETS_URL}assets/daylight/splash_logo.png`
      : `${PLATFORM_ASSETS_URL}assets/sleepio/splash_logo.png`,
    throbberImageUrl: isDaylight
      ? `${PLATFORM_ASSETS_URL}assets/daylight/throbber.gif`
      : `${PLATFORM_ASSETS_URL}assets/sleepio/throbber.gif`,
    homeScreenHeaderUrl: isDaylight
      ? `${PLATFORM_ASSETS_URL}assets/daylight/homescreen_header.png`
      : `${PLATFORM_ASSETS_URL}assets/sleepio/homescreen_header.png`,
    settingsIcon: `${PLATFORM_ASSETS_URL}assets/gear-settings.png`,
  };
};

export { getAssetsForProduct };
