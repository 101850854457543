import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { RUMOptions } from 'lib/realUserMetrics/realUserMetricsTypes';

export const useRealUserMetrics = ({
  clientToken,
  applicationId,
  allowedTracingOrigins,
  version,
  environment,
}: RUMOptions): void => {
  useEffect(() => {
    // if we have the tokens set up RUM
    if (clientToken && applicationId) {
      datadogRum.init({
        applicationId: applicationId,
        clientToken: clientToken,
        site: 'datadoghq.com',
        version,
        env: environment,
        trackInteractions: true,
        service: 'frontend',
        allowedTracingOrigins: allowedTracingOrigins,
      });
    }
    // We only want to initialize on app startup, these parameters should not change after
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
