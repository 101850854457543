import React, { ReactElement } from 'react';
import styled from 'styled-components/native';
import Image from 'cross-platform/Image';
import Link from 'components/Link';

export const CARD_WIDTH_PX = 140;
export const CARD_SPACING_PX = 30;
export const CARD_HEIGHT_PX = 220;

const CardContainer = styled.View`
  width: ${CARD_WIDTH_PX}px;
  height: ${CARD_HEIGHT_PX}px;
  background: white;
  border-radius: 10px;
  min-width: ${CARD_WIDTH_PX}px;
  box-shadow: 0 13px 15px #e1e1e1;
`;

const Poster = styled(Image)`
  width: ${CARD_WIDTH_PX}px;
  height: ${CARD_HEIGHT_PX}px;
  border-radius: 10px;
`;

const Card = ({
  posterSrc,
  to,
  title,
}: {
  posterSrc?: string;
  to: string;
  title: string;
}): ReactElement => {
  return (
    <CardContainer>
      <Link to={to} title={title} data-testid={'Card'}>
        <Poster src={posterSrc} />
      </Link>
    </CardContainer>
  );
};

export default Card;
