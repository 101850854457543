import { DropdownItem } from 'components/generic-question/Dropdown';
import { QuizAttribute } from 'components/constants';

export type BorderStyles = {
  borderTopLeftRadius: number;
  borderTopRightRadius: number;
  borderBottomLeftRadius: number;
  borderBottomRightRadius: number;
  borderLeftWidth: number;
  borderRightWidth: number;
};

export enum ItemLocation {
  START = 'START',
  END = 'END',
  MIDDLE = 'MIDDLE',
}

export type HorizontalScaleItemProps = {
  quiz?: QuizAttribute;
  label?: string;
  location?: ItemLocation;
  onSelect: (value: DropdownItem['value']) => void;
  selected?: boolean;
  value: DropdownItem['value'];
  displayText?: string;
};
