import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { APIErrorResponse } from '@bighealth/api';
import { get_progress_data } from '@bighealth/api/DaylightSessionProgress/v1/get_progress_data';
import { useQueryProgram } from './useQueryProgram';

export const useQueryDaylightProgressData = (): UseQueryResult<
  get_progress_data.Response,
  APIErrorResponse
> => {
  const programId = useQueryProgram().data?.result?.id;

  const result = useQuery({
    queryKey: get_progress_data.queryKey,
    queryFn: () =>
      get_progress_data({
        program_id: programId as number,
      }),
    enabled: typeof programId === 'number',
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: Infinity,
    retry: false,
  });
  return result;
};
