import { ActionTypes } from './ActionTypes';
import { APIResponse } from '@bighealth/api';

type UseServiceCallActionPayloadIdle = {
  type: ActionTypes.IDLE;
};
type UseServiceCallActionPayloadFetching = {
  type: ActionTypes.FETCHING;
};
type UseServiceCallActionPayloadSuccess = {
  type: ActionTypes.SUCCESS;
  response: APIResponse;
};
type UseServiceCallActionPayloadError = {
  type: ActionTypes.ERROR;
  response: unknown;
};

export type UseServiceCallActionPayload =
  | UseServiceCallActionPayloadIdle
  | UseServiceCallActionPayloadFetching
  | UseServiceCallActionPayloadSuccess
  | UseServiceCallActionPayloadError;

export const idle = (): UseServiceCallActionPayload => ({
  type: ActionTypes.IDLE,
});
export const fetching = (): UseServiceCallActionPayload => ({
  type: ActionTypes.FETCHING,
});
export const success = (
  response: APIResponse
): UseServiceCallActionPayload => ({
  type: ActionTypes.SUCCESS,
  response,
});

export const error = (
  response: Record<string, unknown>
): UseServiceCallActionPayload => ({
  type: ActionTypes.ERROR,
  response,
});
