import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';

export const is_session_completed = async (
  args: is_session_completed.Args
): Promise<is_session_completed.Response> => {
  return await api({
    service_name: 'SessionProgress',
    service_version: '1',
    service_method: 'is_session_completed',
    args: args,
  } as is_session_completed.Request);
};

is_session_completed.queryKey = 'SessionProgress/is_session_completed';

export declare namespace is_session_completed {
  export type Args = {
    program_id: number;
    user_timezone: string;
    session_number: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'SessionProgress';
    service_version: '1';
    service_method: 'is_session_completed';
    args: Args;
  }

  export type Result = boolean;

  export type Response = APIResponse<Result>;
}
