import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import { SceneSet, SceneSetGraph } from '../../../types/src';
import { AssetEntity } from '../../../types/src/services/Asset';

export const get_user_session_screen_data = async (
  args: get_user_session_screen_data.Args
): Promise<get_user_session_screen_data.Response> => {
  return await api({
    service_name: 'SessionProgress',
    service_version: '1',
    service_method: 'get_user_session_screen_data',
    args: args,
  } as get_user_session_screen_data.Request);
};

get_user_session_screen_data.queryKey =
  'SessionProgress/get_user_session_screen_data';

export declare namespace get_user_session_screen_data {
  export type Args = {
    program_id: number;
    user_timezone: string; // e.g. Europe/London
  };

  export interface Request extends APIRequestBody {
    service_name: 'SessionProgress';
    service_version: '1';
    service_method: 'get_user_session_screen_data';
    args: Args;
  }

  export type Result = {
    session_status: {
      heading: {
        text: string;
      };
      buttons: Array<{
        text: string;
        colorScheme: 'primary_on_dark' | 'standard'; // ButtonColorSchemes
        action:
          | {
              type: 'jump_to_sceneset';
              payload: {
                sceneSetGraphId: SceneSetGraph['id'];
                destinationSceneSetId: SceneSet['id'];
              };
            }
          | {
              type: 'NAV_TO';
              to: string;
            };
      }>;
    };
    session_list: Array<{
      status: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED' | 'LOCKED';
      heading: {
        text: string;
      };
      status_text: {
        text: string;
      };
      play_action: {
        type: 'jump_to_sceneset';
        payload: {
          sceneSetGraphId: SceneSetGraph['id'];
          destinationSceneSetId: SceneSet['id'];
        };
      } | null;
    }>;
    background_image_asset: AssetEntity | null;
    has_new_session_available: boolean;
    has_in_progress_session_available: boolean;
  };

  export type Response = APIResponse<Result>;
}
