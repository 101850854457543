import { ProductReferences } from 'common/constants/enums';
import { getProductName } from 'lib/productInfo/getProductName';
import React, { ReactElement } from 'react';
import { HomeScreen, SessionsScreen } from '..';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';

const HomeScreenSwitcher = (): ReactElement => {
  useUpdateTitle();
  return getProductName() === ProductReferences.SLEEPIO ? (
    <SessionsScreen />
  ) : (
    <HomeScreen />
  );
};

export { HomeScreenSwitcher };
