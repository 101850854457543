import React, { ReactElement } from 'react';
import Image, { ResizeMode } from 'cross-platform/Image';
import styled from 'styled-components/native';
import { getAssetsForProduct } from 'config';
import { useParams } from 'cross-platform/react-router';
import { FillAbsoluteCenter } from 'components/Containers';
import * as roleProps from 'cross-platform/utils/roleProps';

const StyledImage = styled(Image)`
  height: 70px;
  width: 70px;
  border-radius: 35px;
`;

const Throbber = (props: roleProps.RoleProps): ReactElement => {
  const { productReference } = useParams();
  const { throbberImageUrl } = getAssetsForProduct(productReference as string);
  return (
    <FillAbsoluteCenter {...roleProps.pass(props)}>
      <StyledImage
        style={{
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: '#003366',
        }}
        src={throbberImageUrl}
        fit={ResizeMode.contain}
      />
    </FillAbsoluteCenter>
  );
};

export default Throbber;
