import React, { FunctionComponent, useContext } from 'react';
import { MetricsProps } from '@bighealth/types/src/services/SleepDiaryPayloads/get_sleep_diary_component_data_for_time_period/';
import {
  Container,
  MetricContainer,
  MetricTitle,
  MetricItemView,
  MetricItemTitle,
  MetricItemValue,
} from './styled';
import {
  ScreenResponsiveLayoutContext,
  useTransformContentStyle,
} from 'components/ResponsiveLayout';
import { NavBarStyles } from 'components/Navigation/constants';

/**
 * Component for Web.
 * Based on the sceen width we differentiate between Mobile and Desktop.
 */
const Metrics: FunctionComponent<MetricsProps> = (props: MetricsProps) => {
  const { heading, items } = props;

  const scaleFn = useTransformContentStyle();

  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  const isMobileWeb =
    screenWidth <= NavBarStyles.navBarCollapseMobileWebBreakPointPx;

  return (
    <Container style={{ paddingVertical: '3%' }}>
      <MetricTitle
        {...heading}
        style={{ fontSize: isMobileWeb ? scaleFn(26) : scaleFn(24) }}
      />
      <MetricContainer style={{ marginTop: '3%' }}>
        {items.map((item, index) => (
          <MetricItemView
            key={index}
            style={{
              width: isMobileWeb ? '100%' : '49%',
              paddingVertical: '2.5%',
              paddingLeft: '5%',
            }}
          >
            <MetricItemTitle
              {...item.label}
              style={{ fontSize: isMobileWeb ? scaleFn(32) : scaleFn(24) }}
            />
            <MetricItemValue
              {...item.value}
              style={{
                color: item.value.color,
                fontSize: isMobileWeb ? scaleFn(32) : scaleFn(40),
              }}
            />
          </MetricItemView>
        ))}
      </MetricContainer>
    </Container>
  );
};

export { Metrics };
