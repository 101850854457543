const fallbackFonts = 'Verdana, sans-serif';

export const FontFamilies = {
  Black: `GT-Walsheim-Black, ${fallbackFonts}`,
  BlackItalic: `GT-Walsheim-Black-Oblique, ${fallbackFonts}`,
  Medium: `GT-Walsheim-Condensed-Medium, ${fallbackFonts}`,
  MediumItalic: `GT-Walsheim-Condensed-Medium-Oblique, ${fallbackFonts}`,
  Light: `GT-Walsheim-Condensed-Light, ${fallbackFonts}`,
  LightItalic: `GT-Walsheim-Condensed-Light-Oblique, ${fallbackFonts}`,
  Regular: `GT-Walsheim-Regular, ${fallbackFonts}`,
  Bold: `GT-Walsheim-Bold, ${fallbackFonts}`,
  Italic: `GT-Walsheim-Regular-Oblique, ${fallbackFonts}`,
  BoldItalic: `GT-Walsheim-Bold-Oblique, ${fallbackFonts}`,
} as const;
