export enum ButtonColorSchemes {
  Facebook = 'facebook',
  Standard = 'standard',
  Login = 'login',
  Google = 'google',
  Link = 'link',
  Primary = 'primary',
  PrimaryOnDark = 'primary_on_dark',
  LinkInverse = 'link-inverse',
}

export const buttonColorSchemes = {
  [ButtonColorSchemes.Standard]: {
    default: {
      color: 'white',
      backgroundColor: '#00506b',
      borderColor: 'transparent',
    },
    disabled: {
      color: 'white',
      backgroundColor: 'rgba(0, 80, 107, 0.5)', // Same as #00506b but with 50% opacity
      borderColor: 'transparent',
    },
    pressed: {
      color: 'white',
      backgroundColor: '#00212c',
      borderColor: 'transparent',
    },
    hover: {
      color: 'white',
      backgroundColor: '#017BA3',
      borderColor: 'transparent',
    },
  },
  [ButtonColorSchemes.Login]: {
    default: {
      color: '#003366',
      backgroundColor: '#4db400',
      borderColor: 'transparent',
    },
    disabled: {
      color: '#003366',
      backgroundColor: 'rgba(77, 180, 0, 0.5)', // Same as #4db400 but with 50% opacity
      borderColor: 'transparent',
    },
    pressed: {
      color: '#003366',
      backgroundColor: 'rgba(77, 180, 0, 0.5)',
      borderColor: 'transparent',
    },
    hover: {
      color: '#003366',
      backgroundColor: 'rgba(77, 180, 0, 0.5)',
      borderColor: 'transparent',
    },
  },
  [ButtonColorSchemes.PrimaryOnDark]: {
    default: {
      color: '#FFF',
      backgroundColor: '#008A09',
      borderColor: 'transparent',
    },
    disabled: {
      color: '#FFF',
      backgroundColor: 'rgba(0, 138, 9, 0.5)', // Same as #008a09 but with 50% opacity
      borderColor: 'transparent',
    },
    pressed: {
      color: '#FFF',
      backgroundColor: 'rgba(0, 138, 9, 0.5)', // Same as #008a09 but with 50% opacity
      borderColor: 'transparent',
    },
    hover: {
      color: '#FFF',
      backgroundColor: 'rgba(0, 138, 9, 0.5)', // Same as #008a09 but with 50% opacity
      borderColor: 'transparent',
    },
  },
  [ButtonColorSchemes.Primary]: {
    default: {
      color: '#FFF',
      backgroundColor: '#4db400',
      borderColor: 'transparent',
    },
    disabled: {
      color: '#FFF',
      backgroundColor: 'rgba(77, 180, 0, 0.5)', // Same as #4db400 but with 50% opacity
      borderColor: 'transparent',
    },
    pressed: {
      color: '#FFF',
      backgroundColor: 'rgba(77, 180, 0, 0.5)',
      borderColor: 'transparent',
    },
    hover: {
      color: '#FFF',
      backgroundColor: 'rgba(77, 180, 0, 0.5)',
      borderColor: 'transparent',
    },
  },
  [ButtonColorSchemes.Facebook]: {
    default: {
      color: 'white',
      backgroundColor: 'transparent',
      borderColor: 'white',
    },
    disabled: {
      color: 'gray',
      backgroundColor: 'transparent',
      borderColor: 'gray',
    },
    pressed: {
      color: 'rgba(255, 255, 255, 0.5)',
      backgroundColor: 'transparent',
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    hover: {
      color: 'rgba(255, 255, 255, 0.5)',
      backgroundColor: 'transparent',
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
  },
  [ButtonColorSchemes.Google]: {
    default: {
      color: 'white',
      backgroundColor: 'transparent',
      borderColor: 'white',
    },
    disabled: {
      color: 'gray',
      backgroundColor: 'transparent',
      borderColor: 'gray',
    },
    pressed: {
      color: 'rgba(255, 255, 255, 0.5)',
      backgroundColor: 'transparent',
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    hover: {
      color: 'rgba(255, 255, 255, 0.5)',
      backgroundColor: 'transparent',
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
  },
  [ButtonColorSchemes.Link]: {
    default: {
      color: '#00506b',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    disabled: {
      color: 'rgba(0, 80, 107, 0.5)',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    pressed: {
      color: 'rgba(0, 80, 107, 0.7)',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    hover: {
      color: 'rgba(0, 80, 107, 0.7)',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
  },
  [ButtonColorSchemes.LinkInverse]: {
    default: {
      color: '#ffffff',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    disabled: {
      color: 'rgba(255, 255, 255, 0.5)',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    pressed: {
      color: 'rgba(255, 255, 255, 0.7)',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    hover: {
      color: 'rgba(255, 255, 255, 0.7)',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
  },
} as const;
