import { getQueryClient } from 'components/ProvidersContainer';
import { read_bulk } from '@bighealth/api/SceneSetGraph/v1';

export const queryClientSceneSetGraphsBulk = async ({
  ids,
}: {
  ids: number[];
}): Promise<read_bulk.Response> => {
  const result = await getQueryClient().fetchQuery(
    read_bulk.queryKey,
    () =>
      read_bulk({
        entity_ids: ids,
      }),
    {
      cacheTime: Infinity,
    }
  );
  return result;
};
