import React, { ReactElement, useEffect } from 'react';
import {
  ChangePasswordForm,
  Names,
  ValidationSchema,
  FormValues,
} from './components/ChangePasswordForm';
import { Box, Heading } from './styled';
import {
  useServiceMethodCall,
  UseServiceMethodCall,
} from 'lib/api/hooks/useServiceMethodCall';
import { ActionTypes } from 'lib/api/hooks/useServiceMethodCall/ActionTypes';
import { JSONSchemaProperty } from '@bighealth/types';
import { useQuery } from 'lib/router/useQuery';
import logger from 'lib/logger';
import { useBigHealthLogin } from 'lib/api/hooks/useBigHealthLogin';
import { FormikHelpers } from 'formik';
import { FillAbsoluteCenter } from 'components/Containers';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';

/**
 * TODO remove these hard-coded schema
 * WHEN [PG-476] StaffWeb's PASSWORD_SCHEMA & EMAIL_ADDRESS_SCHEMA is made AJV/JavaScript
 * @see {@link emailSchema} Hard coded below
 * @see {@link passwordSchema} Hard coded below
 * @see {@link https://github.com/epoberezkin/ajv-errors} Set custom errors
 * @see {@link https://github.com/epoberezkin/ajv} For general info
 */
const emailSchema = {
  type: 'string',
  pattern:
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
  minLength: 2,
  maxLength: 254,
  errorMessage: {
    pattern: '$name must be valid', // pattern errorMessage
    _: '$name must be valid', // default errorMessage
  },
} as JSONSchemaProperty;
const passwordSchema = {
  type: 'string',
  minLength: 8,
  maxLength: 254,
  errorMessage: {
    minLength: '$name must be at least 8 characters', // minLength errorMessage
    _: '$name <default message> must is required', // default errorMessage
  },
} as JSONSchemaProperty;

export const getValidationSchema = (
  email: JSONSchemaProperty | undefined,
  password: JSONSchemaProperty | undefined
): ValidationSchema => {
  if (!email || !password) {
    throw ReferenceError(
      `Expected email and password to be set, instead got email: ${email} & password: ${password}`
    );
  }
  return {
    type: 'object',
    required: [Names.EMAIL, Names.NEW_PASSWORD_1, Names.NEW_PASSWORD_2],
    properties: {
      [Names.EMAIL]: email,
      [Names.NEW_PASSWORD_1]: password,
      [Names.NEW_PASSWORD_2]: password,
    },
    errorMessage: {
      required: '$name is a required field',
    },
  };
};
const ResetPasswordScreen = ({
  isHardCodeSchema = false,
}: {
  isHardCodeSchema: boolean;
}): ReactElement => {
  useUpdateTitle('Reset Password');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let onSubmitError: any; // "any" truthy value means error
  const [emailReq, requestEmailSchema] = useServiceMethodCall({
    serviceName: 'UserAccount',
    serviceVersion: '1',
    serviceMethod: 'get_email_address_validation_schema',
    args: {},
  }) as ReturnType<UseServiceMethodCall>;
  const [passwordReq, requestPasswordSchema] = useServiceMethodCall({
    serviceName: 'UserAccount',
    serviceVersion: '1',
    serviceMethod: 'get_password_validation_schema',
    args: {},
  }) as ReturnType<UseServiceMethodCall>;
  const [updatePassword, requestUpdatePasswordWithToken] = useServiceMethodCall(
    {
      serviceName: 'UserAccount',
      serviceVersion: '1',
      serviceMethod: 'update_password_with_token',
      args: {},
    }
  ) as ReturnType<UseServiceMethodCall>;

  const bigHealthLogin = useBigHealthLogin();

  useEffect(() => {
    requestPasswordSchema();
    requestEmailSchema();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const query = useQuery();
  // set schema
  let email: JSONSchemaProperty | undefined;
  let password: JSONSchemaProperty | undefined;
  if (emailReq.status === ActionTypes.SUCCESS) {
    email = emailReq.response.result as JSONSchemaProperty;
  }
  if (passwordReq.status === ActionTypes.SUCCESS) {
    password = passwordReq.response.result as JSONSchemaProperty;
  }

  let schema;
  const isSchemaError =
    emailReq.status === ActionTypes.ERROR ||
    passwordReq.status === ActionTypes.ERROR;
  const isSchemaReady =
    emailReq.status === ActionTypes.SUCCESS &&
    passwordReq.status === ActionTypes.SUCCESS;
  if (isSchemaError) {
    logger('Request for schema returned error');
  } else if (isSchemaReady && !isHardCodeSchema) {
    schema = getValidationSchema(email, password);
  }
  if (isHardCodeSchema) {
    schema = getValidationSchema(emailSchema, passwordSchema);
  }
  // set token
  const token = query.get('token');
  if (!token) {
    logger(
      'No token',
      ReferenceError(`No password reset token in "${query.toString()}"`),
      {
        silent: true,
      }
    );
  }
  const onSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ): Promise<void> => {
    onSubmitError = '';
    try {
      actions.setSubmitting(true);
      await requestUpdatePasswordWithToken({
        email: values[Names.EMAIL],
        // eslint-disable-next-line camelcase
        new_password: values[Names.NEW_PASSWORD_1],
        token,
      });
      await bigHealthLogin.login({
        email: values[Names.EMAIL],
        password: values[Names.NEW_PASSWORD_1],
      });
    } catch (err) {
      onSubmitError = 'Reset password submit error';
      logger('Reset password submit error', err);
    } finally {
      actions.setSubmitting(false);
    }
  };
  const error =
    isSchemaError ||
    updatePassword.status === ActionTypes.ERROR ||
    bigHealthLogin.error ||
    onSubmitError;
  return (
    <FillAbsoluteCenter>
      <Box>
        <Heading>Change your password</Heading>
        <ChangePasswordForm schema={schema} onSubmit={onSubmit} error={error} />
      </Box>
    </FillAbsoluteCenter>
  );
};

export { ResetPasswordScreen };
