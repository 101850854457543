import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';

export const create_with_times = async (
  args: create_with_times.Args
): Promise<create_with_times.Response> => {
  return await api({
    service_name: 'SleepWindow',
    service_version: '1',
    service_method: 'create_with_times',
    args: args,
  } as create_with_times.Request);
};

create_with_times.queryKey = 'SleepWindow/create_with_times';

export declare namespace create_with_times {
  export type Args = {
    from_time: { $time: string };
    to_time: { $time: string };
    scene_set_graph_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'SleepWindow';
    service_version: '1';
    service_method: 'create_with_times';
    args: Args;
  }

  export type Result = {};

  export type Response = APIResponse<Result>;
}
