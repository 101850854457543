import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';

export const extrapolate_missing_diaries_from_past_seven_days = async (
  args: extrapolate_missing_diaries_from_past_seven_days.Args
): Promise<extrapolate_missing_diaries_from_past_seven_days.Response> => {
  return await api({
    service_name: 'SleepDiary',
    service_version: '1',
    service_method: 'extrapolate_missing_diaries_from_past_seven_days',
    args: args,
  } as extrapolate_missing_diaries_from_past_seven_days.Request);
};

extrapolate_missing_diaries_from_past_seven_days.queryKey =
  'SleepDiary/extrapolate_missing_diaries_from_past_seven_days';

export declare namespace extrapolate_missing_diaries_from_past_seven_days {
  export type Args = {
    user_timezone: string;
    product_id?: number;
    program_id?: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'SleepDiary';
    service_version: '1';
    service_method: 'extrapolate_missing_diaries_from_past_seven_days';
    args: Args;
  }

  export type Result = {};

  export type Response = APIResponse<Result>;
}
