/**
 * WHY DOES THIS EXIST?
 *
 * Many of the designs retain layout  when errors are not visible.
 * "No think" solution oo keep markup/DOM semantic:
 * IF props.error THEN
 *  render <ErrorText />
 * ELSE
 *  render <NullText />
 *
 */
import styled from 'styled-components/native';
import { ErrorText } from '../ErrorText';

const NullText = styled(ErrorText).attrs({
  children: ' ',
})``;

export { NullText };
