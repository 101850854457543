import React, { ReactElement } from 'react';
import { Footer } from 'components/Footer';
import { useParams } from 'cross-platform/react-router';
import { SleepDiaryWeek } from 'components/SleepDiaryWeek';
import { SleepDiaryScreenParams } from './types';
import {
  ScreenScroll,
  ContentContainer,
  useGetPageDimensions,
} from 'components/Screens/ContentScreens';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';

export const SleepDiaryScreen = (): ReactElement => {
  useUpdateTitle('Sleep Diary');
  const { calendarWeekOffset } = useParams<SleepDiaryScreenParams>();
  const { pageContentWidth } = useGetPageDimensions();
  return (
    <ScreenScroll
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
    >
      <ContentContainer
        style={{
          flex: 1,
          width: pageContentWidth,
        }}
      >
        <SleepDiaryWeek
          includeMetadata={true}
          isReadOnly={false}
          shouldShowContinueButton={false}
          period={'calendar_week'}
          calendarWeekOffset={parseInt(calendarWeekOffset || '0')}
          titleAlignment={{ horizontal: 'left' }}
          title={{
            text: 'Sleep Diary',
            color: '#003366',
            fontSize: 40,
          }}
          subtitleAlignment={{ horizontal: 'left' }}
          subtitle={{
            color: '#003366',
            fontSize: 30,
          }}
        />
      </ContentContainer>
      <Footer />
    </ScreenScroll>
  );
};
