import { getQueryClient } from 'components/ProvidersContainer';
import { find_with_reference } from '@bighealth/api/Product/v2';

export const queryClientProduct = async ({
  product_reference,
}: {
  product_reference: string;
}): Promise<find_with_reference.Response> => {
  const result = await getQueryClient().fetchQuery(
    find_with_reference.queryKey,
    () =>
      find_with_reference({
        reference: product_reference,
      }),
    {
      cacheTime: Infinity,
    }
  );
  return result;
};
