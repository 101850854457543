import { History } from 'react-router/node_modules/@types/history';

// IDEA find better way to reload on native
// WHEN we decide we don't like
//  - "mutating" history via replace
//  - setTimeout
const refresh = (history: History): void => {
  const pathname = history.location.pathname;

  setTimeout(() => {
    history.replace({ pathname: '/' });
  }, 1);
  setTimeout(() => {
    history.replace({ pathname });
  }, 2);
};
export { refresh };
