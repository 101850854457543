import React, { HTMLProps, ReactElement, ReactNode } from 'react';
import { GenericInputButtonProps } from './types';
import styled, { CSSProperties } from 'styled-components';
import {
  ButtonStates,
  getStylesForButtonStates,
} from 'lib/styles/inputStateStyles';
import { getControlThemes } from 'components/layout/Content/Control/styled';
import { useGetDynamicInputStyles } from 'components/ResponsiveLayout';
import { Span, Button as ButtonWeb } from 'components/primitives/web-only';
import { GenericInputIcon } from './GenericButtonIcon';
import { useInputFocusContext } from 'components/forms/ResponseOptions/providers/InputFocusProvider';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';

const ButtonStatic = styled(ButtonWeb)<ButtonStates & GenericInputButtonProps>`
  appearance: none;
  border: ${getStylesForButtonStates('border')};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  outline: 0;
  position: relative;
  padding: 0;
  ${getControlThemes}
`;

export const Button: React.FC<HTMLProps<
  HTMLButtonElement & ButtonStates & GenericInputButtonProps
>> = ({ style, ...rest }: { style?: CSSProperties }): ReactElement => {
  const { height, borderRadius, fontSize } = useGetDynamicInputStyles();
  return (
    <ButtonStatic
      {...rest}
      style={{
        ...(style || {}),
        minHeight: height,
        fontSize,
        borderRadius,
      }}
    />
  );
};

export const GenericButtonTextContainer = styled(Span)`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const GenericButtonTextStatic = styled(Span)<
  ButtonStates & GenericInputButtonProps
>`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
`;

export const GenericButtonText: React.FC<HTMLProps<HTMLDivElement> &
  ButtonStates &
  GenericInputButtonProps> = ({
  style,
  ...rest
}: {
  style?: CSSProperties;
} & RoleProps): ReactElement => {
  const {
    fontSize,
    paddingLeft,
    paddingRight,
    paddingBottom,
    paddingTop,
    height,
  } = useGetDynamicInputStyles();
  return (
    <GenericButtonTextStatic
      {...rest}
      {...roles.pass(rest, 'text')}
      style={{
        ...(style || {}),
        minHeight: height - paddingTop - paddingBottom,
        fontSize,
        paddingLeft,
        paddingRight,
        flex: 1,
        paddingTop,
        paddingBottom,
      }}
    />
  );
};

export const GenericButtonEditText: React.FC<HTMLProps<HTMLDivElement> &
  ButtonStates &
  GenericInputButtonProps> = ({
  style,
  isSelected,
  ...rest
}: {
  style?: CSSProperties;
} & ButtonStates): ReactElement => {
  const {
    inputEditFontSize,
    paddingRight,
    height,
  } = useGetDynamicInputStyles();
  return (
    <GenericButtonTextStatic
      {...rest}
      style={{
        ...(style || {}),
        fontSize: inputEditFontSize,
        paddingRight,
        height,
        color: isSelected
          ? 'inherit'
          : getStylesForButtonStates('editColor')({}),
        fontWeight: 'bold',
      }}
    />
  );
};

export const GenericButtonLabelText: React.FC<HTMLProps<HTMLDivElement> &
  ButtonStates &
  GenericInputButtonProps> = ({
  style,
  isSelected,
  quiz,
  ...rest
}: {
  style?: CSSProperties;
} & ButtonStates): ReactElement => {
  const { paddingRight, height, labelFontSize } = useGetDynamicInputStyles();
  return (
    <GenericButtonTextStatic
      {...rest}
      style={{
        ...(style || {}),
        fontSize: labelFontSize,
        paddingRight,
        height,
        maxWidth: '50%',
        textAlign: 'right',
        color: isSelected
          ? 'inherit'
          : getStylesForButtonStates('labelColor')({ quiz }),
      }}
    />
  );
};

const GenericButton = ({
  onClick,
  children,
  ...rest
}: GenericInputButtonProps): ReactElement => {
  const { onFocus, onBlur } = useInputFocusContext();

  const handleBlur = () => {
    onBlur();
  };

  const handleFocus = () => {
    // we don't want to call focus on the button that is
    // a non editing state of the input
    if (!rest.isInput) {
      onFocus();
    }
  };

  return (
    <Button
      {...rest}
      onClick={onClick}
      onBlur={handleBlur}
      onFocus={handleFocus}
    >
      {typeof children === 'function' ? children() : children}
    </Button>
  );
};

export const GenericInputButton = ({
  isActive,
  onClick,
  children,
  isSelected,
  label,
  quiz,
  isInput,
  highlight,
  ...rest
}: GenericInputButtonProps & {
  children: ReactNode;
  label?: ReactNode;
}): ReactElement => {
  const { height, iconSize, inputIconMarginRight } = useGetDynamicInputStyles();
  return (
    <GenericButton
      {...roles.pass(rest, undefined, {
        default: 'GenericInputButton',
      })}
      isActive={isActive}
      isInput={isInput}
      isSelected={isSelected}
      quiz={quiz}
      highlight={highlight}
    >
      {typeof children === 'function'
        ? children // Pass through - consumer has to do the work of formatting etc
        : (
            fnProps = {}
          ): ReactElement => ( // We do all the formatting the work for the consumer
            <GenericButtonTextContainer>
              <GenericButtonText
                onClick={onClick}
                isActive={isActive}
                isSelected={isSelected}
                isHovering={fnProps.isPressing}
                quiz={quiz}
                highlight={highlight}
                {...roles.pass(rest, 'ButtonText')}
              >
                {children}
              </GenericButtonText>
              {label ? (
                <GenericButtonLabelText
                  onClick={onClick}
                  isActive={isActive}
                  isSelected={isSelected}
                  isHovering={fnProps.isPressing}
                  quiz={quiz}
                  highlight={highlight}
                  {...roles.pass(rest, 'LabelText')}
                >
                  {label}
                </GenericButtonLabelText>
              ) : null}
              <GenericInputIcon
                quiz={quiz}
                style={{
                  marginTop: 0.5 * (height - iconSize),
                  marginRight: inputIconMarginRight,
                }}
                size={iconSize}
              />
            </GenericButtonTextContainer>
          )}
    </GenericButton>
  );
};
