import { useQuery } from 'react-query';
import { read_bulk } from '@bighealth/api/SceneSetGraph/v1';
import { UseQueryResult } from 'react-query/types/react/types';
import { APIErrorResponse } from '@bighealth/api';
import { useQueryProgram } from './useQueryProgram';

export const useQueryHomeScreen = (): UseQueryResult<
  read_bulk.Response,
  APIErrorResponse
> => {
  const programResult = useQueryProgram();
  const sceneSetGraphIds = programResult.data?.result?.scene_set_graph_ids;

  const result = useQuery({
    queryKey: read_bulk.queryKey,
    queryFn: () =>
      read_bulk({
        entity_ids: sceneSetGraphIds as number[],
      }),
    enabled: typeof sceneSetGraphIds !== 'undefined',
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    cacheTime: Infinity,
    retry: false,
  });
  return result;
};
