import { ResponseOption } from '@bighealth/types';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { flatten } from 'ramda';

import { QuizAttribute } from 'components/constants';

const getQuizFromAttempts = (
  optionId: number,
  attempts: ResponseOption[][],
  selected: DropdownItem,
  isQuizCompletedComplete: boolean,
  incorrectSelection: number[],
  correctSelection: number[],
  correct_response_ids: number[]
): QuizAttribute | undefined => {
  if (typeof selected?.id !== 'number') {
    throw TypeError('id should be number');
  }

  return !isQuizCompletedComplete &&
    !flatten<ResponseOption[][]>(attempts)
      .map(e => e.id)
      .includes(optionId)
    ? undefined
    : incorrectSelection.find(id => id === selected?.id)
    ? QuizAttribute.INCORRECT
    : correctSelection.find(id => id === selected?.id)
    ? QuizAttribute.CORRECT
    : correct_response_ids.includes(selected.id)
    ? QuizAttribute.CORRECT
    : undefined;
};
export { getQuizFromAttempts };
