import styled from 'styled-components/native';
import { Grid as GridRaw } from 'components/layout/Grid';
import {
  yScaler,
  ScaleYValueProps,
} from 'components/Screens/SessionsScreen/utils/scaler';
import { DOMAIN } from './constants';

const Grid = styled(GridRaw)<ScaleYValueProps>`
  margin-top: ${yScaler(120)}px;
  width: 100%;
`;
Grid.displayName = `${DOMAIN}.Grid`;

export { Grid };
