import { PLAYER_ACTIONS, PlayerAction } from './actions';
import { PersistConfig } from 'redux-persist';
import storage from '@react-native-community/async-storage';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { PERSIST_BAN_LIST } from 'state/store/persist/banlist';

export enum PLAYER_STATUS {
  PREPARING = 'PREPARING',
  READY = 'READY',
}

export enum PlaybackState {
  UNINITIALIZED = 'UNINITIALIZED',
  INITIALIZED = 'INITIALIZED',
  PLAYING = 'PLAYING',
  PAUSED = 'PAUSED',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

export const Bar = '123';

export const authPersistConfig: PersistConfig<PlayerState> = {
  key: 'auth',
  storage,
  blacklist: PERSIST_BAN_LIST,
};

export interface PlayerState extends PersistPartial {
  status: PLAYER_STATUS;
  controlsAreVisible: boolean;
  _persist: Readonly<{
    version: number;
    rehydrated: boolean;
  }>;
  playerState: PlaybackState;
  sceneSetRenderKey: number;
}

export const initialPlayerState: PlayerState = {
  status: PLAYER_STATUS.PREPARING,
  _persist: {
    version: 0,
    rehydrated: false,
  },
  controlsAreVisible: true,
  playerState: PlaybackState.UNINITIALIZED,
  sceneSetRenderKey: 0,
};

export const playerReducer = (
  state = initialPlayerState,
  action: PlayerAction
): PlayerState => {
  const { type, payload } = action;
  switch (type) {
    case PLAYER_ACTIONS.RESET: {
      return initialPlayerState;
    }
    case PLAYER_ACTIONS.SET_PLAYER_STATUS_READY: {
      return {
        ...state,
        status: PLAYER_STATUS.READY,
      };
    }
    case PLAYER_ACTIONS.SHOW_CONTROLS: {
      return {
        ...state,
        controlsAreVisible: payload as boolean,
      };
    }
    case PLAYER_ACTIONS.SET_PLAYER_STATE: {
      return {
        ...state,
        playerState: payload as PlaybackState,
      };
    }
    case PLAYER_ACTIONS.INCREMENT_SCENESET_RENDER_KEY: {
      return {
        ...state,
        sceneSetRenderKey: state.sceneSetRenderKey + 1,
      };
    }
    default:
      return state;
  }
};
