import React, { ChangeEventHandler, HTMLProps, ReactElement } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  DatePickerDisplayFormat,
  DatePickerPlaceholder,
  RawDatePickersProps,
} from './types';
import styled, { CSSProperties } from 'styled-components';
import {
  getStylesForInputStates,
  InputStates,
} from 'lib/styles/inputStateStyles';
import { getDateWithoutMilliseconds } from 'lib/getDateWithoutMilliseconds';
import { useGetDynamicInputStyles } from 'components/ResponsiveLayout';
import { Input as InputWeb } from 'components/primitives/web-only';
import { format, setHours, setMinutes } from 'date-fns';
import { createTimezoneOffsetDate } from './utils/createTimezoneOffsetDate';
import { useInputFocusContext } from 'components/forms/ResponseOptions/providers/InputFocusProvider';
import { roles } from 'cross-platform/utils/roleProps';

const DefaultBlurView = styled.div`
  display: flex;
  flex-direction: column;
`;

DefaultBlurView.displayName = 'DefaultBlurView';

const CustomInputStyles = styled(InputWeb)<InputStates>`
  appearance: none;
  background-color: white;
  border: ${getStylesForInputStates('border')};
  box-sizing: border-box;
  color: #000000;
  display: flex;
  outline: 0;
  padding-bottom: 0;
  padding-top: 0;
  width: 100%;
  &:focus {
    color: #1a80a2;
    border-color: #1a80a2;
  }
  &::placeholder {
    color: ${getStylesForInputStates('color')({ isFilled: false })};
    font-style: ${(): string =>
      getStylesForInputStates('fontStyle')({ isFilled: false })};
  }
`;

export const CustomInput: React.FC<HTMLProps<HTMLInputElement>> = ({
  style,
  ...rest
}: {
  style?: CSSProperties;
}): ReactElement => {
  const {
    height,
    borderRadius,
    paddingLeft,
    paddingRight,
    fontSize,
  } = useGetDynamicInputStyles();
  return (
    <CustomInputStyles
      {...rest}
      readOnly={true}
      style={{
        ...(style || {}),
        height,
        paddingLeft,
        paddingRight,
        fontSize,
        borderRadius,
      }}
    />
  );
};

type HTMLInputProps = {
  onChange?: ChangeEventHandler;
  value?: string;
};

// Class component because refs
class InputRenderProp extends React.Component<
  HTMLProps<HTMLInputElement & HTMLInputProps> & { highlight?: boolean }
> {
  render(): ReactElement {
    return <CustomInput {...this.props} />;
  }
}

// react-datepicker blurs the input as soon as you click on the calendar view
// this will keep focus on the calendar
export const DefaultBlurBlocker = ({
  children,
  questionInFocus,
}: {
  children: React.ReactNode;
  questionInFocus: boolean;
}): ReactElement => {
  return (
    <DefaultBlurView
      onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
        if (questionInFocus) {
          e.preventDefault();
        }
      }}
    >
      {children}
    </DefaultBlurView>
  );
};

export const TimePicker = ({
  date,
  onDateChange,
  minDate,
  maxDate,
  highlight,
}: RawDatePickersProps): ReactElement => {
  const { onFocus, onBlur, questionInFocus } = useInputFocusContext();

  return (
    <DefaultBlurBlocker questionInFocus={questionInFocus}>
      <ReactDatePicker
        onChange={(date: Date | null): void => {
          onDateChange(getDateWithoutMilliseconds(date));
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        selected={date}
        dateFormat={DatePickerDisplayFormat.TIME_FORMAT}
        placeholderText={DatePickerPlaceholder.TIME_PLACEHOLDER}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        minDate={minDate}
        maxDate={maxDate}
        customInput={
          <InputRenderProp
            data-testid={'TimePicker-input'}
            highlight={highlight}
          />
        }
        popperClassName="react-datepicker-popper-override"
      />
    </DefaultBlurBlocker>
  );
};

export const DatePicker = ({
  date,
  onDateChange,
  minDate,
  maxDate,
  highlight,
}: RawDatePickersProps): ReactElement => {
  const { onFocus, onBlur, questionInFocus } = useInputFocusContext();
  return (
    <DefaultBlurBlocker questionInFocus={questionInFocus}>
      <ReactDatePicker
        {...roles('DatePicker')}
        onChange={(date: Date | null): void => {
          onDateChange(
            getDateWithoutMilliseconds(
              date ? createTimezoneOffsetDate.not(date) : date
            )
          );
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        selected={date}
        dateFormat={DatePickerDisplayFormat.DATE_FORMAT}
        placeholderText={DatePickerPlaceholder.DATE_PLACEHOLDER}
        minDate={minDate ? createTimezoneOffsetDate(minDate) : minDate}
        maxDate={maxDate ? createTimezoneOffsetDate(maxDate) : maxDate}
        customInput={
          <InputRenderProp
            data-testid={'DatePicker-input'}
            highlight={highlight}
          />
        }
        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              className="react-datepicker__navigation react-datepicker__navigation--previous"
              onClick={decreaseMonth}
            />
            <div className="react-datepicker__current-month">
              {format(date, 'MMMM yyyy')}
            </div>

            <button
              className="react-datepicker__navigation react-datepicker__navigation--next"
              onClick={increaseMonth}
            />
          </div>
        )}
        popperClassName="react-datepicker-popper-override"
      />
    </DefaultBlurBlocker>
  );
};

export const DateTimePicker = ({
  date,
  onDateChange,
  minDate,
  maxDate,
  highlight,
}: RawDatePickersProps): ReactElement => {
  const { onFocus, onBlur, questionInFocus } = useInputFocusContext();
  // TODO: remove `z-index: auto !important` from index.html and replace this
  // code with the `inline` version of the selector and our own portal
  // WHEN: when we get time

  // set the minutes of minTime to 59 if the date is <= minDate. Otherwise set the minTime to 0
  const minimumDate = minDate ? minDate : new Date();
  let minTime = setMinutes(minimumDate, 59);
  if (date !== null && date > minimumDate) {
    minTime = setHours(setMinutes(new Date(), 0), 0);
  }

  return (
    <DefaultBlurBlocker questionInFocus={questionInFocus}>
      <ReactDatePicker
        onChange={(date: Date | null): void => {
          onDateChange(getDateWithoutMilliseconds(date));
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        selected={date}
        dateFormat={DatePickerDisplayFormat.DATE_TIME_FORMAT}
        placeholderText={DatePickerPlaceholder.DATE_TIME_PLACEHOLDER}
        showTimeSelect
        timeFormat={DatePickerDisplayFormat.TIME_FORMAT}
        timeIntervals={15}
        openToDate={minDate}
        minTime={minTime}
        maxTime={setHours(setMinutes(new Date(), 45), 23)}
        minDate={minDate}
        maxDate={maxDate}
        // This API is SO STUPID! Why use JSX like this? So anyway, we need to disable this error...
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        customInput={
          <InputRenderProp
            data-testid={'DateTimePicker-input'}
            highlight={highlight}
          />
        }
        // we're rendering a custom header because the built-in header isn't rendering as expected
        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              className="react-datepicker__navigation react-datepicker__navigation--previous"
              onClick={decreaseMonth}
            />
            <div className="react-datepicker__current-month">
              {format(date, 'MMMM yyyy')}
            </div>

            <button
              className="react-datepicker__navigation react-datepicker__navigation--next"
              onClick={increaseMonth}
            />
          </div>
        )}
        popperClassName="react-datepicker-popper-override"
      />
    </DefaultBlurBlocker>
  );
};
