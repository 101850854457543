import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';
import {
  FormTypes,
  SleepDiaryFormProps,
} from '@bighealth/types/src/scene-components/sleep-diary/entry-form';

export const get_diary_entry_form = async (
  args: get_diary_entry_form.Args
): Promise<get_diary_entry_form.Response> => {
  return await api({
    service_name: 'SleepDiary',
    service_version: '1',
    service_method: 'get_diary_entry_form',
    args: args,
  } as get_diary_entry_form.Request);
};

get_diary_entry_form.queryKey = 'SleepDiary/get_diary_entry_form';

export declare namespace get_diary_entry_form {
  export type Args = {
    // TODO make diary_date "undefined" for "create_from_weekly_average_estimate"
    // WHY more accurate
    // WHEN have time/appetite to fix downstream types in Scene component
    // no diary_date as this automatically chooses last 7 days from now
    // user_id: number; // Added by Request Context in Client Gateway
    form_type: FormTypes;
    diary_date?: {
      $date: string; // e.g. "2020-06-30"
    };
  };

  export interface Request extends APIRequestBody {
    service_name: 'SleepDiary';
    service_version: '1';
    service_method: 'get_diary_entry_form';
    args: Args;
  }

  export type Result = SleepDiaryFormProps;

  export type Response = APIResponse<Result>;
}
