import { ErrorText } from 'components/Text/ErrorText';
import { roles } from 'cross-platform/utils/roleProps';
import { getErrorString } from 'lib/error/getErrorString';
import React from 'react';
import styled from 'styled-components/native';
import { Loading } from './components/Loading';
import { useQueryAllForSessionScreen } from 'lib/api/reactQueryHelpers';
import { WithState } from 'components/Screens/SessionsScreen/components/WithState';

const Wrapper = styled.View`
  flex-grow: 1;
`;
/**
 * SessionScreen: Handles all network logic
 */
const WithNetworkAndState = (): React.ReactElement => {
  const { isLoading, data, error } = useQueryAllForSessionScreen();

  return (
    <Wrapper>
      {isLoading ? (
        <Loading {...roles('Loading')} />
      ) : typeof data?.result !== 'undefined' ? (
        <WithState {...roles('Data')} data={data?.result} />
      ) : null}
      {error ? (
        /**
         * TODO Use ErrorBoundary
         * WHEN clear about requirements
         * WHO Ash? Or someone else if we decide to get someone to test drive the error-handling components
         */
        <ErrorText {...roles('Error')}>
          {getErrorString(
            error?.result?.user_message
              ? Error(error.result.user_message)
              : undefined
          )}
        </ErrorText>
      ) : null}
    </Wrapper>
  );
};

export { WithNetworkAndState };
