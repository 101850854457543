import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'cross-platform/react-router';
import { showQAMenu } from 'lib/showQAMenu';
import { SleepioLogoBranding } from 'components/icons';
import { LoginFooter } from 'components/Footer';
import { useUpdateTitle } from 'lib/dom/useUpdateTitle';
import { roles } from 'cross-platform/utils/roleProps';
import {
  ButtonColorSchemes,
  ButtonSizes,
  UniversalButton,
} from 'components/UniveralButtons';
import {
  BackLinkContainer,
  BackText,
  ButtonContainer,
  Container,
  ErrorContainer,
  FormattedText,
  HeadingText,
  InlineLink,
  Input,
  InstructionText,
  LabelContainer,
  LimitWidth,
  LogoContainer,
  PaddingContainer,
  ValidationText,
  ForgotPasswordScroll,
} from 'components/Screens/ForgotPasswordScreen/styled';
import Link from 'components/Link';
import { sendRequestForForgotPassword } from 'lib/api';
import logger from 'lib/logger';
import { Linking } from 'react-native';
import { useQueryProduct } from 'lib/api/reactQueryHelpers';
import { ButtonTypes } from 'config/index';
import { ButtonContainerWithMinMax } from 'components/Button/components';

type Params = {
  productReference: string;
};

const Logo = ({ onPress }: { onPress: () => void }): ReactElement => {
  return (
    <LogoContainer testID={'developer-logo-button'} onPress={onPress}>
      <SleepioLogoBranding height={28} />
    </LogoContainer>
  );
};

export const copy = {
  missingEmail: 'Please enter your email',
  invalidEmail: 'Please enter a valid email',
  updateTitle: 'Forgot Password',
  forgotPasswordBackLink: '< Back to Forgot Password',
  loginLinkTitle: 'Back to Login',
  loginBackLink: '< Back to Login',
  successHeader: 'Check your email',
  pageHeader: 'Forgot your password?',
  instructionsBegin: 'Please check your email for further details. Contact ',
  emailTo: 'mailto:hello@sleepio.com',
  emailAddress: 'hello@sleepio.com',
  instructionsEnd: ' with any questions.',
  instructions:
    "Forgotten your password? No problem. Just enter the email you signed up with below and we'll send you a link to reset your password.",
  emailPlaceholder: 'Type your email here',
  emailLabel: 'Email',
  actionButtonText: 'Submit',
};

const emailInitialState = {
  isValid: false,
  value: '',
  isPresent: false,
  isSubmitted: false,
};

const ForgotPasswordScreen = (): ReactElement => {
  useUpdateTitle(copy.updateTitle);

  const [email, setEmail] = useState(emailInitialState);
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);
  const productId = useQueryProduct()?.data?.result.id;
  const { productReference } = useParams<Params>();
  const history = useHistory();

  const hasEmailPresentError = email.isSubmitted && !email.isPresent;
  const hasEmailValidError =
    email.isSubmitted && !email.isValid && email.isPresent;
  const showErrorMessage = hasEmailValidError || hasEmailPresentError;

  const onLogoPress = (): void => {
    showQAMenu(productReference as string, history);
  };

  const onChangeEmail = (value: string): void => {
    setEmail(prevState => ({
      ...prevState,
      value: value,
      isValid: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value),
      isPresent: value.length > 0,
      isSubmitted: false,
    }));
  };

  const handleSubmit = async (): Promise<void> => {
    setEmail(prevState => ({ ...prevState, isSubmitted: true }));

    if (email.isPresent && email.isValid) {
      try {
        await sendRequestForForgotPassword(email.value, productId as number);
        setSubmittedSuccessfully(true);
      } catch (e) {
        logger(e.message, e, { silent: true });
        setSubmittedSuccessfully(false);
      }
    }
  };

  const handleBackPress = () => {
    setEmail(emailInitialState);
    setSubmittedSuccessfully(false);
  };

  return (
    <Container>
      <ForgotPasswordScroll>
        <LimitWidth>
          <Logo onPress={onLogoPress} />

          {submittedSuccessfully ? (
            <BackLinkContainer
              {...roles('back-to-forgot-password-link')}
              onPress={handleBackPress}
            >
              <BackText>{copy.forgotPasswordBackLink}</BackText>
            </BackLinkContainer>
          ) : (
            <ButtonContainer>
              <Link
                {...roles('back-to-login-link')}
                to={`/${productReference}/login`}
                title={copy.loginLinkTitle}
              >
                <BackText>{copy.loginBackLink}</BackText>
              </Link>
            </ButtonContainer>
          )}

          <PaddingContainer>
            <HeadingText>
              {submittedSuccessfully ? copy.successHeader : copy.pageHeader}
            </HeadingText>
          </PaddingContainer>
          <PaddingContainer>
            {submittedSuccessfully ? (
              <InstructionText>
                {copy.instructionsBegin}
                <InlineLink
                  onPress={() => {
                    Linking.openURL(copy.emailTo);
                  }}
                >
                  {copy.emailAddress}
                </InlineLink>
                {copy.instructionsEnd}
              </InstructionText>
            ) : (
              <InstructionText>{copy.instructions}</InstructionText>
            )}
          </PaddingContainer>
          {!submittedSuccessfully && (
            <>
              <PaddingContainer>
                <LabelContainer>
                  <FormattedText>{copy.emailLabel}</FormattedText>
                </LabelContainer>
                <Input
                  {...roles('forgot-password-email-input')}
                  onChangeText={onChangeEmail}
                  placeholder={copy.emailPlaceholder}
                  placeholderTextColor={'#757575'}
                  keyboardType={'email-address'}
                  type={'email'}
                  hasErrors={showErrorMessage}
                  required={true}
                  autoCapitalize={'none'}
                  returnKeyType={'next'}
                  autoCorrect={false}
                  value={email.value}
                />
                {hasEmailPresentError ? (
                  <ErrorContainer>
                    <ValidationText>{copy.missingEmail}</ValidationText>
                  </ErrorContainer>
                ) : null}
                {hasEmailValidError ? (
                  <ErrorContainer>
                    <ValidationText>{copy.invalidEmail}</ValidationText>
                  </ErrorContainer>
                ) : null}
              </PaddingContainer>
              <ButtonContainerWithMinMax type={ButtonTypes.PRIMARY_BUTTON}>
                <UniversalButton
                  {...roles('action-button')}
                  onPress={handleSubmit}
                  text={copy.actionButtonText}
                  colorScheme={ButtonColorSchemes.Login}
                  size={ButtonSizes.Small}
                />
              </ButtonContainerWithMinMax>
            </>
          )}
        </LimitWidth>
        <LoginFooter />
      </ForgotPasswordScroll>
    </Container>
  );
};

export default ForgotPasswordScreen;
