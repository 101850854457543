/**
 * "Pipe" value, with integrated type-guard to ensure Array.map() have no undefined values
 * This keeps code neater.
 * @param el
 */
export const pipeOrThrowIfUndefined = <T,>(el: T): T | never => {
  if (typeof el === 'undefined') {
    throw TypeError(`Unexpected undefined array element`);
  }
  return el;
};

export function assertUnreachable(_: never): never {
  throw Error("Didn't expect to get here");
}

export const isStringArray = (
  array: number[] | string[] | Record<string, unknown>[] | boolean[]
): array is string[] => {
  return typeof array[0] === 'string';
};

export const isNumber = (val: unknown): val is number =>
  typeof val === 'number' && !isNaN(val);
