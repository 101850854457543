import React from 'react';
import {
  // CarouselMenu,
  ListMenu,
  MenuProps,
} from 'components/SceneSetGraphMenu';
import { ProductReferences } from 'common/constants/enums';
import { isDevMode } from 'lib/isDevMode';

export enum ButtonTypes {
  LARGE_CTA = 'LARGE_CTA',
  PRIMARY_BUTTON = 'PRIMARY_BUTTON',
  SECONDARY_BUTTON = 'SECONDARY_BUTTON',
  FACEBOOK_BUTTON = 'FACEBOOK_BUTTON',
  GOOGLE_BUTTON = 'GOOGLE_BUTTON',
}
export enum TextTypes {
  DEFAULT = 'DEFAULT',
  ERROR = 'ERROR',
}

type Copy = {
  splashText: string;
};

export const getTextForProduct = (productReference: string): Copy => {
  const isDaylight = productReference === ProductReferences.DAYLIGHT;
  return {
    splashText: isDaylight
      ? 'Your expert through tough times'
      : 'Continue your journey to better sleep',
  };
};

// IMPORTANT used to test PG-1066, error within SceneSet
let isDebugThrowInSceneSet = false;
export const getIsDebugThrowInSceneSet = (): boolean => {
  return isDebugThrowInSceneSet;
};
export const setIsDebugThrowInSceneSet = (val: boolean): void => {
  if (isDevMode()) {
    isDebugThrowInSceneSet = val;
  }
};

type Components = {
  [key: string]: React.FC<MenuProps>;
};

export const getComponentsForProduct = (
  productReference: string
): Components => {
  const isDaylight = productReference === ProductReferences.DAYLIGHT;
  return {
    Menu: isDaylight ? ListMenu : ListMenu,
  };
};

export { getAssetsForProduct } from './getAssetsForProduct';
