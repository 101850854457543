import React, { ReactElement } from 'react';
import Svg, { G, Ellipse, Polygon } from 'react-native-svg';

export const Grey = ({ size }: { size: number }): ReactElement => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 39 39">
    <G
      id="Plat-Gen-Sticker-Sheet-Sticker-Sheet"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <G id="Artboard" transform="translate(-293.000000, -247.000000)">
        <G id="Progress/Grey" transform="translate(293.000000, 247.000000)">
          <Ellipse
            id="Oval"
            fill="#9CAEB4"
            cx="19.3176047"
            cy="19.2"
            rx="19.3176047"
            ry="19.2"
          />
          <Polygon
            id="Path"
            fill="#FFFFFF"
            fillRule="nonzero"
            points="29.7748681 17.13024 29.7748681 13.44 9.01488221 13.44 9.01488221 17.13024"
          />
          <Polygon
            id="Path"
            fill="#FFFFFF"
            fillRule="nonzero"
            points="29.7748681 25.42336 29.7748681 21.69344 9.01488221 21.69344 9.01488221 25.42336"
          />
        </G>
      </G>
    </G>
  </Svg>
);
