import React, { ReactElement } from 'react';
import { Text as TextProps } from '@bighealth/types/src/scene-components/client';
import { Text as TextComponent } from 'components/Text';
import * as roleProps from 'cross-platform/utils/roleProps';
import { RoleProps } from 'cross-platform/utils/roleProps';
import { useTransformStylesToContext } from 'components/ResponsiveLayout';
import { ViewStyle, StyleSheet, Platform, TextStyle } from 'react-native';
import { getTextAlignmentConfig, getFontFamilyFromStyles } from './utils';
import { Scene } from '@bighealth/types/src/scene-component';

type Props = Omit<TextProps, 'text'>;

type CreateFontStylesArg = {
  italic?: boolean;
  underline?: boolean;
  styles?: Scene.Presentational.StyleObject;
};

type CreateFontStylesReturn = {
  fontStyle: 'italic' | 'normal';
  fontWeight?: TextStyle['fontWeight'];
  fontFamily: ReturnType<typeof getFontFamilyFromStyles>;
};

export const createFontStyles = ({
  italic,
  underline,
  styles,
}: CreateFontStylesArg): CreateFontStylesReturn => ({
  [Platform.OS == 'web' ? 'textDecoration' : 'textDecorationLine']: underline
    ? 'underline'
    : 'none',
  fontStyle: italic ? 'italic' : 'normal',
  fontWeight: (styles?.fontWeight || 400).toString() as TextStyle['fontWeight'],
  fontFamily: getFontFamilyFromStyles(styles, italic),
});

const TextPresentation = (
  props: React.PropsWithChildren<Props> & RoleProps
): ReactElement => {
  // IDEA remove Array.isArray
  // WHEN have time to deal with types
  // WHO Ash (or anyone)
  const flatStyles = Array.isArray(props.style)
    ? StyleSheet.flatten(props.style || {})
    : props.style;
  const scaleValues = useTransformStylesToContext();
  const scaledStyles = scaleValues(flatStyles);
  const alignmentStyles = getTextAlignmentConfig(props.alignment);

  if (props.style) {
    const { top, left, bottom, right } = props.style;
    if (top || left || bottom || right) {
      scaledStyles.position = 'absolute';
    }
  }

  return (
    <TextComponent
      {...roleProps.pass(props, undefined, { default: 'Text' })}
      style={StyleSheet.flatten({
        display: flatStyles?.display || 'flex',
        ...scaledStyles,
        ...alignmentStyles,
        ...createFontStyles({
          italic: props?.italic,
          underline: props?.underline,
          styles: flatStyles,
        }),
        color: scaledStyles.color,
      } as ViewStyle)}
      numberOfLines={props?.numberOfLines}
    >
      {props.children}
    </TextComponent>
  );
};

export { TextPresentation };
