import { useContext } from 'react';
import {
  MediaResponsiveLayoutContext,
  ScreenResponsiveLayoutContext,
} from '../providers';
import { PlayerControlsStyles, PlayerControlsStyleType } from '../constants';

type PlayerControlStyle = PlayerControlsStyleType & {
  outerHorizontalPadding: number;
};

export const useGetDynamicPlayerControlsStyles = (): PlayerControlStyle => {
  const { mediaWidth, mediaHeight } = useContext(MediaResponsiveLayoutContext);
  const { screenWidth, screenHeight } = useContext(
    ScreenResponsiveLayoutContext
  );

  const renderedMediaWidth = (screenHeight * mediaWidth) / mediaHeight;
  const mediaSpillsPastScreen = renderedMediaWidth > screenWidth;

  // This value was chosen by an in person design review.
  const scaleFactor = screenHeight / 800;

  const outerHorizontalPadding = mediaSpillsPastScreen
    ? 0
    : (screenWidth - renderedMediaWidth) / 2;

  const styles = {} as PlayerControlStyle;

  for (const [property, value] of Object.entries(PlayerControlsStyles)) {
    // We want to scale the ui elements, but as the screen gets bigger we don't want them to scale past a certain point.
    styles[property as keyof PlayerControlsStyleType] = Math.min(
      scaleFactor * value,
      value
    );
  }
  // These values are not direct scaling of ui and need to account for ui positioning.
  styles.outerHorizontalPadding = outerHorizontalPadding;
  styles.closeButtonLeft =
    styles.closeButtonLeft +
    (mediaSpillsPastScreen ? 0 : outerHorizontalPadding);
  return styles;
};
