import { useContext } from 'react';
import { useSafeArea } from 'react-native-safe-area-context';
import { ScreenResponsiveLayoutContext } from '../providers';
import { getModalWidth, getScaledValueForWidth } from '../helpers';
import { MODAL_REFERENCE_DIMENSION, ModalStyles } from '../constants';
import { Modal } from '@bighealth/types/src/scene-components/client';
import { FlexBoxValues } from 'components/layout/FlexBox/enums';

type ModalStyles = {
  maxWidth: number;
  minWidth: number;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
  paddingTop: number;
  width: number;
  borderRadius: number;
  marginRight: number;
  height: number | string;
  contentInset: number;
  alignModal: FlexBoxValues.alignCenter | FlexBoxValues.flexEnd;
};

type ModalStyle = Modal['style'];
type ModalAlignment = Modal['alignment'];

export const useGetDynamicModalStyles = (
  contentModalStyle?: ModalStyle,
  alignment?: ModalAlignment
): ModalStyles => {
  const insets = useSafeArea();
  const { screenWidth, screenHeight } = useContext(
    ScreenResponsiveLayoutContext
  );
  const isLarge = screenWidth > ModalStyles.largeScreenBreakpointPx;
  const isCenterAligned = alignment?.horizontal === 'middle';

  const minWidth = insets.right + ModalStyles.minWidthPx;
  const maxWidth = insets.right + ModalStyles.maxWidthPx;
  const width = getModalWidth(minWidth, maxWidth, screenWidth);

  const paddingLeft = getScaledValueForWidth(
    width,
    ModalStyles.modalHorizontalPaddingPx,
    contentModalStyle?.paddingLeft
  );
  const paddingRight = getScaledValueForWidth(
    width,
    ModalStyles.modalHorizontalPaddingPx,
    contentModalStyle?.paddingRight
  );

  const paddingTop = getScaledValueForWidth(
    width,
    ModalStyles.modalVerticalPaddingPx,
    contentModalStyle?.paddingTop
  );

  const paddingBottom = getScaledValueForWidth(
    width,
    ModalStyles.modalVerticalPaddingPx,
    contentModalStyle?.paddingBottom
  );

  const borderRadius = isLarge
    ? getScaledValueForWidth(
        width,
        ModalStyles.modalBorderRadiusPx,
        contentModalStyle?.borderRadius
      )
    : 0;

  const height = isLarge ? 'auto' : screenHeight;

  if (isCenterAligned && isLarge) {
    return {
      borderRadius,
      contentInset: 0,
      height,
      marginRight: 0,
      maxWidth: width,
      minWidth: width,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      width,
      alignModal: FlexBoxValues.alignCenter,
    };
  }

  const marginRight = isLarge
    ? (screenWidth * ModalStyles.modalBreakpointMarginRightPx) /
      MODAL_REFERENCE_DIMENSION
    : 0;

  return {
    borderRadius,
    height,
    marginRight,
    maxWidth: width,
    minWidth: width,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    width,
    contentInset: insets.right,
    alignModal: FlexBoxValues.flexEnd,
  };
};
