const getTestId = (obj: {
  'data-testid'?: string;
  testID?: string;
}): { 'data-testid'?: string } => ({
  'data-testid': obj['data-testid'],
});

// Return of this function uses both 'data-testid' and 'testID'
// as a safety precaution to have fallback values
const getTestIdValue = (obj: {
  'data-testid'?: string;
  testID?: string;
}): string => obj['data-testid'] || obj['testID'] || '';

export { getTestId, getTestIdValue };
