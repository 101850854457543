import { path } from 'lib/fp/path';
import { ThemeGetter } from '../types';

export const squareBracketRegEx = /\[([a-zA-Z_][a-zA-Z0-9_]{0,})\]/;

// @TODO: Work with (Sera?) on closing these any's out
// WHEN: When we circle round to handle existing anys.
export interface GetThemeProps extends Record<string, any> {
  theme: Record<string, any>; // Note: general use, no specific theme specified
}

export const get = (
  addr: string,
  theme: Record<string, unknown>,
  props: Record<string, unknown> = {}
): string | number | undefined => {
  const addrSplit = addr
    .split('.')
    .reduce((prev: string[], current: string): string[] => {
      if (squareBracketRegEx.test(current)) {
        const [, matched] = current.match(squareBracketRegEx) as string[];
        if (!props[matched]) {
          const themeStr = JSON.stringify(theme, null, 2);
          throw ReferenceError(
            `Could not find "${matched}" for address "${addr}" in ${themeStr}`
          );
        }
        return [
          ...prev,
          current.replace(squareBracketRegEx, ''),
          props[matched],
        ] as string[];
      }
      return [...prev, current];
    }, []);
  return path<ThemeValue | undefined>(addrSplit, theme);
};

export type ThemeValue = string | number;
/**
 * A convenience function for easily accessing theme values
 * when using styled component template strings.
 *
 * A js-only solution is a bit messy, as theme defaults to
 * an empty object. Using accessor strings ('a.b.d') also
 * allows for clearer error messages and better CTRL + F of
 *
 *
 *
 * usage:
 *
 *      styled.View`
 *        color: ${getTheme('control.interaction.textColor`)}
 *      `
 * js-only alternative
 *
 *      styled.View`
 *        color: ${props =>
 *          (Object.entries(theme).length
 *            ? props.theme.control.interaction.textColor
 *            : daylight.control.interaction.textColor)
 *        }
 *      `
 *
 * @param {string} addr
 *  lodash-style get accessor string e.g.`'a.b.c'`
 *  NOTE: array access such as 'a.b[0].c' will NOT work
 */
const getTheme = (addr: string): ThemeGetter =>
  /**
   * Creates an accessor function which can be called on props which may
   * contains theme. If no theme exists, defaults to the `daylight` theme
   *
   * @param {Object} props
   * @param {Object} props.theme
   */
  (props: GetThemeProps): ThemeValue => {
    if (!props) {
      throw ReferenceError(
        `Expected props to be object, instead got "${props}"`
      );
    }
    const theme = props.theme;
    if (!theme || typeof theme !== 'object') {
      throw ReferenceError(
        `Expected props.theme to be object, instead got "${theme}"`
      );
    }

    const val = get(addr, theme, props);
    if (typeof val === 'undefined') {
      const themeStr = JSON.stringify(theme, null, 2);
      throw ReferenceError(`Could not find "${addr}" in "${themeStr}"`);
    }
    return val;
  };

export { getTheme };
