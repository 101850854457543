import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import SceneSetView from 'components/SceneSetView';
// import Throbber from 'components/Throbber';
import { TitleScreen } from 'components/Screens';
import { StoryBook } from '../StoryBook';
import { Text, View } from 'react-native';
import { Container } from 'components/Screens/ContentScreens/components/ContentPlaceholder';
import { HomeScreen } from 'daylight/components/Screens/HomeScreen';
import { LoginScreen } from 'daylight/components/Screens/LoginScreen';
import { ProgressScreen } from 'daylight/components/Screens/ProgressScreen';
import { WithGlobalNavigation } from 'daylight/components/WithGlobalNavigation';
import { PracticeLibraryScreen } from 'daylight/components/Screens/PracticeLibraryScreen';
import { useIsFetching } from 'react-query';
import Throbber from 'components/Throbber';
import { createErrorBoundaryForDomain } from 'lib/error/utils/createErrorBoundaryForDomain';
import { DefaultErrorFallback } from 'lib/error/ErrorBoundary/components/DefaultErrorFallback/DefaultErrorFallback';
import { withContentPlaceholderWrapper } from 'lib/error/ErrorBoundary/utils/withContentPlaceholderWrapper/withContentPlaceholderWrapper';
import { Footer } from 'components/Footer';
import { VerticalScroll } from '../../VerticalScroll/VerticalScroll';
import { roles } from 'cross-platform/utils/roleProps';

const ErrorBoundaryForDaylightSwitch = createErrorBoundaryForDomain(
  'DaylightSwitch'
);

export const DaylightSwitch = (): ReactElement => {
  const fetchingCount = useIsFetching();
  return (
    <ErrorBoundaryForDaylightSwitch
      fallbackRenderWithWrapper={withContentPlaceholderWrapper}
    >
      <Switch>
        <Route path={'/:productReference'} exact>
          <TitleScreen />
        </Route>

        <Route path={'/:productReference/login'} exact>
          <LoginScreen />
        </Route>

        <Route path={'/:productReference/home'} exact>
          <WithGlobalNavigation>
            <HomeScreen />
          </WithGlobalNavigation>
        </Route>

        <Route path={'/:productReference/progress'} exact>
          <WithGlobalNavigation>
            <ProgressScreen />
          </WithGlobalNavigation>
        </Route>

        <Route path={'/:productReference/practice-library'} exact>
          <PracticeLibraryScreen />
        </Route>

        <Route path={'/:productReference/settings'} exact>
          <View>
            <Text>Settings screen of Daylight</Text>
          </View>
        </Route>

        <Route
          path={'/:productReference/888888/storybook'}
          component={StoryBook}
          exact
        />

        <Route path={'/:productReference/:sceneSetGraphId/:sceneSetId'} exact>
          <SceneSetView />
        </Route>

        <Route
          path={'/:productReference/888888/storybook/:story(.*)'}
          component={StoryBook}
        />

        <Route
          path={
            '/:productReference/:sceneSetGraphId/:sceneSetReference/:sceneId'
          }
          exact
        >
          <SceneSetView />
        </Route>
        <Route>
          {/* Not found / 404 */}
          <WithGlobalNavigation>
            <VerticalScroll>
              <Container>
                <DefaultErrorFallback />
              </Container>
              <Footer />
            </VerticalScroll>
          </WithGlobalNavigation>
        </Route>
      </Switch>
      {fetchingCount > 0 ? <Throbber {...roles('RootThrobber')} /> : null}
    </ErrorBoundaryForDaylightSwitch>
  );
};
