import React, { ReactNode } from 'react';
import styled from 'styled-components/native';
import { ThemeProp } from 'config/getThemeForProducts';
import { getTheme } from 'lib/styles/getTheme';
import { useSafeArea } from 'react-native-safe-area-context';

export const Container = styled.View`
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex: 1;
  width: 100%;
`;

export const ScrollableContainer = styled.ScrollView``;

export const Overlay = styled.View`
  display: flex;
  flex: 1;
  width: 100%;
  background-color: white;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  box-shadow: 0 0 13px #515151;
  border-radius: 10px;
`;

export const FillAbsolute = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

export const FillAbsoluteCenter = styled(FillAbsolute)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AppContainer = styled.View<ThemeProp>`
  background-color: ${getTheme('overlay.backgroundColor')};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FillWithInsetsContainer = styled.View`
  position: absolute;
`;

export const FillWithInsets: React.FC = ({
  children,
  ...rest
}: {
  children?: ReactNode;
}) => {
  const { top, left, bottom, right } = useSafeArea();
  return (
    <FillWithInsetsContainer
      style={{
        top,
        left,
        bottom,
        right,
      }}
      {...rest}
    >
      {children}
    </FillWithInsetsContainer>
  );
};
