import React from 'react';
import { View } from 'react-native';
import { addStory } from 'lib/story-books';
import { DefaultErrorFallback } from '../ErrorBoundary/components/DefaultErrorFallback/DefaultErrorFallback';
import { ThemeProvider } from 'styled-components/native';
import { ProductReferences } from 'common/constants/enums';
import { getThemeForProduct } from 'config/getThemeForProducts';

addStory('error-handling/default-error-handler', () => {
  const theme = getThemeForProduct(ProductReferences.SLEEPIO);
  return (
    <ThemeProvider theme={theme}>
      <View
        style={{
          display: 'flex',
          width: '100%',
        }}
      >
        <DefaultErrorFallback
          error={Error('ok')}
          resetErrorBoundary={console.log}
        />
      </View>
    </ThemeProvider>
  );
});
