import React, { ReactElement } from 'react';
import { ScrollView, View } from 'react-native';

import { useSafeParams } from 'components/Routes/useSafeParams';
import { Text } from 'components/Text';

import { useHistory } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';

import logger from 'lib/logger';

import { PracticeItem } from './PracticeItem';
import { XIcon } from './icons/XIcon';

import { useTransformContentStyle } from 'daylight/components/ResponsiveLayout/useTransformContentStyle';
import { HEIGHT_TOP_BAR, TEXT_COLOR_DARK } from 'daylight/config';
import { PracticeItemPayload } from 'daylight/lib/api/types/apiResponses';
import {
  FetchState,
  useFetchPracticeLibraryScreenData,
} from './hooks/useFetchPracticeLibraryScreenData';
import { useQueryProgram } from 'lib/api/reactQueryHelpers';

const Header = ({
  handleXIcon,
  headerSize,
  iconSize,
}: {
  handleXIcon: () => void;
  headerSize: number;
  iconSize: number;
}) => {
  return (
    <View
      style={{
        backgroundColor: 'white',
        height: HEIGHT_TOP_BAR,
        paddingBottom: '5%',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <View>
        <Text style={{ fontSize: headerSize, color: TEXT_COLOR_DARK }}>
          Practice Library
        </Text>
      </View>

      <View
        style={{
          position: 'absolute',
          right: '9%',
          bottom: '20%',
        }}
      >
        <XIcon size={iconSize} color={'#4A4A4A'} onPress={handleXIcon} />
      </View>
    </View>
  );
};

export const PracticeLibraryScreen = (): ReactElement => {
  const history = useHistory();
  const { productReference } = useSafeParams();
  const program = useQueryProgram()?.data?.result;
  const transformStyle = useTransformContentStyle();

  // TODO: query the backend API for the Practice Library and remove this hardcoded payload
  // WHEN: DP-135 is done
  // const payload = PracticeLibraryMockPayload;
  const response = useFetchPracticeLibraryScreenData({
    programId: program?.id,
  });

  const handleXIcon = async (): Promise<void> => {
    try {
      history.push(`/${productReference}/home`);
    } catch (e) {
      logger(`error in the navigation to home screen`, e);
    }
  };

  let message = undefined;
  let payload = undefined;

  switch (response.state) {
    case FetchState.Loading:
      message = 'Loading...';
      break;
    case FetchState.Error:
      message = response.error.message;
      break;
    case FetchState.GateMessage:
      message = response.payload.warning_message;
      break;
    case FetchState.Success:
      payload = response.payload;
  }

  if (typeof payload === 'undefined') {
    return (
      <View
        {...roles('practiceLibraryMessage')}
        style={{
          flex: 1,
          backgroundColor: '#EDEDED',
        }}
      >
        <Header
          handleXIcon={handleXIcon}
          headerSize={transformStyle(24)}
          iconSize={transformStyle(40)}
        />
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text>{message}</Text>
        </View>
      </View>
    );
  }

  return (
    <View
      {...roles('practiceLibrary')}
      style={{ flex: 1, backgroundColor: '#EDEDED' }}
    >
      <Header
        handleXIcon={handleXIcon}
        headerSize={transformStyle(24)}
        iconSize={transformStyle(40)}
      />

      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ paddingHorizontal: '2%' }}
      >
        {payload.groups.map((group, index) => (
          <View key={index}>
            <Text
              style={{
                fontSize: transformStyle(24),
                fontWeight: '700',
                textAlign: 'center',
                paddingVertical: transformStyle(32),
                color: '#000000',
              }}
            >
              {group.name}
            </Text>
            {group.practice_items.map((item, indexItem) => (
              <PracticeItem
                key={indexItem}
                practiceItem={item as PracticeItemPayload}
              />
            ))}
          </View>
        ))}
      </ScrollView>
    </View>
  );
};
