import React, { ReactElement, useEffect } from 'react';
import { useField } from 'formik';

import { roles } from 'cross-platform/utils/roleProps';
import { useQuizForCurrentScene } from 'lib/question-response/useQuizForCurrentScene';
import { useQuizForCurrentQuestion } from 'lib/question-response/useQuizForCurrentQuestion';
import {
  InputFieldPresentationalProps,
  InputFieldPresentational,
  SelectionFieldTypeData,
  TextFieldTypeData,
} from '../InputField/InputFieldPresentational';
import { createOnChange } from './utils/createOnChange';
import { DropdownItem } from 'components/generic-question/Dropdown';
import { ResponseOptionBase } from '@bighealth/types';

export type QuizInfo = {
  correct_response_ids: ResponseOptionBase['id'][];
  max_number_attempts: number;
};

export type InputFieldProps = {
  name: string;
  label: string;
  error?: string;
  value?: any;
  validate?: (value: DropdownItem[]) => string | undefined;
  quizInfo?: QuizInfo;
  fieldTypeProps: SelectionFieldTypeData | TextFieldTypeData;
} & Omit<
  InputFieldPresentationalProps,
  'onChange' | 'onChangeItem' | 'onBlur' | 'touched' | 'fieldTypes'
>;

const InputField = (props: InputFieldProps): ReactElement => {
  const [field, meta, helpers] = useField<DropdownItem[]>({
    ...props,
    multiple: true,
  });

  const quizForScene = useQuizForCurrentScene();
  const quizForQuestion = useQuizForCurrentQuestion(props.name);
  useEffect(() => {
    if (!meta.initialValue) {
      helpers.setValue([]);
    }
    quizForScene.registerQuizInfoForQuestion(props.name, props.quizInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputFieldPresentational
      {...props}
      {...roles.pass(props)}
      label={props.label}
      onChange={createOnChange(props, field, helpers)}
      onBlur={(): void => {
        field.onBlur(props.name);
        helpers.setTouched(true);
      }}
      value={field.value}
      fieldTypeProps={props.fieldTypeProps}
      touched={meta.touched}
      error={meta.error}
      getTextValue={(item: DropdownItem[]): string => {
        const itemValue = item?.[0]?.value;
        // NOTE remove type-guard WHEN use generics (or remove this)
        if (typeof itemValue !== 'string' && typeof itemValue !== 'undefined') {
          throw TypeError(
            `Expected "${itemValue}" to be string or undefined, instead got "${typeof itemValue}"`
          );
        }
        return itemValue;
      }}
      correctSelection={quizForQuestion.assessment?.correctSelection}
      incorrectSelection={quizForQuestion.assessment?.incorrectSelection}
    />
  );
};

export { InputField };
