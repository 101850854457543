import React, { ComponentProps } from 'react';
import { ErrorBoundary as ErrorBoundaryComponent } from 'lib/error/ErrorBoundary/ErrorBoundary';
import logger from 'lib/logger';
import { DefaultErrorFallback } from '../ErrorBoundary/components/DefaultErrorFallback/DefaultErrorFallback';

type ErrorBoundaryType = React.FC<
  ComponentProps<typeof ErrorBoundaryComponent>
>;
/**
 * *Component factory*
 *
 * @param {string} domain decorate name/error message
 * @returns ErrorBoundary
 */
export const createErrorBoundaryForDomain = (
  domain: string
): ErrorBoundaryType => {
  /**
   *
   * @param props
   * @returns
   */
  const ErrorBoundary: ErrorBoundaryType = props => {
    return (
      <ErrorBoundaryComponent
        fallbackRender={props => {
          return (
            <DefaultErrorFallback
              debugMessage={`ErrorBoundary for ${domain}`}
              error={props?.error}
            />
          );
        }}
        onError={(error: Error) => {
          logger(`${domain} - ${error}`, error, { silent: true });
        }}
        {...props}
      />
    );
  };
  ErrorBoundary.displayName = `ErrorBoundary(${domain})`;
  return ErrorBoundary;
};
