import { Theme } from 'config/getThemeForProducts';
import { GestureResponderEvent, StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { Text } from 'components/Text';
import React, { ReactElement, CSSProperties, PropsWithChildren } from 'react';
import { getTheme } from 'lib/styles/getTheme';
import { ButtonTypes } from 'common/constants/enums';

export type PressHandler = ((e: GestureResponderEvent) => void) | undefined;

export type EnhancedButtonProps<T = ViewStyle> = PropsWithChildren<{
  type: ButtonTypes;
  align?: string;
  onClick?: PressHandler;
  disabled?: boolean;
  theme: Theme;
  testId?: string;
  'data-testid'?: string;
  style?: CSSProperties | StyleProp<T>;
}>;

export const TextContainer = styled.View<EnhancedButtonProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: ${getTheme('controls[type].paddingHorizontal')}px;
  padding-right: ${getTheme('controls[type].paddingHorizontal')}px;
  padding-bottom: ${getTheme('controls[type].paddingVertical')}px;
  padding-top: ${getTheme('controls[type].paddingVertical')}px;
  background-color: ${getTheme('controls[type].backgroundColor')};
  border-radius: ${getTheme('controls[type].borderRadius')}px;
  border-width: ${getTheme('controls[type].borderWidth')};
  border-style: ${getTheme('controls[type].borderStyle')};
  border-color: ${getTheme('controls[type].borderColor')};
}
`;

export const ButtonText = styled(Text)<EnhancedButtonProps>`
  font-weight: bold;
  text-align: center;
  color: ${getTheme('controls[type].color')};
  font-size: ${getTheme('controls[type].fontSize')}px;
`;

export const linkStyles = `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ButtonInternals = ({
  type,
  text,
}: {
  type: ButtonTypes;
  text: string;
}): ReactElement => {
  return (
    <TextContainer type={type}>
      <ButtonText type={type} accessibilityLabel={text}>
        {text}
      </ButtonText>
    </TextContainer>
  );
};
