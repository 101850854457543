import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components/native';
import {
  ClickPosition,
  useWindowAwarePosition,
} from './useWindowAwarePosition';
import { MenuPortal } from './MenuPortal';
import { MenuDismisser } from './MenuDismisser';
import { dismissBoundaryOffset } from './dismissBoundaryOffset';

const MenuContainer = styled.View`
  background-color: #002141;
  border-radius: 8px;
  padding: 20px;
  min-width: 200px;
  margin-top: ${dismissBoundaryOffset}px;
`;

const DismissBoundary = styled.View``;

export const WindowAwareMenu = ({
  children,
  targetPosition,
  onDismiss,
}: {
  onDismiss: () => void;
  children: ReactNode;
  targetPosition: ClickPosition;
}): ReactElement => {
  const { handleLayout, top, left, isReady } = useWindowAwarePosition(
    targetPosition
  );
  return (
    <MenuPortal>
      <MenuDismisser onDismiss={onDismiss} />
      <DismissBoundary
        testID={'WindowAwareMenuBoundary'}
        style={{
          left,
          top: top - dismissBoundaryOffset,
          opacity: isReady ? 1 : 0,
        }}
      >
        <MenuContainer testID={'WindowAwareMenu'} onLayout={handleLayout}>
          {children}
        </MenuContainer>
      </DismissBoundary>
    </MenuPortal>
  );
};
