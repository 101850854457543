import React, { ReactElement, ReactNode } from 'react';
import { Dimensions } from 'react-native';

const initialWindowSize = Dimensions.get('window');

interface MediaContextType {
  mediaWidth: number;
  mediaHeight: number;
}

const defaultContext: MediaContextType = {
  mediaWidth: initialWindowSize.width,
  mediaHeight: initialWindowSize.height,
};

export const MediaResponsiveLayoutContext = React.createContext(defaultContext);

export const MediaResponsiveLayoutProvider = ({
  children,
  height,
  width,
}: {
  children?: ReactNode;
  height: number;
  width: number;
}): ReactElement => {
  return (
    <MediaResponsiveLayoutContext.Provider
      value={{
        mediaHeight: height,
        mediaWidth: width,
      }}
    >
      {children}
    </MediaResponsiveLayoutContext.Provider>
  );
};
