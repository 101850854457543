import React, { ReactElement } from 'react';
import { CommonPropType } from 'components/generic-question/index.story';
import { DateTimePicker } from '..';

export const DateTimePickerStory = (props: CommonPropType): ReactElement => (
  <DateTimePicker
    date={new Date(0)}
    onDateChange={console.log}
    highlight={props.highlight}
  />
);
