import { useEffect, useState } from 'react';
import { useWithResponseHooks } from 'lib/api/hooks';

import { fetchHomeScreenData as fetch } from 'daylight/lib/api';
import { DaylightHomeScreenPayload } from 'daylight/lib/api/types/apiResponses';
import logger from 'lib/logger';

export const useFetchHomeScreenData = ({
  productId,
  programId,
}: {
  productId: number;
  programId: number | undefined;
}): [false, DaylightHomeScreenPayload] | [true, undefined] => {
  const [data, setData] = useState<DaylightHomeScreenPayload | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(true);
  const fetchHomeScreenData = useWithResponseHooks(fetch);

  useEffect(() => {
    const fetchData = async ({
      productId,
      programId,
    }: {
      programId: number;
      productId: number;
    }) => {
      try {
        const homeScreenData = await fetchHomeScreenData({
          productId: productId,
          programId: programId,
        });
        setData(homeScreenData.result);
      } catch (e) {
        logger('Could not Daylight home screen data');
      } finally {
        setIsLoading(false);
      }
    };

    if (typeof programId !== 'undefined' && !data && isLoading) {
      fetchData({ productId: productId, programId: programId });
    }
  }, [data, fetchHomeScreenData, isLoading, productId, programId]);
  if (isLoading && !data) {
    return [true, undefined];
  }
  return [false, data as DaylightHomeScreenPayload];
};
