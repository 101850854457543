import React, { ReactElement } from 'react';
import { Goals } from 'components/Goals';
import styled from 'styled-components/native';
import { FillAbsolute, FillAbsoluteCenter } from 'components/Containers';
import { Text, View } from 'react-native';
import { FontFamilies } from '../Text/fonts';
import {
  ScalingContext,
  ScalingContextProvider,
} from 'components/ResponsiveLayout';
import { useGoalsScalingStyles } from 'components/Goals/useGoalsScalingStyles';

export const Container = styled(FillAbsolute)`
  align-items: center;
`;

export const GoalsContainer = (): ReactElement => {
  const goalStyles = useGoalsScalingStyles();
  return (
    <Container pointerEvents={'none'}>
      <FillAbsoluteCenter>
        <View
          style={{
            position: 'absolute',
            justifyContent: 'center',
            width: goalStyles.containerWidth,
          }}
        >
          <Text
            style={{
              fontSize: goalStyles.sceneTitleFontSize,
              fontFamily: `'${FontFamilies.Bold}'`,
              marginBottom: goalStyles.sceneTitleMarginBottom,
            }}
          >
            Your progress on goals
          </Text>
          <Goals />
        </View>
      </FillAbsoluteCenter>
    </Container>
  );
};

export const GoalsSceneComponent = (): ReactElement => {
  return (
    <ScalingContextProvider scalingContext={ScalingContext.MediaContainer}>
      <GoalsContainer />
    </ScalingContextProvider>
  );
};
