import { Article } from '@bighealth/types';
import { APIRequestBody, APIResponse } from '../../types';
import { api } from '../../api';

export const read_full_article = async (
  args: read_full_article.Args
): Promise<read_full_article.Response> => {
  return await api({
    service_name: 'LibraryArticle',
    service_version: '1',
    service_method: 'read_full_article',
    args: args,
  } as read_full_article.Request);
};

read_full_article.queryKey = 'LibraryArticle/read_full_article';

export declare namespace read_full_article {
  export type Args = {
    entity_id: number;
  };

  export interface Request extends APIRequestBody {
    service_name: 'LibraryArticle';
    service_version: '1';
    service_method: 'read_full_article';
    args: Args;
  }

  export type Result = Article;

  export type Response = APIResponse<Result>;
}
