import { useEffect, useRef } from 'react';
import { Animated } from 'react-native';

export const usePulsingOpacityAnimation = (
  animationDurationSeconds?: number
): Animated.Value => {
  const fadeAnimation = useRef(new Animated.Value(0)).current;
  const animationDurationMillis = (animationDurationSeconds || 0.75) * 1000;
  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(fadeAnimation, {
          toValue: 1,
          duration: animationDurationMillis,
        }),

        Animated.timing(fadeAnimation, {
          toValue: 0,
          duration: animationDurationMillis,
        }),
      ])
    ).start();
  }, [fadeAnimation, animationDurationMillis]);
  return fadeAnimation;
};
